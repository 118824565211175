<template>
  <div>
    <div v-if="isSaved" class="flex items-center"
         :class="isMobile ? 'p-2 text-sm' : 'px-5 py-3 text-base font-medium'"
         style="color: #00C259; background: #00C25933; border-radius: 4px; box-shadow: 0px 4px 4px 0px #BDBDBD2B;"
    >
      <a-icon type="check-circle" class="mr-1" :style="isMobile ? 'font-size: 16px' : 'font-size: 18px'"/>
      Báo cáo đã lưu
    </div>
    <div v-else class="flex items-center cursor-pointer hover:inner-shadow"
         :class="isMobile ? 'p-2 text-sm' : 'px-5 py-3 text-base font-medium'"
         style="background: rgba(103, 159, 246, 0.3); color: rgba(46, 124, 246, 1); border-radius: 4px; box-shadow: 0px 4px 4px 0px rgba(189, 189, 189, 0.17);"
         @click="onClickSaveReport"
    >
      <a-icon v-if="isSaving" class="mr-1" type="loading" :style="isMobile ? 'font-size: 16px' : 'font-size: 18px'"></a-icon>
      <IconSave v-else :height="isMobile ? 16 : 20" :width="isMobile ? 16 : 20" class="mr-1"/>
      Lưu báo cáo
    </div>
    <a-modal title="Lưu Báo cáo"
             :closable="!isSaving"
             :centered="true"
             :footer="null"
             :bodyStyle="{padding: '20px', paddingTop: '8px'}"
             v-model="showPopupSaveReport"
             :maskClosable="!isSaving">
      <p class="text-right" style="font-size: 10px; color: #A5A5A5;">
        <span :class="savedName.length > MAX_LENGTH_OF_REPORT_NAME ? 'text-red-500 text-xs' : ''">{{savedName.length}}</span>/{{MAX_LENGTH_OF_REPORT_NAME}}
      </p>
      <a-input placeholder="Tạo tên cho Báo cáo" v-model="savedName"/>
      <p v-if="errorCreate" class="mt-1 text-red-500">{{errorCreate}}</p>
      <div v-if="listSavedReport && listSavedReport.length" class="mt-5">
        <p class="font-medium text-base">Báo cáo đã lưu</p>
        <p class="text-note mt-1">(Có thể chọn một trong danh sách phía dưới để ghi đè Báo cáo)</p>
        <div class="table-saved-report w-full hide-scrollbar">
          <div class="table-header-row flex sticky top-0 bg-white" :class="isMobile ? 'text-sm' : 'text-base'">
            <div class="table-header-cell font-medium flex-grow-1">Tên Báo cáo</div>
            <div class="table-header-cell created-time-column font-medium" style="width: 30%; min-width: 100px">Ngày lưu</div>
            <div class="table-header-cell action-column" style="min-width: 64px"></div>
          </div>
          <div class="slide-tb-custom table-content-row-container">
            <div v-for="report in listSavedReport" :key="report.report_hash" class="table-content-row flex items-center">
              <div class="table-content-cell flex-grow-1"><p class="line-clamp__2">{{report.data.report_name}}</p></div>
              <div class="table-content-cell created-time-column" style="width: 30%; min-width: 100px">{{new Date(report.data.created_at).valueOf() | timestampToDate}}</div>
              <div class="table-content-cell action-column flex-shrink-0" style="min-width: 64px">
                <div class="px-3 py-1 border"
                     :class="isSaving ? 'border-gray-200 text-gray-200 bg-gray-50 cursor-not-allowed' : 'border-primary-500 text-primary-500 cursor-pointer'"
                     style="border-radius: 3px; min-width: 64px"
                     @click="onClickOverwrite(report.report_hash)"
                >
                  Ghi đè
                </div>
              </div>
            </div>
          </div>
          <div v-show="!isFullSavedReport" class="text-center w-full" id="load-saved-report">
            <a-icon type="loading" />
          </div>
        </div>
        <p v-if="errorOverwrite" class="mt-1 text-red-500">{{errorOverwrite}}</p>
      </div>
      <div class="flex justify-end mt-5">
        <a-button class="mr-2 text-gray-400" @click="closePopupSaveReport" :disabled="isSaving">
          Hủy
        </a-button>
        <a-button type="primary" @click="saveReport" :disabled="isSaving">
          <a-icon v-if="isSaving" class="mr-1" type="loading"></a-icon>
          Lưu Báo cáo
        </a-button>
      </div>
    </a-modal>
    <PopupConfirm
      ok-text="Đồng ý"
      text="Bạn muốn ghi đè Báo cáo đã lưu?"
      :is-shown-popup="!!overwriteReportHash"
      @cancel="overwriteReportHash = null"
      @ok="overwriteReport(overwriteReportHash)"
    />
    <a-modal :closable="true"
             :footer="null"
             :centered="true"
             v-model="showSuccessPopup"
             :maskClosable="true"
             width="450px"
    >
      <div class="flex flex-col justify-center items-center w-full my-4">
        <svg width="105" height="104" viewBox="0 0 105 104" fill="none" xmlns="http://www.w3.org/2000/svg">
          <ellipse cx="52.089" cy="52.1987" rx="52.089" ry="51.8012" fill="#00C259" fill-opacity="0.5"/>
          <path d="M52.0901 93.6391C75.1362 93.6391 93.8188 74.9565 93.8188 51.9104C93.8188 28.8642 75.1362 10.1816 52.0901 10.1816C29.0439 10.1816 10.3613 28.8642 10.3613 51.9104C10.3613 74.9565 29.0439 93.6391 52.0901 93.6391Z" fill="#00C259"/>
          <path d="M45.4707 71.2609L65.0357 90.7611C81.2398 86.4543 93.2429 71.7395 93.2429 54.1534C93.2429 53.7945 93.2429 53.4356 93.2429 53.0767L77.879 38.96L45.4707 71.2609Z" fill="#1EAC60"/>
          <path d="M53.066 60.6C54.7747 62.3087 54.7747 65.2378 53.066 66.9465L49.5266 70.4859C47.8179 72.1946 44.8888 72.1946 43.1801 70.4859L27.6799 54.8637C25.9713 53.155 25.9713 50.2259 27.6799 48.5172L31.2194 44.9778C32.928 43.2691 35.8572 43.2691 37.5659 44.9778L53.066 60.6Z" fill="white"/>
          <path d="M66.6126 33.6272C68.3213 31.9185 71.2505 31.9185 72.9591 33.6272L76.4985 37.1666C78.2072 38.8753 78.2072 41.8045 76.4985 43.5131L49.6479 70.2418C47.9392 71.9504 45.0101 71.9504 43.3014 70.2418L39.762 66.7023C38.0533 64.9937 38.0533 62.0645 39.762 60.3558L66.6126 33.6272Z" fill="white"/>
        </svg>
        <p class="text-green-500 font-medium margin-top-25">Lưu báo cáo thành công!</p>
        <p class="mt-4 text-sm">Xem Danh sách Báo cáo đã lưu <span class="text-primary-500 cursor-pointer" @click="onClickListReport">tại đây</span></p>
      </div>
    </a-modal>
    <a-modal :closable="true"
             :footer="null"
             :centered="true"
             v-model="showSuccessPopup"
             :maskClosable="true"
             width="450px"
    >
      <div class="flex flex-col justify-center items-center w-full my-4">
        <svg width="105" height="104" viewBox="0 0 105 104" fill="none" xmlns="http://www.w3.org/2000/svg">
          <ellipse cx="52.089" cy="52.1987" rx="52.089" ry="51.8012" fill="#00C259" fill-opacity="0.5"/>
          <path d="M52.0901 93.6391C75.1362 93.6391 93.8188 74.9565 93.8188 51.9104C93.8188 28.8642 75.1362 10.1816 52.0901 10.1816C29.0439 10.1816 10.3613 28.8642 10.3613 51.9104C10.3613 74.9565 29.0439 93.6391 52.0901 93.6391Z" fill="#00C259"/>
          <path d="M45.4707 71.2609L65.0357 90.7611C81.2398 86.4543 93.2429 71.7395 93.2429 54.1534C93.2429 53.7945 93.2429 53.4356 93.2429 53.0767L77.879 38.96L45.4707 71.2609Z" fill="#1EAC60"/>
          <path d="M53.066 60.6C54.7747 62.3087 54.7747 65.2378 53.066 66.9465L49.5266 70.4859C47.8179 72.1946 44.8888 72.1946 43.1801 70.4859L27.6799 54.8637C25.9713 53.155 25.9713 50.2259 27.6799 48.5172L31.2194 44.9778C32.928 43.2691 35.8572 43.2691 37.5659 44.9778L53.066 60.6Z" fill="white"/>
          <path d="M66.6126 33.6272C68.3213 31.9185 71.2505 31.9185 72.9591 33.6272L76.4985 37.1666C78.2072 38.8753 78.2072 41.8045 76.4985 43.5131L49.6479 70.2418C47.9392 71.9504 45.0101 71.9504 43.3014 70.2418L39.762 66.7023C38.0533 64.9937 38.0533 62.0645 39.762 60.3558L66.6126 33.6272Z" fill="white"/>
        </svg>
        <p class="text-green-500 font-medium margin-top-25">Lưu báo cáo thành công!</p>
        <p class="mt-4 text-sm">Xem Danh sách Báo cáo đã lưu <span class="text-primary-500 cursor-pointer" @click="onClickListReport">tại đây</span></p>
      </div>
    </a-modal>
    <PopupNotification v-if="showPopupNotification === NOTIFICATION_TYPE.out_of_saved_report"
                       title="Đã hết lượt lưu báo cáo"
                       :contents="['Tài khoản của bản đã hết lượt lưu báo cáo', 'Vui lòng liên hệ hỗ trợ để thêm thông tin']"
                       ok-title="OK"
    >
    </PopupNotification>
  </div>
</template>

<script>
import IconSave from "@/assets/icon/IconSave";
import {getListSavedReport, saveReport, updateSavedReport} from "@/service/report/ReportService";
import {timestampToDate} from "@/helper/FormatHelper";
import PopupConfirm from "@/views/popup/PopupConfirm";
import {MAX_LENGTH_OF_REPORT_NAME, NOTIFICATION_TYPE} from "@/constant/general/GeneralConstant";
import {mixinStoreGetterGlobal} from "@/mixin/store/MixinGlobal";
import {MutationGeneral} from "@/store/modules/GeneralModule/mutation";
import PopupNotification from "@/views/popup/PopupNotification";
import {PERMISSION} from "@/constant/general/PermissionConstant";
import {mixinStoreGetterPermission} from "@/mixin/store/MixinUser";
import {ALL_FIXED_DURATION_DAY} from "@/constant/search/SearchConstant";

export default {
  name: "SaveReportComponent",
  components: {
    IconSave,
    PopupConfirm,
    PopupNotification
  },
  mixins: [
    mixinStoreGetterGlobal,
    mixinStoreGetterPermission
  ],
  props: {
    isSavedDefault: {
      default: false
    }
  },
  computed: {
    showPopupNotification: {
      get() {
        return this.$store.state.GeneralModule.showPopupNotification
      },
      async set(value) {
        await this.$store.commit(`GeneralModule/${MutationGeneral.setShowPopupNotification}`, value)
      }
    },
    email: {
      get() {
        return this.$store.state.UserModule.email
      }
    },
  },
  methods: {
    async saveReport() {
      if (!this.savedName) {
        this.errorCreate = 'Vui lòng nhập tên Báo cáo!'
        return
      }
      if (this.savedName.length > MAX_LENGTH_OF_REPORT_NAME) {
        this.errorCreate = 'Tên báo cáo quá dài, vui lòng rút ngắn tên Báo cáo!'
        return
      }
      this.isSaving = true
      this.errorCreate = null
      let options = this.$store.state.SearchFilterModule.options
      options.durationDay = 0
      let saveOption = JSON.parse(JSON.stringify(options))
      for (const fixedDurationDay of ALL_FIXED_DURATION_DAY) {
        let orderCountRange = saveOption.include_query[`order_count_${fixedDurationDay}d_range`]
        if (orderCountRange && orderCountRange.is_default_from) {
          saveOption.include_query['order_count_custom_range'] = {
            ...orderCountRange
          }
          delete saveOption.include_query[`order_count_${fixedDurationDay}d_range`]
          break
        }
      }
      let reportHash = await saveReport(this.savedName, saveOption)
      this.isSaving = false
      if (!reportHash || (reportHash.status !== 'success' && reportHash.status !== '200')) {
        if (reportHash.message === 'duplicate name') {
          this.errorCreate = 'Tên báo cáo đã tồn tại, xin vui lòng đổi tên khác với các báo cáo đã lưu!'
          return
        }
        this.errorCreate = 'Chưa thể lưu báo cáo, xin vui lòng thử lại sau!'
        return
      }
      this.isSaved = true
      this.showSuccessPopup = true
      this.$emit('saved', reportHash)
      this.closePopupSaveReport()
    },
    async overwriteReport(overwrittenReportHash) {
      this.isSaving = true
      let options = this.$store.state.SearchFilterModule.options
      let reportHash = await updateSavedReport(overwrittenReportHash, null, options)
      this.isSaving = false
      if (!reportHash || (reportHash.status !== 'success' && reportHash.status !== '200')) {
        if (reportHash.message === 'duplicate name') {
          this.errorOverwrite = 'Tên báo cáo đã tồn tại, xin vui lòng đổi tên khác với các báo cáo đã lưu!'
          return
        }
        this.errorOverwrite = 'Chưa thể ghi đè báo cáo, xin vui lòng thử lại sau!'
        return
      }
      this.isSaved = true
      this.overwriteReportHash = null
      this.showSuccessPopup = true
      this.$emit('overwritten', reportHash)
      this.closePopupSaveReport()
    },
    onClickListReport() {
      this.$router.push({name: 'saved_report_view'})
    },
    onClickOverwrite(reportHash) {
      if (this.isSaving) {
        return
      }
      this.overwriteReportHash = reportHash
    },
    async onClickSaveReport() {
      if (!this.isStandardBAccount){
        if (!this.isAgencyOrHigherPriorityAccount || typeof PERMISSION[this.permissionAvailableName].limitSavedReport !== 'number') {
          this.$store.dispatch(`${MutationGeneral.MODULE_PATH}/${MutationGeneral.setShowPopupNotification}`, NOTIFICATION_TYPE.upgrade_company_permission)
          return
        }
      }
      const SPECIAL_EMAIL = 'jane.nguyen@thegioiskinfood.com' // todo: remove
      const IS_VIP_EMAIL = 'jane.nguyen@thegioiskinfood.com' === this.email
      const LIMIT_OF_REPORT = IS_VIP_EMAIL ? 50 : PERMISSION[this.permissionAvailableName].limitSavedReport
      this.listSavedReport = await getListSavedReport(this.pageSavedReport, LIMIT_OF_REPORT + 1)
      if (this.listSavedReport.length >= LIMIT_OF_REPORT) {
        this.$store.dispatch(`${MutationGeneral.MODULE_PATH}/${MutationGeneral.setShowPopupNotification}`, NOTIFICATION_TYPE.out_of_saved_report)
        return;
      }
      this.showPopupSaveReport = true
      setTimeout(this.createIntersectionObserver, 0)
    },
    createIntersectionObserver() {
      let observer = new IntersectionObserver(this.handleIntersection);
      observer.observe(document.getElementById('load-saved-report'));
      this.observer = observer;
    },
    closePopupSaveReport() {
      this.showPopupSaveReport = false
      this.observer?.disconnect();
    },
    async handleIntersection(entries, observer) {
      for (let entry of entries) {
        if (!entry.isIntersecting) {
          return
        }
        switch (entry.target.id) {
          case 'load-saved-report':
            await this.loadMoreSavedReport()
            break
        }
      }
    },
    async loadMoreSavedReport() {
      this.pageSavedReport++
      const nextSavedReport = await getListSavedReport(this.pageSavedReport, 10)
      if (!nextSavedReport?.length) {
        this.isFullSavedReport = true
        return
      }
      this.listSavedReport.push(
        ...nextSavedReport
      )
    }
  },
  filters: {
    timestampToDate
  },
  data() {
    return {
      isSaving: false,
      isSaved: this.isSavedDefault,
      showPopupSaveReport: false,
      showSuccessPopup: false,
      overwriteReportHash: null,
      errorOverwrite: '',
      errorCreate: '',
      listSavedReport: null,
      savedName: '',
      MAX_LENGTH_OF_REPORT_NAME,
      NOTIFICATION_TYPE,
      observer: null,
      pageSavedReport: 1,
      isFullSavedReport: false
    }
  },
  async mounted() {
    // this.listSavedReport = await getListSavedReport(this.pageSavedReport, 10)
  }
}
</script>

<style scoped lang="scss">
.text-note {
  font-size: 12px;
  color: #9C9C9C;
}
.table-saved-report {
  display: block;
  max-height: 240px;
  overflow: scroll;

  .table-header-row {
    position: sticky;
    top: 0;
    left: 0;
    z-index: 2;
    background-color: white;
    border-bottom: 1px #1B1B1B solid;

    .table-header-cell {
      padding: 12px;
    }

    @media screen and (max-width: 900px) {
      .table-header-cell {
        padding: 12px 12px 12px 0;
      }
    }
  }
  .table-content-row-container {
    z-index: 1;
    .table-content-row {
      height: 50px;
      padding: 8px 0;
      border-bottom: 1px dashed;
      border-color: rgba(gray, 60%);

      &:hover {
        background: #FF72270D;
      }

      .table-content-cell {
        padding: 12px;
      }

      @media screen and (max-width: 900px) {
        .table-content-cell {
          padding: 12px 12px 12px 0;
        }
      }
    }
  }

  .created-time-column {
    width: 165px;
  }
  .action-column {
    width: 95px;
  }
}

.hover\:inner-shadow:hover {
  box-shadow: -4px 4px 4px 0px #B9D3FB9C inset !important;
}

.slide-tb-custom {
  animation: slide-tb ease-in-out 1000ms;
}

@keyframes slide-tb {
  0% {
    transform: translateY(-20%);
  }
  90% {
    transform: translateY(20px);
  }
  100% {
    transform: translateY(0%);
  }
}
</style>
