<template>
  <svg @click="$emit('click')" width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M5.14282 6.85742H22.2857V8.57171H5.14282V6.85742Z" fill="currentColor"/>
    <path d="M5.14282 12H22.2857V13.7143H5.14282V12Z" fill="currentColor"/>
    <path d="M5.14282 17.1426H22.2857V18.8569H5.14282V17.1426Z" fill="currentColor"/>
    <path d="M1.85008 8.81051C2.68955 8.81051 3.37008 8.12998 3.37008 7.29051C3.37008 6.45103 2.68955 5.77051 1.85008 5.77051C1.01061 5.77051 0.330078 6.45103 0.330078 7.29051C0.330078 8.12998 1.01061 8.81051 1.85008 8.81051Z" fill="currentColor"/>
    <path d="M1.85008 14.0996C2.68955 14.0996 3.37008 13.419 3.37008 12.5796C3.37008 11.7401 2.68955 11.0596 1.85008 11.0596C1.01061 11.0596 0.330078 11.7401 0.330078 12.5796C0.330078 13.419 1.01061 14.0996 1.85008 14.0996Z" fill="currentColor"/>
    <path d="M1.85008 19.3906C2.68955 19.3906 3.37008 18.7101 3.37008 17.8706C3.37008 17.0311 2.68955 16.3506 1.85008 16.3506C1.01061 16.3506 0.330078 17.0311 0.330078 17.8706C0.330078 18.7101 1.01061 19.3906 1.85008 19.3906Z" fill="currentColor"/>
  </svg>
</template>

<script>
export default {
  name: "IconList"
}
</script>

<style scoped>

</style>
