<template>
  <div>
    <div class="table-keyword" :class="isMobile ? 'mobile-view' : ''">
      <a-table
        :tableLayout="isMobile ? 'fixed' : ''"
        :columns="columns"
        :rowKey="record => record.keyword"
        :dataSource="dataSource"
        :pagination="pagination"
        :loading="isLoading"
        class="keyword-subscribe"
        @change="handleTableChange"
      >
        <template slot="keyword" slot-scope="keyword">
          <a-row align="middle">
            <div class="flex" :style="isMobile ? 'font-size: 8px; margin: 4px 2px 6px 6px;' : ''">
              <div @click="onCLickShowProduct(keyword)" class="cursor-pointer font-medium">{{ keyword }}</div>
              <a-tooltip trigger="click">
                <template slot="title">
                  <span>Đã sao chép</span>
                </template>
                <a-icon v-if="!isMobile" class="ml-2 text-primary-500 cursor-pointer" type="copy" @click="onClickCopy(keyword)"/>
              </a-tooltip>
            </div>
            <div type="link" @click="onCLickShowProduct(keyword)" style="font-size: 8px; margin-left: 6px" class="cursor-pointer flex flex-row justify-start text-primary-500" v-if="isMobile">
              <span><a-icon type="unordered-list" /></span>
              <span>Danh sách sản phẩm</span>
            </div>
          </a-row>
        </template>

        <template slot="order_count" slot-scope="number">
          {{ number | formatNumberHuman }}
        </template>

        <template slot="order_revenue" slot-scope="number">
          {{ number | formatCurrency }}
        </template>

        <template slot="order_count_selected" slot-scope="number">
          {{ number | formatNumberHuman }}
        </template>

        <template slot="order_revenue_selected" slot-scope="number">
          {{ number | formatCurrency }}
        </template>

        <template slot="product_total" slot-scope="number">
          {{ number | formatNumberHuman }}
        </template>

        <template slot="shop_total" slot-scope="number">
          {{ number | formatNumberHuman }}
        </template>

        <template slot="action" slot-scope="_, keywordObj">
          <div class="">
            <span class="text-primary-500 cursor-pointer" type="link" @click="onCLickShowProduct(keywordObj.keyword)" :style="isMobile ? 'font-size: 8px;' : ''">
              <a-icon type="unordered-list" />
              Danh sách sản phẩm
            </span>
          </div>
        </template>

      </a-table>
      <a-modal title=""
               :closable="true"
               :footer="null"
               v-model="showPopupProductList"
               :maskClosable="true"
               :bodyStyle="{padding: '16px', margin: 0}"
               :width="1200"
      >
        <h2 class="title margin-top-25 mobile-title">
          <span class="border-left-line mobile-view"></span>
          Sản phẩm có từ khoá: "{{selectedKeyword}}"
        </h2>
        <div style="height: 500px; overflow-y: scroll; width: calc(100% + 24px); margin-left: -12px;">
          <TableKeywordProduct :key="selectedKeyword" v-if="selectedKeyword" :selected-keywords="[selectedKeyword]"/>
        </div>
      </a-modal>
    </div>
  </div>
</template>
<script>
import {formatCurrency, formatNumberHuman, timestampToDate} from '@/helper/FormatHelper'
import { MutationSearchFilter } from '@/store/modules/SearchFilterModule/mutation'
import {mixinStoreGetterPermission} from "@/mixin/store/MixinUser";
import {generateColumnsKeywordTable} from "@/service/market/MarketService";
import {mixinStoreGetterKeywords} from "@/mixin/store/MixinKeywords";
import {copyToClipboard} from "@/helper/StringHelper";
import TableKeywordProduct from "@/views/search/TableKeywordProduct";
import {mixinStoreGetterGlobal} from "@/mixin/store/MixinGlobal";

export default {
  name: "TableKeyword",
  components: {
    TableKeywordProduct
  },
  props: {
    loadType: {
      default: 'pagination',
    },
    rowCount: {
      default: null
    },
    sortByProp: {
      default: null
    }
  },
  data() {
    return {
      columns: null,
      pagination: {},
      isLoading: false,
      showPopupProductList: false,
      productBaseIdCurrent: null,
      popupProductHistoryTab: null,
      selectedKeyword: null,
      sortBy: null,
      showTooltipCopy: false
    }
  },
  async mounted() {
    this.pagination = this.loadType === 'pagination'
      ? {
        'current': 1,
        'pageSize': 10,
        'total': 100,
        'simple': true,
      }
      : false
    let durationDay = this.$store.state.SearchFilterModule.options?.durationDay
    this.columns = generateColumnsKeywordTable(durationDay)
    if (this.isMobile) {
      this.columns = this.columns
        .map(item => {
          delete item.sorter
          item.width = '20%'
          item.title = item.title.replace('ản phẩm', 'p')
          return item
        })
        .filter(item => !['order_revenue', 'order_count', 'action'].includes(item.dataIndex))
    }
    await this.onSearchKeywords()
  },
  methods: {
    async onSearchKeywords() {
      this.isLoading = true
      let optionsFilter = {...this.$store.state.SearchFilterModule.options}
      optionsFilter['limit'] = this.pagination.total || 100
      optionsFilter['page'] = this.pagination.current || 1
      optionsFilter['sort_by'] = this.sortBy ? this.sortBy : optionsFilter.durationDay !== 0 ? `order_revenue_${optionsFilter.durationDay}d__desc` : 'order_revenue_custom__desc'
      if (optionsFilter.durationDay !== 0) {
        delete optionsFilter.start_date
        delete optionsFilter.end_date
      }
      await this.$store.dispatch(`SearchFilterModule/${MutationSearchFilter.SEARCH_KEYWORDS}`, optionsFilter)
      this.isLoading = false
    },
    async handleTableChange(pagination, filters, sorter) {
      const pager = { ...this.pagination }
      pager.current = pagination.current
      this.pagination = pager
      if (sorter != null && Object.keys(sorter).length > 0) {
        let sort = sorter?.['field']
        if (sorter['order'] === 'ascend') {
          sort += `__asc`
        } else if (sorter['order'] === 'descend') {
          sort += `__desc`
        } else {
          return
        }
        this.sortBy = sort
      }
      await this.onSearchKeywords()
    },
    async onCLickShowProduct(keyword) {
      this.selectedKeyword = keyword
      this.showPopupProductList = true
    },
    onClickCopy(keyword) {
      copyToClipboard(keyword)
    },
  },
  filters: {
    formatCurrency: formatCurrency,
    formatNumberHuman: formatNumberHuman,
    timestampToDate: timestampToDate,
  },
  computed: {
    iDurationDay: {
      get() {
        return this.$store.state.SearchFilterModule.iDurationDay
      },
    },
    iPlatforms: {
      get() {
        return this.$store.state.SearchFilterModule.iPlatforms
      }
    },
    isLogin: {
      get() {
        return this.$store.state.UserModule.isLogin
      }
    },
    options: {
      get() {
        return this.$store.state.SearchFilterModule.options
      }
    },
    dataSource() {
      if (this.rowCount) {
        return this.keywords?.slice(0, this.rowCount)
      }
      return this.keywords
    }
  },
  watch: {
    sortByProp(value) {
      if (value) {
        this.handleTableChange(this.pagination, null, value)
      }
    }
  },
  mixins: [
    mixinStoreGetterGlobal,
    mixinStoreGetterPermission,
    mixinStoreGetterKeywords
  ]
}
</script>

<style lang="scss" scoped>
.mobile-view {
  &.table-keyword {
    .row-thumbnail {
      width: 24px;
      height: 24px;
      object-fit: cover;
    }

    .row-product-name {
      display: block;
      text-align: left;

      .product_name {
        font-size: 8px;
        font-weight: 500;
        margin-bottom: 0;

        &:hover {
          cursor: pointer;
        }

        .icon-mall-wrap {
          height: 10px;
          width: 20px;
          display: inline-block;
        }
        .icon-mall {
          transform: scale(0.5, 0.5);
          transform-origin: bottom left;
        }
      }
    }

    .rating_count {
      font-size: 7px;
    }

    .ant-rate {
      font-size: 8px;
    }

    .ant-rate-star:not(:last-child) {
      margin-right: 2px;
    }
  }

  /deep/ .ant-table {
  }

  /deep/ .ant-table-tbody > tr > td {
    font-size: 8px !important;
    padding: 2px !important;
    text-align: center;
  }

  /deep/ .ant-table-thead > tr > th {
    font-size: 10px !important;
    padding: 2px 6px !important;
    font-weight: 400;
    background: unset;

    &[key=shop_total] {
      padding: 2px 2px !important;
    }
  }
}
</style>
