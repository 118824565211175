<template>
  <svg width="18" height="20" viewBox="0 0 18 20" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g clip-path="url(#clip0_1737_8315)">
      <g filter="url(#filter0_d_1737_8315)">
        <path d="M4.12905 3.31579C4.31871 3.31542 4.50251 3.38143 4.64854 3.50236L8.99932 7.13637L13.3582 3.63215C13.4412 3.56477 13.5368 3.51445 13.6393 3.48408C13.7419 3.45372 13.8494 3.44391 13.9558 3.45522C14.0621 3.46652 14.1652 3.49873 14.2591 3.54997C14.3529 3.60121 14.4357 3.67049 14.5027 3.75382C14.5702 3.83679 14.6205 3.93226 14.6509 4.03474C14.6813 4.13723 14.6911 4.2447 14.6798 4.35098C14.6685 4.45727 14.6362 4.56027 14.585 4.65407C14.5337 4.74787 14.4644 4.83062 14.381 4.89756L9.5107 8.81547C9.36546 8.93478 9.18327 9 8.99526 9C8.80725 9 8.62507 8.93478 8.47982 8.81547L3.60955 4.75966C3.52736 4.69156 3.45942 4.60793 3.40962 4.51356C3.35982 4.41918 3.32914 4.31591 3.31933 4.20967C3.30953 4.10343 3.3208 3.99629 3.35249 3.89441C3.38419 3.79252 3.43568 3.69789 3.50403 3.61592C3.57956 3.52287 3.67479 3.4477 3.78285 3.3958C3.89092 3.34391 4.00915 3.31658 4.12905 3.31579Z" fill="currentColor"/>
      </g>
    </g>
    <g clip-path="url(#clip1_1737_8315)">
      <g filter="url(#filter1_d_1737_8315)">
        <path d="M1.69345 7.15801C1.97794 7.15746 2.25364 7.25647 2.47269 7.43786L8.99886 12.8889L15.5372 7.63254C15.6617 7.53147 15.805 7.456 15.9589 7.41045C16.1127 7.36491 16.274 7.35019 16.4335 7.36715C16.5931 7.38411 16.7477 7.43241 16.8885 7.50928C17.0293 7.58615 17.1535 7.69006 17.254 7.81505C17.3551 7.93951 17.4306 8.08272 17.4762 8.23644C17.5218 8.39016 17.5365 8.55137 17.5195 8.7108C17.5026 8.87023 17.4542 9.02473 17.3773 9.16543C17.3004 9.30613 17.1964 9.43025 17.0713 9.53066L9.76592 15.4075C9.54806 15.5865 9.27479 15.6843 8.99277 15.6843C8.71075 15.6843 8.43748 15.5865 8.21961 15.4075L0.914207 9.32382C0.790918 9.22167 0.689004 9.09622 0.614304 8.95466C0.539604 8.8131 0.493583 8.65819 0.47888 8.49883C0.464177 8.33946 0.48108 8.17877 0.52862 8.02594C0.576159 7.87311 0.6534 7.73116 0.755922 7.60821C0.869221 7.46863 1.01206 7.35587 1.17416 7.27803C1.33626 7.20019 1.51361 7.1592 1.69345 7.15801Z" fill="currentColor"/>
      </g>
    </g>
    <defs>
      <filter id="filter0_d_1737_8315" x="1.31592" y="3.31567" width="15.3684" height="9.68433" filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB">
        <feFlood flood-opacity="0" result="BackgroundImageFix"/>
        <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha"/>
        <feOffset dy="2"/>
        <feGaussianBlur stdDeviation="1"/>
        <feComposite in2="hardAlpha" operator="out"/>
        <feColorMatrix type="matrix" values="0 0 0 0 0.820833 0 0 0 0 0.820833 0 0 0 0 0.820833 0 0 0 0.25 0"/>
        <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_1737_8315"/>
        <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_1737_8315" result="shape"/>
      </filter>
      <filter id="filter1_d_1737_8315" x="-1.52625" y="7.15796" width="21.0526" height="12.5264" filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB">
        <feFlood flood-opacity="0" result="BackgroundImageFix"/>
        <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha"/>
        <feOffset dy="2"/>
        <feGaussianBlur stdDeviation="1"/>
        <feComposite in2="hardAlpha" operator="out"/>
        <feColorMatrix type="matrix" values="0 0 0 0 0.820833 0 0 0 0 0.820833 0 0 0 0 0.820833 0 0 0 0.25 0"/>
        <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_1737_8315"/>
        <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_1737_8315" result="shape"/>
      </filter>
      <clipPath id="clip0_1737_8315">
        <rect width="11.3684" height="11.3684" fill="white" transform="translate(14.6843 11.3684) rotate(-180)"/>
      </clipPath>
      <clipPath id="clip1_1737_8315">
        <rect width="18" height="18" fill="white" transform="translate(18 20) rotate(-180)"/>
      </clipPath>
    </defs>
  </svg>
</template>

<script>
export default {
  name: "IconSwipeDown"
}
</script>

<style scoped>

</style>
