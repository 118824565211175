import {mapGetters} from "vuex";
import {MutationSearchBrandsResult} from "@/store/modules/SearchBrandsResultModule/mutation";

const mixinStoreGetterBrands = {
  computed: {
    ...mapGetters({
      brands:  `${MutationSearchBrandsResult.MODULE_PATH}/${MutationSearchBrandsResult.brands}`,
    })
  }
}

export {
  mixinStoreGetterBrands
}
