<template>
  <div class="rating-star flex items-center" v-if="ratingStar" :style="stylesComponent">
    <p class="text-yellow-700" :style="stylesText" v-if="isShowRatingStarText">{{ ratingStar.toFixed(1) }}</p>
    <!--    <p class="text-red-500" :style="stylesText" v-if="isShowRatingStarText">{{ ratingStar.toFixed(1) }}</p>-->
    <div class="flex flex-wrap items-center w-full h-4 text-yellow-500">
      <template v-for="count in countFull">
        <svg v-if="countFull" :key="'full_'+count" :class="classIcon" aria-hidden="true" focusable="false" data-prefix="fas" data-icon="star" class="svg-inline--fa fa-star fa-w-18" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 576 512"><path fill="currentColor" d="M259.3 17.8L194 150.2 47.9 171.5c-26.2 3.8-36.7 36.1-17.7 54.6l105.7 103-25 145.5c-4.5 26.3 23.2 46 46.4 33.7L288 439.6l130.7 68.7c23.2 12.2 50.9-7.4 46.4-33.7l-25-145.5 105.7-103c19-18.5 8.5-50.8-17.7-54.6L382 150.2 316.7 17.8c-11.7-23.6-45.6-23.9-57.4 0z"></path></svg>
      </template>
      <template v-for="count in countHalf">
        <svg v-if="countHalf" :key="'half_'+count" :class="classIcon" aria-hidden="true" focusable="false" data-prefix="fas" data-icon="star-half-alt" class="svg-inline--fa fa-star-half-alt fa-w-17" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 536 512"><path fill="currentColor" d="M508.55 171.51L362.18 150.2 296.77 17.81C290.89 5.98 279.42 0 267.95 0c-11.4 0-22.79 5.9-28.69 17.81l-65.43 132.38-146.38 21.29c-26.25 3.8-36.77 36.09-17.74 54.59l105.89 103-25.06 145.48C86.98 495.33 103.57 512 122.15 512c4.93 0 10-1.17 14.87-3.75l130.95-68.68 130.94 68.7c4.86 2.55 9.92 3.71 14.83 3.71 18.6 0 35.22-16.61 31.66-37.4l-25.03-145.49 105.91-102.98c19.04-18.5 8.52-50.8-17.73-54.6zm-121.74 123.2l-18.12 17.62 4.28 24.88 19.52 113.45-102.13-53.59-22.38-11.74.03-317.19 51.03 103.29 11.18 22.63 25.01 3.64 114.23 16.63-82.65 80.38z"></path></svg>
      </template>
      <template v-for="count in countLess">
        <svg v-if="countLess" :key="'less_'+count" :class="classIcon" aria-hidden="true" focusable="false" data-prefix="far" data-icon="star" class="svg-inline--fa fa-star fa-w-18" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 576 512"><path fill="currentColor" d="M528.1 171.5L382 150.2 316.7 17.8c-11.7-23.6-45.6-23.9-57.4 0L194 150.2 47.9 171.5c-26.2 3.8-36.7 36.1-17.7 54.6l105.7 103-25 145.5c-4.5 26.3 23.2 46 46.4 33.7L288 439.6l130.7 68.7c23.2 12.2 50.9-7.4 46.4-33.7l-25-145.5 105.7-103c19-18.5 8.5-50.8-17.7-54.6zM388.6 312.3l23.7 138.4L288 385.4l-124.3 65.3 23.7-138.4-100.6-98 139-20.2 62.2-126 62.2 126 139 20.2-100.6 98z"></path></svg>
      </template>
    </div>
  </div>
</template>

<script>

export default {
  props: {
    ratingStar: null,
    stylesIcon: null,
    stylesText: null,
    stylesComponent: null,
    isShowRatingStarText: {
      type: Boolean,
      default: true
    },
    classIcon: {
      type: String,
      default: 'h-4'
    }
  },
  data() {
    return {
      countFull: null,
      countHalf: null,
      countLess: null,
      maxStar: 5
    }
  },
  created() {
    if (this.ratingStar != null) {
      let ratingStar = parseFloat(this.ratingStar)
      this.countFull = Math.floor(ratingStar)
      if (ratingStar - this.countFull >= 0.5) {
        this.countHalf = 1
      } else {
        this.countHalf = 0
      }
      this.countLess = this.maxStar - this.countHalf - this.countFull
    }
  }
}
</script>

<style lang="scss" scoped>
.rating-star {
  //justify-content: right;
  //align-self: center;
}

.icon-rating {
  @apply h-4;
}
</style>
