import {URL_BACKEND_API} from "@/config/ConfigEndpoint";

const urlApiGetListSavedReport = (page, limit, orderBy) => {
  return `${URL_BACKEND_API}/market/user/report/list?page=${page}&limit=${limit}&order_by=${orderBy}`
}

const urlApiGetDownloadSavedReport = (page, limit, orderBy, type) => {
  return `${URL_BACKEND_API}/market/user/report/list?page=${page}&limit=${limit}&order_by=${orderBy}&export_type=${type}`
}

const urlApiGetSavedReportDetail = (reportHash) => {
  return `${URL_BACKEND_API}/market/user/report/save?report_hash=${reportHash}`
}

const urlApiCloneSavedReport = () => {
  return `${URL_BACKEND_API}/market/user/report/clone`
}

const urlApiSaveReport = () => {
  return `${URL_BACKEND_API}/market/user/report/save`
}

export {
  urlApiGetListSavedReport,
  urlApiGetDownloadSavedReport,
  urlApiGetSavedReportDetail,
  urlApiCloneSavedReport,
  urlApiSaveReport,
}
