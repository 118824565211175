<template>
  <a-modal title=""
           v-if="url"
           :closable="true"
           :footer="null"
           v-model="showPopupShareLink"
           :maskClosable="false"
           :width="800">
    <div class="page container-bg-white">
      <div class="rows is-mobile">
        <h1 class="title">
          <a-icon type="share-alt"/>
          Chia sẻ báo cáo thông qua đường dẫn:
        </h1>
        <div class="">
          <div class="content">
            <a-input :value="url" readOnly/>
            <div class="btn-copy">
              <a-button @click="copy(url)">Sao chép đường dẫn</a-button>
              <span class="copy-tooltip">Đã sao chép</span>
            </div>
          </div>
        </div>
      </div>
    </div>
  </a-modal>
</template>

<script>
import {MutationGeneral} from "@/store/modules/GeneralModule/mutation";

export default {
  name: "PopupShareCustomQuery",
  props: {
    url: true
  },
  computed: {
    showPopupShareLink: {
      get() {
        return this.$store.state.GeneralModule.showPopupShareLink
      },
      async set(value) {
        await this.$store.commit(`GeneralModule/${MutationGeneral.setShowPopupShareLink}`, value)
      }
    },
  },
  methods: {
    async copy(text) {
      await navigator.clipboard.writeText(text);
      // alert('Copied!');
      const tooltip = document.querySelectorAll(".copy-tooltip");
      tooltip.forEach(elem => {
        elem.classList.add("show");
        setTimeout(function(){
          elem.classList.remove("show");
        },600);
      })
    }
  }
}
</script>

<style lang="scss" scoped>
  .content {
    padding: 20px;
    width: 100%;
    display: flex;

    input {
      width: 100%;
      margin-right: 8px;
    }

    .btn-copy .copy-tooltip{
      position: relative;
      margin-left: -15px;
      padding-left: 15px;
      font-size: 14px;
      font-weight: 500;
      z-index: -1;
      border-radius: 3px;
      letter-spacing: 1px;
      opacity: 0;
      pointer-events: none;
      transition: opacity 0.6s, margin-left 0.4s;
    }
    .btn-copy .copy-tooltip.show{
      margin-left: 10px;
      opacity: 1;
      z-index: 1;
      pointer-events: auto;
    }
    .copy-tooltip:before{
      content: '';
      position: absolute;
      height: 15px;
      width: 15px;
      top: 50%;
      left: 50%;
      z-index: -1;
      transform: translateY(-50%) rotate(45deg);
    }
  }
</style>
