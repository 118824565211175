import {mapGetters} from "vuex";
import {MutationSearchProductResult} from "@/store/modules/SearchProductResultModule/mutation";

const mixinStoreGetterProducts = {
  computed: {
    ...mapGetters({
      productsResult:  `${MutationSearchProductResult.MODULE_PATH}/${MutationSearchProductResult.products}`,
    })
  }
}

export {
  mixinStoreGetterProducts
}
