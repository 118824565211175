<template>
  <div v-if="productItem" class="flex flex-col border border-gray-400 rounded" style="box-sizing: border-box">
    <div class="relative flex flex-grow-0 justify-space-between items-start bg-gray-100 px-2"
         style="padding-top: 100%; border-top-left-radius: 4px; border-top-right-radius: 4px;"
    >
      <img v-if="productItem.url_thumbnail"
           :src="productItem.url_thumbnail"
           :alt="productItem.product_name"
           class="absolute top-0 left-0 h-full w-full object-cover"
           style="border-top-left-radius: 4px; border-top-right-radius: 4px;"
      >
    </div>
    <div class="border-t border-gray-200 mt-2 pt-2 pb-3 px-2 flex-grow-0">
      <div class="line-clamp__2 text-sm text-black font-medium" style="height: 40px; word-break: break-word">
        <div class="flex">
          <p class="product_name line-clamp__2" @click="onClickProductName">
            <span v-if="productItem['official_type'] === 1" class="icon-mall-wrap"><mall-flag class="icon-mall"/></span>
            <span class="ml-1">{{ productItem.product_name }}</span>
          </p>
          <a-tooltip>
            <template slot="title" v-if="productItem['price_updated_at']">
              <p>{{productItem.product_name}}</p>
              <p class="border-t">Cập nhật thông tin sản phẩm ngày {{productItem['price_updated_at'] | timestampToDate}}</p>
            </template>
            <i>
              <IconInformation style="transform: translateY(-2px)"/>
            </i>
          </a-tooltip>
        </div>
      </div>
      <div class="flex justify-space-between items-center mt-2 text-xs text-gray-400">
        <span class="text-sm text-black font-medium">{{productItem.price | formatCurrency}}</span>
        <div class="flex items-end">
          <template v-if="productItem.rating_count && productItem.rating_avg">
            <RatingStar v-if="productItem.rating_avg" :ratingStar="productItem.rating_avg"
                        :is-show-rating-star-text="false"
                        class="mr-1"
                        class-icon="h-2 text-primary"
            />
            <span class="">{{productItem.rating_count | formatNumberHuman}} Đánh giá</span>
          </template>
          <div class="text-sm text-black font-medium" v-if="productItem.price_raw">
            <span v-if="productItem.price_raw.min !== productItem.price_raw.max">¥{{productItem.price_raw.min | formatNumberHuman}} - ¥{{productItem.price_raw.max | formatNumberHuman}}</span>
            <span v-else>¥{{productItem.price_raw.min | formatNumberHuman}}</span>
          </div>
        </div>
      </div>
      <div class="flex justify-space-between items-center mt-2 text-xs text-gray-400" v-if="productItem.shop_platform_name || productItem.view_count">
        <div class="flex items-center">
          <template v-if="productItem.shop_platform_name">
            <img v-if="platform_logo_url" :src="platform_logo_url" class="img-platform mr-1"/>
            <svg v-else width="12" height="12" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M9.33333 9.99967H5.33333V7.33301H4.66667V9.99967H2.66667V7.33301H2V9.99967C2 10.1765 2.07024 10.3461 2.19526 10.4711C2.32029 10.5961 2.48986 10.6663 2.66667 10.6663H9.33333C9.51014 10.6663 9.67971 10.5961 9.80474 10.4711C9.92976 10.3461 10 10.1765 10 9.99967V7.33301H9.33333V9.99967Z" fill="#9E9E9E"/>
              <path d="M11.2632 4.42301L9.90318 1.70301C9.84798 1.59194 9.7629 1.49846 9.6575 1.4331C9.55209 1.36774 9.43054 1.33307 9.30651 1.33301H2.69318C2.56915 1.33307 2.4476 1.36774 2.34219 1.4331C2.23678 1.49846 2.1517 1.59194 2.09651 1.70301L0.736509 4.42301C0.690074 4.51617 0.666101 4.61891 0.666509 4.72301V5.74967C0.666205 5.90544 0.720455 6.0564 0.819842 6.17634C0.966729 6.34472 1.14814 6.4795 1.35175 6.57153C1.55536 6.66356 1.7764 6.71067 1.99984 6.70967C2.36443 6.71024 2.7179 6.58417 2.99984 6.35301C3.28178 6.5843 3.63517 6.71071 3.99984 6.71071C4.36451 6.71071 4.71791 6.5843 4.99984 6.35301C5.28178 6.5843 5.63517 6.71071 5.99984 6.71071C6.36451 6.71071 6.71791 6.5843 6.99984 6.35301C7.28178 6.5843 7.63517 6.71071 7.99984 6.71071C8.36451 6.71071 8.71791 6.5843 8.99984 6.35301C9.31456 6.61142 9.71721 6.73774 10.1231 6.70541C10.5291 6.67309 10.9067 6.48464 11.1765 6.17968C11.2771 6.06019 11.3325 5.90919 11.3332 5.75301V4.72301C11.3336 4.61891 11.3096 4.51617 11.2632 4.42301ZM4.99984 4.79967V5.30634L4.72651 5.66634C4.6426 5.78071 4.53294 5.87371 4.40641 5.93781C4.27987 6.00191 4.14002 6.03531 3.99818 6.03531C3.85633 6.03531 3.71648 6.00191 3.58995 5.93781C3.46341 5.87371 3.35375 5.78071 3.26984 5.66634L2.99984 5.29301V4.79967L3.86318 1.99967H5.33318L4.99984 4.79967ZM8.99984 5.29301L8.72984 5.66634C8.64594 5.78071 8.53627 5.87371 8.40974 5.93781C8.28321 6.00191 8.14335 6.03531 8.00151 6.03531C7.85966 6.03531 7.71981 6.00191 7.59328 5.93781C7.46674 5.87371 7.35708 5.78071 7.27318 5.66634L6.99984 5.29301V4.79967L6.66651 1.99967H8.14984L8.99984 4.79967V5.29301Z" fill="#9E9E9E"/>
            </svg>
            <span class="ml-1" @click="onClickShopUrlDirect" :class="productItem['shop_url'] ? 'cursor-pointer hover:text-primary-900' : ''">{{productItem.shop_platform_name}}</span>
          </template>
        </div>
      </div>
      <div class="flex justify-space-between items-center mt-2 text-xs text-gray-400">
        <div>
          <template v-if="productItem.platform_created_at">
            Ngày tạo: {{productItem.platform_created_at |  timestampToDate | removeDay}}
            <a-tooltip style="cursor: default">
              <template slot="title">
                Dữ liệu do BeeCost ước tính
              </template>
              <i>
                <IconInformation style="transform: translateY(-4px)"/>
              </i>
            </a-tooltip>
          </template>
        </div>
        <div>
          <a-button v-if="!isGlobalProduct"
                    class="relative flex justify-center items-center"
                    style="color: #2368D4; background-color: rgba(0, 122, 255, 0.1) !important; border: none; height: 26px; width: 125px; font-size: 12px"
                    size="large"
                    @click="onClickHistoryPrice"
          >
            <a-icon class="" type="line-chart"/>
            <span style="margin-left: 4px;">Lịch sử giá/bán</span>
          </a-button>
        </div>
      </div>
    </div>
    <div class="flex-grow border-t border-gray-200 py-2 px-2 flex-grow-1">
      <div class="mt-2 flex justify-start items-start" v-if="productItem.price_min && productItem.price_max">
        <IconDollar />
        <div class="ml-1">
          <p>
            Giá trong phân loại hàng:
            <a-tooltip style="cursor: default">
              <template slot="title">
                <span>Khoảng giá (giá min - giá max) của sản phẩm có nhiều phân loại hàng</span>
              </template>
              <i>
                <IconInformation
                  :width="isMobile ? 10 : 12"
                  :height="isMobile ? 10 : 12"
                  style="transform: translateY(-4px)"
                />
              </i>
            </a-tooltip>
            <span class="text-primary-500">
              {{ productItem.price_min | formatCurrency }} - {{ productItem.price_max | formatCurrency }}
            </span>
          </p>
        </div>
      </div>
      <template v-for="durationDay in [0, 7, 30, 90, 180]">
        <div :key="`${durationDay ? `product_item_order_revenue_${durationDay}d` : 'product_item_order_revenue_custom'}`"
             class="mt-2 flex justify-start items-start"
             v-if="productItem[`${durationDay ? `order_revenue_${durationDay}d` : 'order_revenue_custom'}`]"
        >
          <IconRevenue class="mt-1 flex-shrink-0"/>
          <div class="ml-1">
            <p class="">Doanh số {{durationDay ? `${durationDay} ngày` : 'trong khoảng đã chọn'}}:
              <template v-if="canShowStatistic">
                <span class="text-primary-500 font-semibold">{{productItem[`${durationDay ? `order_revenue_${durationDay}d` : 'order_revenue_custom'}`] | formatCurrency}}</span>
              </template>
              <a v-else @click="onClickPricing">Nâng cấp tài khoản</a>
            </p>
            <p class="flex flex-wrap"
              v-if="canShowStatistic && productItem[`${durationDay ? `order_revenue_${durationDay}d_previous` : 'order_revenue_custom_previous'}`]"
            >
              <CompareValue :value="productItem[`${durationDay ? `order_revenue_${durationDay}d` : 'order_revenue_custom'}`]"
                            :value-comparison="productItem[`${durationDay ? `order_revenue_${durationDay}d_previous` : 'order_revenue_custom_previous'}`]"
                            class="mr-1"
              />
              so với cùng kì
            </p>
          </div>
        </div>
        <div :key="`${durationDay ? `product_item_order_count_${durationDay}d` : 'product_item_order_count_custom'}`"
             class="mt-2 flex justify-start items-start"
             v-if="productItem[`${durationDay ? `order_count_${durationDay}d` : 'order_count_custom'}`]">
          <IconOrder class="mt-1 flex-shrink-0"/>
          <div class="ml-1">
            <p class="">Đã bán {{ durationDay ? `${durationDay} ngày` : 'trong khoảng đã chọn' }}:
              <template v-if="canShowStatistic">
                <span class="text-primary-500">{{productItem[`${durationDay ? `order_count_${durationDay}d` : 'order_count_custom'}`] | formatNumberHuman}}</span>
              </template>
              <a v-else @click="onClickPricing">Nâng cấp tài khoản</a>
            </p>
            <p class="flex flex-wrap"
              v-if="canShowStatistic && productItem[`${durationDay ? `order_count_${durationDay}d_previous` : 'order_count_custom_previous'}`]"
            >
              <CompareValue :value="productItem[`${durationDay ? `order_count_${durationDay}d` : 'order_count_custom'}`]"
                            :value-comparison="productItem[`${durationDay ? `order_count_${durationDay}d_previous` : 'order_count_custom_previous'}`]"
                            class="mr-1"
              />
              so với cùng kì
            </p>
          </div>
        </div>
      </template>
      <div class="mt-2 flex justify-start items-start" v-if="productItem.revenue">
        <IconRevenue class="mt-1 flex-shrink-0"/>
        <div class="ml-1">
          <p class="">Tổng doanh số:
            <template v-if="canShowStatistic">
              <span class="text-primary-500">{{productItem.revenue | formatCurrency}}</span>
            </template>
            <a v-else @click="onClickPricing">Nâng cấp tài khoản</a>
          </p>
        </div>
      </div>
      <div class="mt-2 flex justify-start items-start" v-if="productItem.order_count">
        <IconOrder class="mt-1 flex-shrink-0"/>
        <div class="ml-1">
          <p class="">Tổng đã bán:
<!--            <template v-if="canShowStatistic">-->
              <span class="text-primary-500">{{productItem.order_count | formatNumberHuman}}</span>
<!--            </template>-->
<!--            <a v-else href="/pricing">Nâng cấp tài khoản</a>-->
          </p>
        </div>
      </div>
      <div class="mt-2 flex justify-start items-start" v-if="productItem.category_name || productItem.brand">
        <Icon4Square class="mt-1 flex-shrink-0"/>
        <div class="flex flex-wrap justify-start items-end ml-1">
          <a-popover trigger="hover">
            <template slot="content">
              <template v-if="productItem['categories'] && productItem['categories'].length">
                <p>Ngành hàng:</p>
                <p v-for="category in productItem['categories']" :key="category['id']" class="ml-4">
                  {{getCategoryNameById(category['id'])}}
                </p>
              </template>
              <template v-if="productItem.brand">
                <p>Thương hiệu: {{productItem.brand}}</p>
              </template>
            </template>
            <span class="">Ngành hàng: <span class="text-black">{{productItem.category_name}}<span v-if="productItem.category_name && productItem.brand"> / </span>{{productItem.brand}}</span></span>
          </a-popover>
        </div>
      </div>
    </div>
    <div class="mt-2 mb-3 flex justify-end items-center px-2 text-gray-400">
      <a class="text-gray-400" @click="onClickProductDirect">Link sản phẩm</a>
    </div>
    <a-modal title=""
             :closable="true"
             :footer="null"
             v-model="showPopupProductHistory"
             :maskClosable="true"
             :width="960"
    >
      <PopupProductHistory v-if="productItem.product_base_id" :product-base-id="productItem.product_base_id" popup-product-history-tab="history_price"
      />
    </a-modal>
  </div>
</template>


<script>
import RatingStar from "@/components/rating/RatingStar";
import {formatCurrency, formatNumberHuman, timestampToDate} from "@/helper/FormatHelper";
import {canShowHistoryPrice, canShowProductLink} from "@/service/user/UserPermissionService";
import {MutationGeneral} from "@/store/modules/GeneralModule/mutation";
import {
  ActionNameConstants,
  ALL_COUNTRY_SUPPORTED,
  NOTIFICATION_TYPE
} from "@/constant/general/GeneralConstant";
import CompareValue from "@/components/common/CompareValue";
import IconRevenue from "@/assets/icon/IconRevenue";
import IconOrder from "@/assets/icon/IconOrder";
import Icon4Square from "@/assets/icon/Icon4Square";
import PopupProductHistory from "@/views/product/PopupProductHistory";
import MallFlag from '@/components/product/MallFlag'
import {mixinStoreGetterPermission} from "@/mixin/store/MixinUser";
import {getPlatformById, getPlatformByUrl} from "@/service/platform/PlatformCommonService";
import {extractProductBaseId} from "@/service/product/UrlProductService";
import {getCategoryNameById} from "@/service/search/SearchService";
import IconInformation from "@/assets/icon/IconInformation";
import IconDollar from "@/assets/icon/IconDollar";

export default {
  name: "ProductItem",
  components: {
    RatingStar,
    CompareValue,
    IconRevenue,
    IconOrder,
    Icon4Square,
    MallFlag,
    PopupProductHistory,
    IconInformation,
    IconDollar,
  },
  mixins: [
    mixinStoreGetterPermission
  ],
  props: {
    productItem: {
      required: true
    }
  },
  computed: {
    canShowStatistic() {
      return !this.isDefaultAccount && this.freeSearchCount !== 0
    },
    isGlobalProduct() {
      return getPlatformById(extractProductBaseId(this.productItem.product_base_id).platform_id).country !== ALL_COUNTRY_SUPPORTED.vietnam
    },
    platform_logo_url() {
      return getPlatformByUrl(this.productItem.shop_url).urlLogo
    },
  },
  filters: {
    formatCurrency: formatCurrency,
    formatNumberHuman: formatNumberHuman,
    timestampToDate: timestampToDate,
    removeDay(dateString) {
      return dateString.split('/').slice(1).join('/')
    },
  },
  methods: {
    async onClickProductDirect() {
      let productUrl = this.productItem.url_direct
      let options = this.$store.state.SearchFilterModule.options
      let resultPermission = await canShowProductLink(options)
      if (resultPermission.isPermission) {
        window.open(productUrl, '_blank')
      } else {
        await this.$store.dispatch(`${MutationGeneral.MODULE_PATH}/${MutationGeneral.setShowPopupNotification}`, NOTIFICATION_TYPE.upgrade_permission)
      }
    },
    async onClickProductName() {
      let productUrl = this.isGlobalProduct
        ? this.productItem.url_direct
        : this.productItem.url_product_detail_beecost
      let options = this.$store.state.SearchFilterModule.options
      let resultPermission = await canShowProductLink(options)
      if (resultPermission.isPermission) {
        window.open(productUrl, '_blank')
      } else {
        await this.$store.dispatch(`${MutationGeneral.MODULE_PATH}/${MutationGeneral.setShowPopupNotification}`, NOTIFICATION_TYPE.upgrade_permission)
      }
    },
    async onClickShopUrlDirect() {
      let shopUrl = this.productItem.shop_url
      window.open(shopUrl, '_blank')
    },
    async onClickHistoryPrice() {
      let options = this.$store.state.SearchFilterModule.options
      let resultPermission = await canShowHistoryPrice(options)
      if (resultPermission.isPermission) {
        this.showPopupProductHistory = true
      } else {
        if (this.$store.state.GeneralModule.actionName === ActionNameConstants.HOME_PAGE) {
          // hiển thị popup direct to Market View
          this.$store.commit(`GeneralModule/${MutationGeneral.setShowPopupSearchDetail}`, true)
          return
        }
        await this.$store.dispatch(`${MutationGeneral.MODULE_PATH}/${MutationGeneral.setShowPopupNotification}`, NOTIFICATION_TYPE.upgrade_permission)
      }
    },
    getCategoryNameById: getCategoryNameById,
    onClickPricing() {
      this.$store.commit(`GeneralModule/${MutationGeneral.setShowPopupSignUpLeadForm}`, true)
    }
  },
  data() {
    return {
      showPopupProductHistory: false
    }
  }
}
</script>

<style scoped lang="scss">
.product_name {
  font-size: 15px;
  font-weight: 500;
  margin-bottom: 0;

  &:hover {
    cursor: pointer;
  }
}

.img-platform {
  border-radius: 100%;
  width: 16px;
  height: 16px;
}
</style>
