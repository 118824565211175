<template>
  <svg width="12" height="12" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g clip-path="url(#clip0_1403_5797)">
      <path d="M1.60635 6.62761C1.55971 6.62761 1.51494 6.60909 1.48189 6.57605L0.520254 5.61441C0.451582 5.54574 0.451582 5.43417 0.520254 5.36527L4.55502 1.33097C4.62393 1.2623 4.73549 1.2623 4.80416 1.33097L6.89736 3.42394L7.85736 2.46417C7.92627 2.3955 8.0376 2.3955 8.1065 2.46417C8.17518 2.53284 8.17518 2.64441 8.1065 2.71331L7.02205 3.79777C6.95314 3.86644 6.84158 3.86644 6.77291 3.79777L4.67971 1.7048L0.893848 5.48995L1.60635 6.20222L4.55525 3.25378C4.5883 3.22074 4.63307 3.20222 4.67994 3.20222C4.72658 3.20222 4.77135 3.22074 4.80463 3.25378L6.89783 5.34699L10.8018 1.443C10.8707 1.37433 10.9823 1.37433 11.051 1.443L11.0849 1.47722L11.166 0.366517L10.0553 0.447611L10.0893 0.481595C10.1224 0.514642 10.1409 0.559407 10.1409 0.606048C10.1409 0.652689 10.1224 0.697454 10.0893 0.730501L8.60478 2.2148C8.53588 2.28347 8.42432 2.28347 8.35564 2.2148C8.28697 2.14613 8.28697 2.03456 8.35564 1.96566L9.71572 0.605814L9.53385 0.424173C9.4851 0.375423 9.46916 0.302767 9.4933 0.238079C9.51721 0.173392 9.57697 0.128861 9.64564 0.123939L11.3437 0.000423043C11.395 -0.00309258 11.4449 0.015423 11.481 0.0515168C11.5171 0.0876105 11.5359 0.137767 11.5321 0.188861L11.4084 1.88667C11.4035 1.95534 11.3587 2.01488 11.2942 2.03902C11.2298 2.06292 11.1569 2.04722 11.1084 1.99847L10.9265 1.81683L7.02205 5.72058C6.95314 5.78925 6.84182 5.78925 6.77291 5.72058L4.67971 3.62761L1.7308 6.57605C1.69775 6.60886 1.65299 6.62761 1.60635 6.62761Z" fill="black"/>
      <path d="M11.8654 8.36159C11.6782 8.11315 11.3519 8.02737 11.0702 8.1455C11.0629 8.03276 11.0249 7.92308 10.9579 7.82956C10.7817 7.583 10.447 7.50354 10.1791 7.64464L9.76026 7.86542C9.75487 7.76698 9.72581 7.67018 9.67425 7.58464C9.50714 7.30831 9.15393 7.21245 8.87011 7.3662L8.55698 7.53589C8.55136 7.41425 8.51011 7.29542 8.43651 7.19534C8.253 6.9455 7.92042 6.86862 7.64597 7.01253L5.38542 8.19823C5.13112 8.10073 4.32066 7.83659 3.37402 8.01284C3.228 8.04003 3.07777 8.07823 2.92753 8.12604C2.83495 8.15558 2.78362 8.25472 2.81339 8.34729C2.84292 8.43987 2.94206 8.4912 3.03464 8.46144C3.17081 8.41784 3.30675 8.38339 3.43847 8.35901C4.44909 8.17081 5.31417 8.54886 5.32261 8.55261C5.34534 8.56269 5.36995 8.56808 5.39479 8.56808H7.74768C7.91479 8.56808 8.05097 8.70448 8.05097 8.87229C8.05097 9.02511 7.93706 9.15472 7.78589 9.17394L4.8037 9.55175C4.71558 9.563 4.64948 9.638 4.64972 9.72706C4.64995 9.81589 4.71651 9.89066 4.80487 9.90144L6.07144 10.0542C7.29839 10.2021 8.51784 9.96284 9.59831 9.36214L11.181 8.48229C11.3203 8.40987 11.4895 8.44808 11.5842 8.5737C11.7025 8.723 11.6512 8.96276 11.4815 9.05019L9.78276 9.97597C9.57979 10.0826 9.7537 10.3978 9.95128 10.2853L11.65 9.35956C12.0053 9.17651 12.1134 8.67472 11.8654 8.36159ZM7.80956 7.32472C7.92862 7.26237 8.07276 7.29565 8.15245 7.40417C8.25042 7.5305 8.20589 7.73089 8.06339 7.80354L7.30214 8.21628H6.10964L7.80956 7.32472ZM7.96964 8.25519L9.03792 7.67604C9.15604 7.61206 9.303 7.65214 9.3726 7.76722C9.44854 7.88651 9.40096 8.05854 9.27464 8.12159L8.34815 8.6098C8.2762 8.4455 8.13909 8.31636 7.96964 8.25519ZM9.42675 9.0544C8.45784 9.593 7.36964 9.82269 6.27042 9.72144L7.82995 9.52386C8.11917 9.4873 8.34534 9.2637 8.39362 8.98409L10.3434 7.95636C10.4561 7.89683 10.5972 7.93034 10.6713 8.0344C10.7146 8.09487 10.7294 8.16847 10.713 8.24112C10.6966 8.31378 10.6518 8.37354 10.5871 8.40964C10.5871 8.40964 10.5871 8.40964 10.5869 8.40964L9.42675 9.0544Z" fill="black"/>
      <path d="M9.1643 10.3123C9.04523 10.3773 8.9243 10.4443 8.80195 10.5123C7.76602 11.0867 6.5918 11.7376 5.46375 11.6368L1.46789 11.2801C1.46273 11.2796 1.45758 11.2794 1.45219 11.2794H0.352266V9.30171H1.45219C1.49391 9.30171 1.53422 9.28694 1.56586 9.25999C1.56914 9.25717 1.90453 8.97545 2.40984 8.71999C2.49656 8.67616 2.53148 8.57022 2.48742 8.48327C2.44359 8.39655 2.33742 8.36186 2.2507 8.40569C1.82086 8.62319 1.50797 8.85616 1.38984 8.94944H0.17625C0.0789844 8.94944 0 9.02819 0 9.12569V11.4556C0 11.5529 0.07875 11.6319 0.17625 11.6319H1.44445L5.43258 11.9879C5.52258 11.9958 5.61258 11.9998 5.70234 11.9998C6.84609 11.9998 7.97156 11.3759 8.97281 10.8205C9.09445 10.753 9.21445 10.6864 9.33305 10.6219C9.41836 10.5753 9.45 10.4684 9.40336 10.3829C9.35672 10.2973 9.24984 10.2659 9.1643 10.3123Z" fill="black"/>
    </g>
    <defs>
      <clipPath id="clip0_1403_5797">
        <rect width="12" height="12" fill="white"/>
      </clipPath>
    </defs>
  </svg>
</template>

<script>
export default {
  name: "IconOrder"
}
</script>

<style scoped>

</style>
