<template>
  <div>
    <div class="d-flex justify-space-between">
      <h2 class="title" :class="isMobile ? 'mobile-title' : ''">
        <span class="border-left-line" :class="isMobile ? 'mobile-view' : ''"></span>
<!--        <a-icon type="bar-chart"/>-->
        Thống kê chung
      </h2>
      <SaveReportComponent v-if="isMobile && !isMultiplePlatform" :is-saved-default="isSavedReport" class=""/>
    </div>

    <div>
      <div class="justify-center" :class="isDesktop ? 'flex' : getChartLayoutClassMobileView()">
        <div v-if="!showCommission && revenueTotal" class="metric-statistic-general-container">
          <MetricRectangle :value="revenueTotal | formatCurrency" title="Doanh số"
                           :title-comparison="`So với ${textComparison}`"
                           :value-comparison="comparisonRevenueTotal | formatCurrency"
                           :has-detail="hasGeneralStatisticDetail('order_revenue')"
                           @clickDetail="onClickDetail('order_revenue')"
                           detail-color="rgba(255, 135, 160, 1)"
                           :bg-color="'rgba(237, 188, 198, 0.71)'"
                           title-color="#000"
                           :value-color="'#fd397a'"
          >
            <a-icon type="dollar" class="icon"
                    style="font-size: 50px; margin-right: 10px; color: rgba(253,57,122,.1);"/>
          </MetricRectangle>
        </div>
        <div class="metric-statistic-general-container" v-if="orderTotal">
          <MetricRectangle :value="orderTotal | formatNumberHuman" title="Sản phẩm đã bán"
                           :title-comparison="`So với ${textComparison}`"
                           :value-comparison="comparisonOrderTotal | formatNumberHuman"
                           :has-detail="hasGeneralStatisticDetail('order_count')"
                           @clickDetail="onClickDetail('order_count')"
                           :value-color="'rgba(46, 124, 246, 1)'"
                           :bg-color="'rgba(190, 208, 235, 0.71)'"
                           title-color="#1f1f1f"
                           detail-color="rgba(75, 145, 255, 1)"
          >
            <a-icon type="tag" class="icon"
                    style="font-size: 50px; margin-right: 10px; color: #91d5ff;"/>
          </MetricRectangle>
        </div>
        <div class="metric-statistic-general-container" v-if="shopTotal">
          <MetricRectangle :value="shopTotal | formatNumberHuman" :title="getTabLabel('Shop có lượt bán')"
                           :title-comparison="`So với ${textComparison}`"
                           :value-comparison="comparisonShopTotal | formatNumberHuman"
                           :value-color="'rgba(3, 189, 55, 1)'"
                           :bg-color="'rgba(167, 217, 203, 0.71)'"
                           title-color="#000"
          >

            <a-icon type="shop" class="icon"
                    style="font-size: 50px; margin-right: 10px; color: rgba(10,187,135,.1);"/>
          </MetricRectangle>
        </div>
        <div v-else-if="commissionTotal" class="metric-statistic-general-container">
          <MetricRectangle :value="commissionTotal | formatCurrency" title="Hoa Hồng"
                           :title-comparison="`So với ${textComparison}`"
                           :value-comparison="comparisonCommissionTotal | formatCurrency"
                           :bg-color="'rgba(189, 197, 237, 0.71)'"
                           :value-color="'#fd397a'"
                           title-color="#000"
          >
            <a-icon type="dollar" class="icon"
                    style="font-size: 50px; margin-right: 10px; color: rgba(253,57,122,.1);"/>
          </MetricRectangle>
        </div>
        <div class="metric-statistic-general-container" v-if="reviewTotal">
          <MetricRectangle :value="reviewTotal | formatNumberHuman" title="Số lượt đánh giá"
                           :title-comparison="`So với ${textComparison}`"
                           :value-comparison="comparisonReviewTotal | formatNumberHuman"
                           :value-color="'rgba(133, 78, 223, 1)'"
                           :bg-color="'rgba(196, 185, 235, 0.71)'"
                           title-color="#000"
          >
            <a-icon type="tag" class="icon"
                    style="font-size: 50px; margin-right: 10px; color: rgba(93,120,255,.1);"/>
          </MetricRectangle>
        </div>
        <div class="metric-statistic-general-container" v-if="productTotal">
          <MetricRectangle :value="productTotal | formatNumberHuman" :title="getTabLabel('Sản phẩm có lượt bán')"
                           :title-comparison="`So với ${textComparison}`"
                           :value-comparison="comparisonProductTotal | formatNumberHuman"
                           :bg-color="'rgba(189, 197, 237, 0.71)'"
                           :value-color="'rgba(93, 120, 255, 1)'"
                           title-color="#000"
          >
            <a-icon type="tag" class="icon"
                    style="font-size: 50px; margin-right: 10px; color: rgba(93,120,255,.1);"/>
          </MetricRectangle>
        </div>

      </div>
      <a-row class="margin-top-15" v-if="isDesktop">
        <a-spin :spinning="isLoadingGeneralStatisticDetail">
          <template v-if="generalStatisticDetailData != null && generalStatisticDetailData.seriesData.length">
            <div>
              <span class="margin-right-10">Phân tích theo khoảng thời gian: </span>
              <a-select v-model="timeRangeSelected" style="width: 100px" @change="onChangeTimeRangeDetail" v-if="listTimeRange && listTimeRange.length">
                <template v-for="timeRange in listTimeRange">
                  <a-select-option :value="timeRange.value" :key="timeRange.value">{{timeRange.label}}</a-select-option>
                </template>
              </a-select>
            </div>
            <LineChart class="market-chart" color="#096dd9"
                       :title="generalStatisticDetailData['title']"
                       :sub-title="generalStatisticDetailData['subTitle']"
                       :categories-name="generalStatisticDetailData['categoriesName']"
                       :category-title="generalStatisticDetailData['categoryTitle']"
                       :series-data="generalStatisticDetailData['seriesData']"
                       :series-title="generalStatisticDetailData['seriesTitle']"
                       :unit="getTabUnit(metric)"
            />

          </template>
        </a-spin>
      </a-row>
      <a-modal title=""
               v-if="!isDesktop"
               :closable="true"
               :footer="null"
               v-model="modalVisible"
               :maskClosable="false"
      >
        <h2 slot="title" class="text-primary-500 font-normal">
          <a-icon type="line-chart" class="mr-2"/>
          <span>Chi tiết tăng trưởng</span>
        </h2>
        <a-spin :spinning="isLoadingGeneralStatisticDetail">
          <template v-if="generalStatisticDetailData != null && generalStatisticDetailData.seriesData.length">
            <div style="font-size: 12px">
              <span class="margin-right-10">Phân tích theo khoảng thời gian: </span>
              <a-select v-model="timeRangeSelected" style="width: 100px" @change="onChangeTimeRangeDetail" v-if="listTimeRange && listTimeRange.length">
                <template v-for="timeRange in listTimeRange">
                  <a-select-option :value="timeRange.value" :key="timeRange.value">{{timeRange.label}}</a-select-option>
                </template>
              </a-select>
            </div>
            <LineChart class="market-chart" color="#096dd9"
                       :title="generalStatisticDetailData['title']"
                       :sub-title="generalStatisticDetailData['subTitle']"
                       :categories-name="generalStatisticDetailData['categoriesName']"
                       :category-title="generalStatisticDetailData['categoryTitle']"
                       :series-data="generalStatisticDetailData['seriesData']"
                       :series-title="generalStatisticDetailData['seriesTitle']"
                       :chart-height="260"
                       :unit="getTabUnit(metric)"
            />

          </template>
        </a-spin>
      </a-modal>
    </div>
    <PopupNotification v-if="showPopupNotification === NOTIFICATION_TYPE.upgrade_permission_statistic_detail"
                       title="Hãy nâng cấp tài khoản"
                       :contents="['Tài khoản của bạn cần nâng cấp lên gói Standard để xem chi tiết tăng trưởng.']"
                       ok-title="Nâng cấp tài khoản"
                       @ok="onClickPricing"
    >
    </PopupNotification>
  </div>
</template>

<script>

  import MetricRectangle from '@/components/statistic/metric/MetricRectangle'
  import { formatCurrency, formatNumberHuman } from '@/helper/FormatHelper'
  import BtnSearchFilter from "@/components/button/BtnSearchFilter";
  import {
    mixinGetSearchStatisticAggComparisonResult,
    mixinGetSearchStatisticGeneralComparisonResult
  } from "@/mixin/store/MixinSearchStatisticComparisonResult";
  import {ALL_GENERAL_STATISTIC, NOTIFICATION_TYPE} from "@/constant/general/GeneralConstant";
  import {getPlatformById} from "@/service/platform/PlatformCommonService";
  import {mixinGetSearchStatisticResult} from "@/mixin/store/MixinSearchStatisticResult";
  import {MutationSearchStatisticResult} from "@/store/modules/SearchStatisticResultModule/mutation";
  import {MutationSearchFilter} from "@/store/modules/SearchFilterModule/mutation";
  import {mixinStoreGetterPermission, mixinStoreGetterUser} from "@/mixin/store/MixinUser";
  import {MutationGeneral} from "@/store/modules/GeneralModule/mutation";
  import {getDateFromOption, getLeafFieldValueFromObject} from "@/service/search/SearchService";
  import {PERMISSION} from "@/constant/general/PermissionConstant";
  import LineChart from "@/components/chart/LineChart";
  import moment from "moment";
  import {mixinStoreGetterGlobal} from "@/mixin/store/MixinGlobal";
  import PopupNotification from "@/views/popup/PopupNotification";
  import SaveReportComponent from "@/components/report/SaveReportComponent";

  export default {
    components: {
      LineChart,
      BtnSearchFilter,
      MetricRectangle,
      PopupNotification,
      SaveReportComponent
    },
    props: {
      showCommission: {
        default: false
      }
    },
    data() {
      let allTimeRange = [
          {
            label: 'ngày',
            value: 1
          },
          {
            label: 'tuần',
            value: 7
          },
          {
            label: 'tháng',
            value: 30
          },
        ]
      return {
        isInsightReportPage: this.$route.name === 'custom_market_view',
        isLoadingGeneralStatisticDetail: false,
        allTimeRange: allTimeRange,
        listTimeRange: allTimeRange,
        timeRangeSelected: null,
        metric: null,
        generalStatisticDetailStoreName: null,
        cache: {},
        modalVisible: null,
        NOTIFICATION_TYPE,
        isMultiplePlatform: false,
      }
    },
    async mounted() {
      this.$nextTick(function () {
        this.$emit('mounted')
      })
      this.isMultiplePlatform = this.options?.include_query?.platforms?.length > 1
    },
    created() {
    },
    methods: {
      async onSearchAdvanced() {
        // let options = this.$store.state.SearchFilterModule.options
        // let resultPermission = await canFilterProduct(options)

        // if (resultPermission.isPermission) {
        await this.$router.push({name: 'insight' })
        // } else {
        //   if (this.$store.state.GeneralModule.actionName === ActionNameConstants.HOME_PAGE) {
        //     hiển thị popup tới trang market
        // this.$store.commit(`GeneralModule/${MutationGeneral.setShowPopupSearchDetail}`, true)
        // } else {
        //   this.$notification['warning']({
        //     message: resultPermission.title,
        //     description: resultPermission.message,
        //   })
        // }
        // }
      },
      async onClickDetail(metric) {
        if (!this.isLogin) {
          // this.$notification['error']({
          //   message: 'Đăng nhập để tiếp tục',
          //   description: 'Chức năng này cần đăng nhập',
          // })
        this.$router.push({ name: 'login' })
          return
        }
        if (!this.hasGeneralStatisticDetail(metric)) {
          this.showPopupNotification = NOTIFICATION_TYPE.upgrade_permission_statistic_detail
          return
        }
        if (this.freeSearchCount <= 0) {
          this.showPopupNotification = NOTIFICATION_TYPE.out_of_free_search
          return
        }
        this.modalVisible = true
        this.metric = metric
        if (this.timeRangeSelected === this.cache[metric]) {
          this.generalStatisticDetailStoreName = getLeafFieldValueFromObject(ALL_GENERAL_STATISTIC, 'apiKeyDetailStatistic', metric, 'storeNameDetailStatistic')
          return
        }
        this.isLoadingGeneralStatisticDetail = true
        let optionsFilter = this.$store.state.SearchFilterModule.options
        if (!this.timeRangeSelected) {
          let durationDay = optionsFilter.durationDay
          if (!durationDay) {
            if (!optionsFilter['start_date'] || !optionsFilter['end_date']) {
              durationDay = 0
            } else {
              let  startDate = moment(getDateFromOption(optionsFilter[`start_date`]))
              let endDate = moment(getDateFromOption(optionsFilter[`end_date`]))
              durationDay = endDate.diff(startDate, "day")
            }
          }
          const minPoint = 4
          this.timeRangeSelected = durationDay
            ? (
              durationDay > 30 * (minPoint - 1)
                ? 30
                : (
                  durationDay > 7 * (minPoint - 1) ? 7 : 1
                )
            )
            : 30
          if (durationDay) {
            this.listTimeRange = this.allTimeRange.filter(item => item.value < durationDay)
          }
        }
        let options = {
          ...optionsFilter,
          "interval": this.timeRangeSelected,
          "data_type": metric
        }
        this.generalStatisticDetailStoreName = getLeafFieldValueFromObject(ALL_GENERAL_STATISTIC, 'apiKeyDetailStatistic', metric, 'storeNameDetailStatistic')
        await this.$store.dispatch(`${MutationSearchFilter.MODULE_PATH}/${MutationSearchFilter.SEARCH_DETAIL_STATISTIC}`, options)
        this.cache[metric] = this.timeRangeSelected
        this.isLoadingGeneralStatisticDetail = false
      },
      hasGeneralStatisticDetail(metric) {
        if (this.$route.name === 'market_global') {
          return null
        }
        return !!PERMISSION[this.permissionAvailableName].availableGeneralStatisticDetail?.includes(metric)
      },
      getTabLabel(label) {
        return label
        // return this.platformName === ALL_PLATFORM_BASE_OBJECT.lazada.name ? label.replace('có lượt bán', 'có lượt đánh giá') : label
      },
      getTabUnit(tab) {
        return ALL_GENERAL_STATISTIC[tab] ? ALL_GENERAL_STATISTIC[tab].unit : ''
      },
      onChangeTimeRangeDetail() {
        this.onClickDetail(this.metric)
      },
      getChartLayoutClassMobileView() {
        let layoutClass = 'grid grid-cols-2 gap-3 mobile-view mt-4 w-full-all-child '
        // layoutClass += this.comparisonStatus ? 'w-full-all-child ' : this.statisticCount%2 === 1 ? 'w-full-first-child ' : ''
        return layoutClass
      },
      async onClickPricing() {
        this.$store.commit(`GeneralModule/${MutationGeneral.setShowPopupSignUpLeadForm}`, true)
      }
    },
    computed: {
      showPopupNotification: {
        get() {
          return this.$store.state.GeneralModule.showPopupNotification
        },
        async set(value) {
          await this.$store.commit(`GeneralModule/${MutationGeneral.setShowPopupNotification}`, value)
        }
      },
      generalStatisticDetailData() {
        return this.generalStatisticDetailStoreName ? this[MutationSearchStatisticResult[this.generalStatisticDetailStoreName]] : null
      },
      options: {
        get() {
          return this.$store.state.SearchFilterModule.options
        }
      },
      platformName() {
        return getPlatformById(this.options?.include_query?.platforms?.[0]).name
      },
      actionName: {
        get() {
          return this.$store.state.GeneralModule.actionName
        }
      },
      isLoading: {
        get() {
          return this.$store.state.SearchFilterModule.isLoading
        }
      },
      orderTotal: {
        get() {
          return this.$store.state.SearchStatisticResultModule.orderTotal
        }
      },
      revenueTotal: {
        get() {
          return this.$store.state.SearchStatisticResultModule.revenueTotal
        }
      },
      reviewTotal: {
        get() {
          return this.$store.state.SearchStatisticResultModule.reviewTotal
        }
      },
      commissionTotal: {
        get() {
          return this.$store.state.SearchStatisticResultModule.commissionTotal
        }
      },
      productTotal: {
        get() {
          return this.$store.state.SearchStatisticResultModule.productTotal
        }
      },
      shopTotal: {
        get() {
          return this.$store.state.SearchStatisticResultModule.shopTotal
        }
      },
      statisticCount() {
        return +!!this.orderTotal + +!!this.revenueTotal + +!!this.reviewTotal + +!!this.commissionTotal + +!!this.productTotal + +!!this.shopTotal
      },
      isSavedReport: {
        get() {
          return this.$store.getters[`${MutationSearchFilter.MODULE_PATH}/${MutationSearchFilter.isSavedReport}`]
        }
      },
    },
    mixins: [
      mixinStoreGetterGlobal,
      mixinStoreGetterUser,
      mixinStoreGetterPermission,
      mixinGetSearchStatisticResult,
      mixinGetSearchStatisticAggComparisonResult,
      mixinGetSearchStatisticGeneralComparisonResult
    ],
    filters: {
      formatCurrency: formatCurrency,
      formatNumberHuman: formatNumberHuman,
    },
  }
</script>

<style scoped lang="scss">
  .metric-statistic-general-container {
    width: 100%;
    margin: 8px 0;

    @media only screen and (min-width: 700px){
      flex-grow: 1;
      max-width: 350px;
      width: calc(20% - 16px);
      margin: 8px 8px;
    }
  }
  .note-box {

    &.mobile-view {
      color: #989898;
      font-size: 10px;
    }

    .charting-percent {
      color: #096dd9;
    }
  }
  .mobile-view {
    > * {
      margin: 0;
    }

    &.w-full-all-child > * {
      grid-column-start: 1;
      grid-column-end: 3
    }

    &.w-full-first-child > *:first-child {
      grid-column-start: 1;
      grid-column-end: 3
    }
  }
</style>
