<template>
  <a-modal :closable="true"
           :maskClosable="true"
           :footer="null"
           v-model="showPopupChatToDownload"
           :width="500"
  >
    <div slot="title" :style="isMobile ? 'font-size: 16px' : ''">
      <p class="text-gray-700">
        <a-icon type="download" class="mr-2"/>
        <span>Download danh sách</span>
      </p>
    </div>
    <div style="font-size: 16px" :style="isMobile ? 'font-size: 12px' : ''">
      <p>Chat với chúng tôi để nhận file danh sách (Miễn phí).</p>
      <p>Hoặc <span class="text-primary-500 cursor-pointer" @click="goToPricing">Nâng cấp tài khoản</span>.</p>
      <div class="flex flex-row justify-end items-end">
        <img :src="urlSticker" style="width: auto;height: 67px; margin-right: 10px" alt="beecost-login" class="img-sticker">
        <button type="button"
                style="align-items: center; display: flex; margin-top: 20px; padding: 10px 20px; background-color: white; border: 1px solid rgba(170, 170, 170, 0.34)"
                class="btn-login shadow-md cursor-pointer"
                @click="onClickChatFacebook">
          <IconMessenger style=" margin-right: 18px; width: 45px;"/>
          <span>Chat trên Facebook</span>
        </button>
      </div>
    </div>
  </a-modal>
</template>

<script>
import {MutationGeneral} from "@/store/modules/GeneralModule/mutation";
import {getUrlGPhotoSize} from "@/helper/GPhotoHelper";
import {StickerConstants} from "@/constant/beecost/BeeCostResourceConstant";
import {MESSENGER_ID} from "@/constant/general/GeneralConstant";
import IconMessenger from "@/components/icon/IconMessenger";
import {getUserProfile} from "@/service/user/UserProfileService";
import {ALL_CATEGORIES_BY_PLATFORM} from "@/constant/product/ProductCategoriesConstant";
import {getPlatformById} from "@/service/platform/PlatformCommonService";
import {mixinStoreGetterGlobal} from "@/mixin/store/MixinGlobal";

export default {
  name: "PopupChatToDownload",
  components: {
    IconMessenger
  },
  props: {
    source: {
      required: true
    }
  },
  data() {
    return {
      urlSticker: getUrlGPhotoSize(StickerConstants.search, '200'),
    }
  },
  computed: {
    showPopupChatToDownload: {
      get() {
        return !!this.$store.getters[`${MutationGeneral.MODULE_PATH}/${MutationGeneral.showPopupChatToDownload}`]
      },
      async set(value) {
        await this.$store.commit(`${MutationGeneral.MODULE_PATH}/${MutationGeneral.showPopupChatToDownload}`, value)
      }
    },
  },
  methods: {
    async onClickChatFacebook() {
      const profile = await getUserProfile()
      const options = this.$store.state.SearchFilterModule.options
      let iCategoriesSelected = this.$store.state.SearchFilterModule.options?.include_query?.categories
      let platform = getPlatformById(options?.include_query?.platforms?.[0])
      let searchCategory = ALL_CATEGORIES_BY_PLATFORM[platform.uniqueName].find(item => item.value === iCategoriesSelected?.[0])
      searchCategory = searchCategory ? searchCategory.label : 'all'
      let params = `action=DOWNLOAD_FILE_FROM_UI`
      params += `&email=${profile?.email ? profile.email : ''}`
      params += `&utm_source=${this.source ? this.source : ''}`
      params += `&utm_content=${searchCategory ? searchCategory : 'all'}`
      window.open(`https://m.me/${MESSENGER_ID}?ref=${encodeURIComponent(params)}`, '_blank')
    },
    goToPricing() {
      this.showPopupChatToDownload = false
      this.$router.push({name: 'pricing'})
    }
  },
  mixins: [
    mixinStoreGetterGlobal
  ]
}
</script>

<style scoped>

</style>
