import {http} from "@/plugin/http";
import {urlApiGetShopDetail} from "@/api/shop/ShopEndpoint";

const apiGetShopDetail = async (data) => {
  if (!data) {
    return null
  }
  let urlApi = urlApiGetShopDetail()
  try {
    let response = await http.post(urlApi, data)

    if (response?.data) {
      return response.data
    }
  } catch (e) {
    return {status: e.response.status, data: e.response.data}
  }
}

export {
  apiGetShopDetail
}
