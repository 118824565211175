<template>
  <div>
    <div class="flex">
      <div>
        <span class="margin-right-10">Phân tích theo khoảng thời gian: </span>
        <a-select v-model="timeRangeSelected" style="width: 100px" @change="onChangeOption" v-if="listTimeRange && listTimeRange.length">
          <template v-for="timeRange in listTimeRange">
            <a-select-option :value="timeRange.value" :key="timeRange.value">{{timeRange.label}}</a-select-option>
          </template>
        </a-select>
      </div>
      <div class="ml-4">
        <span class="margin-right-10">Số lượng shop: </span>
        <a-select v-model="shopLimit" style="width: 100px" @change="onChangeOption">
          <a-select-option :value="5">5</a-select-option>
          <a-select-option :value="10">10</a-select-option>
        </a-select>
      </div>
    </div>
    <div class="mt-4">
      <span class="margin-right-10">Phân tích theo: </span>
      <a-select v-model="dataTypeSelect" style="width: 200px" @change="onChangeOption">
        <template v-for="timeRange in listDataType">
          <a-select-option :value="timeRange.value" :key="timeRange.value">{{timeRange.label}}</a-select-option>
        </template>
      </a-select>
    </div>
    <a-spin :spinning="isLoading">
      <LineChart class="market-chart"
                 v-if="isDisplay && !isLoading && !!shopsInsight && shopsInsight.length > 0"
                 :title="`So sánh tăng trưởng các shop`"
                 :sub-title="`Theo ${listDataType.find(item => item.value === dataTypeSelect).label} ${listTimeRange.find(item => item.value === timeRangeSelected).label}`"
                 :multi-series="shopsInsight.map(item => {return {name: item.name, data: item.data}})"
                 :series-title="listDataType.find(item => item.value === dataTypeSelect).label"
                 :categories-name="shopsInsight[0].label"
                 :unit="listDataType.find(item => item.value === dataTypeSelect).unit"
      />
    </a-spin>
  </div>
</template>

<script>
import LineChart from "@/components/chart/LineChart";
import {MutationSearchFilter} from "@/store/modules/SearchFilterModule/mutation";
import {mixinStoreGetterShops} from "@/mixin/store/MixinShops";
import moment from "moment";
import {getDateFromOption} from "@/service/search/SearchService";

export default {
  name: "ShopChart",
  components: {
    LineChart
  },
  methods: {
    async onGetChartData() {
      this.isLoading = true
      let optionsFilter = {...this.$store.state.SearchFilterModule.options}
      optionsFilter['listShop'] = this.shops
        .slice(0, this.shopLimit)
        .map(item => {
        return {shopId: item.shop_platform_id, shopName: item.shop_name}
      })
      optionsFilter.interval = this.timeRangeSelected
      optionsFilter["data_type"] = this.dataTypeSelect
      await this.$store.dispatch(`SearchFilterModule/${MutationSearchFilter.SEARCH_INSIGHT_SHOPS}`, optionsFilter)
      this.isLoading = false
    },
    onChangeOption() {
      this.onGetChartData()
    }
  },
  mixins: [
    mixinStoreGetterShops
  ],
  watch: {
  },
  props: {
    isDisplay: {
      default: false,
    },
  },
  data() {
    return {
      multiSeries: null,
      isLoading: false,
      shopLimit: 5,
      dataTypeSelect: 'order_revenue',
      listDataType: [
        {
          value: 'order_count',
          label: 'Số sản phẩm bán được',
          unit: ' Sp'
        },
        {
          value: 'order_revenue',
          label: 'Doanh số',
          unit: ' VNĐ'
        }
      ],
      timeRangeSelected: 7,
      listTimeRange: [
        {
          value: 1,
          label: 'Ngày'
        },
        {
          value: 7,
          label: 'Tuần'
        },
        {
          value: 30,
          label: 'Tháng'
        }
      ]
    }
  },
  mounted() {
    let durationDay = this.$store.state.SearchFilterModule.options.durationDay
    if (!durationDay) {
      let startDate = this.$store.state.SearchFilterModule.options.start_date
      let endDate = this.$store.state.SearchFilterModule.options.end_date
      durationDay = moment(getDateFromOption(endDate)).diff(moment(getDateFromOption(startDate)), 'day')
    }
    const minPoint = 2
    this.timeRangeSelected = durationDay > 30 * minPoint
      ? 30
      : durationDay > 7 * minPoint
        ? 7
        : 1
    this.listTimeRange = this.listTimeRange.filter(item => item.value <= durationDay/minPoint)
    if (!this.shopsInsight?.length) {
      this.onGetChartData()
    }
  },
}
</script>

<style scoped>

</style>
