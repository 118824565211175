<template>
  <div class="market-view-iframe">
    <div class="container-bg-white container-space-default text-black flex items-center justify-space-between">
      <div class="text-lg font-medium">
        Số liệu Thị trường TMĐT
        <span class="text-sm font-normal">- Cung cấp bởi BeeCost Market</span>
      </div>
      <div class="text-base font-medium text-primary-500">Tư vấn chuyên sâu số liệu TMĐT miễn phí</div>
    </div>
    <SearchFilter @search="onCLickSearch" @reset="onCLickReset" />
    <div
      style="text-align: center; margin-top: 16px"
      v-if="permissionAvailableName"
    >
      <template
        v-if="
          (waitingTransaction && waitingTransaction.length > 0) ||
          (updatedTransaction && updatedTransaction.length > 0)
        "
      >
        <p v-if="waitingTransaction && waitingTransaction.length === 1">
          Bạn đang có {{ waitingTransaction.length }} lệch chờ xác nhận thanh
          toán,
          <span
            class="text-highlight"
            style="cursor: pointer; text-decoration: underline"
            @click="
              onClickShowPaymentDetail(
                waitingTransaction[0].voucher_id,
                waitingTransaction[0].voucher_code
              )
            "
            >Xem chi tiết</span
          >
        </p>
        <p v-if="waitingTransaction && waitingTransaction.length > 1">
          Bạn đang có {{ waitingTransaction.length }} lệch chờ xác nhận thanh
          toán,
          <span
            class="text-highlight"
            style="cursor: pointer; text-decoration: underline"
            @click="onClickShowPaymentDetail"
            >Xem chi tiết</span
          >
        </p>
        <template v-if="updatedTransaction && updatedTransaction.length === 1">
          <p v-if="updatedTransaction[0].isComplete">
            Bạn đã thanh toán thành công và được áp dụng gói cước,
            <span
              class="text-highlight"
              style="cursor: pointer; text-decoration: underline"
              @click="
                onClickShowPaymentDetail(
                  updatedTransaction[0].voucher_id,
                  updatedTransaction[0].voucher_code
                )
              "
              >Xem chi tiết</span
            >
          </p>
          <p v-else>
            Có lỗi xảy ra trong việc thanh toán gói cước của bạn,
            <span
              class="text-highlight"
              style="cursor: pointer; text-decoration: underline"
              @click="
                onClickShowPaymentDetail(
                  updatedTransaction[0].voucher_id,
                  updatedTransaction[0].voucher_code
                )
              "
              >Xem chi tiết</span
            >
          </p>
        </template>
        <template
          v-else-if="updatedTransaction && updatedTransaction.length > 1"
        >
          <p>
            {{ updatedTransaction.length }} Thanh toán của bạn đã được cập nhật
            trạng thái,
            <span
              class="text-highlight"
              style="cursor: pointer; text-decoration: underline"
              @click="onClickShowPaymentDetail"
              >Xem chi tiết</span
            >
          </p>
        </template>
      </template>
    </div>
    <a-spin
      v-if="responseStatus && permissionAvailableName"
      :spinning="isClickSearch"
      class="
        report-content
        container-bg-white container-space-default
        border-solid
      "
      id="report-wrap"
    >
      <div
        class="duration-range-comparison-wrap"
        v-if="
          !isClickSearch &&
          durationComparisonArray &&
          durationComparisonArray.length
        "
      >
        <div
          class="duration-range-comparison"
          v-if="
            isBusinessAccount ||
            isHigherPriorityAccount(PERMISSION.market_standard.priority)
          "
        >
          <span>So sánh với</span>
          <div class="duration-range-comparison-option-container">
            <div
              v-for="(item, index) in durationComparisonArray"
              :key="index"
              class="duration-range-comparison-option"
              :class="{
                active: durationCompareIndex === index,
                disabled:
                  !isBusinessAccount &&
                  !isHigherPriorityAccount(PERMISSION.market_standard.priority),
              }"
              @click="onClickComparison(index)"
            >
              {{ item.label }}
            </div>
          </div>
        </div>
      </div>
      <div class="relative">
        <h2 class="page-title">Báo cáo Phân tích thị trường</h2>
      </div>

      <h2
        class="page-sub-title"
        v-if="
          !isClickSearch &&
          isBusinessAccount &&
          durationRangeOptionArray &&
          durationRangeOptionArray[0] &&
          durationRangeOptionArray[1]
        "
      >
        <template
          v-if="
            !durationRangeOptionArray[0].isSame(durationRangeOptionArray[1])
          "
        >
          Từ {{ durationRangeOptionArray[0].format("DD-MM-YYYY") }} đến
          {{ durationRangeOptionArray[1].format("DD-MM-YYYY") }}
        </template>
        <template v-else>
          Cho ngày {{ durationRangeOptionArray[0].format("DD-MM-YYYY") }}
        </template>
      </h2>
      <h2
        class="page-sub-title"
        v-if="
          !isClickSearch &&
          isBusinessAccount &&
          typeof durationCompareIndex === 'number' &&
          durationComparisonArray &&
          durationComparisonArray.length
        "
      >
        <template
          v-if="
            !durationComparisonArray[durationCompareIndex].value[0].isSame(
              durationComparisonArray[durationCompareIndex].value[1]
            )
          "
        >
          So sánh với
          {{
            durationComparisonArray[durationCompareIndex].value[0].format(
              "DD-MM-YYYY"
            )
          }}
          đến
          {{
            durationComparisonArray[durationCompareIndex].value[1].format(
              "DD-MM-YYYY"
            )
          }}
        </template>
        <template v-else>
          So sánh với ngày
          {{
            durationComparisonArray[durationCompareIndex].value[0].format(
              "DD-MM-YYYY"
            )
          }}
        </template>
      </h2>
      <div class="container-space-default border-solid" v-if="!isClickSearch">
        <StatisticGeneral @mounted="onMountedStatisticGeneral" />
      </div>
      <div v-if="!isClickSearch">
        <div class="container-space-default border-solid">
          <MarketChartV2
            :is-comparison-mode="typeof durationCompareIndex === 'number'"
            :is-search-demo-init="isSearchDemoInit"
          />
        </div>
      </div>
    </a-spin>

    <div
      class="container-bg-white container-space-default border-solid"
      v-if="!responseStatus || !permissionAvailableName"
    >
      <a-row :gutter="24" type="flex" class="product-empty" align="middle">
        <a-col :span="24" :md="12" :lg="12" :xl="12" :xxl="12" class="">
          <div class="content">
            Phân tích thị trường dựa trên phân tích Big Data
          </div>
        </a-col>
        <a-col :span="24" :md="12" :lg="12" :xl="12" :xxl="12">
          <div class="">
            <img
              src="@/assets/img/product/empty-product.svg"
              class="img-responsive cover"
            />
          </div>
        </a-col>
      </a-row>
    </div>
    <PopupChatToDownload :source="popupDownloadVisible" />
    <PopupNotification
      v-if="showPopupNotification === NOTIFICATION_TYPE.create_saved_report"
      title="Tạo Báo cáo"
      :contents="['Vui lòng thực hiện lệnh Phân tích và Lưu Báo cáo']"
      @ok="$router.push({ query: { popup: undefined } })"
      @cancel="$router.push({ query: { popup: undefined } })"
    />
  </div>
</template>

<script>
import Default from "@/layout/Default";
import TableProduct from "@/views/search/TableProduct";
import MetricRectangle from "@/components/statistic/metric/MetricRectangle";
import { formatCurrency, formatNumberHuman } from "@/helper/FormatHelper";
import SearchFilter from "./SearchFilter";
import { MutationSidebar } from "@/store/modules/SidebarModule/mutation";
import { MutationGeneral } from "@/store/modules/GeneralModule/mutation";
import {
  ActionNameConstants,
  NOTIFICATION_TYPE,
} from "@/constant/general/GeneralConstant";
import { TRANSACTION_STATE } from "@/constant/pricing/PricingPlansConstant";
import BarChart from "@/components/chart/BarChart";
import LineChart from "@/components/chart/LineChart";
import ColumnChart from "@/components/chart/ColumnChart";
import PieChart from "@/components/chart/PieChart";
import {
  getListVoucherWaiting,
  isLogined,
  shareCustomQuery,
} from "@/service/user/UserProfileService";
import StatisticGeneral from "@/views/market/StatisticGeneral";
import {
  downloadFile,
  getFileProductData,
  getListShopData,
} from "@/service/market/MarketService";
import { URL_WEB } from "@/config/ConfigEndpoint";
import { apiSearchDemo } from "@/api/product/ApiProductSearch";
import { MutationSearchFilter } from "@/store/modules/SearchFilterModule/mutation";
import {
  findPlanInfo,
  getListTransaction,
} from "@/service/payment/PaymentService";
import {
  getDateFromOption,
  getDurationPrevious,
  getDurationPreviousQuarter,
  getDurationRange,
  getSearchConfig,
  isOverRange,
} from "@/service/search/SearchService";
import moment from "moment";
import {
  mixinStoreGetterUser,
  mixinStoreGetterPayment,
  mixinStoreGetterPermission,
} from "@/mixin/store/MixinUser";
import { MutationSearchStatisticComparisonResult } from "@/store/modules/SearchStatisticComparisonResultModule/mutation";
import { fbTrackingSearch } from "@/service/tracking/FacebookTrackingService";
import {
  ALL_FIELD_FILTER,
  ALL_FIXED_DURATION_DAY,
} from "@/constant/search/SearchConstant";
import MarketChartV2 from "@/views/market/MarketChartV2";
import { getPlatformById } from "@/service/platform/PlatformCommonService";
import { PERMISSION } from "@/constant/general/PermissionConstant";
import TableShop from "@/views/search/TableShop";
import TableKeyword from "@/views/search/TableKeyword";
import PopupChatToDownload from "@/views/popup/PopupChatToDownload";
import MobileDefault from "@/layout/MobileDefault";
import { mixinStoreGetterGlobal } from "@/mixin/store/MixinGlobal";
import ShopChart from "@/components/chart/ShopChart";
import { mixinStoreGetterShops } from "@/mixin/store/MixinShops";
import { MutationSearchShopsResult } from "@/store/modules/SearchShopsResultModule/mutation";
import { MutationSearchKeywordsResult } from "@/store/modules/SearchKeywordsResultModule/mutation";
import { mixinStoreGetterProducts } from "@/mixin/store/MixinProducts";
import ProductItem from "@/components/product/ProductItem";
import Icon4Square from "@/assets/icon/Icon4Square";
import IconList from "@/assets/icon/IconList";
import Pagination from "@/components/common/Pagination";
import ProductItemMobile from "@/components/product/ProductItemMobile";
import IconSort from "@/assets/icon/IconSort";
import PopupSortMobile from "@/views/popup/PopupSortMobile";
import { toNumber } from "@/helper/StringHelper";
import SaveReportComponent from "@/components/report/SaveReportComponent";
import PopupNotification from "@/views/popup/PopupNotification";
import { apiDownloadGroupSearch } from "@/api/market/ApiMarket";
import TableBrand from "@/views/table/TableBrand";
import { MutationSearchBrandsResult } from "@/store/modules/SearchBrandsResultModule/mutation";
import IconNotification from "@/assets/icon/IconNotification";
import { BRAND_NAME, SOLOGAN } from "@/constant/general/GeneralConstant";

export default {
  components: {
    Default,
    MobileDefault,
    SearchFilter,
    TableProduct,
    MetricRectangle,
    BarChart,
    LineChart,
    ColumnChart,
    PieChart,
    MarketChartV2,
    StatisticGeneral,
    TableShop,
    TableKeyword,
    TableBrand,
    PopupChatToDownload,
    ShopChart,
    ProductItem,
    ProductItemMobile,
    Icon4Square,
    IconList,
    IconSort,
    Pagination,
    PopupSortMobile,
    SaveReportComponent,
    PopupNotification,
    IconNotification,
  },
  data() {
    return {
      tabStatistic: "revenue",
      hash: this.$route.query.hash,
      searchOptions: null,
      url: null,
      isLoadingShareCustomQuery: false,
      isDownloadingCSV: false,
      isDownloadingShop: false,
      isDownloadingBrand: false,
      popupDownloadVisible: null,
      limitLineDownloadProduct: null,
      typeDownloadProduct: null,
      limitLineDownloadShop: null,
      limitLineDownloadBrand: null,
      typeDownloadShop: null,
      typeDownloadBrand: null,
      waitingTransaction: 0,
      updatedTransaction: null,
      durationCompareIndex: null,
      durationComparisonArray: null,
      durationRangeOptionArray: null,
      isClickSearch: false,
      isSearchDemoInit: false,
      PERMISSION: PERMISSION,
      activeTab: "table-product",
      showPopupTableFullProduct: false,
      shopViewType: "table",
      productViewType: "table",
      allProductSort: [],
      productSortValue: null,
      productSortDirection: null,
      isLoadingProductData: false,
      popupSortProductVisible: false,
      NOTIFICATION_TYPE,
      isMultiplePlatform: false,
      BRAND_NAME,
      SOLOGAN,
    };
  },
  async mounted() {
    // if (typeof this.hash === 'string') {
    //   this.$router.push({name: 'custom_market_view', params: {hash: this.hash}})
    //   return
    // }
    window.scroll(0, 0);
    if (await isLogined()) {
      let allPayment = await getListTransaction();
      this.waitingTransaction = allPayment?.filter(
        (transaction) => transaction.state === TRANSACTION_STATE.WAITING
      );
      this.updatedTransaction = await this.getUpdateStateTransaction(
        allPayment
      );
    }
    let popup = this.$route.query["popup"];
    this.showPopupNotification =
      popup === NOTIFICATION_TYPE.create_saved_report
        ? NOTIFICATION_TYPE.create_saved_report
        : "";
    let urlShop = this.$route.query["shop_url"];
    let savedReportHash = this.$route.query["saved-report"];
    if (!urlShop && !savedReportHash) {
      this.isSearchDemoInit = true;
      await this.updateSearchConfig();
      this.updateState();
    }
  },
  created() {
    // update state for UI
    // Cập nhật trạng thái {menu, actionName} đang ở màn hình search
    this.$store.commit(
      `GeneralModule/${MutationGeneral.setActionName}`,
      ActionNameConstants.MARKET
    );
    this.$store.commit(
      `SidebarModule/${MutationSidebar.menuSelected}`,
      ActionNameConstants.MARKET
    );
  },

  methods: {
    onCLickSearch(value) {
      this.isSearchDemoInit = false;
      this.isClickSearch = value;
      if (value) {
        this.shopViewType = "table";
        this.productViewType = "table";
        this.$store.dispatch(
          `${MutationSearchShopsResult.MODULE_PATH}/${MutationSearchShopsResult.RESET}`
        );
        this.$store.dispatch(
          `${MutationSearchBrandsResult.MODULE_PATH}/${MutationSearchBrandsResult.RESET}`
        );
        this.$store.dispatch(
          `${MutationSearchKeywordsResult.MODULE_PATH}/${MutationSearchKeywordsResult.RESET}`
        );
      }
      this.isMultiplePlatform =
        this.options?.include_query?.platforms?.length > 1;
    },
    onCLickReset(value) {
      this.isSearchDemoInit = true;
      this.isClickSearch = value;
      if (value) {
        this.shopViewType = "table";
        this.productViewType = "table";
        this.$store.dispatch(
          `${MutationSearchShopsResult.MODULE_PATH}/${MutationSearchShopsResult.RESET}`
        );
        this.$store.dispatch(
          `${MutationSearchBrandsResult.MODULE_PATH}/${MutationSearchBrandsResult.RESET}`
        );
        this.$store.dispatch(
          `${MutationSearchKeywordsResult.MODULE_PATH}/${MutationSearchKeywordsResult.RESET}`
        );
      }
    },
    async updateSearchConfig() {
      let searchConfig = await getSearchConfig(this.iPlatforms?.[0]);
      this.searchConfig = {
        startDate:
          getDateFromOption(searchConfig?.start_date) ||
          moment("2020 01 01", "YYYY MM DD").toDate(),
        endDate:
          getDateFromOption(searchConfig?.current_date) || moment().toDate(),
      };
      if (!this.isSavedReport) {
        this.iDurationDay = this.iDurationDay
          ? this.iDurationDay
          : ALL_FIXED_DURATION_DAY[0];
        this.iDurationRange = getDurationRange(
          this.iDurationDay,
          this.searchConfig.endDate
        );
      }
    },
    getSearchCountdownWarningColor(remainSearchCount) {
      if (remainSearchCount <= 1) {
        return "#ee0033";
      }
      if (remainSearchCount <= 3) {
        return "#FFB655";
      }
      return "#008000";
    },
    async onClickShowNoti() {
      await this.$store.commit(
        `GeneralModule/${MutationGeneral.setShowPopupPermissionNotification}`,
        true
      );
    },
    async onClickShowPricing() {
      this.$store.commit(
        `GeneralModule/${MutationGeneral.setShowPopupSignUpLeadForm}`,
        true
      );
      // await this.$router.push({name: 'pricing'})
    },
    async onClickShowPaymentDetail(voucherId, voucherCode) {
      if (!voucherCode || !voucherId) {
        this.$router.push({ name: "user_view" });
        return;
      }
      this.$router.push({
        name: "transaction_detail",
        params: { voucherId: voucherId, voucherCode: voucherCode },
      });
    },
    async onClickDownloadListProduct() {
      this.popupDownloadVisible = null;
      this.isDownloadingCSV = true;
      if (
        !this.allLimitLineProductDownload?.[0] ||
        !this.allTypeProductDownload?.[0]
      ) {
        return;
      }
      if (!this.isLogin) {
        // this.$notification['error']({
        //   message: 'Đăng nhập để tiếp tục',
        //   description: 'Chức năng này cần đăng nhập',
        // })
        await this.$store.commit(
          `GeneralModule/${MutationGeneral.setShowPopupLogin}`,
          true
        );
        this.isDownloadingCSV = false;
        return;
      }
      if (
        this.isExpired ||
        !this.isHigherPriorityAccount(PERMISSION.market_default.priority)
      ) {
        // await this.$store.commit(`GeneralModule/${MutationGeneral.setShowPopupPermissionNotification}`, true)
        // this.$notification['error']({
        //   message: 'Hãy nâng cấp tài khoản',
        //   description: 'Tài khoản của bạn cần nâng cấp để tải danh sách sản phẩm',
        // })
        await this.$store.dispatch(
          `${MutationGeneral.MODULE_PATH}/${MutationGeneral.setShowPopupNotification}`,
          NOTIFICATION_TYPE.upgrade_permission
        );
        this.isDownloadingCSV = false;
        return;
      }
      // await this.$store.dispatch(`SearchFilterModule/${MutationSearchFilter.BUILD_OPTIONS}`)
      let optionsFilter = this.$store.state.SearchFilterModule.options;
      let optionsProduct = await this.$store.dispatch(
        `${MutationSearchFilter.MODULE_PATH}/${MutationSearchFilter.BUILD_PRODUCT_OPTIONS}`,
        optionsFilter
      );
      let options = {
        ...optionsFilter,
        ...optionsProduct,
      };
      if (options.durationDay !== 0) {
        delete options.start_date;
        delete options.end_date;
      }
      options.limit_line =
        this.limitLineDownloadProduct || this.allLimitLineProductDownload[0];
      options.file_type =
        this.typeDownloadProduct || this.allTypeProductDownload[0];
      // delete options.durationDay
      let data = await getFileProductData(options);
      let platformName = getPlatformById(
        options.include_query.platforms[0]
      ).name;
      downloadFile(
        data,
        `beecost-market-${platformName}-${this.durationDayString}`,
        options.file_type === "csv" ? "csv" : "xlsx"
      );
      this.isDownloadingCSV = false;
    },
    async onClickDownloadListShop() {
      this.popupDownloadVisible = null;
      this.isDownloadingShop = true;
      if (
        !this.allLimitLineShopDownload?.[0] ||
        !this.allTypeShopDownload?.[0]
      ) {
        return;
      }
      if (!this.isLogin) {
        // this.$notification['error']({
        //   message: 'Đăng nhập để tiếp tục',
        //   description: 'Chức năng này cần đăng nhập',
        // })
        await this.$store.commit(
          `GeneralModule/${MutationGeneral.setShowPopupLogin}`,
          true
        );
        this.isDownloadingShop = false;
        return;
      }
      if (
        this.isExpired ||
        !this.isHigherPriorityAccount(PERMISSION.market_basic.priority)
      ) {
        // await this.$store.commit(`GeneralModule/${MutationGeneral.setShowPopupPermissionNotification}`, true)
        // this.$notification['error']({
        //   message: 'Hãy nâng cấp tài khoản',
        //   description: 'Tài khoản của bạn cần nâng cấp lên Tài Khoản Doanh nghiệp để tải danh sách shop',
        // })
        await this.$store.dispatch(
          `${MutationGeneral.MODULE_PATH}/${MutationGeneral.setShowPopupNotification}`,
          NOTIFICATION_TYPE.upgrade_permission
        );
        this.isDownloadingShop = false;
        return;
      }
      // await this.$store.dispatch(`SearchFilterModule/${MutationSearchFilter.BUILD_OPTIONS}`)
      let optionsFilter = this.$store.state.SearchFilterModule.options;
      let optionsProduct = await this.$store.dispatch(
        `${MutationSearchFilter.MODULE_PATH}/${MutationSearchFilter.BUILD_PRODUCT_OPTIONS}`,
        optionsFilter
      );
      let options = {
        ...optionsFilter,
        ...optionsProduct,
      };
      if (options.durationDay !== 0) {
        delete options.start_date;
        delete options.end_date;
      }
      options.limit_line =
        this.limitLineDownloadShop || this.allLimitLineShopDownload[0];
      options.file_type = this.typeDownloadShop || this.allTypeShopDownload[0];
      // delete options.durationDay
      let data = await getListShopData(options);
      let platformName = getPlatformById(
        options.include_query.platforms[0]
      ).name;
      downloadFile(
        data,
        `beecost-market-shop-list-${platformName}-${this.durationDayString}`,
        options.file_type === "csv" ? "csv" : "xlsx"
      );
      this.isDownloadingShop = false;
    },
    async onClickDownloadListBrand() {
      this.popupDownloadVisible = null;
      this.isDownloadingBrand = true;
      if (!this.isLogin) {
        await this.$store.commit(
          `GeneralModule/${MutationGeneral.setShowPopupLogin}`,
          true
        );
        this.isDownloadingBrand = false;
        return;
      }
      if (!this.isAvailableToDownloadBrand) {
        await this.$store.dispatch(
          `${MutationGeneral.MODULE_PATH}/${MutationGeneral.setShowPopupNotification}`,
          NOTIFICATION_TYPE.upgrade_permission
        );
        this.isDownloadingBrand = false;
        return;
      }
      // await this.$store.dispatch(`SearchFilterModule/${MutationSearchFilter.BUILD_OPTIONS}`)
      let optionsFilter = this.$store.state.SearchFilterModule.options;
      let optionsProduct = await this.$store.dispatch(
        `${MutationSearchFilter.MODULE_PATH}/${MutationSearchFilter.BUILD_PRODUCT_OPTIONS}`,
        optionsFilter
      );
      let options = {
        ...optionsFilter,
        ...optionsProduct,
      };
      if (options.durationDay !== 0) {
        delete options.start_date;
        delete options.end_date;
      }
      options.limit_line =
        this.limitLineDownloadBrand || this.allLimitLineBrandDownload[0];
      options.file_type =
        this.typeDownloadBrand || this.allTypeBrandDownload[0];
      // delete options.durationDay
      let data = await apiDownloadGroupSearch(options, "brand");
      let platformName = getPlatformById(
        options.include_query.platforms[0]
      ).name;
      downloadFile(
        data,
        `beecost-market-brand-list-${platformName}-${this.durationDayString}`,
        options.file_type === "csv" ? "csv" : "xlsx"
      );
      this.isDownloadingBrand = false;
    },
    onClickBtnPromote() {
      this.$router.push({ name: "referral_program" });
      // this.showPopupPromotedNotification = true
    },
    async onShareReport() {
      this.isLoadingShareCustomQuery = true;
      if (document.location.href.includes("hash=")) {
        this.url = document.location.href;
        this.$store.dispatch(
          `GeneralModule/${MutationGeneral.setShowPopupShareLink}`,
          true
        );
        this.isLoadingShareCustomQuery = false;
        return;
      }
      let options = this.$store.state.SearchFilterModule.options;
      const hashString = await shareCustomQuery(options);
      if (!hashString) {
        this.$notification["error"]({
          message: "Đã xảy ra lỗi",
          description: "Chức năng này chỉ dành cho tài khoản cao cấp",
        });
        this.isLoadingShareCustomQuery = false;
        return;
      }
      this.url = this.$router;
      this.url = `${URL_WEB}/insight/${hashString}`;
      this.isLoadingShareCustomQuery = false;
      this.$store.dispatch(
        `GeneralModule/${MutationGeneral.setShowPopupShareLink}`,
        true
      );
    },
    async getUpdateStateTransaction(latestTransactionState) {
      let voucherCodeLocal = await getListVoucherWaiting();
      if (!voucherCodeLocal?.length) {
        return null;
      }
      return latestTransactionState
        ?.filter((updatedTransaction) => {
          if (
            voucherCodeLocal.includes(updatedTransaction.voucher_code) &&
            updatedTransaction.state !== TRANSACTION_STATE.WAITING
          ) {
            return true;
          }
          return false;
        })
        ?.map((transaction) => {
          let plan = findPlanInfo(transaction.voucher_type);
          let id = plan?.name ? plan.type : plan.id;
          return {
            voucher_id: id,
            isComplete: transaction.state === TRANSACTION_STATE.COMPLETE,
            ...transaction,
          };
        });
    },
    async onClickComparison(index) {
      if (
        (!this.isBusinessAccount &&
          !this.isHigherPriorityAccount(PERMISSION.market_standard.priority)) ||
        this.isLoading ||
        this.durationCompareIndex === index
      ) {
        return;
      }
      this.isSearchDemoInit = false;
      this.durationCompareIndex = index;
      this.isClickSearch = true;
      // await this.$store.dispatch(`SearchFilterModule/${MutationSearchFilter.BUILD_OPTIONS}`)
      let optionsFilter = this.$store.state.SearchFilterModule.options;
      // if (optionsFilter.durationDay) {
      //   let optionsText = JSON.stringify(optionsFilter)
      //   optionsText = optionsText.replaceAll(`${optionsFilter.durationDay}d`, 'custom')
      //   optionsText = optionsText.replaceAll(`${optionsFilter.durationDay}D`, 'CUSTOM')
      //   optionsFilter = JSON.parse(optionsText)
      // }
      let durationRange = this.durationComparisonArray[index]?.value;
      if (durationRange && durationRange[0] && durationRange[1]) {
        optionsFilter = {
          ...optionsFilter,
          start_date: durationRange[0].format("YYYY MM DD").replaceAll(" ", ""),
          end_date: durationRange[1].format("YYYY MM DD").replaceAll(" ", ""),
          durationDay: 0,
        };
        await this.$store.commit(
          `${MutationSearchFilter.MODULE_PATH}/${MutationSearchFilter.iDurationRangeComparison}`,
          durationRange
        );
      }
      let optionsGeneralStatistic = await this.$store.dispatch(
        `${MutationSearchFilter.MODULE_PATH}/${MutationSearchFilter.BUILD_GENERAL_STATISTIC}`,
        optionsFilter
      );
      // let optionsChartStatistic = this.iPlatforms[0] === ALL_PLATFORM_BASE_OBJECT.lazada.platform_id
      //   ? await this.$store.dispatch(`${MutationSearchFilter.MODULE_PATH}/${MutationSearchFilter.BUILD_REVIEW_COUNT_STATISTIC}`, optionsFilter)
      //   : await this.$store.dispatch(`${MutationSearchFilter.MODULE_PATH}/${MutationSearchFilter.BUILD_REVENUE_STATISTIC}`, optionsFilter)
      let optionsChartStatistic = await this.$store.dispatch(
        `${MutationSearchFilter.MODULE_PATH}/${MutationSearchFilter.BUILD_REVENUE_STATISTIC}`,
        optionsFilter
      );
      let options = {
        ...optionsFilter,
        statistics: [...optionsGeneralStatistic, ...optionsChartStatistic],
      };
      options.type =
        MutationSearchFilter.SET_RESPONSE_SEARCH_PRODUCT_COMPARISON;
      await this.$store.dispatch(
        `${MutationSearchStatisticComparisonResult.MODULE_PATH}/${MutationSearchStatisticComparisonResult.setTextComparison}`,
        this.durationComparisonArray[index]?.label
      );
      this.isClickSearch = !(await this.$store.dispatch(
        `SearchFilterModule/${MutationSearchFilter.SEARCH_PRODUCT}`,
        options
      ));
      fbTrackingSearch();
    },
    getDurationComparisonArray() {
      let result = [];
      if (!this.searchConfig || !this.durationRangeOptionArray) {
        return null;
      }
      let fixedRange = [
        this.searchConfig?.startDate,
        this.searchConfig?.endDate,
      ];
      let previous = getDurationPrevious(this.durationRangeOptionArray);
      if (previous && !isOverRange(previous.value, fixedRange)) {
        result.push(previous);
      }
      let previousQuarter = getDurationPreviousQuarter(
        this.durationRangeOptionArray
      );
      if (previousQuarter && !isOverRange(previousQuarter.value, fixedRange)) {
        result.push(previousQuarter);
      }
      return result;
    },
    getDurationRangeOptionArray() {
      let options = this.$store.state.SearchFilterModule.options;
      let durationDay = options.durationDay || ALL_FIXED_DURATION_DAY[1];
      let startDate = options[`start_date`];
      let endDate = options[`end_date`];
      if (!startDate || !endDate) {
        return getDurationRange(durationDay, this.searchConfig?.endDate);
      }
      startDate = moment(getDateFromOption(startDate));
      endDate = moment(getDateFromOption(endDate));
      if (!startDate || !endDate) {
        return getDurationRange(durationDay, this.searchConfig?.endDate);
      }
      return [startDate, endDate];
    },
    updateState() {
      this.durationRangeOptionArray = this.getDurationRangeOptionArray();
      this.durationComparisonArray = this.getDurationComparisonArray();
    },
    onMountedStatisticGeneral() {
      if (this.isSearchDemoInit) {
        return;
      }
      const reportWrapElem = this.isDesktop
        ? document.getElementById("report-wrap")
        : document.getElementById("mobile-report-wrap");
      reportWrapElem.scrollIntoView();
      // reportWrapElem.scrollIntoView({behavior: "smooth"}); //bug for chrome: not scroll
    },
    onClickBtnDownload(type) {
      this.popupDownloadVisible = type;
      switch (type) {
        case "product":
          if (!this.isAvailableToDownloadProduct) {
            this.showPopupChatToDownload = true;
            return;
          }
          this.limitLineDownloadProduct = this.allLimitLineProductDownload?.[0];
          this.typeDownloadProduct = this.allTypeProductDownload?.[0];
          break;
        case "shop":
          if (!this.isAvailableToDownloadShop) {
            this.showPopupChatToDownload = true;
            return;
          }
          this.limitLineDownloadShop = this.allLimitLineShopDownload?.[0];
          this.typeDownloadShop = this.allTypeShopDownload?.[0];
          break;
        case "brand":
          if (!this.isAvailableToDownloadBrand) {
            this.showPopupChatToDownload = true;
            return;
          }
          this.limitLineDownloadBrand = this.allLimitLineBrandDownload?.[0];
          this.typeDownloadBrand = this.allTypeBrandDownload?.[0];
          break;
      }
    },
    onClickShowMore(pageTableName) {
      this.$router.push({ name: pageTableName });
    },
    async onClickLogin() {
      await this.$store.commit(
        `GeneralModule/${MutationGeneral.setShowPopupLogin}`,
        true
      );
    },
    async onClickChangeShopViewType(shopViewType) {
      if (!this.isBusinessAccount) {
        await this.$store.dispatch(
          `${MutationGeneral.MODULE_PATH}/${MutationGeneral.setShowPopupNotification}`,
          NOTIFICATION_TYPE.upgrade_business_permission
        );
        return;
      }
      this.shopViewType = shopViewType;
    },
    updateProductSort(isDefault = false) {
      let durationDay =
        this.$store.state.SearchFilterModule.options?.durationDay;
      let sortBy = isDefault
        ? this.$store.state.SearchFilterModule.options?.sort_by
        : this.$store.state.SearchFilterModule.iSortBy;
      if (!sortBy) {
        this.productSortValue = durationDay
          ? `order_revenue_${durationDay}d`
          : `order_revenue_custom`;
        this.productSortDirection = "desc";
        this.$store.commit(
          `SearchFilterModule/${MutationSearchFilter.iSortBy}`,
          `${this.productSortValue}__${this.productSortDirection}`
        );
        return;
      }
      [this.productSortValue, this.productSortDirection] = sortBy.split("__");
    },
    resetProductSort() {
      this.updateProductSort(true);
      this.updateProductResult();
    },
    onChangeProductSort() {
      this.$store.commit(
        `SearchFilterModule/${MutationSearchFilter.iSortBy}`,
        `${this.productSortValue}__${this.productSortDirection}`
      );
      this.updateProductResult();
    },
    async updateProductResult() {
      this.isLoadingProductData = true;
      let optionsFilter = this.$store.state.SearchFilterModule.options;
      let optionsProduct = await this.$store.dispatch(
        `${MutationSearchFilter.MODULE_PATH}/${MutationSearchFilter.BUILD_PRODUCT_OPTIONS}`,
        optionsFilter
      );
      let options = {
        ...optionsFilter,
        ...optionsProduct,
      };
      options.type = MutationSearchFilter.SET_RESPONSE_SEARCH_PRODUCT;
      await this.$store.commit(
        `SearchFilterModule/${MutationSearchFilter.isDemo}`,
        !this.isLogin
      );
      await this.$store.dispatch(
        `SearchFilterModule/${MutationSearchFilter.SEARCH_PRODUCT}`,
        options
      );
      this.isLoadingProductData = false;
    },
    onChangeProductPagination(page, pageSize) {
      let productSection =
        document.getElementById("product-section") ||
        document.getElementById("product-section-mobile");
      productSection?.scrollIntoView();
      if (page) {
        this.iPage = page;
      }
      if (pageSize) {
        this.iLimit = pageSize;
      }
      this.updateProductResult();
    },
    async onChangeLimitNumberProduct() {
      await this.updateProductResult();
    },

    onClickProductSortPopup() {
      this.popupSortProductVisible = true;
    },
    handleSelectSort(value) {
      this.popupSortProductVisible = false;
      if (!value || value === this.sortMetricDefault) {
        return;
      }
      this.$store.commit(
        `SearchFilterModule/${MutationSearchFilter.iSortBy}`,
        value
      );
      this.updateProductResult();
    },
  },
  computed: {
    isAvailableToDownloadProduct() {
      return (
        this.allLimitLineProductDownload?.length &&
        this.remainProductDownloadCountPerWeek > 0
      );
    },
    isAvailableToDownloadShop() {
      return (
        this.allLimitLineShopDownload?.length &&
        this.remainShopDownloadCountPerWeek > 0
      );
    },
    isAvailableToDownloadBrand() {
      return (
        this.allLimitLineShopDownload?.length &&
        this.remainShopDownloadCountPerWeek > 0 &&
        !this.isExpired &&
        this.isHigherPriorityAccount(PERMISSION.market_advance.priority)
      );
    },
    isDownloadingSomething() {
      return (
        this.isDownloadingCSV ||
        this.isDownloadingShop ||
        this.isDownloadingBrand
      );
    },
    responseStatus() {
      return this.$store.getters["SearchStatisticResultModule/status"];
    },
    durationDayString: {
      get() {
        let startDate = moment(this.durationRangeOptionArray[0]).format(
          "DD_MM_YYYY"
        );
        let endDate = moment(this.durationRangeOptionArray[1]).format(
          "DD_MM_YYYY"
        );
        return `${startDate}-${endDate}`;
      },
    },
    iPlatforms: {
      get() {
        return this.$store.getters[
          `${MutationSearchFilter.MODULE_PATH}/iPlatforms`
        ];
      },
    },
    options: {
      get() {
        return this.$store.state.SearchFilterModule.options;
      },
    },
    searchConfig: {
      get() {
        return this.$store.getters[
          `${MutationSearchFilter.MODULE_PATH}/searchConfig`
        ];
      },
      async set(value) {
        await this.$store.commit(
          `${MutationSearchFilter.MODULE_PATH}/${MutationSearchFilter.searchConfig}`,
          value
        );
      },
    },
    iDurationDay: {
      get() {
        return this.$store.state.SearchFilterModule.iDurationDay;
      },
      async set(value) {
        await this.$store.commit(
          `${MutationSearchFilter.MODULE_PATH}/${MutationSearchFilter.iDurationDay}`,
          value
        );
      },
    },
    iDurationRange: {
      get() {
        return this.$store.getters["SearchFilterModule/iDurationRange"];
      },
      async set(value) {
        await this.$store.commit(
          `${MutationSearchFilter.MODULE_PATH}/${MutationSearchFilter.iDurationRange}`,
          value
        );
      },
    },
    iLimit: {
      get() {
        return this.$store.getters["SearchFilterModule/iLimit"];
      },
      async set(value) {
        await this.$store.commit(
          `${MutationSearchFilter.MODULE_PATH}/${MutationSearchFilter.iLimit}`,
          value
        );
      },
    },
    iPage: {
      get() {
        return this.$store.getters["SearchFilterModule/iPage"];
      },
      async set(value) {
        await this.$store.commit(
          `${MutationSearchFilter.MODULE_PATH}/${MutationSearchFilter.iPage}`,
          value
        );
      },
    },
    isSavedReport: {
      get() {
        return this.$store.getters[
          `${MutationSearchFilter.MODULE_PATH}/${MutationSearchFilter.isSavedReport}`
        ];
      },
    },
    isLoading: {
      get() {
        return this.$store.state.SearchFilterModule.isLoading;
      },
    },
    comparisonStatus: {
      get() {
        return this.$store.getters[
          "SearchStatisticComparisonResultModule/status"
        ];
      },
    },
    showPopupSystemError: {
      get() {
        return this.$store.state.GeneralModule.showPopupSystemError;
      },
    },
    showPopupPromotedNotification: {
      get() {
        return this.$store.state.GeneralModule.showPopupPromotedNotification;
      },
      async set(value) {
        await this.$store.commit(
          `GeneralModule/${MutationGeneral.setShowPopupPromotedNotification}`,
          value
        );
      },
    },
    allLimitLineProductDownload: {
      get() {
        if (
          !PERMISSION[this.permissionAvailableName]?.allLimitLineProductDownload
            ?.length
        ) {
          return [];
        }
        let result = PERMISSION[
          this.permissionAvailableName
        ].allLimitLineProductDownload.filter(
          (item) => item < this.remainProductDownloadCountPerWeek
        );
        if (this.remainProductDownloadCountPerWeek > 0 && result?.length <= 0) {
          return [this.remainProductDownloadCountPerWeek];
        }
        return result;
      },
    },
    allTypeProductDownload: {
      get() {
        return PERMISSION[this.permissionAvailableName]?.allTypeProductDownload;
      },
    },
    allLimitLineShopDownload: {
      get() {
        if (
          !PERMISSION[this.permissionAvailableName]?.allLimitLineShopDownload
            ?.length
        ) {
          return [];
        }
        let result = PERMISSION[
          this.permissionAvailableName
        ].allLimitLineShopDownload.filter(
          (item) => item < this.remainShopDownloadCountPerWeek
        );
        if (this.remainShopDownloadCountPerWeek > 0 && result?.length <= 0) {
          return [this.remainShopDownloadCountPerWeek];
        }
        return result;
      },
    },
    allTypeShopDownload: {
      get() {
        return PERMISSION[this.permissionAvailableName]?.allTypeShopDownload;
      },
    },
    allLimitLineBrandDownload: {
      get() {
        if (
          !PERMISSION[this.permissionAvailableName]?.allLimitLineBrandDownload
            ?.length
        ) {
          return [];
        }
        // let result = PERMISSION[this.permissionAvailableName].allLimitLineBrandDownload.filter(item => item < this.remainShopDownloadCountPerWeek)
        // if (this.remainShopDownloadCountPerWeek > 0 && result?.length <=0 ) {
        //   return [this.remainShopDownloadCountPerWeek]
        // }
        let result =
          PERMISSION[this.permissionAvailableName].allLimitLineBrandDownload;
        return result;
      },
    },
    allTypeBrandDownload: {
      get() {
        return PERMISSION[this.permissionAvailableName]?.allTypeBrandDownload;
      },
    },
    showPopupChatToDownload: {
      get() {
        return !!this.$store.getters[
          `${MutationGeneral.MODULE_PATH}/${MutationGeneral.showPopupChatToDownload}`
        ];
      },
      async set(value) {
        await this.$store.commit(
          `${MutationGeneral.MODULE_PATH}/${MutationGeneral.showPopupChatToDownload}`,
          value
        );
      },
    },
    sortMetricArray() {
      let product = this.productsResult?.[0];
      if (!product) {
        return null;
      }
      let metricArray = [];
      for (const productKey in product) {
        let durationDay = toNumber(productKey);
        switch (true) {
          case productKey === "price":
            metricArray.push({
              label: `Giá`,
              id: productKey,
              optionArray: [
                {
                  label: "Cao > Thấp",
                  id: "desc",
                },
                {
                  label: "Thấp > Cao",
                  id: "asc",
                },
              ],
            });
            break;
          case productKey === `order_count_${durationDay}d`:
          case productKey === `order_count_custom`:
            metricArray.push({
              label: `Đã bán ${durationDay ? `${durationDay} ngày` : ""}`,
              id: productKey,
              optionArray: [
                {
                  label: "Cao > Thấp",
                  id: "desc",
                },
                {
                  label: "Thấp > Cao",
                  id: "asc",
                },
              ],
            });
            break;
          case productKey === `order_revenue_${durationDay}d`:
          case productKey === `order_revenue_custom`:
            metricArray.push({
              label: `Doanh số ${durationDay ? `${durationDay} ngày` : ""}`,
              id: productKey,
              optionArray: [
                {
                  label: "Cao > Thấp",
                  id: "desc",
                },
                {
                  label: "Thấp > Cao",
                  id: "asc",
                },
              ],
            });
            break;
          case productKey === "product_name":
            metricArray.push({
              label: "Tên sản phẩm",
              id: productKey,
              optionArray: [
                {
                  label: "A > Z",
                  id: "asc",
                },
                {
                  label: "Z > A",
                  id: "desc",
                },
              ],
            });
            break;
          default:
            break;
        }
      }
      return metricArray;
    },
    sortMetricDefault() {
      return `${this.productSortValue}__${this.productSortDirection}`;
    },
    showPopupNotification: {
      get() {
        return this.$store.state.GeneralModule.showPopupNotification;
      },
      async set(value) {
        await this.$store.commit(
          `GeneralModule/${MutationGeneral.setShowPopupNotification}`,
          value
        );
      },
    },
  },
  watch: {
    comparisonStatus(value) {
      if (!value) {
        this.durationCompareIndex = null;
        this.updateState();
      }
    },
    isLoading(value) {
      if (!value) {
        this.updateState();
      }
    },
    productsResult(product) {
      this.allProductSort = [];
      if (!product) {
        return;
      }
      if (product[0]?.["product_name"]) {
        this.allProductSort.push({
          value: "product_name",
          label: "Tên sản phẩm",
        });
      }
      if (product[0]?.["order_revenue"]) {
        this.allProductSort.push({
          value: "order_revenue",
          label: ALL_FIELD_FILTER["revenue"].title,
        });
      }
      //todo: update ALL_FIELD_FILTER then remove this block
      if (product[0]?.["order_revenue_custom"]) {
        this.allProductSort.push({
          value: "order_revenue_custom",
          label: "Tổng doanh số tuỳ chỉnh",
        });
      }
      if (product[0]?.["order_count_custom"]) {
        this.allProductSort.push({
          value: "order_count_custom",
          label: "Tổng đã bán tuỳ chỉnh",
        });
      }
      // end
      for (const productKey in product[0]) {
        if (ALL_FIELD_FILTER[productKey]?.availableSort) {
          this.allProductSort.push({
            value: productKey,
            label: ALL_FIELD_FILTER[productKey].title,
          });
        }
      }
      this.updateProductSort();
    },
  },
  mixins: [
    mixinStoreGetterGlobal,
    mixinStoreGetterUser,
    mixinStoreGetterPermission,
    mixinStoreGetterPayment,
    mixinStoreGetterShops,
    mixinStoreGetterProducts,
  ],
  filters: {
    formatCurrency: formatCurrency,
    formatNumberHuman: formatNumberHuman,
  },
  beforeDestroy() {
    let subPage = [
      "table_product_view",
      "table_keyword_view",
      "table_shop_view",
    ];
    if (subPage.includes(this.$route.name)) {
      return;
    }
    this.$store.dispatch(
      `${MutationSearchFilter.MODULE_PATH}/${MutationSearchFilter.RESET_SEARCH}`
    );
  },
};
</script>

<style lang="scss">
.tab-filter-option {
  .ant-tabs-nav {
    display: flex;
    justify-content: space-between;
  }
}
</style>

<style scoped lang="scss">
.market-view-iframe {
  padding-top: 24px;
  .border-solid {
    border: 1px solid #deebff;
  }
  .container-space-default {
    margin: 0 16px 24px 16px;
    padding: 24px;
  }
  .share-report-button {
    width: 100%;
    text-align: right;
    padding: 20px 20px 0 0;

    button {
      margin-left: 12px;
    }
  }
  .market-chart {
    padding: 20px;
    border: solid 0.5px #cfd8dc66;
    margin-top: 20px;
    margin-bottom: 20px;
  }

  .text-highlight {
    color: $--color-primary;
  }

  .report-content {
    .page-title {
      margin-top: 24px;
      color: $--color-primary;
      font-size: 2rem;
      text-align: center;

      &.mobile-title {
        margin-top: 0;
        margin-bottom: 0;
        font-size: 18px;
      }
    }

    .page-sub-title {
      font-size: 1.25rem;
      text-align: center;

      &.mobile-title {
        font-size: 12px;
        font-weight: normal;
        color: #484848;
      }
    }
  }

  .duration-range-comparison-wrap {
    margin: 0 16px -12px 0;
    text-align: right;

    .duration-range-comparison {
      display: inline-flex;
      align-items: center;
      font-weight: 500;
      font-size: 1.1rem;

      &.mobile-title {
        display: flex;
        flex-direction: column;
        font-size: 12px;
      }

      .duration-range-comparison-option-container {
        display: inline-flex;
        margin-left: 8px;
        border-radius: 6px;
        background-color: rgba(#fff, 1);
        border: rgba(gray, 0.5) 1px solid;
        overflow: hidden;

        &.mobile-view {
          border: none;
          border-radius: 0;
          background-color: unset;
          margin-left: 0;
        }

        .duration-range-comparison-option {
          padding: 4px 8px;
          border-right: gray 1px solid;
          cursor: pointer;

          &.mobile-view {
            display: flex;
            align-items: center;
            justify-content: center;
            height: 24px;
            font-size: 12px;
            margin: 0 3px;
            padding: 0 10px;
            border: #c4c4c4 1px solid;
            border-radius: 4px;

            &:last-child {
              border-right: #c4c4c4 1px solid;
            }
          }

          &:last-child {
            border-right: none;
          }

          &:hover {
            border-color: #ccc;
            box-shadow: inset 0px 1px 2px rgba(0, 0, 0, 0.2);
            color: #222;
          }

          &.active {
            background-color: rgba($--color-primary, 0.9);
            color: white;
          }

          &.disabled {
            opacity: 50%;

            &:hover {
              border-color: revert;
              box-shadow: none;
              color: revert;
            }
          }
        }
      }
    }
  }

  .select-option-tooltip-wrap {
    right: 0;
    top: 100%;
    z-index: 2;
  }

  .select-option-tooltip {
    padding: 16px 40px;
    background: white;
    border-radius: 8px;
    border: 1px solid;
    text-align: left;
  }
}
</style>
