import {
  urlApiCloneSavedReport, urlApiGetDownloadSavedReport,
  urlApiGetListSavedReport,
  urlApiGetSavedReportDetail,
  urlApiSaveReport
} from "@/api/report/ReportEndpoint";
import {http} from "@/plugin/http";

/**
 *
 * @param page
 * @param limit
 * @return {Promise<{data: T, status: number}|null|*>}
 */
const apiGetListSavedReport = async (page = 0, limit = 1, orderBy) => {
  let urlApi = urlApiGetListSavedReport(page, limit, orderBy)
  try {
    let response = await http.get(urlApi)

    if (response?.data) {
      return response.data
    }
    return null
  } catch (e) {
    if (!e.response) {
      return {status: 'error', data: null}
    }
    return {status: e.response.data.status || e.response.status, data: e.response, message: e.response.data.message}
  }
}

const apiDownloadSavedReport = async (page = 0, limit = 1, orderBy, downloadType = 'excel') => {
  let urlApi = urlApiGetDownloadSavedReport(page, limit, orderBy, downloadType)
  const config = {
    responseType: 'blob'
  }
  try {
    let response = await http.get(urlApi, config)
    if (response?.data) {
      return response.data
    }
    return null
  } catch (e) {
    if (!e.response) {
      return {status: 'error', data: null}
    }
    return {status: e.response.data.status || e.response.status, data: e.response, message: e.response.data.message}
  }
}


/**
 * get detail information of report by report hash
 * @param reportHash{string}
 * @return {Promise<{data: T, status: number}|null|*>}
 */
const apiGetSavedReportDetail = async (reportHash) => {
  if (!reportHash) {
    return null
  }
  let urlApi = urlApiGetSavedReportDetail(reportHash)
  try {
    let response = await http.get(urlApi)

    if (response?.data) {
      return response.data
    }
  } catch (e) {
    if (!e.response) {
      return {status: 'error', data: null}
    }
    return {status: e.response.data.status || e.response.status, data: e.response, message: e.response.data.message}
  }
}

/**
 * clone a report to new report
 * @param name{string}
 * @param reportHashP{string}
 * @return {Promise<{data: T, status: number}|null|*>}
 */
const apiCloneSavedReport = async (reportHash, name) => {
  if (!name || !reportHash) {
    return null
  }
  const data = {
    "name": name,
    "report_hash": reportHash
  }
  let urlApi = urlApiCloneSavedReport()
  try {
    let response = await http.post(urlApi, data)

    if (response?.data) {
      return response.data
    }
  } catch (e) {
    if (!e.response) {
      return {status: 'error', data: null}
    }
    return {status: e.response.data.status || e.response.status, data: e.response, message: e.response.data.message}
  }
}

/**
 * save report by name or optionsSearchFilter or both
 * @param name{string}
 * @param optionsSearchFilter{object}
 * @return {Promise<{data: T, status: number}|null|*>}
 */
const apiSaveReport = async (name, optionsSearchFilter) => {
  if (!name || !optionsSearchFilter) {
    return null
  }
  let data = {}
  if (name) {
    data.name = name
  }
  if (optionsSearchFilter) {
    data.body = optionsSearchFilter
  }
  let urlApi = urlApiSaveReport()
  try {
    let response = await http.put(urlApi, data)

    if (response?.data) {
      return response.data
    }
  } catch (e) {
    console.log(e)
    if (!e.response) {
      return {status: 'error', data: null}
    }
    return {status: e.response.data.status || e.response.status, data: e.response, message: e.response.data.message}
  }
}

/**
 * update name or options search filter of report when put not null value
 * @param reportHash{string}
 * @param name{string | null}
 * @param optionsSearchFilter {object | null}
 * @return {Promise<{data: T, status: number}|null|*>}
 */
const apiUpdateSavedReport = async (reportHash, name = null, optionsSearchFilter = null) => {
  if (!name && !optionsSearchFilter) {
    return null
  }
  let data = {
    report_hash: reportHash
  }
  if (name) {
    data.name = name
  }
  if (optionsSearchFilter) {
    data.body = optionsSearchFilter
  }
  let urlApi = urlApiSaveReport()
  try {
    let response = await http.post(urlApi, data)

    if (response?.data) {
      return response.data
    }
  } catch (e) {
    if (!e.response) {
      return {status: 'error', data: null}
    }
    return {status: e.response.data.status || e.response.status, data: e.response, message: e.response.data.message}
  }
}

/**
 * delete report by reportHash
 * @param reportHash{string}
 * @return {Promise<{data: T, status: number}|null|*>}
 */
const apiDeleteSavedReport = async (reportHash) => {
  if (!reportHash) {
    return null
  }
  const data = {
    report_hash: reportHash
  }
  let urlApi = urlApiSaveReport()
  try {
    let response = await http.delete(urlApi, {data: data})

    if (response?.data) {
      return response.data
    }
  } catch (e) {
    if (!e.response) {
      return {status: 'error', data: null}
    }
    return {status: e.response.data.status || e.response.status, data: e.response, message: e.response.data.message}
  }
}

export {
  apiGetListSavedReport,
  apiDownloadSavedReport,
  apiGetSavedReportDetail,
  apiSaveReport,
  apiUpdateSavedReport,
  apiDeleteSavedReport,
  apiCloneSavedReport
}
