<template>
  <a-modal title=""
           :closable="true"
           :footer="null"
           :centered="true"
           v-model="showPopupSearchFilter"
           :bodyStyle="{padding: '14px'}"
           :maskClosable="false"
           @cancel="$emit('close')"
  >
    <div class="">
      <h2 slot="title" class="text-primary-500 font-normal">
        <a-icon type="filter" class="mr-2"/>
        <span>Bộ lọc nâng cao</span>
      </h2>
      <a-spin :spinning="!(localAllPlatformObject && filterOption)">
        <div v-if="localAllPlatformObject && filterOption">
          <div :style="isDesktop ? 'width: 90%; max-width: 610px' : 'width: 100%;'">
            <div class="flex justify-space-between">
              <h2 class="atitle">Từ khóa loại trừ<span style="color: #ff7227">*</span>
                <a-tooltip placement="top" title="Từ khóa loại trừ sẽ không xuất hiện trong Báo cáo">
                  <i>
                    <IconInformation class="ml-0.5" style="transform: translateY(-4px)"/>
                  </i>
                </a-tooltip>
              </h2>
            </div>
            <a-row>
              <a-select mode="tags" style="width: 100%"
                        :tokenSeparators="[',',';']"
                        v-model="iQueriesExclude"
                        :placeholder="isDesktop ? 'Nhập từ khóa cách nhau bởi dấu phẩy' : ''"
              >
                <a-select-option v-for="query in iQueriesExclude" :key="query">
                  <span :class="{'ant-select-selection__remove-choice': query.trim()[0]==='-', 'ant-select-selection__required-choice': query.trim()[0]==='+'}">{{ query }}</span>
                </a-select-option>
              </a-select>
            </a-row>
            <p v-if="!isDesktop" class="text-note mt-2">Nhập từ khóa loại trừ cách nhau bởi dấu phẩy</p>
          </div>
          <a-row :gutter="12" type="flex" class="">
            <a-col :xs="24" :sm="24" :md="12" :lg="12" :xl="12" :xxl="12">
              <div v-if="isBusinessAccount" class="d-inline-flex justify-space-between w-100 margin-top-15" style="flex-wrap: wrap">
                <div class="margin-right-5" style="width: 100%;">
                  <h2 class="atitle">Phân tích trong khoảng ngày<span style="color: #ff7227">**</span></h2>
                  <a-date-picker
                    style="width: calc(50% - 17px);"
                    v-model="iDurationRange[0]"
                    :disabled-date="disabledStartDate"
                    :showToday="false"
                    format="DD-MM-YYYY"
                    placeholder="Ngày đầu"
                    @openChange="handleStartOpenChange"
                    @change="handleDatePickerChangeValue"
                  />
                  <span> đến </span>
                  <a-date-picker
                    style="width:  calc(50% - 17px);"
                    v-model="iDurationRange[1]"
                    :disabled-date="disabledEndDate"
                    :showToday="false"
                    format="DD-MM-YYYY"
                    placeholder="Ngày cuối"
                    :open="isOpenEndDatePicker"
                    @openChange="handleEndOpenChange"
                    @change="handleDatePickerChangeValue"
                  />
                </div>
              </div>
<!--              <div class="margin-top-15">-->
<!--                <h2 class="atitle">Danh mục cơ bản</h2>-->
<!--                <div class="ant-form-item-control">-->
<!--                  <a-select @change="handleCategoryChange()" v-model="categorySelected" :disabled="isCategoryDetail" style="width: 100%;" size="default">-->
<!--                    <a-select-option value="all">Tất cả</a-select-option>-->
<!--                    <a-select-option v-for="option in categoriesOption" :key="option['value']">-->
<!--                      {{ option['label'] }}-->
<!--                    </a-select-option>-->
<!--                    <a-select-option v-if="isCategoryDetail" value="none">Không chọn</a-select-option>-->
<!--                    <a-select-option v-if="isSelectedMultiCategory && !isCategoryDetail" value="custom">Tuỳ chọn</a-select-option>-->
<!--                  </a-select>-->
<!--                </div>-->
<!--              </div>-->
              <div class="margin-top-15">
                <h2 class="atitle">Nhiều Danh mục Chuyên sâu</h2>
                <div class="flex flex-row justify-start">
                  <a-icon type="info-circle" class="mr-1 text-primary-500" style="margin-top: 3px"/>
                  <p class="text-note">Hãy sử dụng bản web trên máy tính để trải nghiệm tính năng này tốt hơn!</p>
                </div>
              </div>
            </a-col>
            <a-col :xs="24" :sm="24" :md="12" :lg="12" :xl="12" :xxl="12">
              <div class="margin-top-15" v-if="filterOption.allFixedOptionFilter">
                <div class="d-flex">
                  <h2 class="atitle">Bộ Lọc hữu ích</h2>
                  <span class="text-feature text-primary-500">New</span>
                </div>
                <div v-if="filterOption.allFixedOptionFilter && filterOption.allFixedOptionFilter.trending" class="flex flex-row justify-start items-start">
                  <span class="margin-right-25">Trending:</span>
                  <a-radio-group v-model="fixedFilterTrendingDay" @change="onChangeTrending">
                    <a-radio :value="0">
                      Không chọn
                    </a-radio>
                    <template v-for="itemKey in Object.keys(filterOption.allFixedOptionFilter.trending)">
                      <a-radio :value="itemKey" :key="`${itemKey}_day`">
                        {{itemKey}} ngày
                      </a-radio>
                    </template>
                  </a-radio-group>
                </div>
              </div>
              <div class="margin-top-15" v-if="filterOption.allValueRangeFieldFilter">
                <div class="d-flex">
                  <h2 class="atitle">Bộ Lọc theo giá trị
                    <a-icon type="plus-circle" class="icon-action" @click="onClickAddValueRangeField"/>
                  </h2>
                </div>
                <a-row type="flex" align="middle" class="margin-bottom-10"
                       v-for="(id, indexField) in listIdValueRangeIndex"
                       :key="`valueRangeFieldFilter_${id}`">
                  <div class="w-full flex items-center mb-1">
                    <a-select class="field-value-range" @change="onSelectValueRangeField" :default-value="listValueRangeFieldName[indexField]" :key="listValueRangeFieldName[indexField]">
                      <a-select-option v-for="field in filterOption.allValueRangeFieldFilter"
                                       :value="field.id"
                                       :key="field.id"
                                       :indexField="indexField"
                                       :disabled="listValueRangeFieldName.includes(field.id) || (isCheckedFixedFilterGoodSeller && iValueRange[listValueRangeFieldName[indexField]] && iValueRange[listValueRangeFieldName[indexField]].min === FIXED_VALUE_RANGE_GOOD_SELLER_PRODUCT.min && listValueRangeFieldName[indexField] === FIXED_VALUE_RANGE_GOOD_SELLER_PRODUCT.field)"
                      >
                        {{ field.title }}
                      </a-select-option>
                    </a-select>
                    <a-icon class="remove-icon" type="minus-circle" @click="onClickRemoveValueRangeField(indexField)"/>
                  </div>
                  <div class="w-full flex items-center">
                    <template v-if="listValueRangeFieldName[indexField] === VALUE_RANGE_DEFAULT">
                      <a-input-number class="input-value-range"
                                      :step="10000"
                                      placeholder="Giá trị min"
                      />
                      <span class="margin-left-5 margin-right-5">-</span>
                      <a-input-number class="input-value-range"
                                      :step="10000"
                                      placeholder="Giá trị max"
                      />
                    </template>
                    <template v-if="iValueRange[listValueRangeFieldName[indexField]]">
                      <template v-if="filterOption.allValueRangeFieldFilter[listValueRangeFieldName[indexField]].type === 'date'">
                        <a-date-picker class="input-value-range"
                                       v-model="iValueRange[listValueRangeFieldName[indexField]].min"
                                       :disabled-date="disabledStartDate"
                                       :showToday="false"
                                       format="DD-MM-YYYY"
                                       placeholder="Ngày đầu"
                                       @change="onChangeValueRange(indexField)"
                        />
                                              <span class="margin-left-5 margin-right-5"> đến </span>
                        <a-date-picker v-model="iValueRange[listValueRangeFieldName[indexField]].max"
                                       :disabled-date="disabledEndDate"
                                       :showToday="false"
                                       format="DD-MM-YYYY"
                                       placeholder="Ngày cuối"
                                       class="input-value-range"
                                       @change="onChangeValueRange(indexField)"
                        />
                        <a-icon class="remove-icon" type="minus-circle" @click="onClickRemoveValueRangeField(indexField)"/>
                      </template>
                      <template v-else>
                        <a-input-number class="input-value-range"
                                        v-model="iValueRange[listValueRangeFieldName[indexField]].min"
                                        :step="10000"
                                        :disabled="isCheckedFixedFilterGoodSeller && iValueRange[listValueRangeFieldName[indexField]].min === FIXED_VALUE_RANGE_GOOD_SELLER_PRODUCT.min && listValueRangeFieldName[indexField] === FIXED_VALUE_RANGE_GOOD_SELLER_PRODUCT.field"
                                        placeholder="Giá trị min"
                                        @change="onChangeValueRange(indexField)"
                        />
                        <span class="margin-left-5 margin-right-5"> - </span>
                        <a-input-number class="input-value-range"
                                        v-model="iValueRange[listValueRangeFieldName[indexField]].max"
                                        :step="10000"
                                        placeholder="Giá trị max"
                                        @change="onChangeValueRange(indexField)"
                        />
                      </template>
                    </template>
                  </div>
                </a-row>
              </div>
              <div class="margin-top-15" v-if="filterOption.allValueCompareFieldFilter">
                <div class="d-flex">
                  <h2 class="atitle">Bộ Lọc theo tham số
                    <a-icon v-if="isDesktop" type="plus-circle" class="icon-action" @click="onClickAddValueCompareField"/>
                  </h2>
                </div>
                <div class="flex flex-row justify-start" v-if="!isDesktop">
                  <a-icon type="info-circle" class="mr-1 text-primary-500" style="margin-top: 3px"/>
                  <p class="text-note">Hãy sử dụng bản web trên máy tính để trải nghiệm tính năng này tốt hơn!</p>
                </div>
                <table class="table-compare" v-if="isDesktop">
                  <tr class="table-header">
                    <th>Số liệu 1</th>
                    <th style="text-align: center;">
                      <a-checkbox v-model="ableToChangeRatioOfValueComparison" style="margin-right: 8px">
                      </a-checkbox>
                      <span>Hệ số</span>
                    </th>
                    <th style="text-align: center;">Điều kiện</th>
                    <th>Số liệu 2</th>
                    <th></th>
                  </tr>
                  <tr class="table-body" v-for="(id, indexField) in iCompare.length" :key="`valueRangeFieldFilter_${id}`">
                    <td>
                      <a-select class="field-value-range" v-model="iCompare[indexField].firstField"
                                default-value="Tên số liệu" @change="updateOptionFilters">
                        <a-select-option :value="field.id"
                                         :indexField="indexField"
                                         v-for="field in localAllValueCompareFieldFilter"
                                         :key="field.id"
                                         :disabled="isPreparedComparison(indexField)"
                        >
                          {{ field.title }}
                        </a-select-option>
                      </a-select>
                    </td>
                    <td>
                      <a-input-number v-model="iCompare[indexField].ratio"
                                      :placeholder="'Hệ số'"
                                      :step="0.1"
                                      :formatter="value => `x ${value}`"
                                      :parser="value => value.replace('x', '').trim()"
                                      :disabled="!ableToChangeRatioOfValueComparison || isPreparedComparison(indexField)"
                                      style="width: 100%; margin: 0 0"
                                      @change="onRatioChange(indexField)"
                      />
                    </td>
                    <td>
                      <a-select v-model="iCompare[indexField].comparisonOperator"
                                class="compare-operator"
                                @change="updateOptionFilters"
                      >
                        <a-select-option :value="comparisonOperator"
                                         :indexField="indexField"
                                         v-for="comparisonOperator in ALL_COMPARISON_OPERATOR"
                                         :key="comparisonOperator"
                                         :disabled="isPreparedComparison(indexField)"
                        >
                          <p style="text-align: center; padding: 0 10px; color: #ff7227">{{ comparisonOperator }}</p>
                        </a-select-option>
                      </a-select>
                    </td>
                    <td>
                      <a-select class="field-value-range" v-model="iCompare[indexField].secondField"
                                default-value="Tên số liệu" @change="updateOptionFilters">
                        <a-select-option :value="field.id"
                                         :indexField="indexField"
                                         v-for="field in localAllValueCompareFieldFilter"
                                         :key="field.id"
                                         :disabled="isPreparedComparison(indexField)"
                        >
                          {{ field.title }}
                        </a-select-option>
                      </a-select>
                    </td>
                    <td>
                      <a-icon class="remove-icon" type="minus-circle" @click="onClickRemoveValueCompareField(indexField)" v-if="!isPreparedComparison(indexField)"/>
                    </td>
                  </tr>
                </table>
              </div>
              <div class="margin-top-15">
                <h2 class="atitle">Thương hiệu</h2>
                <a-row>
                  <a-select mode="tags" style="width: 100%; max-width: 610px"
                            :tokenSeparators="[',',';']"
                            v-model="iBrands"
                            placeholder="Nhập cách nhau bởi dấu phẩy, để loại trừ hãy thêm dấu trừ phía trước">
                    <a-select-option v-for="brand in iBrands" :key="brand">
                      <span :class="{'ant-select-selection__remove-choice': brand[0]==='-'}">{{ brand }}</span>
                    </a-select-option>
                  </a-select>
                </a-row>

              </div>
              <div class="margin-top-15" v-if="filterOption.allLocation">
                <h2 class="atitle">Kho</h2>
                <a-select mode="tags" style="width: 100%; max-width: 610px"
                          v-model="iLocationWarehouses"
                          placeholder="Địa điểm kho">
                  <a-select-option value="all">Tất cả</a-select-option>
                  <a-select-option v-for="location in filterOption.allLocation" :key="location"
                                   :value="location">{{ location }}
                  </a-select-option>
                </a-select>
              </div>
              <div class="margin-top-15" v-if="filterOption.allShopType">
                <h2 class="atitle">Người bán (Shop)</h2>
                <div class="" v-if="filterOption.allShopType.mall">
                  <a-checkbox v-model="iIsShopMall">{{ filterOption.allShopType.mall.label }}</a-checkbox>
                </div>
                <div class="" v-if="filterOption.allShopType.normal">
                  <a-checkbox v-model="iIsShopNormal">{{ filterOption.allShopType.normal.label }}</a-checkbox>
                </div>
              </div>
            </a-col>
          </a-row>

          <a-row :gutter="12" type="flex" justify="end" style="margin-top: 8px">
            <a-col>
              <a-button @click="onResetSearch" @mouseup="showPopupSearchFilter = false" type="default" size="large" style="width: 118px; height: 30px" :style="isDesktop ? '' : 'font-size: 12px; padding: 0'">
                <a-icon type="redo"/>
                Reset
              </a-button>
            </a-col>
            <a-col>
              <a-button type="primary" size="large" :disabled="isOldFilter || isLoading" @click="onClickBtnSearch" @mouseup="showPopupSearchFilter = false"
                        :style="isDesktop ? 'padding: 0px 34px 0 27px; font-size: 20px;' : 'width: 118px; height: 30px; font-size: 14px; padding: 0'"
              >
                  <a-icon :type="iconSearch"/>
                  <span style="font-weight: 600;">{{ buttonSearchName }}</span>
              </a-button>
            </a-col>
          </a-row>
        </div>
      </a-spin>
      <!--    <div style="margin-top: 12px">Ghi chú: <span style="color: #ff7227">*</span> - Tính năng nâng cao</div>-->
      <div style="margin-top: 12px; font-size: 10px;" v-if="localAllPlatformObject && filterOption">
        <div class="">
          <div class=""><span style="color: #ff7227">*</span> Tính năng nâng cao</div>
          <div class="" v-if="isBusinessAccount"><span style="color: #ff7227">**</span> Tính năng doanh nghiệp</div>
        </div>
      </div>
    </div>
  </a-modal>
</template>

<script>

import Default from '@/layout/Default'
import TableProduct from '@/views/search/TableProduct'
import {
  MutationSearchFilter
} from '@/store/modules/SearchFilterModule/mutation'
import { formatCurrency, formatNumberHuman } from '@/helper/FormatHelper'
import {ActionNameConstants, NOTIFICATION_TYPE} from '@/constant/general/GeneralConstant'
import { isLogined } from '@/service/user/UserProfileService'
import { MutationGeneral } from '@/store/modules/GeneralModule/mutation'
import {
  getBunchOfAvailablePlatformObjectByPermissionName,
  getPlatformById,
  getPlatformByUrl
} from '@/service/platform/PlatformCommonService'
import { apiSearchDemo } from '@/api/product/ApiProductSearch'
import { MutationUser } from '@/store/modules/UserModule/mutation'
import {isAdvanceOption} from '@/service/user/UserPermissionService'
import { fbTrackingSearch } from '@/service/tracking/FacebookTrackingService'
import {
  ALL_COMPARISON_OPERATOR, ALL_FIXED_DURATION_DAY, ALL_SHOP_TYPE, VALUE_COMPARE_DEFAULT_OBJECT, VALUE_RANGE_DEFAULT
} from '@/constant/search/SearchConstant'
import moment from 'moment'
import 'moment/locale/vi'
import { MutationSearchStatisticComparisonResult } from '@/store/modules/SearchStatisticComparisonResultModule/mutation'
import {
  extractOptionFromRoute,
  getDateFromOption,
  getDurationRange, getFilterOptionByPermission,
  getSearchConfig
} from '@/service/search/SearchService'
import {toNumber} from "@/helper/StringHelper";
import {isSameNormalObject} from "@/helper/ObjectHelper";
import {FIXED_VALUE_RANGE_GOOD_SELLER_PRODUCT, FIXED_VALUE_COMPARE_BAD_PRODUCT_CONDITION_LIST, FIXED_VALUE_COMPARE_TRENDING} from "@/constant/search/FixedOptionFilterConstant";
import {ALL_FILTER_OPTION_BY_PLATFORM_OBJECT, FILTER_OPTION_DEFAULT} from "@/constant/general/PlatformConstant";
import {mixinStoreGetterPermission} from "@/mixin/store/MixinUser";
import PopupNotification from "@/views/popup/PopupNotification";
import {mixinStoreGetterGlobal} from "@/mixin/store/MixinGlobal";
import {PERMISSION} from "@/constant/general/PermissionConstant";
import IconInformation from "@/assets/icon/IconInformation";

moment.locale('vi')

const generateCategoryDetailData = (allCategories) => {
  let catDetailResult = {}
  for (const categoryElem of allCategories) {
    catDetailResult[categoryElem.value] = {
      indeterminate: false,
      checkAll: false,
      checkedCount: 0
    }
  }
  return catDetailResult
}

export default {
  name: 'PopupSearchFilterAdvance',
  components: {
    Default,
    TableProduct,
    PopupNotification,
    IconInformation
  },
  data() {
    const listCategoryTab = {
      'category_lv1': {
        key: 'category_lv1',
      },
      'category_lv2': {
        key: 'category_lv2',
        currentParent: null,
        data: null
      }
    }
    return {
      // Local State Data
      indeterminateCategory: false,
      checkCategoryAll: true,
      categorySelected: 'all',
      listCategoryTab: listCategoryTab,
      categoryKeyTab: listCategoryTab.category_lv1.key,
      isCheckedFixedFilterBadProduct: false,
      isCheckedFixedFilterGoodSeller: false,
      listIdValueRangeIndex: [1],
      listValueRangeFieldName: [],
      fixedFilterTrendingDay: 0,
      shopPlatformId: null,
      shopUrl: null,
      // UI Data
      valueRangeFieldIndex: 1,
      isOpenEndDatePicker: false,
      ableToChangeRatioOfValueComparison: false,
      // Constant Data source
      ALL_COMPARISON_OPERATOR: ALL_COMPARISON_OPERATOR,
      filterOption: null,
      VALUE_RANGE_DEFAULT: VALUE_RANGE_DEFAULT,
      FIXED_VALUE_RANGE_GOOD_SELLER_PRODUCT: FIXED_VALUE_RANGE_GOOD_SELLER_PRODUCT,
      NOTIFICATION_TYPE: NOTIFICATION_TYPE
    }
  },
  async mounted() {
    this.initLocalStateComputed()
  },
  methods: {
    async initLocalStateComputed() {
      this.filterOption = getFilterOptionByPermission(FILTER_OPTION_DEFAULT, this[MutationUser.permissionAvailableName])
      // init local state data
      this.indeterminateCategory = false
      this.checkCategoryAll = true
      this.categorySelected = 'all'
      this.listCategoryTab.category_lv2.data = generateCategoryDetailData(this.filterOption?.allCategories)
      this.isCheckedFixedFilterBadProduct = false
      this.isCheckedFixedFilterGoodSeller = false
      this.listIdValueRangeIndex = [1]
      this.listValueRangeFieldName = [VALUE_RANGE_DEFAULT]
      this.fixedFilterTrendingDay = 0
      // init computed state
      this.iCompare = [{ ...VALUE_COMPARE_DEFAULT_OBJECT }]
      let result = {}
      for (const filterOptionKey in this.filterOption?.allValueRangeFieldFilter) {
        result[filterOptionKey] = {
          min: null,
          max: null
        }
      }
      this.iValueRange = result
      await this.updateSearchConfig()
    },
    async resetLocalState() {
      // reset local state data
      this.indeterminateCategory = false
      this.checkCategoryAll = true
      this.categorySelected = 'all'
      this.listCategoryTab.category_lv2.data = generateCategoryDetailData(this.filterOption?.allCategories)
      this.listCategoryTab.category_lv2.currentParent = null
      this.isCheckedFixedFilterBadProduct = false
      this.isCheckedFixedFilterGoodSeller = false
      this.listIdValueRangeIndex = [1]
      this.listValueRangeFieldName = [VALUE_RANGE_DEFAULT]
      this.fixedFilterTrendingDay = 0
      // reset local state computed
      this.iCompare = [{ ...VALUE_COMPARE_DEFAULT_OBJECT }]
      let result = {}
      for (const filterOptionKey in this.filterOption?.allValueRangeFieldFilter) {
        result[filterOptionKey] = {
          min: null,
          max: null
        }
      }
      this.iValueRange = result
      this.iQueries = []
      this.iBrands = []
      this.iIsShopMall = !!this.filterOption.allShopType?.[ALL_SHOP_TYPE.mall.name]
      this.iIsShopNormal = !!this.filterOption.allShopType?.[ALL_SHOP_TYPE.normal.name]
      this.iLocationWarehouses = 'all'
      await this.updateSearchConfig()
    },
    async applyFilter(option) {
      if (option.iPlatform) {
        this.iPlatforms = option.iPlatform
        await this.handleChangePlatform(option.iPlatform)
      }
      this.iQueries = option.iQueries || this.iQueries
      await this.onSearch()
    },
    async updateSearchConfig() {
      let searchConfig = await getSearchConfig(this.iPlatforms?.[0])
      this.searchConfig = {
        startDate: getDateFromOption(searchConfig?.start_date) || moment('2020 01 01', 'YYYY MM DD')
          .toDate(),
        endDate: getDateFromOption(searchConfig?.current_date) || moment()
          .toDate()
      }
      if (!this.isSavedReport) {
        this.iDurationDay = this.iDurationDay ? this.iDurationDay : ALL_FIXED_DURATION_DAY[0]
        this.iDurationRange = getDurationRange(this.iDurationDay, this.searchConfig.endDate)
      }
    },
    handleCategoryChange() {
      if (this.categorySelected === 'all') {
        this.checkCategoryAll = true
        this.iCategoriesSelected = this.categoriesOption.map(cat => cat?.['value'])
        return
      }
      this.iCategoriesSelected = [this.categorySelected]
      this.checkCategoryAll = false
    },
    async onChangeCheckBoxCategory() {
      this.indeterminateCategory = !!this.iCategoriesSelected.length && this.iCategoriesSelected.length < this.categoriesOption.length
      this.checkCategoryAll = this.iCategoriesSelected.length === this.categoriesOption.length
      this.categorySelected = this.checkCategoryAll ? 'all' : this.iCategoriesSelected.length > 1 ? 'custom' : this.iCategoriesSelected[0]
    },
    disabledStartDate(startValue) {
      const endValue = this.iDurationRange[1] ? new Date(this.iDurationRange[1]) : moment()
        .subtract(1, 'day')
      if (!startValue || !endValue) {
        return startValue < this.searchConfig.startDate
      }
      return startValue >= endValue || startValue < this.searchConfig.startDate
    },
    disabledEndDate(endValue) {
      let startValue = new Date(this.iDurationRange[0])
      startValue = moment(startValue)
      if (!endValue || !startValue) {
        return endValue > this.searchConfig.endDate
      }
      return endValue <= startValue || endValue > this.searchConfig.endDate
    },
    handleStartOpenChange(open) {
      if (!open) {
        this.isOpenEndDatePicker = true
      }
    },
    handleEndOpenChange(open) {
      this.isOpenEndDatePicker = open
    },
    handleDatePickerChangeValue() {
      let durationDay = 0
      if (this.iDurationRange && this.iDurationRange[0] && this.iDurationRange[1]) {
        if (this.iDurationRange[1].format('DD MM YYYY') === moment()
          .subtract(1, 'day')
          .format('DD MM YYYY')) {
          durationDay = this.iDurationRange[1].diff(this.iDurationRange[0], 'day') + 1
          durationDay = ALL_FIXED_DURATION_DAY.includes(durationDay) ? durationDay : 0
        }
        if (durationDay !== this.iDurationDay) {
          this.iDurationDay = durationDay
        }
        this.iDurationRange = [...this.iDurationRange]
      }
    },
    handleSelectDurationDay(value) {
      this.iDurationRange = getDurationRange(value, this.searchConfig?.endDate)
    },
    async onCheckAllChangeCategory(e) {
      this.iCategoriesSelected = e.target.checked ? this.categoriesOption.map(cat => cat?.['value']) : []
      this.indeterminateCategory = false
      this.checkCategoryAll = e.target.checked
      this.categorySelected = 'all'
    },
    async onResetSearch(e) {
      this.$emit('reset', true)
      await this.$store.commit(`SearchFilterModule/${MutationSearchFilter.isLoading}`, true)
      await this.$store.dispatch(`SearchFilterModule/${MutationSearchFilter.RESET_SEARCH}`)
      this.isSearchDemoInit = true
      await this.$store.dispatch(`SearchFilterModule/${MutationSearchFilter.BUILD_OPTIONS}`)
      await this.initLocalStateComputed()
      let response = await apiSearchDemo(this.iPlatforms[0], this.searchConfig?.endDate?.valueOf())
      if (response == null || response['data'] == null) {
        console.warn('response search', response)
        await this.$store.commit(`GeneralModule/${MutationGeneral.setShowPopupSystemError}`, true)
        return
      } else {
        let responseData = response['data']
        await this.$store.dispatch(`SearchFilterModule/${MutationSearchFilter.SET_RESPONSE_SEARCH_PRODUCT}`, responseData)
        await this.$store.commit(`SearchFilterModule/${MutationSearchFilter.isLoading}`, false)
      }
      this.$emit('reset', false)
    },
    async onClickBtnSearch() {
      this.iSortBy = null
      this[MutationSearchFilter.timestamp] = null
      await this.onSearch()
    },
    async onSearch() {
      let isUser = await isLogined()
      this.isOldFilter = true
      await this.$store.dispatch(`SearchFilterModule/${MutationSearchFilter.BUILD_OPTIONS}`)
      let optionsFilter = this.$store.state.SearchFilterModule.options
      let optionsGeneralStatistic = await this.$store.dispatch(`${MutationSearchFilter.MODULE_PATH}/${MutationSearchFilter.BUILD_GENERAL_STATISTIC}`, optionsFilter)
      // let optionsChartStatistic = this.iPlatforms[0] === ALL_PLATFORM_BASE_OBJECT.lazada.platform_id
      //   ? await this.$store.dispatch(`${MutationSearchFilter.MODULE_PATH}/${MutationSearchFilter.BUILD_REVIEW_COUNT_STATISTIC}`, optionsFilter)
      //   : await this.$store.dispatch(`${MutationSearchFilter.MODULE_PATH}/${MutationSearchFilter.BUILD_REVENUE_STATISTIC}`, optionsFilter)
      let optionsChartStatistic = await this.$store.dispatch(`${MutationSearchFilter.MODULE_PATH}/${MutationSearchFilter.BUILD_REVENUE_STATISTIC}`, optionsFilter)
      let optionsProduct = await this.$store.dispatch(`${MutationSearchFilter.MODULE_PATH}/${MutationSearchFilter.BUILD_PRODUCT_OPTIONS}`, optionsFilter)
      let options = {
        ...optionsFilter,
        ...optionsProduct,
        'statistics': [
          ...optionsGeneralStatistic,
          ...optionsChartStatistic
        ]
      }
      options.type = MutationSearchFilter.SET_RESPONSE_SEARCH_PRODUCT
      if (!isUser && isAdvanceOption(options)) {
        // this.$notification['error']({
        //   message: 'Đăng nhập để tiếp tục',
        //   description: 'Chức năng này cần đăng nhập',
        // })
        this.$router.push({ name: 'login' })
        return
      }
      let isSearchDemo = !isUser || (isUser && this.freeSearchCount == 0 && !isAdvanceOption(options))
      if (
        !isSearchDemo
        && isUser
        && (
          (this.freeSearchCount == 0) // user can't search
          // || !isAvailableOption(options, this[MutationUser.permissionAvailableName]) // search option is not available for user
        )
      ) {
        // await this.$store.commit(`GeneralModule/${MutationGeneral.setShowPopupPermissionNotification}`, true)
        // this.$notification['warning']({
        //   message: 'Hãy nâng cấp tài khoản',
        //   description: 'Tài khoản của bạn cần nâng cấp để thực hiện phân tích này',
        // })
        await this.$store.dispatch(`${MutationGeneral.MODULE_PATH}/${MutationGeneral.setShowPopupNotification}`, NOTIFICATION_TYPE.upgrade_permission)
        return
      }
      // load statistic
      // if (isUser && this.isExpired) {
      //   if (typeof this.freeSearchCount === 'number') {
      //     this.freeSearchCount = this.freeSearchCount - 1
      //   } else {
      //     this.freeSearchCount = 4
      //   }
      // }
      this.$emit('search', true)
      await this.$store.commit(`SearchFilterModule/${MutationSearchFilter.isDemo}`, isSearchDemo)
      await this.$store.dispatch(`SearchFilterModule/${MutationSearchFilter.SEARCH_PRODUCT}`, options)
      await this.$store.dispatch(`${MutationSearchStatisticComparisonResult.MODULE_PATH}/${MutationSearchStatisticComparisonResult.RESET}`)
      this.$emit('search', false)
      fbTrackingSearch()
    },
    updateOptionFilters() {
      this.isOldFilter = false
    },
    resetValueRangeField(fieldName) {
      if (!fieldName || !this.iValueRange[fieldName]) {
        return
      }
      this.iValueRange[fieldName].min = null
      this.iValueRange[fieldName].max = null
      this.onChangeValueRange()
    },
    onSelectValueRangeField(value, opt) {
      let index = opt.data?.attrs?.indexField
      if (this.listValueRangeFieldName.includes(value)) {
        return
      }
      this.resetValueRangeField(this.listValueRangeFieldName[index])
      this.listValueRangeFieldName.splice(index, 1, value)
    },
    onClickAddValueRangeField() {
      this.valueRangeFieldIndex++
      let numberValueRangeField = Object.keys(this.localAllValueRangeFieldFilter).length
      if (this.listIdValueRangeIndex.length < numberValueRangeField) {
        this.listIdValueRangeIndex.push(this.valueRangeFieldIndex)
        this.listValueRangeFieldName.push(VALUE_RANGE_DEFAULT)
      }
    },
    onClickRemoveValueRangeField(index) {
      if (this.listValueRangeFieldName[index] !== VALUE_RANGE_DEFAULT) {
        this.resetValueRangeField(this.listValueRangeFieldName[index])
      }
      this.listValueRangeFieldName.splice(index, 1)
      this.listIdValueRangeIndex.splice(index, 1)
      if (!this.listIdValueRangeIndex.length) {
        this.onClickAddValueRangeField()
      }
    },
    onChangeValueRange() {
      this.iValueRange = {...this.iValueRange}
    },
    onClickAddValueCompareField() {
      this.iCompare.push({ ...VALUE_COMPARE_DEFAULT_OBJECT })
    },
    onClickRemoveValueCompareField(index) {
      this.iCompare.splice(index, 1)
      if (!this.iCompare.length) {
        this.onClickAddValueCompareField()
      }
      this.updateOptionFilters()
    },
    onRatioChange(indexField) {
      this.iCompare[indexField].ratio = this.iCompare[indexField].ratio ? this.iCompare[indexField].ratio : 1
      this.updateOptionFilters()
    },

    /**
     * Add prepared trending condition as comparison filter
     * Always add to first index to easy to update trending
     */
    onChangeTrending(event) {
      this.isOldFilter = false
      const fixedIndex = 0
      let durationDayFirstField = toNumber(this.iCompare?.[0].firstField)
      if (!!durationDayFirstField
        && isSameNormalObject(this.iCompare[0], FIXED_VALUE_COMPARE_TRENDING[durationDayFirstField])
      ) {
        this.iCompare.splice(fixedIndex, 1)
      }
      if (isSameNormalObject(this.iCompare?.[0], VALUE_COMPARE_DEFAULT_OBJECT)
      ) {
        this.iCompare.splice(0, 1)
      }
      let durationDay = event?.target?.value
      if (!durationDay) {
        if (this.iCompare?.length === 0) {
          this.onClickAddValueCompareField()
        }
        return
      }
      let filterComparisonTrendingObject = {
        ...FIXED_VALUE_COMPARE_TRENDING[durationDay]
      }
      if (!filterComparisonTrendingObject) {
        return
      }
      this.iCompare.splice(fixedIndex, 0, filterComparisonTrendingObject)
      this.updateOptionFilters()
    },

    /**
     * Add prepared bad product condition as comparison filter
     * Always add to second index to easy to remove condition
     */
    onChangeFixedFilterBadProduct() {
      const fixedIndex = 1
      if (this.isCheckedFixedFilterBadProduct) {
        this.iCompare.splice(fixedIndex, 0, ...FIXED_VALUE_COMPARE_BAD_PRODUCT_CONDITION_LIST)
        if (isSameNormalObject(this.iCompare[0], VALUE_COMPARE_DEFAULT_OBJECT)) {
          this.iCompare.splice(0, 1)
        }
      } else {
        const index = isSameNormalObject(this.iCompare?.[0], FIXED_VALUE_COMPARE_BAD_PRODUCT_CONDITION_LIST[0]) || this.iCompare?.length <= 1 ? 0 : fixedIndex
        for (let i = 0; i < FIXED_VALUE_COMPARE_BAD_PRODUCT_CONDITION_LIST.length; i++) {
          const item = FIXED_VALUE_COMPARE_BAD_PRODUCT_CONDITION_LIST[i]
          if (isSameNormalObject(this.iCompare[index], item)) {
            this.iCompare.splice(index, 1)
          }
        }
        if (this.iCompare?.length === 0) {
          this.onClickAddValueCompareField()
        }
      }
      this.updateOptionFilters()
    },

    /**
     * add prepared min value of order total count as value range filter
     */
    onChangeFixedFilterOrderCount() {
      if (this.isCheckedFixedFilterGoodSeller) {
        if (this.listValueRangeFieldName.indexOf(FIXED_VALUE_RANGE_GOOD_SELLER_PRODUCT.field) === -1) {
          if (this.listValueRangeFieldName[this.listValueRangeFieldName.length - 1] !== VALUE_RANGE_DEFAULT) {
            this.onClickAddValueRangeField()
          }
          this.listValueRangeFieldName[this.listIdValueRangeIndex.length-1] = FIXED_VALUE_RANGE_GOOD_SELLER_PRODUCT.field
        }
        this.iValueRange[FIXED_VALUE_RANGE_GOOD_SELLER_PRODUCT.field].min = FIXED_VALUE_RANGE_GOOD_SELLER_PRODUCT.min
      } else {
        this.iValueRange[FIXED_VALUE_RANGE_GOOD_SELLER_PRODUCT.field].min = null
        this.onClickRemoveValueRangeField(this.listValueRangeFieldName.indexOf(FIXED_VALUE_RANGE_GOOD_SELLER_PRODUCT.field))
      }
      this.updateOptionFilters()
    },
    isPreparedComparison(index) {
      let preparedComparisonIndex = this.isCheckedFixedFilterBadProduct ? FIXED_VALUE_COMPARE_BAD_PRODUCT_CONDITION_LIST.length : 0
      preparedComparisonIndex += this.fixedFilterTrendingDay ? 1 : 0
      return index < preparedComparisonIndex
    },
    async handleChangePlatform(value) {
      let platform = getPlatformById(value)
      this.filterOption = getFilterOptionByPermission(ALL_FILTER_OPTION_BY_PLATFORM_OBJECT[platform?.uniqueName], this[MutationUser.permissionAvailableName])
      let allCategoryId = this.isCategoryDetail ? [] : this.filterOption.allCategories.map(category => category.value)
      await this.$store.commit(`SearchFilterModule/${MutationSearchFilter.iCategoriesSelected}`, allCategoryId)
      await this.$store.commit(`SearchFilterModule/${MutationSearchFilter.iSortBy}`, null)
      await this.resetLocalState()
    },
    onCheckAllCategoryDetailChange(value) {
      let newCatSelected = [...this.iCategoriesSelected]
      if (this.listCategoryTab.category_lv2.data[value].checkAll || this.listCategoryTab.category_lv2.data[value].indeterminate) {
        // when category be selected all or a part then remove all
        let catRemoved = this.categoriesDetailOption.filter(item => item.parent === value).map(item => item.value)
        newCatSelected = newCatSelected.filter(item => !catRemoved.includes(item))
        this.iCategoriesSelected = newCatSelected
        this.listCategoryTab.category_lv2.data[value].checkAll = false
        this.listCategoryTab.category_lv2.data[value].indeterminate = false
        this.listCategoryTab.category_lv2.data[value].checkedCount = 0
        return
      }
      // selected all category detail
      let allCategoryDetail = this.categoriesDetailOption.filter(item => item.parent === value).map(item => item.value)
      newCatSelected.push(...allCategoryDetail)
      this.iCategoriesSelected = newCatSelected
      this.listCategoryTab.category_lv2.data[value].checkAll = true
      this.listCategoryTab.category_lv2.data[value].indeterminate = false
      this.listCategoryTab.category_lv2.data[value].checkedCount = allCategoryDetail.length
    },
    onClickCategoryParent(categoryParentValue) {
      this.listCategoryTab.category_lv2.currentParent = categoryParentValue
    },
    onCheckCategoryDetail(evt) {
      if (!evt) {
        return
      }
      const categoryDetailValue = evt.target.value
      const isChecked = evt.target.checked
      let categoryParentValue = this.categoriesDetailOption.find(item => item.value === categoryDetailValue).parent
      let categoryParentLength = this.categoriesDetailOption.filter(item => item.parent === categoryParentValue).length
      if (isChecked) {
        this.listCategoryTab.category_lv2.data[categoryParentValue].checkedCount++
      } else {
        this.listCategoryTab.category_lv2.data[categoryParentValue].checkedCount--
      }
      if (this.listCategoryTab.category_lv2.data[categoryParentValue].checkedCount === categoryParentLength) {
        this.listCategoryTab.category_lv2.data[categoryParentValue].indeterminate = false
        this.listCategoryTab.category_lv2.data[categoryParentValue].checkAll = true
      } else {
        this.listCategoryTab.category_lv2.data[categoryParentValue].indeterminate = true
        this.listCategoryTab.category_lv2.data[categoryParentValue].checkAll = false
      }
    },
    onChangeCategoryTab(tabKey) {
      if (tabKey === this.listCategoryTab.category_lv1.key) {
        this.iCategoriesSelected = this.categoriesOption.map(cat => cat?.['value'])
        this.categorySelected = 'all'
        this.checkCategoryAll = true
      } else if (tabKey === this.listCategoryTab.category_lv2.key) {
        this.iCategoriesSelected = []
        this.categorySelected = 'none'
        this.listCategoryTab.category_lv2.currentParent = null
        this.listCategoryTab.category_lv2.data = generateCategoryDetailData(this.filterOption?.allCategories)
      }
    },
    onAddQueries(value) {
      if (!value) {
        return
      }
      this.shopPlatformId = getPlatformByUrl(value)?.platform_id
      if (this.shopPlatformId && !this.isHigherPriorityAccount(PERMISSION.market_default.priority)) {
        this.showPopupNotification = NOTIFICATION_TYPE.upgrade_permission_search_shop
      }
      if (this.shopPlatformId && this.iPlatforms?.[0] !== this.shopPlatformId) {
        this.shopUrl = value
        this.showPopupNotification = NOTIFICATION_TYPE.platform_url_error
      }
    },
    async changePlatformByAddedShopUrl() {
      this.iPlatforms = this.shopPlatformId
      await this.handleChangePlatform(this.shopPlatformId)
      this.iQueries.push(this.shopUrl)
    },
    removeAddedQuery() {
      this.iQueries.splice(this.iQueries.indexOf(this.shopUrl), 1)
    }
  },
  computed: {
    showPopupSearchFilter: {
      get() {
        return this.$store.state.GeneralModule.showPopupSearchFilter
      },
      async set(value) {
        await this.$store.commit(`GeneralModule/${MutationGeneral.setShowPopupSearchFilter}`, value)
      }
    },
    localAllPlatformObject() {
      return getBunchOfAvailablePlatformObjectByPermissionName(this[MutationUser.permissionAvailableName])
    },
    iValueRange: {
      get() {
        return this.$store.state.SearchFilterModule.iValueRange
      },
      async set(value) {
        this.isCheckedFixedFilterGoodSeller = value.order_count?.min === FIXED_VALUE_RANGE_GOOD_SELLER_PRODUCT.min
        await this.$store.commit(`${MutationSearchFilter.MODULE_PATH}/${MutationSearchFilter.iValueRange}`, value)
      }
    },
    [MutationSearchFilter.timestamp]: {
      get() {
        return this.$store.state.SearchFilterModule[MutationSearchFilter.timestamp]
      },
      async set(value) {
        await this.$store.commit(`${MutationSearchFilter.MODULE_PATH}/${MutationSearchFilter.timestamp}`, value)
      }
    },
    localAllDurationDayArray(){
      return this.filterOption?.allDurationDay
    },
    localAllValueRangeFieldFilter(){
      return this.filterOption?.allValueRangeFieldFilter
    },
    localAllValueCompareFieldFilter(){
      return this.filterOption?.allValueCompareFieldFilter
    },
    isSelectedMultiCategory() {
      return (this.iCategoriesSelected.length > 1 && this.iCategoriesSelected.length !== this.categoriesOption.length)
    },
    categoriesOption: {
      get() {
        return this.filterOption.allCategories
      },
      set(value) {
        return value
      }
    },
    categoriesDetailOption: {
      get() {
        return this.filterOption.allCategoriesDetail
      },
      set(value) {
        return value
      }
    },
    isSavedReport: {
      get() {
        return this.$store.getters[`${MutationSearchFilter.MODULE_PATH}/${MutationSearchFilter.isSavedReport}`]
      }
    },
    isCategoryDetail: {
      get() {
        return this.categoryKeyTab === this.listCategoryTab.category_lv2.key
      }
    },
    isInstallExtension: {
      get() {
        return this.$store.state.UserModule.isInstallExtension
      }
    },
    showPopupUpgradeAdvance: {
      get() {
        return this.$store.state.GeneralModule.showPopupUpgradeAdvance
      }
    },
    showPopupInstallExtension: {
      get() {
        return this.$store.state.GeneralModule.showPopupInstallExtension
      }
    },
    showPopupNotification: {
      get() {
        return this.$store.state.GeneralModule.showPopupNotification
      },
      async set(value) {
        await this.$store.commit(`GeneralModule/${MutationGeneral.setShowPopupNotification}`, value)
      }
    },
    iCategoriesSelected: {
      get() {
        return this.$store.state.SearchFilterModule.iCategoriesSelected
      },
      async set(value) {
        // if (value != null) {
        //   if (Array.isArray(value)) {
        //     await this.$store.commit(`SearchFilterModule/${MutationSearchFilter.setICategoriesSelected}`, value)
        //   } else if (value !== 'all') {
        //     await this.$store.commit(`SearchFilterModule/${MutationSearchFilter.setICategoriesSelected}`, [value])
        //   }
        // }
        await this.$store.commit(`SearchFilterModule/${MutationSearchFilter.iCategoriesSelected}`, value)
      }
    },
    iLocationWarehouses: {
      get() {
        return this.$store.state.SearchFilterModule.iLocationWarehouses
      },
      async set(value) {
        await this.$store.commit(`SearchFilterModule/${MutationSearchFilter.iLocationWarehouses}`, value)
      }
    },
    iKeywords: {
      get() {
        return this.$store.state.SearchFilterModule.iKeywords
      },
      async set(value) {
        await this.$store.commit(`SearchFilterModule/${MutationSearchFilter.iKeywords}`, value)
      }
    },
    iQueries: {
      get() {
        return this.$store.state.SearchFilterModule.iQueries
      },
      async set(value) {
        await this.$store.commit(`SearchFilterModule/${MutationSearchFilter.iQueries}`, value)
      }
    },
    iQueriesExclude: {
      get() {
        return this.$store.state.SearchFilterModule.iQueriesExclude
      },
      async set(value) {
        await this.$store.commit(`SearchFilterModule/${MutationSearchFilter.iQueriesExclude}`, value)
      }
    },
    iBrands: {
      get() {
        return this.$store.state.SearchFilterModule.iBrands
      },
      async set(value) {
        await this.$store.commit(`SearchFilterModule/${MutationSearchFilter.iBrands}`, value)
      }
    },
    iIsShopMall: {
      get() {
        return this.$store.state.SearchFilterModule.iIsShopMall
      },
      async set(value) {
        await this.$store.commit(`SearchFilterModule/${MutationSearchFilter.iIsShopMall}`, value)
      }
    },
    iIsShopNormal: {
      get() {
        return this.$store.state.SearchFilterModule.iIsShopNormal
      },
      async set(value) {
        await this.$store.commit(`SearchFilterModule/${MutationSearchFilter.iIsShopNormal}`, value)
      }
    },
    iSortBy: {
      get() {
        return this.$store.state.SearchFilterModule.iSortBy
      },
      async set(value) {
        await this.$store.commit(`SearchFilterModule/${MutationSearchFilter.iSortBy}`, value)
      }
    },
    iPlatforms: {
      async set(value) {
        await this.$store.commit(`SearchFilterModule/${MutationSearchFilter.iPlatforms}`, [value])
      },
      get() {
        return this.$store.state.SearchFilterModule.iPlatforms
      }
    },
    iDurationDay: {
      get() {
        return this.$store.state.SearchFilterModule.iDurationDay
      },
      async set(value) {
        await this.$store.commit(`SearchFilterModule/${MutationSearchFilter.iDurationDay}`, value)
      }
    },
    iDurationRange: {
      get() {
        return this.$store.state.SearchFilterModule.iDurationRange
      },
      async set(value) {
        await this.$store.commit(`SearchFilterModule/${MutationSearchFilter.iDurationRange}`, value)
      }
    },
    iDurationRangeFrom: {
      get() {
        return this.$store.getters['SearchFilterModule/iDurationRangeFrom']
      },
    },
    iDurationRangeTo: {
      get() {
        return this.$store.getters['SearchFilterModule/iDurationRangeTo']
      },
    },
    searchConfig: {
      get() {
        return this.$store.getters["SearchFilterModule/searchConfig"]
      },
      async set(value) {
        await this.$store.commit(`SearchFilterModule/${MutationSearchFilter.searchConfig}`, value)
      }
    },
    iCompare: {
      get() {
        return this.$store.state.SearchFilterModule.iCompare
      },
      async set(value) {
        await this.$store.commit(`SearchFilterModule/${MutationSearchFilter.iCompare}`, value)
      }
    },
    title: {
      get() {
        if (this.$store.state.GeneralModule.actionName === ActionNameConstants.SEARCH) {
          return 'Tìm kiếm sản phẩm Trending'
        } else if (this.$store.state.GeneralModule.actionName === ActionNameConstants.MARKET) {
          return 'Phân tích thị trường'
        }
        return 'Tìm kiếm sản phẩm Trending'
      }
    },
    isLoading: {
      get() {
        return this.$store.state.SearchFilterModule.isLoading
      }
    },
    isOldFilter: {
      get() {
        return this.$store.state.SearchFilterModule.isOldFilter
      },
      async set(value) {
        await this.$store.commit(`${MutationSearchFilter.MODULE_PATH}/${MutationSearchFilter.isOldFilter}`, value)
      }
    },
    iconSearch: {
      get() {
        if (this.$store.state.SearchFilterModule.isLoading) {
          return 'loading'
        }
        return 'search'
      }
    },
    buttonSearchName: {
      get() {
        if (this.$store.state.GeneralModule.actionName === ActionNameConstants.SEARCH) {
          return 'Phân tích'
          // return 'Tìm kiếm'
        } else if (this.$store.state.GeneralModule.actionName === ActionNameConstants.MARKET) {
          return 'Phân tích'
        }
        return 'Phân tích'
        // return 'Tìm kiếm'
      }
    }
  },
  mixins: [
    mixinStoreGetterGlobal,
    mixinStoreGetterPermission
  ],
  filters: {
    formatCurrency: formatCurrency,
    formatNumberHuman: formatNumberHuman,
  },
  watch: {
    [MutationUser.permissionAvailableName]() {
      let platform = getPlatformById(this.iPlatforms[0])
      this.filterOption = getFilterOptionByPermission(ALL_FILTER_OPTION_BY_PLATFORM_OBJECT[platform?.uniqueName], this[MutationUser.permissionAvailableName])
      let option = extractOptionFromRoute(this.$route)
      if (option) {
        this.applyFilter(option)
      }
    }
  }
}
</script>

<style scoped lang="scss">
.text-feature {
  transform: translate(5px, -5px);
  font-size: 10px;
}

.product-empty {
  margin: 50px 100px !important;

  .content {
    font-size: 36px;
    font-style: normal;
    font-stretch: normal;
    line-height: 1.33;
  }

  img.cover {
    max-width: 424px;
  }
}

.field-value-range {
  width: 105px;
  flex-grow: 1;
}

.compare-operator {
  width: 100%;
}

.input-value-range {
  flex-grow: 1;
  //max-width: 95px;
}

.icon-action {
  margin-left: 10px;
  cursor: pointer;
}

.table-compare {
  border-collapse: collapse;

  .table-header {
    th {
      font-weight: normal;
    }
  }

  .table-body {

    td {
      min-width: 80px;
      align-self: center;
    }
  }
}

.remove-icon {
  margin-left: 4px;
}
.text-note {
  font-size: .75rem;
  color: rgba(107,114,128,0.75);
}

h2.atitle {
  font-size: 14px;
  font-weight: 400;
}

/deep/ .ant-select {
  .ant-select-selection__rendered {
    ul {
      width: 100%;
      display: flex;
      flex-wrap: wrap;

      .ant-select-selection__choice {
        flex-shrink: 0;
      }

      .ant-select-search {
        flex-grow: 1;
      }

      .ant-select-search__field__wrap {
        flex-grow: 1;

        .ant-select-search__field {
          width: 100%;
        }
      }
    }

    &::after {
      display: none;
    }
  }
}

/deep/ {
  input, .ant-checkbox-wrapper, .ant-select {
    font-size: 12px
  }
  .ant-select-selection__choice {
    .ant-select-selection__remove-choice {
      color: #ee0033;
    }

    .ant-select-selection__required-choice {
      color: rgb(0, 128, 0);;
    }
  }

  .ant-radio-group {
    display: grid;
    grid-auto-flow: row;
    grid-template-columns: repeat(2, minmax(0, 1fr));
    grid-template-rows: repeat(2, minmax(0, 1fr));
    row-gap: 0.5rem;
    column-gap: 2rem;
  }
}
</style>
