<template>
  <div class="">
    <!--    <h2 class="title">-->
    <!--      {{title}}-->
    <!--    </h2>-->
    <highcharts ref="highchartsRef" v-if="chartOptions!=null" :options="chartOptions"
                :highcharts="hcInstance"></highcharts>
  </div>
</template>

<script>
  import { Chart } from 'highcharts-vue'
  import Highcharts from 'highcharts'

  export default {
    components: {
      highcharts: Chart,
    },
    props: {
      title: null,
      subTitle: null,
      color: null,
      categoriesName: null,
      categoryTitle: null,
      seriesData: null,
      seriesTitle: null,
      multiSeries: null,
      chartHeight: null,
      unit: {
        default: ''
      }
    },
    data() {
      return {
        hcInstance: Highcharts,
        chartOptions: null,
      }
    },
    mounted() {
      this.hcInstance.setOptions({
        lang: {
          numericSymbols: [` Nghìn ${this.unit}`, ` Triệu ${this.unit}`, ` Tỉ ${this.unit}`]
        }
      });
      this.redraw()
    },
    updated() {
      const chart = this.$refs.highchartsRef.chart
      chart.setSize(undefined, this.chartHeight)
    },
    methods: {
      redraw() {
        this.chartOptions = {
          chart: {
            type: 'line'
          },
          title: {
            text: this.title
          },
          subtitle: {
            text: this.subTitle
          },

          plotOptions: {
            series: {
              color: this.color
            }
          },
          xAxis: {
            categories: this.categoriesName,
            title: {
              text: this.categoryTitle
            },
            labels: {
              style: {
                color: '#000',
                fontSize: '15px'
              }
            }
          },

          yAxis: {
            min: 0,
            title: {
              text: this.seriesTitle
            },
            labels: {
              overflow: 'justify'
            }
          },
          series: this.multiSeries
            ? this.multiSeries.map(item => {return {name: item.name, data: item.data}})
            : [{
              name: this.seriesTitle,
              data: this.seriesData
            }],
          responsive: {
            rules: [{
              condition: {
                maxWidth: 500
              },
              chartOptions: {
                xAxis: {
                  labels: {
                    style: {
                      color: '#A8A8A8',
                      fontSize: '8px'
                    }
                  }
                },
                yAxis: {
                  min: 0,
                  labels: {
                    overflow: 'justify',
                    style: {
                      color: '#A8A8A8',
                      fontSize: '8px'
                    }
                  },
                  title: {
                    style: {
                      color: '#A8A8A8',
                      fontSize: '10px'
                    }
                  },
                },
                legend: {
                  layout: 'horizontal',
                  align: 'right',
                  verticalAlign: 'bottom',
                  itemStyle: {'color': '#A8A8A8', 'font-size': '8px'}
                }
              }
            }]
          }
        }
      }
    },
    watch: {
      seriesData() {
        this.redraw()
      },
      multiSeries() {
        this.redraw()
      },
    },
  }

</script>

<style scoped>

</style>
