<template>
  <svg width="19" height="19" viewBox="0 0 19 19" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M4.92975 8.70759H14.0711C14.7535 8.70759 15.1161 7.90009 14.6625 7.38947L10.0922 2.24759C10.0181 2.16392 9.92702 2.09693 9.82508 2.05105C9.72314 2.00517 9.61262 1.98145 9.50083 1.98145C9.38904 1.98145 9.27852 2.00517 9.17658 2.05105C9.07463 2.09693 8.98359 2.16392 8.90945 2.24759L4.33758 7.38947C3.88395 7.90009 4.24654 8.70759 4.92975 8.70759ZM8.90866 16.7501C8.9828 16.8338 9.07384 16.9008 9.17578 16.9467C9.27773 16.9926 9.38825 17.0163 9.50004 17.0163C9.61183 17.0163 9.72235 16.9926 9.82429 16.9467C9.92623 16.9008 10.0173 16.8338 10.0914 16.7501L14.6617 11.6083C15.1161 11.0984 14.7535 10.2909 14.0703 10.2909H4.92975C4.24733 10.2909 3.88475 11.0984 4.33837 11.6091L8.90866 16.7501Z" fill="currentColor"/>
  </svg>
</template>

<script>
export default {
  name: "IconSort"
}
</script>

<style scoped>

</style>
