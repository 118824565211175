<template>
  <svg width="12" height="12" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M2.46094 8.8125C2.46094 8.61825 2.30363 8.46094 2.10938 8.46094H0.351562C0.157313 8.46094 0 8.61825 0 8.8125V11.6484C0 11.8427 0.157313 12 0.351562 12H2.10938C2.30363 12 2.46094 11.8427 2.46094 11.6484V8.8125Z" fill="currentColor"/>
    <path d="M5.29688 6.35156H3.53906C3.34481 6.35156 3.1875 6.50888 3.1875 6.70312V11.6484C3.1875 11.8427 3.34481 12 3.53906 12H5.29688C5.49113 12 5.64844 11.8427 5.64844 11.6484V6.70312C5.64844 6.50888 5.49113 6.35156 5.29688 6.35156Z" fill="currentColor"/>
    <path d="M8.46094 5.64844H6.70312C6.50888 5.64844 6.35156 5.80575 6.35156 6V11.6484C6.35156 11.8427 6.50888 12 6.70312 12H8.46094C8.65519 12 8.8125 11.8427 8.8125 11.6484V6C8.8125 5.80575 8.65519 5.64844 8.46094 5.64844Z" fill="currentColor"/>
    <path d="M11.6484 2.83594H9.89062C9.69638 2.83594 9.53906 2.99325 9.53906 3.1875V11.6484C9.53906 11.8427 9.69638 12 9.89062 12H11.6484C11.8427 12 12 11.8427 12 11.6484V3.1875C12 2.99325 11.8427 2.83594 11.6484 2.83594Z" fill="currentColor"/>
    <path d="M1.25391 7.73438C1.83553 7.73438 2.30859 7.26131 2.30859 6.67969C2.30859 6.58088 2.29059 6.48712 2.26509 6.39656L3.63478 5.42963C3.80672 5.55169 4.01559 5.625 4.24209 5.625C4.77572 5.625 5.21316 5.22544 5.28253 4.71056L6.68503 4.41525C6.87066 4.71788 7.20159 4.92188 7.58184 4.92188C8.16347 4.92188 8.63653 4.44881 8.63653 3.86719C8.63653 3.72656 8.60766 3.59288 8.55759 3.47006L10.2402 1.97438C10.3912 2.05763 10.5618 2.10938 10.7459 2.10938C11.3275 2.10938 11.8006 1.63631 11.8006 1.05469C11.8006 0.473063 11.3275 0 10.7459 0C10.1643 0 9.69122 0.473063 9.69122 1.05469C9.69122 1.19531 9.72009 1.329 9.77016 1.45181L8.08753 2.9475C7.93659 2.86425 7.76597 2.8125 7.58184 2.8125C7.04822 2.8125 6.61078 3.21206 6.54141 3.72694L5.13891 4.02225C4.95328 3.71963 4.62234 3.51563 4.24209 3.51563C3.66047 3.51563 3.18741 3.98869 3.18741 4.57031C3.18741 4.66913 3.20541 4.76288 3.23091 4.85344L1.86122 5.82038C1.68909 5.69831 1.48022 5.625 1.25391 5.625C0.672281 5.625 0.199219 6.09806 0.199219 6.67969C0.199219 7.26131 0.672281 7.73438 1.25391 7.73438Z" fill="currentColor"/>
  </svg>
</template>

<script>
export default {
  name: "IconGrowChart"
}
</script>

<style scoped>

</style>
