<template>
  <div class="">
    <!--    <h2 class="title">-->
    <!--      {{title}}-->
    <!--    </h2>-->
    <highcharts ref="highchartsRef" v-if="chartOptions!=null" :options="chartOptions"
                :highcharts="hcInstance"></highcharts>
  </div>
</template>

<script>
  import { Chart } from 'highcharts-vue'
  import Highcharts from 'highcharts'
  import {getPlatformById} from "@/service/platform/PlatformCommonService";
import { formatNumberHuman } from '@/helper/FormatHelper';

  export default {
    components: {
      highcharts: Chart,
    },
    props: {
      title: null,
      subTitle: null,
      color: null,
      comparisonColor: null,
      categoriesName: null,
      categoryTitle: null,
      seriesData: null,
      seriesTitle: null,
      seriesDataMultiplePlatformByCategories: null,
      platformIdsIncluded: null,
      categoriesComparisonName: null,
      seriesComparisonTitle: null,
      seriesComparisonData: null,
      seriesComparisonDataMultiplePlatformByCategories: null,
      chartHeight: null,
      unit: {
        default: ''
      }
    },
    data() {
      return {
        hcInstance: Highcharts,
        chartOptions: null,
        colorDefault: 'gray',
      }
    },
    mounted() {
      this.hcInstance.setOptions({
        lang: {
          numericSymbols: [` Nghìn ${this.unit}`, ` Triệu ${this.unit}`, ` Tỉ ${this.unit}`]
        }
      });
      this.redraw()
    },
    updated() {
      const chart = this.$refs.highchartsRef.chart
      chart.setSize(undefined, this.chartHeight)
    },
    methods: {
      redraw() {
        this.chartOptions = {
          chart: {
            type: 'column',
            height: 350,
          },
          title: {
            text: null
          },
          subtitle: {
            text: null
          },

          plotOptions: {
            series: {
              color: this.color
            },
            column: {},
            bar: {}
          },
          xAxis: {
            categories: !this.categoriesComparisonName || this.categoriesName.length >= this.categoriesComparisonName?.length ? this.categoriesName : this.categoriesComparisonName,
            title: {
              text: this.categoryTitle,
              align: 'high'
            },
            labels: {
              style: {
                color: '#000',
                fontSize: '12px'
              }
            }
          },

          yAxis: {
            min: 0,
            title: {
              text: this.seriesTitle
            },
            labels: {
              overflow: 'justify'
            }
          },
          series: this.platformIdsIncluded?.length > 1
            ? this.getSeriesDataForMultiplePlatform()
            : this.getChartSeriesDataForOnePlatform(),
          responsive: {
            rules: [{
              condition: {
                maxWidth: 500
              },
              chartOptions: {
                title: {
                  style: { "color": "#3B3B3B", "fontSize": "14px" }
                },
                xAxis: {
                  labels: {
                    rotation:  -40,
                    style: {
                      color: '#A8A8A8',
                      fontSize: '8px',
                      lineHeight: '8px',
                      width: '60px',
                    },
                    overflow: 'justify'
                  },
                  title: {
                    style: {
                      color: '#A8A8A8',
                      fontSize: '10px'
                    }
                  },
                },
                yAxis: {
                  min: 0,
                  labels: {
                    overflow: 'justify',
                    style: {
                      color: '#A8A8A8',
                      fontSize: '8px'
                    }
                  },
                  title: {
                    text: undefined,
                    style: {
                      color: '#A8A8A8',
                      fontSize: '10px'
                    }
                  },
                },
                legend: {
                  enabled: false,
                  layout: 'horizontal',
                  align: 'right',
                  verticalAlign: 'bottom',
                  itemStyle: {'color': '#A8A8A8', 'font-size': '8px'}
                }
              }
            }]
          }
        }
        if (this.platformIdsIncluded?.length > 1) {
          this.chartOptions.plotOptions.series.stacking = 'normal'
          this.chartOptions.plotOptions.bar.stacking = 'normal'
          this.chartOptions.tooltip = {
            formatter: function () {
              return '<b>' + this.x + '</b><br/>' +
                this.series.name + ': ' + formatNumberHuman(this.y) + '<br/>' +
                'Tất cả: ' + formatNumberHuman(this.point.stackTotal);
            }
          }
        }
      },

      getChartSeriesDataForOnePlatform() {
        let seriesData = []
        seriesData.push(
          this.generateSeriesDataOfOnePlatform(
            this.seriesData,
            this.seriesTitle,
            this.color
          )
        )
        if (this.seriesComparisonData) {
          seriesData.push(
            this.generateSeriesDataOfOnePlatform(
              this.seriesComparisonData,
              this.seriesComparisonTitle,
              this.colorDefault
            )
          )
        }
        return seriesData
      },
      generateSeriesDataOfOnePlatform(data, name, color) {
        return {name, data, color, showInLegend: false}
      },
      getSeriesDataForMultiplePlatform() {
        let seriesData = []
        seriesData.push(
          ...this.generateSeriesDataOfMultiplePlatform(
            this.platformIdsIncluded,
            this.seriesDataMultiplePlatformByCategories,
            this.seriesTitle,
            null,
            `current_${this.seriesTitle.length}`
          )
        )
        if (this.seriesComparisonData) {
          seriesData.push(
            ...this.generateSeriesDataOfMultiplePlatform(
              this.platformIdsIncluded,
              this.seriesComparisonDataMultiplePlatformByCategories,
              this.seriesComparisonTitle,
              this.comparisonColor,
              `comparison_${this.seriesTitle.length}`
            )
          )
        }
        return seriesData
      },
      generateSeriesDataOfMultiplePlatform(platformIdsIncluded, dataByCategories, name, color, stack) {
        if (!dataByCategories) {
          return
        }
        let multiplePlatform
        multiplePlatform = platformIdsIncluded.map((id, index) => {
          let platform = getPlatformById(id)
          return {
            id,
            name: name + ' của ' + platform.name,
            data: [],
            color: color?.[index] || platform.color,
            stack: stack,
            showInLegend: false
          }
        }).reverse()
        for (const categoryElement of dataByCategories) {
          if (!categoryElement) {
            multiplePlatform.forEach(platformElement => platformElement.data.push(null))
            continue
          }
          let bunchOfPlatformsInCategoryElement = categoryElement
          multiplePlatform.forEach(platformElement => {
            let platformValue = bunchOfPlatformsInCategoryElement.find(platform => platformElement.id === platform?.platform_id)?.value || 0
            platformElement.data.push(platformValue)
          })
        }
        return multiplePlatform
      },
    },
    watch: {
      seriesData() {
        this.redraw()
      },
      seriesComparisonData() {
        this.redraw()
      },
      seriesDataMultiplePlatformByCategories() {
        this.redraw()
      },
      seriesComparisonDataMultiplePlatformByCategories() {
        this.redraw()
      },
    },
  }

</script>

<style scoped>

</style>
