import {mapGetters} from "vuex";
import {MutationSearchStatisticResult} from "@/store/modules/SearchStatisticResultModule/mutation";
import {ALL_CHART_TAB, ALL_GENERAL_STATISTIC, ALL_METRIC} from "@/constant/general/GeneralConstant";

let gettersAgg = {}
for (const chartTabKey in ALL_CHART_TAB) {
  let chartTabStoreName = ALL_CHART_TAB[chartTabKey].storeName
  for (const metricKey in ALL_METRIC) {
    let metricStoreName = ALL_METRIC[metricKey].storeName
    gettersAgg[MutationSearchStatisticResult[chartTabStoreName][metricStoreName]] = `${MutationSearchStatisticResult.MODULE_PATH}/${MutationSearchStatisticResult[chartTabStoreName][metricStoreName]}`
  }
}

for (const generalStatisticKey in ALL_GENERAL_STATISTIC) {
  let generalStatisticStoreName =  ALL_GENERAL_STATISTIC[generalStatisticKey].storeName
  gettersAgg[generalStatisticStoreName] = `${MutationSearchStatisticResult.MODULE_PATH}/${MutationSearchStatisticResult[generalStatisticStoreName]}`
  if (ALL_GENERAL_STATISTIC[generalStatisticKey].storeNameDetailStatistic) {
    gettersAgg[ALL_GENERAL_STATISTIC[generalStatisticKey].storeNameDetailStatistic] = `${MutationSearchStatisticResult.MODULE_PATH}/${MutationSearchStatisticResult[ALL_GENERAL_STATISTIC[generalStatisticKey].storeNameDetailStatistic]}`
  }
}

const mixinGetSearchStatisticResult = {
  computed: {
    ...mapGetters({
      // orderTotal: `${MutationSearchStatisticResult.MODULE_PATH}/orderTotal`,
      // revenueTotal: `${MutationSearchStatisticResult.MODULE_PATH}/revenueTotal`,
      // productTotal: `${MutationSearchStatisticResult.MODULE_PATH}/productTotal`,
      // shopTotal: `${MutationSearchStatisticResult.MODULE_PATH}/shopTotal`,
      // commissionTotal: `${MutationSearchStatisticResult.MODULE_PATH}/commissionTotal`,
      revenueByShopMall: `${MutationSearchStatisticResult.MODULE_PATH}/${MutationSearchStatisticResult.revenueByShopMall}`,
      revenueByOtherShop: `${MutationSearchStatisticResult.MODULE_PATH}/${MutationSearchStatisticResult.revenueByOtherShop}`,
      orderByShopMall: `${MutationSearchStatisticResult.MODULE_PATH}/${MutationSearchStatisticResult.orderByShopMall}`,
      orderByOtherShop: `${MutationSearchStatisticResult.MODULE_PATH}/${MutationSearchStatisticResult.orderByOtherShop}`,
      shopMall: `${MutationSearchStatisticResult.MODULE_PATH}/${MutationSearchStatisticResult.shopMall}`,
      otherShop: `${MutationSearchStatisticResult.MODULE_PATH}/${MutationSearchStatisticResult.otherShop}`,
      aggRevenueByCategories: `${MutationSearchStatisticResult.MODULE_PATH}/aggRevenueByCategories`,
      aggRevenueByPriceRange: `${MutationSearchStatisticResult.MODULE_PATH}/aggRevenueByPriceRange`,
      aggRevenueByBrands: `${MutationSearchStatisticResult.MODULE_PATH}/aggRevenueByBrands`,
      aggRevenueByLocations: `${MutationSearchStatisticResult.MODULE_PATH}/aggRevenueByLocations`,
      aggOrderCountByCategories: `${MutationSearchStatisticResult.MODULE_PATH}/aggOrderCountByCategories`,
      aggOrderCountByPriceRange: `${MutationSearchStatisticResult.MODULE_PATH}/aggOrderCountByPriceRange`,
      aggOrderCountByBrands: `${MutationSearchStatisticResult.MODULE_PATH}/aggOrderCountByBrands`,
      aggOrderCountByLocations: `${MutationSearchStatisticResult.MODULE_PATH}/aggOrderCountByLocations`,
      aggCommissionByCategories: `${MutationSearchStatisticResult.MODULE_PATH}/aggCommissionByCategories`,
      aggCommissionByPriceRange: `${MutationSearchStatisticResult.MODULE_PATH}/aggCommissionByPriceRange`,
      aggCommissionByBrands: `${MutationSearchStatisticResult.MODULE_PATH}/aggCommissionByBrands`,
      aggCommissionByLocations: `${MutationSearchStatisticResult.MODULE_PATH}/aggCommissionByLocations`,
      ...gettersAgg
    })
  }
}

export {
  mixinGetSearchStatisticResult
}
