const {apiCheckProductLinkDetail} = require("@/api/product/ApiProductSearch");

const checkErrorProductUrl = async (shopUrl, platformId) => {
  let data = {
    "product_url": shopUrl,
    "search_platform_id": platformId
  }
  let shopDetail = await apiCheckProductLinkDetail(data)
  if (!shopDetail) {
    return
  }
  if (!shopDetail.status) {
    return
  }
  return shopDetail.data?.message
}

export {
  checkErrorProductUrl
}
