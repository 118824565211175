<template>
  <div>
    <div class="table-product" :class="isMobile ? 'mobile-view' : ''">
      <a-table
        :tableLayout="isMobile ? 'fixed' : ''"
        :columns="columns"
        :rowKey="record => record.product_base_id"
        :dataSource="dataSource"
        :pagination="pagination"
        :loading="isLoading"
        :scroll="isMobile ? {} : { x: 1500, y: 600 }"
        @change="handleTableChange"
      >
        <!--        heading table-->
        <span slot="order_count_custom">Sản phẩm đã bán</span>
        <span slot="order_count_7d">Sản phẩm đã bán <br>7 ngày</span>
        <span slot="order_count_30d">Sản phẩm đã bán <br>30 ngày</span>
        <span slot="order_count_90d">Sản phẩm đã bán <br>90 ngày</span>
        <span slot="order_count_180d">Sản phẩm đã bán <br>180 ngày</span>

        <span slot="order_revenue_custom">Doanh số</span>
        <span slot="order_revenue_7d">Doanh số <br>7 ngày</span>
        <span slot="order_revenue_30d">Doanh số <br>30 ngày</span>
        <span slot="order_revenue_90d">Doanh số <br>90 ngày</span>
        <span slot="order_revenue_180d">Doanh số <br>180 ngày</span>

        <span slot="review_count_custom">Đánh giá</span>
        <span slot="review_revenue_7d">Đánh giá <br>7 ngày</span>
        <span slot="review_revenue_30d">Đánh giá <br>30 ngày</span>
        <span slot="review_revenue_90d">Đánh giá <br>90 ngày</span>
        <span slot="review_revenue_180d">Đánh giá <br>180 ngày</span>

        <span slot="view_count_custom_title">
          Lượt xem
          <a-tooltip style="cursor: default" :overlayStyle="{fontSize: isDesktop ? '' : '8px', minHeight: isDesktop ? '' : 'unset'}">
            <template slot="title">
              Dữ liệu do BeeCost ước tính
            </template>
            <i>
              <IconInformation
                :width="isMobile ? 10 : 12"
                :height="isMobile ? 10 : 12"
                style="transform: translateY(-4px)"
              />
            </i>
          </a-tooltip>
        </span>

        <!--        row -->
        <template slot="product_name" slot-scope="product_name, product">
          <div class="row-product-name pr-0.5 w-full" style="flex-flow: row nowrap;" :style="isMobile ? '' : 'min-width: 170px'">
            <a-popover title="" trigger="hover">
              <template slot="content">
                <img :src="product['url_thumbnail']" alt="" class="img-responsive image-zoom" style="width: 200px" :class="isMobile ? 'margin-left-5' : ''">
              </template>
              <img :src="product['url_thumbnail']" alt="" class="img-responsive row-thumbnail" :class="isMobile ? 'margin-left-5' : ''">
            </a-popover>
            <div class="margin-left-5" style="width: 100%;">
              <div class="flex">
                <p class="product_name line-clamp__2 flex-grow-1" @click="!isMobile || isPaidAccount ? onClickProductName(product) : null">
                  <span v-if="product['official_type'] === 1" class="icon-mall-wrap"><mall-flag class="icon-mall"/></span>
                  <span :class="product['official_type'] === 1 ? 'ml-1' : ''">{{ product_name }}</span>
                </p>
                <a-tooltip>
                  <template slot="title" v-if="product['price_updated_at']">
                    <p>{{product_name}}</p>
                    <p class="border-t">Cập nhật thông tin sản phẩm ngày {{product['price_updated_at'] | timestampToDate}}</p>
                  </template>
                  <i>
                    <IconInformation
                      :width="isMobile ? 10 : 12"
                      :height="isMobile ? 10 : 12"
                      class="ml-0.5"
                      :style="{transform: isMobile && product['official_type'] === 1 ? 'translateY(4px)' : 'translateY(-4px)'}"
                    />
                  </i>
                </a-tooltip>
              </div>
              <div class="ant-row-flex"
                   style="flex-flow: row; justify-content: space-between; align-items: center;">
                <div class="flex" style="flex-flow: row wrap">
                  <a-rate :defaultValue="product['rating_avg']" allowHalf disabled :class="'mr-1 mobile-star'"/>
                  <span v-if="isMobile" class="rating_count">({{product['rating_count'] | formatNumberHuman}})</span>
                  <span v-else class="rating_count"> {{product['rating_count'] | formatNumberHuman}} đánh giá</span>
                </div>
              </div>
              <div>
                <span v-if="product['order_count']" class="rating_count">Tổng sản phẩm đã bán:
                  <span>{{product['order_count'] | formatNumberHuman}}</span>
                </span>
              </div>
              <div v-if="isMobile">
                <span v-if="product['revenue']" class="rating_count">Tổng doanh số:
                  <template v-if="canShowStatistic">
                    <span>{{product['revenue'] | formatCurrency}}</span>
                  </template>
                  <a class="opacity-50" v-else @click="onClickShowPricing">Nâng cấp tài khoản</a>
                </span>
              </div>
              <div v-if="product['platform_created_at']">
                <span class="rating_count">
                  <span>Ngày tạo: </span>
                  <span>
                    {{product['platform_created_at'] | timestampToDate | removeDay}}
                    <a-tooltip style="cursor: default" :trigger="isDesktop ? 'hover' : 'click'" :overlayStyle="{fontSize: isDesktop ? '' : '8px', minHeight: isDesktop ? '' : 'unset'}">
                      <template slot="title">
                        <span>Dữ liệu do BeeCost ước tính</span>
                      </template>
                      <i>
                        <IconInformation
                          :width="isMobile ? 10 : 12"
                          :height="isMobile ? 10 : 12"
                          style="transform: translateY(-4px)"
                        />
                      </i>
                    </a-tooltip>
                  </span>
                </span>
              </div>
              <div v-if="product['shop_platform_name']">
                <span class="rating_count">
                  <img v-if="product.platform_logo_url" :src="product.platform_logo_url" class="img-platform mr-1"/>
                  <a-icon v-else type="shop" class="mr-1"/>
                  <span @click="onClickShopUrlDirect(product['shop_url'])" :class="product['shop_url'] ? 'cursor-pointer' : ''" class="hover:text-primary-900">{{product['shop_platform_name']}}</span>
                </span>
              </div>
              <div v-if="isDesktop">
                <span @click="onClickUrlDirect(product)" class="text-primary-500 cursor-pointer">Link sản phẩm</span>
<!--                <span v-if="product['shop_url']"> | <span @click="onClickShopUrlDirect(product['shop_url'])" class="text-primary-500 cursor-pointer">Link shop</span></span>-->
              </div>
              <div v-if="isMobile" class="relative h-full w-screen">
                <div class="absolute bottom-0 left-0 w-full flex justify-end items-end" style="width: calc(100% - 2*12px - 2*5px)">
                  <div @click="onClickUrlDirect(product)" class="p-1 link cursor-pointer flex items-center justify-center">
                    <span class="">Link sản phẩm</span>
                    <svg width="10" height="6" viewBox="0 0 10 6" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <path fill-rule="evenodd" clip-rule="evenodd" d="M0.625051 2.99985C0.625051 3.08273 0.657975 3.16221 0.71658 3.22082C0.775186 3.27942 0.854671 3.31235 0.937551 3.31235L8.30818 3.31235L6.3413 5.2786C6.31225 5.30765 6.2892 5.34215 6.27347 5.38011C6.25775 5.41807 6.24966 5.45876 6.24966 5.49985C6.24966 5.54094 6.25775 5.58163 6.27347 5.61959C6.2892 5.65755 6.31225 5.69204 6.3413 5.7211C6.37036 5.75015 6.40485 5.7732 6.44281 5.78893C6.48077 5.80465 6.52146 5.81274 6.56255 5.81274C6.60364 5.81274 6.64433 5.80465 6.68229 5.78893C6.72025 5.7732 6.75475 5.75015 6.7838 5.7211L9.2838 3.2211C9.3129 3.19207 9.33599 3.15759 9.35175 3.11962C9.3675 3.08165 9.37561 3.04095 9.37561 2.99985C9.37561 2.95874 9.3675 2.91804 9.35175 2.88008C9.33599 2.84211 9.3129 2.80763 9.2838 2.7786L6.7838 0.278599C6.75475 0.249544 6.72025 0.226496 6.68229 0.210772C6.64433 0.195047 6.60364 0.186954 6.56255 0.186954C6.52146 0.186954 6.48077 0.195047 6.44281 0.210772C6.40485 0.226496 6.37036 0.249544 6.3413 0.278599C6.31225 0.307654 6.2892 0.342147 6.27347 0.380109C6.25775 0.418071 6.24966 0.458759 6.24966 0.499849C6.24966 0.540939 6.25775 0.581627 6.27347 0.619589C6.2892 0.657551 6.31225 0.692044 6.3413 0.721099L8.30818 2.68735L0.937551 2.68735C0.854671 2.68735 0.775186 2.72027 0.71658 2.77888C0.657975 2.83748 0.625051 2.91697 0.625051 2.99985Z" fill="currentColor"/>
                    </svg>
                  </div>
                </div>
              </div>
<!--              <p>-->
<!--                <span v-if="isMobile" @click="onClickUrlDirect(product)" class="link text-primary-500 cursor-pointer">Link sản phẩm</span>-->
<!--              </p>-->
            </div>
          </div>
        </template>

        <template slot="price" slot-scope="price">
          {{price | formatCurrency}}
        </template>

        <span slot="min_max_price_title">
          Giá trong phân loại hàng
          <a-tooltip style="cursor: default" :trigger="isDesktop ? 'hover' : 'click'" :overlayStyle="{fontSize: isDesktop ? '' : '8px', minHeight: isDesktop ? '' : 'unset'}">
            <template slot="title">
              <span>Khoảng giá (giá min - giá max) của sản phẩm có nhiều phân loại hàng</span>
            </template>
            <i>
              <IconInformation
                :width="isMobile ? 10 : 12"
                :height="isMobile ? 10 : 12"
                style="transform: translateY(-4px)"
              />
            </i>
          </a-tooltip>
        </span>

        <template slot="min_max_price" slot-scope="product" v-if="product.price_min && product.price_max">
          {{ product.price_min | formatCurrency }} - {{ product.price_max | formatCurrency }}
        </template>

        <template slot="order_count_custom" slot-scope="order_count_custom, product">
          <div v-if="canShowStatistic">
            <p>{{order_count_custom | formatNumberHuman}}</p>
            <p v-if="product.order_count_custom_previous"><CompareValue :value="order_count_custom" :value-comparison="product.order_count_custom_previous"/> so với cùng kì</p>
          </div>
          <a class="opacity-50" v-else @click="onClickShowPricing">Nâng cấp tài khoản</a>
        </template>

        <template slot="order_count_7d" slot-scope="order_count_7d, product">
          <div v-if="canShowStatistic">
            <p>{{order_count_7d | formatNumberHuman}}</p>
            <p v-if="product.order_count_7d_previous"><CompareValue :value="order_count_7d" :value-comparison="product.order_count_7d_previous"/> so với cùng kì</p>
          </div>
          <a class="opacity-50" v-else @click="onClickShowPricing">Nâng cấp tài khoản</a>
        </template>

        <template slot="order_count_30d" slot-scope="order_count_30d, product">
          <div v-if="canShowStatistic">
            <p>{{order_count_30d | formatNumberHuman}}</p>
            <p v-if="product.order_count_30d_previous"><CompareValue :value="order_count_30d" :value-comparison="product.order_count_30d_previous"/> so với cùng kì</p>
          </div>
          <a class="opacity-50" v-else @click="onClickShowPricing">Nâng cấp tài khoản</a>
        </template>

        <template slot="order_count_90d" slot-scope="order_count_90d, product">
          <div v-if="canShowStatistic">
            <p>{{order_count_90d | formatNumberHuman}}</p>
            <p v-if="product.order_count_90d_previous"><CompareValue :value="order_count_90d" :value-comparison="product.order_count_90d_previous"/> so với cùng kì</p>
          </div>
          <a class="opacity-50" v-else @click="onClickShowPricing">Nâng cấp tài khoản</a>
        </template>

        <template slot="order_count_180d" slot-scope="order_count_180d, product">
          <div v-if="canShowStatistic">
            <p>{{order_count_180d | formatNumberHuman}}</p>
            <p v-if="product.order_count_180d_previous"><CompareValue :value="order_count_180d" :value-comparison="product.order_count_180d_previous"/> so với cùng kì</p>
          </div>
          <a class="opacity-50" v-else @click="onClickShowPricing">Nâng cấp tài khoản</a>
        </template>

        <template slot="order_revenue_custom" slot-scope="order_revenue_custom, product">
          <div v-if="canShowStatistic">
            <p>{{order_revenue_custom | formatCurrency}}</p>
            <p v-if="product.order_revenue_custom_previous"><CompareValue :value="order_revenue_custom" :value-comparison="product.order_revenue_custom_previous"/> so với cùng kì</p>
          </div>
          <a class="opacity-50" v-else @click="onClickShowPricing">Nâng cấp tài khoản</a>
        </template>

        <template slot="order_revenue_7d" slot-scope="order_revenue_7d, product">
          <div v-if="canShowStatistic">
            <p>{{order_revenue_7d | formatCurrency}}</p>
            <p v-if="product.order_revenue_7d_previous"><CompareValue :value="order_revenue_7d" :value-comparison="product.order_revenue_7d_previous"/> so với cùng kì</p>
          </div>
          <a class="opacity-50" v-else @click="onClickShowPricing">Nâng cấp tài khoản</a>
        </template>

        <template slot="order_revenue_30d" slot-scope="order_revenue_30d, product">
          <div v-if="canShowStatistic">
            <p>{{order_revenue_30d | formatCurrency}}</p>
            <p v-if="product.order_revenue_30d_previous"><CompareValue :value="order_revenue_30d" :value-comparison="product.order_revenue_30d_previous"/> so với cùng kì</p>
          </div>
          <a class="opacity-50" v-else @click="onClickShowPricing">Nâng cấp tài khoản</a>
        </template>

        <template slot="order_revenue_90d" slot-scope="order_revenue_90d, product">
          <div v-if="canShowStatistic">
            <p>{{order_revenue_90d | formatCurrency}}</p>
            <p v-if="product.order_revenue_90d_previous"><CompareValue :value="order_revenue_90d" :value-comparison="product.order_revenue_90d_previous"/> so với cùng kì</p>
          </div>
          <a class="opacity-50" v-else @click="onClickShowPricing">Nâng cấp tài khoản</a>
        </template>

        <template slot="order_revenue_180d" slot-scope="order_revenue_180d, product">
          <div v-if="canShowStatistic">
            <p>{{order_revenue_180d | formatCurrency}}</p>
            <p v-if="product.order_revenue_180d_previous"><CompareValue :value="order_revenue_180d" :value-comparison="product.order_revenue_180d_previous"/> so với cùng kì</p>
          </div>
          <a class="opacity-50" v-else @click="onClickShowPricing">Nâng cấp tài khoản</a>
        </template>

        <template slot="revenue" slot-scope="revenue, product">
          <div v-if="canShowStatistic">
            <p>{{revenue | formatCurrency}}</p>
            <p v-if="product.revenue_previous"><CompareValue :value="revenue" :value-comparison="product.revenue_previous"/> so với cùng kì</p>
          </div>
          <a class="opacity-50" v-else @click="onClickShowPricing">Nâng cấp tài khoản</a>
        </template>

        <template slot="review_count_custom" slot-scope="review_count_custom, product">
          <div v-if="canShowStatistic">
            <p>{{review_count_custom | formatNumberHuman}}</p>
            <p v-if="product.review_count_custom_previous"><CompareValue :value="review_count_custom" :value-comparison="product.review_count_custom_previous"/> so với cùng kì</p>
          </div>
          <a class="opacity-50" v-else @click="onClickShowPricing">Nâng cấp tài khoản</a>
        </template>

        <template slot="review_count_7d" slot-scope="review_count_7d, product">
          <div v-if="canShowStatistic">
            <p>{{review_count_7d | formatNumberHuman}}</p>
            <p v-if="product.review_count_7d_previous"><CompareValue :value="review_count_7d" :value-comparison="product.review_count_7d_previous"/> so với cùng kì</p>
          </div>
          <a class="opacity-50" v-else @click="onClickShowPricing">Nâng cấp tài khoản</a>
        </template>

        <template slot="review_count_30d" slot-scope="review_count_30d, product">
          <div v-if="canShowStatistic">
            <p>{{review_count_30d | formatNumberHuman}}</p>
            <p v-if="product.review_count_30d_previous"><CompareValue :value="review_count_30d" :value-comparison="product.review_count_30d_previous"/> so với cùng kì</p>
          </div>
          <a class="opacity-50" v-else @click="onClickShowPricing">Nâng cấp tài khoản</a>
        </template>

        <template slot="review_count_90d" slot-scope="review_count_90d, product">
          <div v-if="canShowStatistic">
            <p>{{review_count_90d | formatNumberHuman}}</p>
            <p v-if="product.review_count_90d_previous"><CompareValue :value="review_count_90d" :value-comparison="product.review_count_90d_previous"/> so với cùng kì</p>
          </div>
          <a class="opacity-50" v-else @click="onClickShowPricing">Nâng cấp tài khoản</a>
        </template>

        <template slot="review_count_180d" slot-scope="review_count_180d, product">
          <div v-if="canShowStatistic">
            <p>{{review_count_180d | formatNumberHuman}}</p>
            <p v-if="product.review_count_180d_previous"><CompareValue :value="review_count_180d" :value-comparison="product.review_count_180d_previous"/> so với cùng kì</p>
          </div>
          <a class="opacity-50" v-else @click="onClickShowPricing">Nâng cấp tài khoản</a>
        </template>

        <template slot="category_name" slot-scope="_, product">

          <a-popover trigger="hover">
            <template slot="content">
              <p v-for="category in product['categories']" :key="category['id']">
                {{getCategoryNameById(category['id'])}}
              </p>
            </template>
            <p style="cursor: pointer">
              {{product['category_name']}}
            </p>
          </a-popover>
        </template>


        <template slot="action" slot-scope="_, product">
          <div class="flex flex-col justify-space-between">
            <div class="text-primary-500 cursor-pointer flex flex-col justify-start" :style="isMobile ? 'font-size: 8px; line-height: 14px; word-spacing: -.1ch;' : ''">
              <div class="mb-1" type="link" @click="onClickHistoryPrice(product)">
                <a-icon class="mr-1" type="line-chart"/>
                <span>Lịch sử giá</span>
              </div>
              <div class="" type="link" @click="onClickHistoryOrder(product)">
                <a-icon class="mr-1" type="rise"/>
                <span>Lịch sử bán</span>
              </div>
            </div>
          </div>
        </template>

        <!--      <template slot="name" slot-scope="name"> {{ name.first }} {{ name.last }}</template>-->
      </a-table>

      <a-modal title=""
               :closable="true"
               :footer="null"
               v-model="showPopupProductHistory"
               :maskClosable="true"
               :width="960"
      >
        <PopupProductHistory v-if="productBaseIdCurrent" :product-base-id="productBaseIdCurrent"
                             :popup-product-history-tab="popupProductHistoryTab"
        />
      </a-modal>
    </div>
  </div>
</template>
<script>
  import Default from '@/layout/Default'
  import {formatCurrency, formatNumberHuman, timestampToDate} from '@/helper/FormatHelper'
  import { MutationSearchFilter } from '@/store/modules/SearchFilterModule/mutation'
  import MallFlag from '@/components/product/MallFlag'
  import {
    canShowHistoryOrder, canShowHistoryPrice,
    canShowProductLink
  } from '@/service/user/UserPermissionService'
  import { MutationGeneral } from '@/store/modules/GeneralModule/mutation'
  import PopupProductHistory from '@/views/product/PopupProductHistory'
  import {ActionNameConstants, ALL_PLATFORM_BASE_OBJECT, NOTIFICATION_TYPE} from '@/constant/general/GeneralConstant'
  import PopupInstallExtension from '@/views/popup/PopupInstallExtension'
  import {getPlatformById, getPlatformByUrl} from "@/service/platform/PlatformCommonService";
  import {mixinStoreGetterPermission} from "@/mixin/store/MixinUser";
  import {removeColumnsTable} from "@/service/market/MarketService";
  import {mixinStoreGetterGlobal} from "@/mixin/store/MixinGlobal";
  import CompareValue from "@/components/common/CompareValue";
  import {getCategoryNameById} from "@/service/search/SearchService";
  import IconInformation from "@/assets/icon/IconInformation";

  export default {
    components: {
      Default,
      PopupProductHistory,
      MallFlag,
      PopupInstallExtension,
      CompareValue,
      IconInformation,
    },
    props: {
      loadType: {
        default: 'pagination',
      },
      rowCount: {
        default: null
      },
      sortBy: {
        default: null
      }
    },
    data() {
      return {
        pagination: {},
        isLoading: false,
        showPopupProductHistory: false,
        productBaseIdCurrent: null,
        popupProductHistoryTab: null
      }
    },
    async mounted() {
      this.pagination = this.loadType === 'pagination'
        ? {
            'current': this.iPage,
            'pageSize': this.iLimit,
            'total': 240,
            'simple': true,
          }
        : false
      // this.isLoading = true
      // let optionsFilter = this.$store.state.SearchFilterModule.options
      // if (!optionsFilter) {
      //   optionsFilter = await this.$store.dispatch(`SearchFilterModule/${MutationSearchFilter.BUILD_OPTIONS}`)
      // }
      // let optionsProduct = await this.$store.dispatch(`${MutationSearchFilter.MODULE_PATH}/${MutationSearchFilter.BUILD_PRODUCT_OPTIONS}`, optionsFilter)
      // let options = {
      //   ...optionsFilter,
      //   ...optionsProduct
      // }
      // options.type = MutationSearchFilter.SET_RESPONSE_SEARCH_PRODUCT
      // await this.$store.dispatch(`SearchFilterModule/${MutationSearchFilter.SEARCH_PRODUCT}`, options)
      // this.isLoading = false
    },
    methods: {
      async handleTableChange(pagination, filters, sorter) {
        this.isLoading = true
        if (this.pagination) {
          const pager = { ...this.pagination }
          pager.current = pagination.current
          this.pagination = pager
        }
        if (sorter != null && Object.keys(sorter).length > 0) {
          let sort = sorter?.['field']

          if (sorter?.['field'] === 'category_name') {
            sort = 'categories.name'
          }
          if (sorter['order'] === 'ascend') {
            sort += `__asc`
          } else if (sorter['order'] === 'descend') {
            sort += `__desc`
          } else {
            return
          }
          this.$store.commit(`SearchFilterModule/${MutationSearchFilter.iSortBy}`, sort)
        }
        this.$store.commit(`SearchFilterModule/${MutationSearchFilter.iPage}`, pagination.current)
        let optionsFilter = this.$store.state.SearchFilterModule.options
        let optionsProduct = await this.$store.dispatch(`${MutationSearchFilter.MODULE_PATH}/${MutationSearchFilter.BUILD_PRODUCT_OPTIONS}`, optionsFilter)
        let options = {
          ...optionsFilter,
          ...optionsProduct
        }
        options.type = MutationSearchFilter.SET_RESPONSE_SEARCH_PRODUCT
        await this.$store.commit(`SearchFilterModule/${MutationSearchFilter.isDemo}`, !this.isLogin)
        await this.$store.dispatch(`SearchFilterModule/${MutationSearchFilter.SEARCH_PRODUCT}`, options)
        this.isLoading = false
      },

      async onClickHistoryPrice(product) {
        let options = this.$store.state.SearchFilterModule.options
        let resultPermission = await canShowHistoryPrice(options)
        if (resultPermission.isPermission) {
          // window.open(product?.['url_product_detail_beecost_lsg'], "_blank")
          this.popupProductHistoryTab = 'history_price'
          this.productBaseIdCurrent = product['product_base_id']
          this.showPopupProductHistory = true
        } else {
          if (this.$store.state.GeneralModule.actionName === ActionNameConstants.HOME_PAGE) {
            // hiển thị popup direct to Market View
            this.$store.commit(`GeneralModule/${MutationGeneral.setShowPopupSearchDetail}`, true)
            return
          }
          // this.$notification['warning']({
          //   message: resultPermission.title,
          //   description: resultPermission.message,
          // })
          await this.$store.dispatch(`${MutationGeneral.MODULE_PATH}/${MutationGeneral.setShowPopupNotification}`, NOTIFICATION_TYPE.upgrade_permission)
        }
      },
      async onClickHistoryOrder(product) {
        let options = this.$store.state.SearchFilterModule.options
        let resultPermission = await canShowHistoryOrder(options)
        if (resultPermission.isPermission) {
          this.popupProductHistoryTab = 'history_order'
          this.productBaseIdCurrent = product['product_base_id']
          this.showPopupProductHistory = true
        } else {
          if (this.$store.state.GeneralModule.actionName === ActionNameConstants.HOME_PAGE) {
            // hiển thị popup direct to Market View
            this.$store.commit(`GeneralModule/${MutationGeneral.setShowPopupSearchDetail}`, true)
            return
          }
          // this.$notification['warning']({
          //   message: resultPermission.title,
          //   description: resultPermission.message,
          // })
          await this.$store.dispatch(`${MutationGeneral.MODULE_PATH}/${MutationGeneral.setShowPopupNotification}`, NOTIFICATION_TYPE.upgrade_permission)
        }
      },
      async onClickShopUrlDirect(shopUrl) {
        if(!shopUrl) {
          return
        }
        window.open(shopUrl, '_blank')
      },
      async onClickProductName(product) {
        let productUrl = this.isGlobalProduct
          ? product.url_direct
          : product.url_product_detail_beecost
        let options = this.$store.state.SearchFilterModule.options
        let resultPermission = await canShowProductLink(options)
        if (resultPermission.isPermission) {
          window.open(productUrl, '_blank')
        } else {
          await this.$store.dispatch(`${MutationGeneral.MODULE_PATH}/${MutationGeneral.setShowPopupNotification}`, NOTIFICATION_TYPE.upgrade_permission)
        }
      },
      async onClickUrlDirect(product) {
        let options = this.$store.state.SearchFilterModule.options

        let resultPermission = await canShowProductLink(options)
        if (resultPermission.isPermission) {
          window.open(product?.['url_direct'], '_blank')
        } else {
          // this.$notification['warning']({
          //   message: resultPermission.title,
          //   description: resultPermission.message,
          // })
          await this.$store.dispatch(`${MutationGeneral.MODULE_PATH}/${MutationGeneral.setShowPopupNotification}`, NOTIFICATION_TYPE.upgrade_permission)
          // if (this.$store.state.GeneralModule.actionName === ActionNameConstants.HOME_PAGE) {
          //   // hiển thị popup tới trang market
          //   this.$store.commit(`GeneralModule/${MutationGeneral.setShowPopupSearchDetail}`, true)
          // } else {
          //   this.$notification['warning']({
          //     message: resultPermission.title,
          //     description: resultPermission.message,
          //   })
          //   this.$store.commit(`GeneralModule/${MutationGeneral.setShowPopupLogin}`, true)
          // }
        }
      },
      getCategoryNameById: getCategoryNameById,
      onClickShowPricing() {
        this.$store.commit(`GeneralModule/${MutationGeneral.setShowPopupSignUpLeadForm}`, true)
      },
    },
    filters: {
      formatCurrency: formatCurrency,
      formatNumberHuman: formatNumberHuman,
      timestampToDate: timestampToDate,
      removeDay(dateString) {
        return dateString.split('/')?.length ? dateString.split('/').slice(1).join('/') : dateString
      },
    },
    computed: {
      canShowStatistic() {
        return !this.isDefaultAccount && this.freeSearchCount !== 0
      },
      isLogin: {
        get() {
          return this.$store.state.UserModule.isLogin
        }
      },
      isExpired: {
        get() {
          return this.$store.state.UserModule.isExpired
        }
      },
      columns: {
        get() {
          let localColumns = [...this.$store.getters[`SearchFilterModule/columnsTable`]]
          let platform = getPlatformById(this.options?.include_query?.platforms?.[0])
          // if (!this.isBusinessAccount || platform.name === ALL_PLATFORM_BASE_OBJECT.lazada.name) {
          //   localColumns = removeColumnsTable(localColumns, 'view_count')
          // }
          if ([ALL_PLATFORM_BASE_OBJECT.tiki.name, ALL_PLATFORM_BASE_OBJECT.lazada.name].includes(platform.name)) {
            localColumns = localColumns.filter(item => item.key !== 'min_max_price')
          }
          // if (platform.name === ALL_PLATFORM_BASE_OBJECT.lazada.name) {
          // //   localColumns = removeColumnsTable(localColumns, 'order_count')
          // //   localColumns = removeColumnsTable(localColumns, 'order_revenue')
          // } else {
          //   localColumns = removeColumnsTable(localColumns, 'review_count')
          // }
          if (this.isMobile) {
            localColumns = removeColumnsTable(localColumns, 'review_count')
            // localColumns = removeColumnsTable(localColumns, 'view_count')
            localColumns = removeColumnsTable(localColumns, 'category_name')
            localColumns = removeColumnsTable(localColumns, 'brand')
            localColumns = removeColumnsTable(localColumns, 'revenue')
            localColumns = localColumns.map(item => {
              delete item.sorter
              delete item.defaultSortOrder
              item.width = item.dataIndex === 'product_name' ? '32%' : '17%'
              if (item.dataIndex.indexOf('order_count') === 0) {
                item.title = item.title.replace('Sản phẩm', 'Sp')
              }
              if (item.dataIndex.indexOf('action') === 0) {
                delete item.fixed
                item.width = '65px'
              }
              return item
            })
          }
          if (this.options?.include_query?.platforms?.length > 1) {
            localColumns = localColumns.map(item => {
              if (['brand'].includes(item.dataIndex)) {
                item.dataIndex = `bee_${item.dataIndex}`
              }
              return item
            })
          }
          return localColumns
        }
      },
      options: {
        get() {
          return this.$store.state.SearchFilterModule.options
        }
      },
      iPage: {
        get() {
          return this.$store.state.SearchFilterModule.iPage
        }
      },
      iLimit: {
        get() {
          return this.$store.state.SearchFilterModule.iLimit
        }
      },
      products: {
        get() {
          return this.$store.state.SearchProductResultModule.products
        }
      },
      dataSource() {
        if (this.rowCount) {
          return this.products?.slice(0, this.rowCount)
        }
        return this.products.map(item => {
          item.platform_logo_url = getPlatformByUrl(item.shop_url)?.urlLogo
          return item
        })
      }
    },
    watch: {
      sortBy(value) {
        if (value) {
          this.handleTableChange(this.pagination, null, value)
        }
      },
      iPage(value) {
        this.pagination = this.pagination
          ? {
            'current': this.iPage,
            'pageSize': this.iLimit,
            'total': 240,
            'simple': true,
          }
          : this.pagination
      },
      iLimit(value) {
        this.pagination = this.pagination
          ? {
            'current': this.iPage,
            'pageSize': this.iLimit,
            'total': 240,
            'simple': true,
          }
          : this.pagination
      }
    },
    mixins: [
      mixinStoreGetterGlobal,
      mixinStoreGetterPermission
    ]
  }
</script>

<style lang="scss" scoped>
.table-product {
  .row-thumbnail {
    width: 50px;
    height: 50px;
    object-fit: cover;
  }

  .image-zoom {
    width: 200px;
    height: 200px;
    object-fit: cover;
  }

  .row-product-name {
    display: inline-flex;

    .product_name {
      font-size: 15px;
      font-weight: 500;
      margin-bottom: 0;

      &:hover {
        cursor: pointer;
      }
    }

    .img-platform {
      border-radius: 100%;
      width: 14px;
      height: 14px;
    }
  }

  .rating_count {
    font-size: 10px;
  }

  .ant-rate {
    font-size: 10px;
  }

  .ant-rate-star:not(:last-child) {
    margin-right: 2px;
  }
}
.mobile-view {
  &.table-product {
    .row-thumbnail {
      width: 30px;
      height: 30px;
      object-fit: cover;
    }

    .row-product-name {
      display: block;
      text-align: left;

      .product_name {
        font-size: 8px;
        font-weight: 500;
        margin-bottom: 0;

        &:hover {
          cursor: pointer;
        }

        .icon-mall-wrap {
          height: 10px;
          width: 20px;
          display: inline-block;
        }
        .icon-mall {
          transform: scale(0.5, 0.5);
          transform-origin: bottom left;
        }
      }

      .img-platform {
        width: 10px;
        height: 10px;
      }
    }

    .rating_count {
      font-size: 8px;
    }

    .ant-rate {
      font-size: 8px;
    }

    .link {
      font-size: 8px;
    }

    .ant-rate-star:not(:last-child) {
      margin-right: 2px;
    }
  }


  /deep/ .ant-table-tbody > tr > td {
    font-size: 8px !important;
    padding: 2px !important;
    text-align: center;
  }

  /deep/ .ant-table-tbody > tr {
    //position: relative; undefined position of tr, ... in safari
  }

  /deep/ .ant-table-thead > tr > th {
    font-size: 10px !important;
    padding: 2px 6px !important;
    font-weight: 400;
    background: unset;
  }
}

/deep/ .ant-table .ant-table-body {
  //overflow-x: scroll;
}

/deep/ .ant-table-fixed-right .ant-table-fixed tr > th {
  padding-right: 8px;
  padding-left: 8px;
}

/deep/ .ant-table-fixed-right .ant-table-fixed tr > td {
  padding-right: 8px;
  padding-left: 8px;
}
/deep/ .ant-table-thead > tr > th .ant-table-header-column .ant-table-column-sorters > .ant-table-column-title {
  line-height: 16.41px;
}
.mobile-star {
  /deep/ .ant-rate-star {
    margin-right: 0;
  }
}
</style>
