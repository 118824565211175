<template>
  <svg width="12" height="12" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g clip-path="url(#clip0_1403_5809)">
      <path d="M1.8 8C0.90525 8 0 8.27475 0 8.8V11.2C0 11.7253 0.90525 12 1.8 12C2.69475 12 3.6 11.7253 3.6 11.2V8.8C3.6 8.27475 2.69475 8 1.8 8ZM1.8 8.4C2.7095 8.4 3.2 8.684 3.2 8.8C3.2 8.916 2.7095 9.2 1.8 9.2C0.8905 9.2 0.4 8.916 0.4 8.8C0.4 8.684 0.8905 8.4 1.8 8.4ZM1.8 11.6C0.8905 11.6 0.4 11.316 0.4 11.2V10.92C0.83925 11.1185 1.31825 11.2143 1.8 11.2C2.28175 11.2143 2.76075 11.1185 3.2 10.92V11.2C3.2 11.316 2.7095 11.6 1.8 11.6ZM1.8 10.8C0.8905 10.8 0.4 10.516 0.4 10.4V10.12C0.83925 10.3185 1.31825 10.4143 1.8 10.4C2.28175 10.4143 2.76075 10.3185 3.2 10.12V10.4C3.2 10.516 2.7095 10.8 1.8 10.8ZM1.8 10C0.8905 10 0.4 9.716 0.4 9.6V9.32C0.83925 9.5185 1.31825 9.61425 1.8 9.6C2.28175 9.61425 2.76075 9.5185 3.2 9.32V9.6C3.2 9.716 2.7095 10 1.8 10Z" fill="black"/>
      <path d="M5.99995 6.40039C5.1052 6.40039 4.19995 6.67514 4.19995 7.20039V11.2004C4.19995 11.7256 5.1052 12.0004 5.99995 12.0004C6.8947 12.0004 7.79995 11.7256 7.79995 11.2004V7.20039C7.79995 6.67514 6.8947 6.40039 5.99995 6.40039ZM5.99995 6.80039C6.90945 6.80039 7.39995 7.08439 7.39995 7.20039C7.39995 7.31639 6.90945 7.60039 5.99995 7.60039C5.09045 7.60039 4.59995 7.31639 4.59995 7.20039C4.59995 7.08439 5.09045 6.80039 5.99995 6.80039ZM5.99995 11.6004C5.09045 11.6004 4.59995 11.3164 4.59995 11.2004V10.9204C5.0392 11.1189 5.5182 11.2146 5.99995 11.2004C6.4817 11.2146 6.9607 11.1189 7.39995 10.9204V11.2004C7.39995 11.3164 6.90945 11.6004 5.99995 11.6004ZM5.99995 10.8004C5.09045 10.8004 4.59995 10.5164 4.59995 10.4004V10.1204C5.0392 10.3189 5.5182 10.4146 5.99995 10.4004C6.4817 10.4146 6.9607 10.3189 7.39995 10.1204V10.4004C7.39995 10.5164 6.90945 10.8004 5.99995 10.8004ZM5.99995 10.0004C5.09045 10.0004 4.59995 9.71639 4.59995 9.60039V9.32039C5.0392 9.51889 5.5182 9.61464 5.99995 9.60039C6.4817 9.61464 6.9607 9.51889 7.39995 9.32039V9.60039C7.39995 9.71639 6.90945 10.0004 5.99995 10.0004ZM5.99995 9.20039C5.09045 9.20039 4.59995 8.91639 4.59995 8.80039V8.52039C5.0392 8.71889 5.5182 8.81464 5.99995 8.80039C6.4817 8.81464 6.9607 8.71889 7.39995 8.52039V8.80039C7.39995 8.91639 6.90945 9.20039 5.99995 9.20039ZM5.99995 8.40039C5.09045 8.40039 4.59995 8.11639 4.59995 8.00039V7.72039C5.0392 7.91889 5.5182 8.01464 5.99995 8.00039C6.4817 8.01464 6.9607 7.91889 7.39995 7.72039V8.00039C7.39995 8.11639 6.90945 8.40039 5.99995 8.40039Z" fill="black"/>
      <path d="M10.1999 4C9.30515 4 8.3999 4.27475 8.3999 4.8V11.2C8.3999 11.7253 9.30515 12 10.1999 12C11.0947 12 11.9999 11.7253 11.9999 11.2V4.8C11.9999 4.27475 11.0947 4 10.1999 4ZM10.1999 4.4C11.1094 4.4 11.5999 4.684 11.5999 4.8C11.5999 4.916 11.1094 5.2 10.1999 5.2C9.2904 5.2 8.7999 4.916 8.7999 4.8C8.7999 4.684 9.2904 4.4 10.1999 4.4ZM10.1999 11.6C9.2904 11.6 8.7999 11.316 8.7999 11.2V10.92C9.23915 11.1185 9.71815 11.2143 10.1999 11.2C10.6817 11.2143 11.1607 11.1185 11.5999 10.92V11.2C11.5999 11.316 11.1094 11.6 10.1999 11.6ZM10.1999 10.8C9.2904 10.8 8.7999 10.516 8.7999 10.4V10.12C9.23915 10.3185 9.71815 10.4143 10.1999 10.4C10.6817 10.4143 11.1607 10.3185 11.5999 10.12V10.4C11.5999 10.516 11.1094 10.8 10.1999 10.8ZM10.1999 10C9.2904 10 8.7999 9.716 8.7999 9.6V9.32C9.23915 9.5185 9.71815 9.61425 10.1999 9.6C10.6817 9.61425 11.1607 9.5185 11.5999 9.32V9.6C11.5999 9.716 11.1094 10 10.1999 10ZM10.1999 9.2C9.2904 9.2 8.7999 8.916 8.7999 8.8V8.52C9.23915 8.7185 9.71815 8.81425 10.1999 8.8C10.6817 8.81425 11.1607 8.7185 11.5999 8.52V8.8C11.5999 8.916 11.1094 9.2 10.1999 9.2ZM10.1999 8.4C9.2904 8.4 8.7999 8.116 8.7999 8V7.72C9.23915 7.9185 9.71815 8.01425 10.1999 8C10.6817 8.01425 11.1607 7.9185 11.5999 7.72V8C11.5999 8.116 11.1094 8.4 10.1999 8.4ZM10.1999 7.6C9.2904 7.6 8.7999 7.316 8.7999 7.2V6.92C9.23915 7.1185 9.71815 7.21425 10.1999 7.2C10.6817 7.21425 11.1607 7.1185 11.5999 6.92V7.2C11.5999 7.316 11.1094 7.6 10.1999 7.6ZM10.1999 6.8C9.2904 6.8 8.7999 6.516 8.7999 6.4V6.12C9.23915 6.3185 9.71815 6.41425 10.1999 6.4C10.6817 6.41425 11.1607 6.3185 11.5999 6.12V6.4C11.5999 6.516 11.1094 6.8 10.1999 6.8ZM10.1999 6C9.2904 6 8.7999 5.716 8.7999 5.6V5.32C9.23915 5.5185 9.71815 5.61425 10.1999 5.6C10.6817 5.61425 11.1607 5.5185 11.5999 5.32V5.6C11.5999 5.716 11.1094 6 10.1999 6Z" fill="black"/>
      <path d="M9.80011 0V0.4H11.3174L7.51736 4.2H3.32486L0.0683594 7.0495L0.332109 7.35075L3.47536 4.6H7.68286L11.6001 0.68275V2.2H12.0001V0H9.80011Z" fill="black"/>
    </g>
    <defs>
      <clipPath id="clip0_1403_5809">
        <rect width="12" height="12" fill="white"/>
      </clipPath>
    </defs>
  </svg>
</template>

<script>
export default {
  name: "IconRevenue"
}
</script>

<style scoped>

</style>
