<template>
  <div class="chart-container">
    <div v-if="seriesComparisonData">
<!--      <h2 class="chart-title text-center" :class="isMobile ? 'mobile-view' : ''">-->
<!--        {{title}}-->
<!--      </h2>-->
      <TableStatistic v-if="tableData" :table-data="tableData.slice(0, isMobile ? 4 : tableData.length)"
                      :columns="columns"
                      :number-total="numberTotal"
                      :number-charting="numberCharting"
                      :title="title"
                      :category-title="categoryTitle"
                      :sub-title="subTitle"
                      :series-title="seriesTitle"
      >
        <div v-if="isMobile" slot="show_more" class="text-right mt-4 mb-2 cursor-pointer" style="color: #8C8C8C; font-size: 8px; margin-right: 0" @click="onClickShowMore">
          <span>Xem thêm</span>
          <a-icon type="right" />
        </div>
      </TableStatistic>
    </div>
    <highcharts v-else-if="chartOptions!=null" :options="chartOptions"
                :highcharts="hcInstance"></highcharts>
    <div class="text-center text-primary-500" :class="isMobile ? 'text-xs' : ''" v-if="platformsSupported && platformsSupported.length">
      <p class="">Dữ liệu thống kê bao gồm {{ platformsSupported.length }} kênh bán hàng: {{ platformsSupported.join(', ') }}</p>
    </div>
    <div class="note-box mt-6" v-if="numberTotal && numberCharting">
      <p class="charting-percent"><span v-if="titleCharting">{{titleCharting}}</span><span v-else>Có {{categoryTitle}}</span>: {{numberCharting | compareNumberByPercent(numberTotal)}}%</p>
      <p class="unknown-percent" v-if="numberTotal > numberCharting"><span v-if="titleCharting">{{titleUnCharting}}</span><span v-else>Không xác định</span>: {{ numberTotal - numberCharting | compareNumberByPercent(numberTotal)}}%</p>
    </div>
    <div class="note-box mt-6" v-if="shopMall && otherShop">
      <p class="charting-percent"><span>Có {{shopMall | formatNumberHuman}} shop Mall</span>: chiếm {{shopMall | compareNumberByPercent(shopMall + otherShop)}}%</p>
      <p class="unknown-percent"><span>Có {{otherShop | formatNumberHuman}} shop thường</span>: chiếm {{otherShop | compareNumberByPercent(shopMall + otherShop)}}%</p>
    </div>
    <a-modal :closable="true"
             :footer="null"
             :centered="true"
             v-model="visibleAllDataMobile"
             :maskClosable="true">
      <p slot="title" class="text-primary-500" style="padding-right: 56px">
        <span>{{title}}</span>
      </p>
      <TableStatistic v-if="tableData" :table-data="tableData"
                      :columns="columns"
                      :number-total="numberTotal"
                      :number-charting="numberCharting"
                      :title="title"
                      :category-title="categoryTitle"
                      :sub-title="subTitle"
                      :series-title="seriesTitle"
                      :isDisplayNote="true"
      />
    </a-modal>
  </div>
</template>

<script>
  import { Chart } from 'highcharts-vue'
  import Highcharts from 'highcharts'
  import {compareNumberByPercent, formatCurrency, formatNumberHuman} from "@/helper/FormatHelper";
  import {mixinStoreGetterGlobal} from "@/mixin/store/MixinGlobal";
  import TableStatistic from "@/components/statistic/metric/TableStatistic";

  export default {
    components: {
      highcharts: Chart,
      TableStatistic,
    },
    props: {
      title: null,
      subTitle: null,
      color: null,
      categoriesName: null,
      categoryTitle: null,
      seriesData: null,
      seriesTitle: null,
      categoriesComparisonName: null,
      seriesComparisonTitle: null,
      seriesComparisonData: null,
      numberTotal: null,
      numberCharting: null,
      shopMall: null,
      otherShop: null,
      titleCharting: null,
      titleUnCharting: null,
      unit: {
        default: ''
      },
      seriesColor: null,
      platformsSupported: {
        default: null,
        type: Array
      },
    },
    data() {
      return {
        hcInstance: Highcharts,
        chartOptions: null,
        columns: null,
        tableData: null,
        visibleAllDataMobile: false,
      }
    },
    mounted() {
      this.hcInstance.setOptions({
        lang: {
          numericSymbols: [` Nghìn ${this.unit}`, ` Triệu ${this.unit}`, ` Tỉ ${this.unit}`]
        }
      });
      this.redraw()
      this.generatorTableData()
    },
    filters: {
      formatNumberHuman,
      compareNumberByPercent,
      formatNumber(value, seriesTitle) {
        if (seriesTitle === "Doanh số") {
          return formatCurrency(value)
        } else {
          return formatNumberHuman(value)
        }
      },
      abs(value) {
        return Math.abs(value)
      },
    },
    methods: {
      redraw() {
        let series = []
        for (let [idx, categoryName] of this.categoriesName.entries()) {
          let seriesItem = {
            name: categoryName,
            color: this.seriesColor?.[idx],
            y: this.seriesData[idx],
          }
          series.push(seriesItem)
        }
        this.chartOptions = {
          chart: {
            type: 'pie'
          },
          title: {
            text: '',
            style: {
              fontSize: '14px'
            }
          },
          subtitle: {
            text: ''
          },
          // tooltip: {
          //   pointFormat: `{series.name}: {point.total} ({point.percentage:.1f} %)`
          // },
          plotOptions: {
            pie: {
              dataLabels: {
                enabled: true,
                format: '<b>{point.name}</b>:<br>{point.percentage:.1f} %',
              }
            }
          },
          series: [{
            minPointSize: 10,
            innerSize: '20%',
            zMin: 0,
            name: this.seriesTitle,
            data: series
          }]
        }
      },
      generatorTableData() {
        if (!this.seriesComparisonData?.length) {
          return
        }
        this.columns = [
          {
            title: this.categoryTitle,
            dataIndex: 'category_name',
            scopedSlots: { customRender: 'category_name' },
          },
          {
            title: `${this.subTitle}`,
            dataIndex: 'percent',
            scopedSlots: { customRender: 'percent' },
            sorter: true,
            defaultSortOrder: 'ascend'
          }
        ]
        if (this.seriesComparisonData && this.seriesComparisonTitle) {
          this.columns.push(
            {
              title: `${this.seriesComparisonTitle}`,
              dataIndex: 'percent_comparison',
              scopedSlots: { customRender: 'percent_comparison' },
              sorter: true,
            }
          )
        }
        let tableData = []
        let totalValue = this.seriesData.reduce((accumulator, currentValue) => accumulator + currentValue)
        let totalValueComparison = this.seriesComparisonData.reduce((accumulator, currentValue) => accumulator + currentValue)
        for (let [idx, categoryName] of this.categoriesName.entries()) {
          tableData.push({
            category_name: categoryName,
            value: this.seriesData[idx],
            percent: this.seriesData[idx]/totalValue * 100,
            value_comparison: this.seriesComparisonData[idx],
            percent_comparison: this.seriesComparisonData[idx]/totalValueComparison * 100
          })
        }
        for (let i = this.seriesData.length ; i < this.seriesComparisonData.length; i++) {
          let comparisonName = this.categoriesComparisonName[i]
          let comparisonValue = this.seriesComparisonData[i]
          tableData.push({
            category_name: comparisonName,
            value: 0,
            percent: 0,
            value_comparison: comparisonValue,
            percent_comparison: comparisonValue/totalValueComparison * 100
          })
        }
        tableData.sort((data1, data2) => data2.value - data1.value)
        this.tableData = tableData
      },
      onClickShowMore() {
        this.visibleAllDataMobile = true
      }
    },
    mixins: [
      mixinStoreGetterGlobal
    ],
    watch: {
      seriesData() {
        this.redraw()
        this.generatorTableData()
      },
      seriesComparisonData() {
        this.generatorTableData()
      }
    },
  }

</script>

<style lang="scss" scoped>
  .chart-container {

    .chart-title {

      &.mobile-view {
        font-size: 14px;
        font-weight: normal;
      }
    }

    .category-table {
      width: 100%;
      margin-bottom: 12px;
      border-collapse: collapse;
      border-color: rgba(229, 231, 235, 1);
      border-top-width: 1px;
      border-bottom-width: 1px;
      border-radius: 0.5rem;
      --tw-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.1), 0 1px 2px 0 rgba(0, 0, 0, 0.06);
      box-shadow: 0 0 #0000, 0 0 #0000, var(--tw-shadow);

      .table-header {

        th {
          padding: 12px 24px;
          text-align: left;
          font-size: .875rem;
          line-height: 1.25rem;
          color: rgba(107,114,128,1);
          text-transform: uppercase;
        }
      }

      .table-content {
        &.background-color {
          @apply gray-50;
        }
        td {
          &.first-column {
            color: rgba(17,24,39,1);
            font-weight: 500;
          }

          padding: 12px 24px;
          text-align: left;
          font-size: .875rem;
          line-height: 1.25rem;
          color: rgba(107,114,128,1);

          .text-note {
            font-size: .75rem;
            color: rgba(107,114,128,0.75);
          }
        }
      }
    }

    .category-table-mobile-view {
      font-size: 12px;
      border-radius: 4px;

      .table-header {

        th {
          width: calc(100% / 3);
          padding: 10px 4px;
          text-align: left;
          font-size: 12px;
          line-height: 14px;
          color: #3B3B3B;
          font-weight: normal;
        }
      }

      .table-content {
        &.background-color {
          background-color: #F8F8F8;
        }
        td {
          color: #3B3B3B;
          padding: 10px 4px;
          text-align: left;
          font-size: 12px;
          line-height: 14px;

          .text-note {
            font-size: 9px;
            color: #717171;
          }
        }
      }

    }

    .note-box {

      .charting-percent {
        color: #096dd9;
      }
    }
  }

  .gray-50 {
    background-color: rgba(249,250,251,1);
  }
</style>
