<template>
  <div class="chart-container">
    <div class="relative chart-relative-position">
      <highcharts ref="pieChartInnerText" v-if="chartOptions!=null" :options="chartOptions"
                  :highcharts="hcInstance"></highcharts>
      <div class="absolute top-0 left-0 w-full"
           :key="innerContainerElementSize"
           :style="`--app-inner-element-size-size: ${innerContainerElementSize}px`"
      >
        <div class="pieChartTextContainer flex justify-center align-center w-full"
             style="visibility: hidden"
        >
          <span class="scale" :style="{fontSize: `${innerTextSize}px`}" :key="innerText">{{ innerText }}</span>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  import { Chart } from 'highcharts-vue'
  import Highcharts from 'highcharts'
  import {formatCurrency, formatNumberHuman} from "@/helper/FormatHelper";
  import {mixinStoreGetterGlobal} from "@/mixin/store/MixinGlobal";

  const ANIMATION_TIME_OF_CHART = 1500
  const TIMEOUT_DEBOUNCE = 250
  // base on design to config
  const SPACE_CONFIG_DESKTOP = 32
  const SPACE_CONFIG_MOBILE = 16
  const RATIO_INNER_SIZE_CONFIG_DESKTOP = 300/160
  const RATIO_INNER_SIZE_CONFIG_MOBILE = 180/100
  const RATIO_INNER_TEXT_SIZE_CONFIG_DESKTOP = 98/30
  const RATIO_INNER_TEXT_SIZE_CONFIG_MOBILE = 70/16

  export default {
    components: {
      highcharts: Chart,
    },
    props: {
      title: null,
      subTitle: null,
      color: null,
      categoriesName: null,
      categoryTitle: null,
      seriesData: null,
      seriesTitle: null,
      seriesColor: null,
      unit: {
        default: ''
      },
      chartSize: {
        default: 350
      }
    },
    data() {
      return {
        hcInstance: Highcharts,
        chartOptions: {},
        columns: null,
        tableData: null,
        visibleAllDataMobile: false,
        innerText: null,
        innerTextSize: 0,
        innerSize: 0,
        spaceInnerConfig: 0,
      }
    },
    computed: {
      innerContainerElementSize() {
        return this.innerSize - 2 * this.spaceInnerConfig
      },
    },
    mounted() {
      const chart = this.$refs.pieChartInnerText.chart
      chart.setSize(this.chartSize, this.chartSize)
      if (this.isDesktop) {
        this.spaceInnerConfig = SPACE_CONFIG_DESKTOP
        this.innerSize = this.chartSize / RATIO_INNER_SIZE_CONFIG_DESKTOP
        this.innerTextSize = this.innerContainerElementSize / RATIO_INNER_TEXT_SIZE_CONFIG_DESKTOP
      } else {
        this.spaceInnerConfig = SPACE_CONFIG_MOBILE
        this.innerSize = this.chartSize / RATIO_INNER_SIZE_CONFIG_MOBILE
        this.innerTextSize = this.innerContainerElementSize / RATIO_INNER_TEXT_SIZE_CONFIG_MOBILE
      }
      this.redraw()
      this.innerText = '100%'
      // window.addEventListener(
      //   'resize',
      //   debounce(
      //     () => {
      //       this.drawInnerText()
      //       setTimeout(this.drawInnerText, TIMEOUT_DEBOUNCE)
      //     },
      //     TIMEOUT_DEBOUNCE
      //   )
      // )
    },
    updated() {
      const chart = this.$refs.pieChartInnerText.chart
      chart.setSize(undefined, this.chartSize)
    },
    filters: {
      formatNumberHuman,
      formatNumber(value, seriesTitle) {
        if (seriesTitle === "Doanh số") {
          return formatCurrency(value)
        } else {
          return formatNumberHuman(value)
        }
      },
      abs(value) {
        return Math.abs(value)
      },
    },
    methods: {
      redraw() {
        let series = []
        for (let [idx, categoryName] of this.categoriesName.entries()) {
          let seriesItem = {
            name: categoryName,
            y: this.seriesData[idx],
            color: this.seriesColor[idx]
          }
          series.push(seriesItem)
        }
        this.chartOptions = {
          chart: {
            type: 'pie'
          },
          title: {
            text: this.title,
          },
          subtitle: {
            text: this.subTitle
          },
          // tooltip: {
          //   pointFormat: `{series.name}: {point.total} ({point.percentage:.1f} %)`
          // },
          plotOptions: {
            pie: {
              dataLabels: {
                enabled: false,
              }
            },
            series: {
              point: {
                events: {
                  mouseOver:(event) => {
                    this.innerText = ''
                    this.innerText = `${Number(event.target.percentage).toFixed(1)}%`
                  }
                }
              },
              events: {
                mouseOut: (event) => {
                  this.innerText = ''
                  this.innerText = '100%'
                }
              }
            }
          },
          series: [{
            minPointSize: 10,
            innerSize: `${this.innerSize}px`,
            zMin: 0,
            name: this.seriesTitle,
            data: series
          }]
        }
        let oldSizeOfPieChartRelativePosition = {height: null, width: null}
        let oldSizeOfPieChartElement = {height: null, width: null}
        let drawInnerTextIntervalId = setInterval(() => {
          let pieChartRelativePosition = this.$el.getElementsByClassName('chart-relative-position').item(0).getBoundingClientRect();
          let pieChartElement = this.$el.getElementsByClassName('highcharts-series-group').item(0).getBoundingClientRect()
          let isFinishDrawPieChart = oldSizeOfPieChartRelativePosition.height === pieChartRelativePosition.height
            && oldSizeOfPieChartRelativePosition.width === pieChartRelativePosition.width
            && oldSizeOfPieChartElement.height === pieChartElement.height
            && oldSizeOfPieChartElement.width === pieChartElement.width
          if (isFinishDrawPieChart) {
            oldSizeOfPieChartRelativePosition = {height: null, width: null}
            oldSizeOfPieChartElement = {height: null, width: null}
            this.drawInnerText()
            clearInterval(drawInnerTextIntervalId)
          }
          oldSizeOfPieChartRelativePosition = pieChartRelativePosition
          oldSizeOfPieChartElement = pieChartElement
        }, 100)
        let timeOutOfDrawInnerText = 20 * 1000
        setTimeout(() => clearInterval(drawInnerTextIntervalId), timeOutOfDrawInnerText)
      },
      onClickShowMore() {
        this.visibleAllDataMobile = true
      },
      drawInnerText() {
        let pieChartRelativePosition = this.$el.getElementsByClassName('chart-relative-position').item(0);
        let pieChartElement = this.$el.getElementsByClassName('highcharts-series-group').item(0)
        let pieChartTextContainer = this.$el.getElementsByClassName('pieChartTextContainer')[0];
        if (!pieChartElement || !pieChartRelativePosition || !pieChartTextContainer) {
          return
        }
        let top = (
          pieChartElement.getBoundingClientRect().top
          - pieChartRelativePosition.getBoundingClientRect().top
        ) + (
          pieChartElement.getBoundingClientRect().height
          - this.innerContainerElementSize
        ) / 2
        let left = (
          pieChartElement.getBoundingClientRect().left
          - pieChartRelativePosition.getBoundingClientRect().left
        ) + (
          pieChartElement.getBoundingClientRect().width
          - this.innerContainerElementSize
        ) / 2
        pieChartTextContainer.style.top = `${top}px`;
        pieChartTextContainer.style.left = `${left}px`;
        pieChartTextContainer.style.visibility = ''
      }
    },
    mixins: [
      mixinStoreGetterGlobal
    ],
    watch: {
      seriesData() {
        this.redraw()
      },
    },
  }

</script>

<style lang="scss" scoped>
  .chart-container {

    .chart-title {

      &.mobile-view {
        font-size: 14px;
        font-weight: normal;
      }
    }
  }

  .gray-50 {
    background-color: rgba(249,250,251,1);
  }
  .pieChartInnerTextWrap {
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }
  .pieChartTextContainer {
    position:absolute;
    width: var(--app-inner-element-size-size);
    height: var(--app-inner-element-size-size);
    text-align:center;
    border-radius: 100%;
    box-shadow: 0px 0px 4px 4px #0F123F40;
  }
</style>
