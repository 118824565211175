import {
  apiCloneSavedReport,
  apiDeleteSavedReport, apiDownloadSavedReport,
  apiGetListSavedReport,
  apiGetSavedReportDetail,
  apiSaveReport,
  apiUpdateSavedReport
} from "@/api/report/ApiReport";
import {isLogined} from "@/service/user/UserProfileService";

const getListSavedReport = async (page = 0, limit= 1, orderBy= 'created_at__desc') => {
  if (!await isLogined()) {
    return []
  }
  let result = await apiGetListSavedReport(page, limit, orderBy)
  return result?.report_lst
}

const downloadSavedReport = async (page = 0, limit= 1, orderBy= 'created_at__desc', downloadType= 'excel') => {
  if (!await isLogined()) {
    return null
  }
  let result = await apiDownloadSavedReport(page, limit, orderBy)
  return result
}

const getSavedReportDetail = async (hashReport) => {
  if (!await isLogined()) {
    return null
  }
  let result = await apiGetSavedReportDetail(hashReport)
  return result
}

const saveReport = async (name, optionsSearch) => {
  let result = await apiSaveReport(name, optionsSearch)
  return result
}

const updateSavedReport = async (reportHash, name, optionsSearch) => {
  let result = await apiUpdateSavedReport(reportHash, name, optionsSearch)
  return result
}

const deleteSavedReport = async (reportHash) => {
  let result = await apiDeleteSavedReport(reportHash)
  return result
}

const cloneSavedReport = async (reportHash, name) => {
  let result = await apiCloneSavedReport(reportHash, name)
  return result
}

export {
  getListSavedReport,
  downloadSavedReport,
  getSavedReportDetail,
  saveReport,
  updateSavedReport,
  deleteSavedReport,
  cloneSavedReport
}
