<template>
  <div>
    <div class="table-product" :class="isMobile ? 'mobile-view' : ''">
      <a-table
        :tableLayout="isMobile ? 'fixed' : ''"
        :columns="columns"
        :rowKey="record => record.product_base_id"
        :dataSource="products"
        :pagination="isMobile ? false : pagination"
        :loading="isLoading"
        @change="handleTableChange"
      >
        <!--        heading table-->
        <span slot="order_count_custom">Sản phẩm đã bán</span>
        <span slot="order_count_7d">Sản phẩm đã bán <br>7 ngày</span>
        <span slot="order_count_30d">Sản phẩm đã bán <br>30 ngày</span>
        <span slot="order_count_90d">Sản phẩm đã bán <br>90 ngày</span>
        <span slot="order_count_180d">Sản phẩm đã bán <br>180 ngày</span>

        <span slot="order_revenue_custom">Doanh số</span>
        <span slot="order_revenue_7d">Doanh số <br>7 ngày</span>
        <span slot="order_revenue_30d">Doanh số <br>30 ngày</span>
        <span slot="order_revenue_90d">Doanh số <br>90 ngày</span>
        <span slot="order_revenue_180d">Doanh số <br>180 ngày</span>

        <span slot="review_count_custom">Đánh giá</span>
        <span slot="review_revenue_7d">Đánh giá <br>7 ngày</span>
        <span slot="review_revenue_30d">Đánh giá <br>30 ngày</span>
        <span slot="review_revenue_90d">Đánh giá <br>90 ngày</span>
        <span slot="review_revenue_180d">Đánh giá <br>180 ngày</span>

        <span slot="view_count_custom_title">
          Lượt xem
          <a-tooltip style="cursor: default" :overlayStyle="{fontSize: isDesktop ? '' : '8px', minHeight: isDesktop ? '' : 'unset'}">
            <template slot="title">
              Dữ liệu do BeeCost ước tính
            </template>
            <i>
              <IconInformation style="transform: translateY(-4px)"/>
            </i>
          </a-tooltip>
        </span>

        <!--        row -->
        <template slot="product_name" slot-scope="product_name, product">
          <div class="row-product-name" style="flex-flow: row nowrap;">
            <img :src="product['url_thumbnail']" alt="" class="img-responsive row-thumbnail" :class="isMobile ? 'margin-left-5' : ''">
            <div class="margin-left-5" style="width: 100%;">
              <a-tooltip>
                <template slot="title" v-if="product['price_updated_at']">
                  Cập nhật thông tin sản phẩm ngày {{product['price_updated_at'] | timestampToDate}}
                </template>
                <p class="product_name" @click="onClickUrlDirect(product)">
                  <span v-if="product['official_type'] === 1" class="icon-mall-wrap"><mall-flag class="icon-mall"/></span>
                  <span>{{ product_name }}</span>
                </p>
              </a-tooltip>
              <div class="ant-row-flex"
                   style="flex-flow: row nowrap; justify-content: space-between; align-items: center;">
                <div class="">
                  <a-rate :defaultValue="product['rating_avg']" allowHalf disabled/>
                  <span class="rating_count"> {{product['rating_count'] | formatNumberHuman}} đánh giá</span>
                </div>
                <div v-if="isDesktop">
                  <a-button @click="onClickUrlDirect(product)" type="link">Link sản phẩm</a-button>
                </div>
              </div>
              <div>
                <span v-if="product['order_count']" class="rating_count">Tổng sản phẩm đã bán:
                  <span>{{product['order_count'] | formatNumberHuman}}</span>
                </span>
              </div>
              <p>
                <span v-if="isMobile" @click="onClickUrlDirect(product)" class="link text-primary-500 cursor-pointer">Link sản phẩm</span>
              </p>
            </div>
          </div>
        </template>

        <template slot="price" slot-scope="price">
          {{price | formatCurrency}}
        </template>

        <template slot="order_count_custom" slot-scope="order_count_custom">
          <span v-if="canShowStatistic">{{order_count_custom | formatNumberHuman}}</span>
          <a class="opacity-50" v-else href="/pricing">Nâng cấp tài khoản</a>
        </template>

        <template slot="order_count_7d" slot-scope="order_count_7d">
          <span v-if="canShowStatistic">{{order_count_7d | formatNumberHuman}}</span>
          <a class="opacity-50" v-else href="/pricing">Nâng cấp tài khoản</a>
        </template>

        <template slot="order_count_30d" slot-scope="order_count_30d">
          <span v-if="canShowStatistic">{{order_count_30d | formatNumberHuman}}</span>
          <a class="opacity-50" v-else href="/pricing">Nâng cấp tài khoản</a>
        </template>

        <template slot="order_count_90d" slot-scope="order_count_90d">
          <span v-if="canShowStatistic">{{order_count_90d | formatNumberHuman}}</span>
          <a class="opacity-50" v-else href="/pricing">Nâng cấp tài khoản</a>
        </template>

        <template slot="order_count_180d" slot-scope="order_count_180d">
          <span v-if="canShowStatistic">{{order_count_180d | formatNumberHuman}}</span>
          <a class="opacity-50" v-else href="/pricing">Nâng cấp tài khoản</a>
        </template>

        <template slot="order_revenue_custom" slot-scope="order_revenue_custom">
          <span v-if="canShowStatistic">{{order_revenue_custom | formatCurrency}}</span>
          <a class="opacity-50" v-else href="/pricing">Nâng cấp tài khoản</a>
        </template>

        <template slot="order_revenue_7d" slot-scope="order_revenue_7d">
          <span v-if="canShowStatistic">{{order_revenue_7d | formatCurrency}}</span>
          <a class="opacity-50" v-else href="/pricing">Nâng cấp tài khoản</a>
        </template>

        <template slot="order_revenue_30d" slot-scope="order_revenue_30d">
          <span v-if="canShowStatistic">{{order_revenue_30d | formatCurrency}}</span>
          <a class="opacity-50" v-else href="/pricing">Nâng cấp tài khoản</a>
        </template>

        <template slot="order_revenue_90d" slot-scope="order_revenue_90d">
          <span v-if="canShowStatistic">{{order_revenue_90d | formatCurrency}}</span>
          <a class="opacity-50" v-else href="/pricing">Nâng cấp tài khoản</a>
        </template>

        <template slot="order_revenue_180d" slot-scope="order_revenue_180d">
          <span v-if="canShowStatistic">{{order_revenue_180d | formatCurrency}}</span>
          <a class="opacity-50" v-else href="/pricing">Nâng cấp tài khoản</a>
        </template>

        <template slot="review_count_custom" slot-scope="review_count_custom">
          <span v-if="canShowStatistic">{{review_count_custom | formatNumberHuman}}</span>
          <a class="opacity-50" v-else href="/pricing">Nâng cấp tài khoản</a>
        </template>

        <template slot="review_count_7d" slot-scope="review_count_7d">
          <span v-if="canShowStatistic">{{review_count_7d | formatNumberHuman}}</span>
          <a class="opacity-50" v-else href="/pricing">Nâng cấp tài khoản</a>
        </template>

        <template slot="review_count_30d" slot-scope="review_count_30d">
          <span v-if="canShowStatistic">{{review_count_30d | formatNumberHuman}}</span>
          <a class="opacity-50" v-else href="/pricing">Nâng cấp tài khoản</a>
        </template>

        <template slot="review_count_90d" slot-scope="review_count_90d">
          <span v-if="canShowStatistic">{{review_count_90d | formatNumberHuman}}</span>
          <a class="opacity-50" v-else href="/pricing">Nâng cấp tài khoản</a>
        </template>

        <template slot="review_count_180d" slot-scope="review_count_180d">
          <span v-if="canShowStatistic">{{review_count_180d | formatNumberHuman}}</span>
          <a class="opacity-50" v-else href="/pricing">Nâng cấp tài khoản</a>
        </template>

        <template slot="category_name" slot-scope="_, product">

          <a-popover trigger="hover">
            <template slot="content">
              <p v-for="category in product['categories']" :key="category['id']"
                 :class="'margin-left-'+10*category['level']">
                {{category.name}}
              </p>
            </template>
            <p style="cursor: pointer">
              {{product['category_name']}}
            </p>
          </a-popover>
        </template>


        <template slot="action" slot-scope="_, product">
          <div class="text-primary-500 cursor-pointer" :style="isMobile ? 'font-size: 8px; line-height: 18px; word-spacing: -.1ch;' : ''">
            <span type="link" @click="onClickHistoryPrice(product)">
              <a-icon type="line-chart"/>
              Lịch sử giá
            </span>
            <br>
            <span type="link" @click="onClickHistoryOrder(product)">
              <a-icon type="rise"/>
              Lịch sử bán
            </span>
            <br>
          </div>
<!--          <div class="">-->
<!--            <a-button type="link" @click="onClickHistoryPrice(product)">-->
<!--              <a-icon type="line-chart"/>-->
<!--              Lịch sử giá-->
<!--            </a-button>-->
<!--          </div>-->
<!--          <div class="">-->
<!--            <a-button type="link" @click="onClickHistoryOrder(product)">-->
<!--              <a-icon type="rise"/>-->
<!--              Lịch sử bán-->
<!--            </a-button>-->
<!--          </div>-->
        </template>

        <!--      <template slot="name" slot-scope="name"> {{ name.first }} {{ name.last }}</template>-->
      </a-table>

      <a-modal title=""
               :closable="true"
               :footer="null"
               v-model="showPopupProductHistory"
               :maskClosable="true"
               :width="960"
      >
        <PopupProductHistory v-if="productBaseIdCurrent" :product-base-id="productBaseIdCurrent"
                             :popup-product-history-tab="popupProductHistoryTab"
        />
      </a-modal>
    </div>
  </div>
</template>
<script>
  import Default from '@/layout/Default'
  import {formatCurrency, formatNumberHuman, timestampToDate} from '@/helper/FormatHelper'
  import { MutationSearchFilter } from '@/store/modules/SearchFilterModule/mutation'
  import MallFlag from '@/components/product/MallFlag'
  import {
    canShowHistoryOrder, canShowHistoryPrice,
    canShowProductLink
  } from '@/service/user/UserPermissionService'
  import { MutationGeneral } from '@/store/modules/GeneralModule/mutation'
  import PopupProductHistory from '@/views/product/PopupProductHistory'
  import {ActionNameConstants, ALL_PLATFORM_BASE_OBJECT, NOTIFICATION_TYPE} from '@/constant/general/GeneralConstant'
  import PopupInstallExtension from '@/views/popup/PopupInstallExtension'
  import {getPlatformById} from "@/service/platform/PlatformCommonService";
  import {mixinStoreGetterPermission} from "@/mixin/store/MixinUser";
  import {removeColumnsTable} from "@/service/market/MarketService";
  import {mixinStoreGetterKeywords} from "@/mixin/store/MixinKeywords";
  import {mixinStoreGetterGlobal} from "@/mixin/store/MixinGlobal";
  import IconInformation from "@/assets/icon/IconInformation";

  export default {
    components: {
      Default,
      PopupProductHistory,
      MallFlag,
      PopupInstallExtension,
      IconInformation
    },
    props: {
      selectedKeywords: {
        required: true
      }
    },
    data() {
      return {
        sortBy: null,
        pagination: {
          'current': 1,
          'pageSize': 20,
          'total': 200,
          'simple': true,
        },
        isLoading: false,
        showPopupProductHistory: false,
        productBaseIdCurrent: null,
        popupProductHistoryTab: null
      }
    },
    async mounted() {
      await this.searchProduct()
    },
    methods: {
      async searchProduct() {
        this.scrollToTop()
        this.isLoading = true
        let optionsFilter = {...this.$store.state.SearchFilterModule.options}
        let optionsProduct = await this.$store.dispatch(`${MutationSearchFilter.MODULE_PATH}/${MutationSearchFilter.BUILD_PRODUCT_OPTIONS}`, optionsFilter)
        let options = {
          ...optionsFilter,
          ...optionsProduct
        }
        if (options.durationDay !== 0) {
          delete options.start_date
          delete options.end_date
        }
        options['limit'] = this.pagination.total
        options['page'] = this.pagination.current - 1
        options['sort_by'] = this.sortBy ? this.sortBy : options.durationDay !== 0 ? `order_revenue_${options.durationDay}d__desc` : 'order_revenue_custom__desc'
        options.type = MutationSearchFilter.SET_RESPONSE_SEARCH_KEYWORD_PRODUCT
        options.include_query.bee_keywords = this.selectedKeywords
        await this.$store.commit(`SearchFilterModule/${MutationSearchFilter.isDemo}`, !this.isLogin)
        await this.$store.dispatch(`SearchFilterModule/${MutationSearchFilter.SEARCH_PRODUCT}`, options)
        this.isLoading = false
      },
      async handleTableChange(pagination, filters, sorter) {
        this.pagination.current = pagination.current
        if (sorter != null && Object.keys(sorter).length > 0) {
          let sort = sorter?.['field']

          if (sorter?.['field'] === 'category_name') {
            sort = 'categories.name'
          }
          if (sorter['order'] === 'ascend') {
            sort += `__asc`
          } else if (sorter['order'] === 'descend') {
            sort += `__desc`
          } else {
            return
          }
          this.sortBy = sort
        }
        await this.searchProduct()
      },

      async onClickHistoryPrice(product) {
        let options = this.$store.state.SearchFilterModule.options
        let resultPermission = await canShowHistoryPrice(options)
        if (resultPermission.isPermission) {
          // window.open(product?.['url_product_detail_beecost_lsg'], "_blank")
          this.popupProductHistoryTab = 'history_price'
          this.productBaseIdCurrent = product['product_base_id']
          this.showPopupProductHistory = true
        } else {
          if (this.$store.state.GeneralModule.actionName === ActionNameConstants.HOME_PAGE) {
            // hiển thị popup direct to Market View
            this.$store.commit(`GeneralModule/${MutationGeneral.setShowPopupSearchDetail}`, true)
            return
          }
          await this.$store.dispatch(`${MutationGeneral.MODULE_PATH}/${MutationGeneral.setShowPopupNotification}`, NOTIFICATION_TYPE.upgrade_permission)
        }
      },
      async onClickHistoryOrder(product) {
        let options = this.$store.state.SearchFilterModule.options
        let resultPermission = await canShowHistoryOrder(options)
        if (resultPermission.isPermission) {
          this.popupProductHistoryTab = 'history_order'
          this.productBaseIdCurrent = product['product_base_id']
          this.showPopupProductHistory = true
        } else {
          if (this.$store.state.GeneralModule.actionName === ActionNameConstants.HOME_PAGE) {
            // hiển thị popup direct to Market View
            this.$store.commit(`GeneralModule/${MutationGeneral.setShowPopupSearchDetail}`, true)
            return
          }
          await this.$store.dispatch(`${MutationGeneral.MODULE_PATH}/${MutationGeneral.setShowPopupNotification}`, NOTIFICATION_TYPE.upgrade_permission)
        }
      },
      async onClickUrlDirect(product) {
        let options = this.$store.state.SearchFilterModule.options

        let resultPermission = await canShowProductLink(options)
        if (resultPermission.isPermission) {
          window.open(product?.['url_product_detail_beecost'], '_blank')
        } else {
          await this.$store.dispatch(`${MutationGeneral.MODULE_PATH}/${MutationGeneral.setShowPopupNotification}`, NOTIFICATION_TYPE.upgrade_permission)
        }
      },
      scrollToTop() {
        this.$el.scrollIntoView({block: 'start', inline: 'start'})
      }
    },
    filters: {
      formatCurrency: formatCurrency,
      formatNumberHuman: formatNumberHuman,
      timestampToDate: timestampToDate,
    },
    computed: {
      canShowStatistic() {
        return !this.isDefaultAccount && this.freeSearchCount !== 0
      },
      isLogin: {
        get() {
          return this.$store.state.UserModule.isLogin
        }
      },
      isExpired: {
        get() {
          return this.$store.state.UserModule.isExpired
        }
      },
      columns: {
        get() {
          let localColumns = [...this.$store.getters[`SearchFilterModule/columnsTable`]]
          let platform = getPlatformById(this.options?.include_query?.platforms?.[0])
          if (!this.isBusinessAccount || platform.name === ALL_PLATFORM_BASE_OBJECT.lazada.name) {
            localColumns = removeColumnsTable(localColumns, 'view_count')
          }
          if (platform.name === ALL_PLATFORM_BASE_OBJECT.lazada.name) {
          //   localColumns = removeColumnsTable(localColumns, 'order_count')
          //   localColumns = removeColumnsTable(localColumns, 'order_revenue')
          } else {
            localColumns = removeColumnsTable(localColumns, 'review_count')
          }
          if (this.isMobile) {
            localColumns = removeColumnsTable(localColumns, 'review_count')
            localColumns = removeColumnsTable(localColumns, 'view_count')
            localColumns = removeColumnsTable(localColumns, 'category_name')
            localColumns = removeColumnsTable(localColumns, 'brand')
            localColumns = localColumns.map(item => {
              delete item.sorter
              item.width = item.dataIndex === 'product_name' ? '32%' : '17%'
              if (item.dataIndex.indexOf('order_count') === 0) {
                item.title = item.title.replace('Sản phẩm', 'Sp')
              }
              return item
            })
          }
          return localColumns
        }
      },
      options: {
        get() {
          return this.$store.state.SearchFilterModule.options
        }
      },
    },
    watch: {
      async selectedKeywords() {
        await this.searchProduct()
      }
    },
    mixins: [
      mixinStoreGetterGlobal,
      mixinStoreGetterPermission,
      mixinStoreGetterKeywords
    ]
  }
</script>

<style lang="scss" scoped>
  .table-product {
    .row-thumbnail {
      width: 50px;
      height: 50px;
      object-fit: cover;
    }

    .row-product-name {
      .product_name {
        font-size: 15px;
        font-weight: 500;
        margin-bottom: 0;

        &:hover {
          cursor: pointer;
        }
      }
    }

    .rating_count {
      font-size: 10px;
    }

    .ant-rate {
      font-size: 10px;
    }

    .ant-rate-star:not(:last-child) {
      margin-right: 2px;
    }
  }

  .mobile-view {
    &.table-product {
      .row-thumbnail {
        width: 24px;
        height: 24px;
        object-fit: cover;
      }

      .row-product-name {
        display: block;
        text-align: left;

        .product_name {
          font-size: 8px;
          font-weight: 500;
          margin-bottom: 0;

          &:hover {
            cursor: pointer;
          }

          .icon-mall-wrap {
            height: 10px;
            width: 20px;
            display: inline-block;
          }
          .icon-mall {
            transform: scale(0.5, 0.5);
            transform-origin: bottom left;
          }
        }
      }

      .rating_count {
        font-size: 8px;
      }

      .ant-rate {
        font-size: 8px;
      }

      .link {
        font-size: 8px;
      }

      .ant-rate-star:not(:last-child) {
        margin-right: 2px;
      }
    }

    /deep/ .ant-table {

    }

    /deep/ .ant-table-tbody > tr > td {
      font-size: 8px !important;
      padding: 2px !important;
      text-align: center;
    }

    /deep/ .ant-table-thead > tr > th {
      font-size: 10px !important;
      padding: 2px 6px !important;
      font-weight: 400;
      background: unset;
    }
  }
</style>
