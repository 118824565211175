<template>
  <a-modal title=""
           :closable="true"
           :maskClosable="false"
           :footer="null"
           v-model="showPopupMarketNotification"
           :width="600">
    <div class="page container-bg-white">
      <div class="rows is-mobile">
        <h1 class="title">
          <a-icon type="notification" />
          <span class="title-text">Thông báo</span>:
        </h1>
        <div class="">
          <div class="content">
            <a class="main-content">Chức năng này được cung cấp bởi Metric Ecom Việt</a>
          </div>
          <div class="button-action">
            <a-button @click="onClickButton" size="large" type="primary">Tới Metric Ecom Việt</a-button>
          </div>
        </div>
      </div>
    </div>
  </a-modal>
</template>

<script>
import {MutationGeneral} from "@/store/modules/GeneralModule/mutation";
import {URL_WEB} from "@/config/ConfigEndpoint";

export default {
  name: "PopupMarketNotification",
  computed: {
    showPopupMarketNotification: {
      get() {
        return this.$store.state.GeneralModule.showPopupMarketNotification
      },
      async set(value) {
        await this.$store.commit(`GeneralModule/${MutationGeneral.setShowPopupMarketNotification}`, value)
      }
    },
  },
  methods: {
    onClickButton () {
      window.open(URL_WEB, '_top')
      this.showPopupMarketNotification = false
    },
    onClickIgnore () {
      this.showPopupMarketNotification = false
    }
  },
}
</script>

<style lang="scss" scoped>
  .title-text {
    margin-left: 4px;
  }

  .content {
    margin-bottom: 24px;

    .main-content {
      font-size: 20px;
      color: $--color-primary;
    }

    .sub-content {
      width: 100%;
      text-align: end;

      a {
        color: gray;
        text-decoration: underline;
      }
    }
  }

  .button-action {
    display: flex;
    justify-content: flex-end;
    align-items: flex-end;

    button {
      margin-left: 10px;
    }

    .sub-text-btn {
      font-size: 10px;
    }
  }
</style>
