var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"market-view-iframe"},[_vm._m(0),_c('SearchFilter',{on:{"search":_vm.onCLickSearch,"reset":_vm.onCLickReset}}),(_vm.permissionAvailableName)?_c('div',{staticStyle:{"text-align":"center","margin-top":"16px"}},[(
        (_vm.waitingTransaction && _vm.waitingTransaction.length > 0) ||
        (_vm.updatedTransaction && _vm.updatedTransaction.length > 0)
      )?[(_vm.waitingTransaction && _vm.waitingTransaction.length === 1)?_c('p',[_vm._v(" Bạn đang có "+_vm._s(_vm.waitingTransaction.length)+" lệch chờ xác nhận thanh toán, "),_c('span',{staticClass:"text-highlight",staticStyle:{"cursor":"pointer","text-decoration":"underline"},on:{"click":function($event){return _vm.onClickShowPaymentDetail(
              _vm.waitingTransaction[0].voucher_id,
              _vm.waitingTransaction[0].voucher_code
            )}}},[_vm._v("Xem chi tiết")])]):_vm._e(),(_vm.waitingTransaction && _vm.waitingTransaction.length > 1)?_c('p',[_vm._v(" Bạn đang có "+_vm._s(_vm.waitingTransaction.length)+" lệch chờ xác nhận thanh toán, "),_c('span',{staticClass:"text-highlight",staticStyle:{"cursor":"pointer","text-decoration":"underline"},on:{"click":_vm.onClickShowPaymentDetail}},[_vm._v("Xem chi tiết")])]):_vm._e(),(_vm.updatedTransaction && _vm.updatedTransaction.length === 1)?[(_vm.updatedTransaction[0].isComplete)?_c('p',[_vm._v(" Bạn đã thanh toán thành công và được áp dụng gói cước, "),_c('span',{staticClass:"text-highlight",staticStyle:{"cursor":"pointer","text-decoration":"underline"},on:{"click":function($event){return _vm.onClickShowPaymentDetail(
                _vm.updatedTransaction[0].voucher_id,
                _vm.updatedTransaction[0].voucher_code
              )}}},[_vm._v("Xem chi tiết")])]):_c('p',[_vm._v(" Có lỗi xảy ra trong việc thanh toán gói cước của bạn, "),_c('span',{staticClass:"text-highlight",staticStyle:{"cursor":"pointer","text-decoration":"underline"},on:{"click":function($event){return _vm.onClickShowPaymentDetail(
                _vm.updatedTransaction[0].voucher_id,
                _vm.updatedTransaction[0].voucher_code
              )}}},[_vm._v("Xem chi tiết")])])]:(_vm.updatedTransaction && _vm.updatedTransaction.length > 1)?[_c('p',[_vm._v(" "+_vm._s(_vm.updatedTransaction.length)+" Thanh toán của bạn đã được cập nhật trạng thái, "),_c('span',{staticClass:"text-highlight",staticStyle:{"cursor":"pointer","text-decoration":"underline"},on:{"click":_vm.onClickShowPaymentDetail}},[_vm._v("Xem chi tiết")])])]:_vm._e()]:_vm._e()],2):_vm._e(),(_vm.responseStatus && _vm.permissionAvailableName)?_c('a-spin',{staticClass:"report-content container-bg-white container-space-default border-solid",attrs:{"spinning":_vm.isClickSearch,"id":"report-wrap"}},[(
        !_vm.isClickSearch &&
        _vm.durationComparisonArray &&
        _vm.durationComparisonArray.length
      )?_c('div',{staticClass:"duration-range-comparison-wrap"},[(
          _vm.isBusinessAccount ||
          _vm.isHigherPriorityAccount(_vm.PERMISSION.market_standard.priority)
        )?_c('div',{staticClass:"duration-range-comparison"},[_c('span',[_vm._v("So sánh với")]),_c('div',{staticClass:"duration-range-comparison-option-container"},_vm._l((_vm.durationComparisonArray),function(item,index){return _c('div',{key:index,staticClass:"duration-range-comparison-option",class:{
              active: _vm.durationCompareIndex === index,
              disabled:
                !_vm.isBusinessAccount &&
                !_vm.isHigherPriorityAccount(_vm.PERMISSION.market_standard.priority),
            },on:{"click":function($event){return _vm.onClickComparison(index)}}},[_vm._v(" "+_vm._s(item.label)+" ")])}),0)]):_vm._e()]):_vm._e(),_c('div',{staticClass:"relative"},[_c('h2',{staticClass:"page-title"},[_vm._v("Báo cáo Phân tích thị trường")])]),(
        !_vm.isClickSearch &&
        _vm.isBusinessAccount &&
        _vm.durationRangeOptionArray &&
        _vm.durationRangeOptionArray[0] &&
        _vm.durationRangeOptionArray[1]
      )?_c('h2',{staticClass:"page-sub-title"},[(
          !_vm.durationRangeOptionArray[0].isSame(_vm.durationRangeOptionArray[1])
        )?[_vm._v(" Từ "+_vm._s(_vm.durationRangeOptionArray[0].format("DD-MM-YYYY"))+" đến "+_vm._s(_vm.durationRangeOptionArray[1].format("DD-MM-YYYY"))+" ")]:[_vm._v(" Cho ngày "+_vm._s(_vm.durationRangeOptionArray[0].format("DD-MM-YYYY"))+" ")]],2):_vm._e(),(
        !_vm.isClickSearch &&
        _vm.isBusinessAccount &&
        typeof _vm.durationCompareIndex === 'number' &&
        _vm.durationComparisonArray &&
        _vm.durationComparisonArray.length
      )?_c('h2',{staticClass:"page-sub-title"},[(
          !_vm.durationComparisonArray[_vm.durationCompareIndex].value[0].isSame(
            _vm.durationComparisonArray[_vm.durationCompareIndex].value[1]
          )
        )?[_vm._v(" So sánh với "+_vm._s(_vm.durationComparisonArray[_vm.durationCompareIndex].value[0].format( "DD-MM-YYYY" ))+" đến "+_vm._s(_vm.durationComparisonArray[_vm.durationCompareIndex].value[1].format( "DD-MM-YYYY" ))+" ")]:[_vm._v(" So sánh với ngày "+_vm._s(_vm.durationComparisonArray[_vm.durationCompareIndex].value[0].format( "DD-MM-YYYY" ))+" ")]],2):_vm._e(),(!_vm.isClickSearch)?_c('div',{staticClass:"container-space-default border-solid"},[_c('StatisticGeneral',{on:{"mounted":_vm.onMountedStatisticGeneral}})],1):_vm._e(),(!_vm.isClickSearch)?_c('div',[_c('div',{staticClass:"container-space-default border-solid"},[_c('MarketChartV2',{attrs:{"is-comparison-mode":typeof _vm.durationCompareIndex === 'number',"is-search-demo-init":_vm.isSearchDemoInit}})],1)]):_vm._e()]):_vm._e(),(!_vm.responseStatus || !_vm.permissionAvailableName)?_c('div',{staticClass:"container-bg-white container-space-default border-solid"},[_c('a-row',{staticClass:"product-empty",attrs:{"gutter":24,"type":"flex","align":"middle"}},[_c('a-col',{attrs:{"span":24,"md":12,"lg":12,"xl":12,"xxl":12}},[_c('div',{staticClass:"content"},[_vm._v(" Phân tích thị trường dựa trên phân tích Big Data ")])]),_c('a-col',{attrs:{"span":24,"md":12,"lg":12,"xl":12,"xxl":12}},[_c('div',{},[_c('img',{staticClass:"img-responsive cover",attrs:{"src":require("@/assets/img/product/empty-product.svg")}})])])],1)],1):_vm._e(),_c('PopupChatToDownload',{attrs:{"source":_vm.popupDownloadVisible}}),(_vm.showPopupNotification === _vm.NOTIFICATION_TYPE.create_saved_report)?_c('PopupNotification',{attrs:{"title":"Tạo Báo cáo","contents":['Vui lòng thực hiện lệnh Phân tích và Lưu Báo cáo']},on:{"ok":function($event){return _vm.$router.push({ query: { popup: undefined } })},"cancel":function($event){return _vm.$router.push({ query: { popup: undefined } })}}}):_vm._e()],1)
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"container-bg-white container-space-default text-black flex items-center justify-space-between"},[_c('div',{staticClass:"text-lg font-medium"},[_vm._v(" Số liệu Thị trường TMĐT "),_c('span',{staticClass:"text-sm font-normal"},[_vm._v("- Cung cấp bởi BeeCost Market")])]),_c('div',{staticClass:"text-base font-medium text-primary-500"},[_vm._v("Tư vấn chuyên sâu số liệu TMĐT miễn phí")])])
}]

export { render, staticRenderFns }