<template>
  <a-modal :closable="true"
           :footer="null"
           v-model="visibleModel"
           :maskClosable="false"
           :width="300"
           :bodyStyle="{padding: '12px'}"
           @cancel="$emit('close')"
  >
    <p style="font-size: 13px;" class="font-medium text-primary-500 mb-4">
      Sắp xếp
    </p>
    <a-radio-group v-model="sortMetricSelected">
      <template v-for="metricObject in sortMetricArray">
        <div v-if="metricObject" :key="metricObject.id" class="flex justify-start mb-3">
          <p class="text-xs mr-1" style="width: 85px;">{{metricObject.label}}:</p>
          <template v-for="metricOptionObject in metricObject.optionArray">
            <a-radio v-if="metricOptionObject" :value="`${metricObject.id}__${metricOptionObject.id}`" :key="`${metricObject.id}__${metricOptionObject.id}`">
              <span style="font-size: 10px">{{metricOptionObject.label}}</span>
            </a-radio>
          </template>
        </div>
      </template>
    </a-radio-group>
    <div class="w-full flex justify-end">
      <a-button class="relative" type="primary" size="large"
                @click="onClickApply"
                style="margin: 10px 0; font-size: 10px; height: 28px;"
      >
        <span>Áp dụng</span>
      </a-button>
    </div>
  </a-modal>
</template>

<script>
export default {
  name: "PopupSortMobile",
  props: {
    visible: {
      type: Boolean,
      default: false
    },
    sortMetricDefault: {
      type: String || undefined || null,
      default: null
    },
    sortMetricArray: {
      type: Array,
      default: null
    }
  },
  methods: {
    onClickApply() {
      this.$emit('select', this.sortMetricSelected)
    }
  },
  watch: {
    visible(value) {
      this.visibleModel = value
    },
    sortMetricDefault(value) {
      this.sortMetricSelected = value
    }
  },
  data() {
    return {
      sortMetricSelected: this.sortMetricDefault,
      visibleModel: this.visible
    }
  }
}
</script>

<style scoped>

</style>
