<template>
  <a-modal title="Bộ lọc nâng cao"
           :closable="true"
           :footer="null"
           v-model="showPopupSearchFilter"
           :maskClosable="true">
<!--    <a-row :gutter="[24, 24]">-->
<!--      <a-col :span="12">-->
<!--        <a-row :gutter="12">-->
<!--          <h2 class="atitle atitle-first">Ngành hàng</h2>-->
<!--          <a-select style="width: 100%" v-model="iCategoriesSelected">-->
<!--            <a-select-option :value="ALL_VALUE">Tất cả</a-select-option>-->
<!--            <template v-for="option in categoriesOption">-->
<!--              <a-select-option :value="option['value']" :key="option['value']">{{option['label']}}</a-select-option>-->
<!--            </template>-->
<!--          </a-select>-->
<!--        </a-row>-->
<!--        <a-row :gutter="12">-->
<!--          <h2 class="atitle">Vị trí Kho</h2>-->
<!--          <a-select style="width: 100%" v-model="iLocationWarehouses">-->
<!--            <a-select-option :value="ALL_VALUE">Tất cả</a-select-option>-->
<!--            <template v-for="location in locationsWarehouse">-->
<!--              <a-select-option :value="location" :key="location">{{location}}</a-select-option>-->
<!--            </template>-->
<!--          </a-select>-->
<!--        </a-row>-->
<!--        <a-row :gutter="12">-->
<!--          <h2 class="atitle">Người bán (Shop)</h2>-->
<!--          <div class="">-->
<!--            <a-checkbox v-model="iIsShopMall">Chính hãng (Mall)</a-checkbox>-->
<!--          </div>-->
<!--          <div class="">-->
<!--            <a-checkbox v-model="iIsShopNormal">Thông thường</a-checkbox>-->
<!--          </div>-->
<!--        </a-row>-->
<!--      </a-col>-->
<!--      <a-col :span="12">-->
<!--&lt;!&ndash;        <div class="d-flex">&ndash;&gt;-->
<!--&lt;!&ndash;          <h2 class="atitle">Bộ Lọc Giá</h2>&ndash;&gt;-->
<!--&lt;!&ndash;        </div>&ndash;&gt;-->

<!--        <a-row type="flex" align="middle">-->
<!--          <i aria-label="icon: price" class="margin-right-5 anticon">-->
<!--            <svg xmlns="http://www.w3.org/2000/svg" width="13" height="12"-->
<!--                 viewBox="0 0 13 12">-->
<!--              <g fill="none" fill-rule="evenodd" stroke="#696969" stroke-width=".25">-->
<!--                <path-->
<!--                  d="M11.833.721h-4.67c-.215 0-.52.225-.71.402L1.088 6.057a.731.731 0 0 0 0 1.096l4.171 3.84a.896.896 0 0 0 1.192 0l5.363-4.936c.209-.193.406-.483.406-.681V1.079c0-.197-.174-.358-.388-.358zm-1.508 3.965a1.79 1.79 0 0 1-2.383 0 1.465 1.465 0 0 1 0-2.193 1.79 1.79 0 0 1 2.383 0 1.465 1.465 0 0 1 0 2.193z"/>-->
<!--                <path-->
<!--                  d="M8.537 3.041a.733.733 0 0 0 0 1.097.896.896 0 0 0 1.192 0 .732.732 0 0 0 0-1.097.895.895 0 0 0-1.192 0z"/>-->
<!--                <path fill="#696969" fill-rule="nonzero"-->
<!--                      d="M12.221.005H6.774c-.43 0-.508.173-.918.57L.494 5.508a1.465 1.465 0 0 0 0 2.193l4.17 3.839a1.79 1.79 0 0 0 2.383 0l5.364-4.935c.344-.318.589-.476.589-.872V.721c0-.395-.349-.716-.779-.716zm-.406 6.052l-5.363 4.935a.896.896 0 0 1-1.192 0L1.09 7.153a.731.731 0 0 1 0-1.096l5.362-4.934c.191-.177.496-.402.711-.402h4.67c.214 0 .388.16.388.358v4.297c0 .198-.197.488-.406.681z"/>-->
<!--                <path fill="#696969" fill-rule="nonzero"-->
<!--                      d="M7.942 2.493a1.465 1.465 0 0 0 0 2.193 1.79 1.79 0 0 0 2.383 0 1.465 1.465 0 0 0 0-2.193 1.79 1.79 0 0 0-2.383 0zm1.787 1.645a.896.896 0 0 1-1.192 0 .733.733 0 0 1 0-1.097.895.895 0 0 1 1.192 0c.33.303.33.794 0 1.097z"/>-->
<!--              </g>-->
<!--            </svg>-->
<!--          </i>-->
<!--          Giá sản phẩm-->

<!--          <a-input-number style="width: 200px !important" v-model="iPriceMin"-->

<!--                          :step="10000"-->
<!--                          placeholder="Giá sản phẩm (min)"-->
<!--          />-->
<!--          <span class="margin-left-5 margin-right-5">-</span>-->
<!--          <a-input-number style="width: 200px !important" v-model="iPriceMax"-->

<!--                          :step="10000"-->
<!--                          placeholder="Giá sản phẩm (max)"-->

<!--          />-->
<!--        </a-row>-->

<!--        <a-row type="flex" align="middle" class="margin-top-10">-->
<!--          <i aria-label="icon: revenue" class="margin-right-5 anticon">-->

<!--            <svg xmlns="http://www.w3.org/2000/svg" width="13" height="13"-->
<!--                 viewBox="0 0 13 13">-->
<!--              <g fill="#696969" fill-rule="evenodd">-->
<!--                <path-->
<!--                  d="M8.313 8.042a1.517 1.517 0 0 0-.424-.439 2.446 2.446 0 0 0-.817-.355l-.191-.05-.174-.043V5.889c.163 0 .316.074.417.2a.753.753 0 0 1 .173.487h1.02a1.604 1.604 0 0 0-.159-.644 1.42 1.42 0 0 0-.347-.455 1.619 1.619 0 0 0-.5-.287 2.515 2.515 0 0 0-.605-.138V4.5H6.28v.552a2.356 2.356 0 0 0-.623.124 1.713 1.713 0 0 0-.532.294 1.39 1.39 0 0 0-.509 1.112c-.007.207.035.412.122.599.079.154.19.29.326.398.14.109.296.196.463.257.174.064.347.122.52.173.05.01.093.021.135.033.032.01.064.02.095.033V9.57a1.004 1.004 0 0 1-.552-.3.875.875 0 0 1-.212-.653H4.5c.049.563.222.99.52 1.281.347.309.793.484 1.257.493V11h.425v-.604c.307-.013.609-.088.885-.223a1.88 1.88 0 0 0 .55-.408A1.39 1.39 0 0 0 8.5 8.9a3.651 3.651 0 0 0-.026-.4 1.105 1.105 0 0 0-.161-.458zM6.28 7.06c-.178-.03-.348-.101-.494-.208a.454.454 0 0 1-.153-.37.585.585 0 0 1 .054-.261.52.52 0 0 1 .147-.185.64.64 0 0 1 .209-.113.837.837 0 0 1 .236-.034l.001 1.17zm1.128 2.108a.763.763 0 0 1-.173.211.85.85 0 0 1-.252.132c-.087.03-.177.05-.269.06V8.193c.21.046.411.131.59.25a.5.5 0 0 1 .174.42.586.586 0 0 1-.07.3v.005z"/>-->
<!--                <path fill-rule="nonzero" stroke="#696969" stroke-width=".25"-->
<!--                      d="M11.183 4.752c-.95-1.17-2.083-1.95-3.266-2.282.3-.116.616-.274.916-.534l.017-.015.95-.939c.2-.159.267-.39.183-.606C9.867.144 9.617 0 9.317 0h-.05L6.983.26A7.637 7.637 0 0 1 4.55.173L3.767.014C3.717 0 3.667 0 3.617 0c-.267.014-.434.101-.534.246-.15.23-.033.49.084.664 0 .014.016.029.033.043l.167.203c.1.115.2.23.3.332.4.404.9.736 1.466.982-1.2.318-2.333 1.098-3.3 2.282C.7 6.14 0 7.916 0 9.39v.144C0 11.816 3.1 13 6.167 13h.666C9.9 13 13 11.816 13 9.533V9.39c0-1.473-.7-3.25-1.817-4.637zM4.15 1.112c-.1-.086-.183-.188-.267-.289l-.15-.187c0-.015-.016-.03-.016-.044l.683.13a8.362 8.362 0 0 0 2.683.101L9.3.578v.014l-.967.94c-.416.346-.833.476-1.283.606-.167.043-.333.1-.5.159-.95-.174-1.8-.593-2.4-1.185zm8.183 8.421c0 2.11-3.366 2.89-5.5 2.89h-.666c-2.134 0-5.5-.78-5.5-2.89V9.39c0-3.438 3.166-6.5 5.666-6.5h.334c2.5 0 5.666 3.062 5.666 6.5v.144z"/>-->
<!--              </g>-->
<!--            </svg>-->

<!--          </i>-->
<!--          Doanh số-->
<!--          <a-input-number v-model="iRevenueMin"-->
<!--                          :placeholder="'Doanh số '+iDurationDay+' ngày (min)'"-->
<!--                          style="width: 200px !important"-->
<!--                          :step="100000"-->
<!--          />-->
<!--          <span class="margin-left-5 margin-right-5">-</span>-->
<!--          <a-input-number v-model="iRevenueMax"-->
<!--                          :placeholder="'Doanh số '+iDurationDay+' ngày (max)'"-->
<!--                          :step="100000"-->
<!--                          style="width: 200px !important"-->
<!--          />-->
<!--        </a-row>-->

<!--        <a-row type="flex" align="middle" class="margin-top-10">-->
<!--          <i aria-label="icon: sale" class="margin-right-5 anticon">-->
<!--            <svg xmlns="http://www.w3.org/2000/svg" width="10" height="14"-->
<!--                 viewBox="0 0 10 14">-->
<!--              <path fill="#696969" fill-rule="nonzero"-->
<!--                    d="M8.166 5.748a.38.38 0 0 0 .033-.045l1.052-1.4c.362-.482.3-1.153-.145-1.562a5.595 5.595 0 0 0-2.335-1.28v-.287C6.77.526 6.242 0 5.587 0h-1.25a1.18 1.18 0 0 0-1.183 1.174v.275C1.426 1.989.292 3.366.292 5.053c0 1.248.515 2.078 1.184 2.64-.085.066-.16.146-.222.235L.214 9.393a1.169 1.169 0 0 0 .132 1.505c.77.763 1.71 1.277 2.808 1.541v.189c0 .649.53 1.173 1.183 1.173h1.25c.655 0 1.184-.525 1.184-1.173v-.226C8.58 11.865 9.7 10.47 9.7 8.642c0-1.441-.702-2.332-1.534-2.894zm-2.578 5.694v1.187h-1.25v-1.187c-1.397-.12-2.421-.647-3.154-1.373l1.038-1.465A3.91 3.91 0 0 0 4.338 9.74V7.694c-1.372-.33-2.862-.844-2.862-2.64 0-1.333 1.065-2.468 2.862-2.653V1.175H5.59v1.254c1.076.118 1.996.514 2.714 1.174l-1.052 1.4a3.774 3.774 0 0 0-1.661-.859v1.82c1.384.343 2.927.871 2.927 2.679 0 1.466-.973 2.6-2.929 2.799zm0-3.444v1.768c.68-.132 1-.515 1-.937-.001-.436-.414-.647-1-.83zM3.42 4.91c0 .381.371.58.918.751V4.065c-.586.079-.918.395-.918.845z"/>-->
<!--            </svg>-->

<!--          </i>-->
<!--          Tổng đơn-->
<!--          <a-input-number  v-model="iOrderCountMin"-->
<!--                          :placeholder="'Tổng đơn '+iDurationDay+' ngày (min)'"-->
<!--                          :step="10"-->
<!--                          style="width: 200px !important"/>-->
<!--          <span class="margin-left-5 margin-right-5">-</span>-->
<!--          <a-input-number  v-model="iOrderCountMax"-->
<!--                          :placeholder="'Tổng đơn '+iDurationDay+' ngày (max)'"-->
<!--                          :step="10"-->
<!--                          style="width: 200px !important"-->
<!--          />-->
<!--        </a-row>-->
<!--      </a-col>-->
<!--    </a-row>-->
    <a-row :gutter="12" type="flex" justify="end">
      <a-col>
        <a-button type="primary" size="large" @click="onSearch">
          <a-icon type="search"/>
          Phân tích
        </a-button>
      </a-col>
    </a-row>
  </a-modal>
</template>

<script>
import {MutationSearchFilter} from "@/store/modules/SearchFilterModule/mutation";
import {ALL_CATEGORIES_BY_PLATFORM} from '@/constant/product/ProductCategoriesConstant'
import {locationsWarehouse} from "@/constant/product/LocationWarehouseConstant";
import {MutationGeneral} from "@/store/modules/GeneralModule/mutation";
import {FILTER_OPTION_DEFAULT} from "@/constant/general/PlatformConstant";

const ALL_VALUE = 'all'

export default {
  name: "PopupSearchFilter",
  data() {
    return {
      categoriesOption: ALL_CATEGORIES_BY_PLATFORM[FILTER_OPTION_DEFAULT.uniqueName],
      locationsWarehouse: locationsWarehouse,
      ALL_VALUE: ALL_VALUE
    }
  },
  computed: {
    showPopupSearchFilter: {
      get() {
        return this.$store.state.GeneralModule.showPopupSearchFilter
      },
      async set(value) {
        await this.$store.commit(`GeneralModule/${MutationGeneral.setShowPopupSearchFilter}`, value)
      }
    },
    iCategoriesSelected: {
      get() {
        return this.$store.state.SearchFilterModule.iCategoriesSelected || ALL_VALUE
      },
      async set(value) {
        await this.$store.commit(
          `SearchFilterModule/${MutationSearchFilter.iCategoriesSelected}`,
          value === ALL_VALUE ? undefined : [value]
        )
      }
    },
    iLocationWarehouses: {
      get() {
        return this.$store.state.SearchFilterModule.iLocationWarehouses || ALL_VALUE
      },
      async set(value) {
        await this.$store.commit(
          `SearchFilterModule/${MutationSearchFilter.iLocationWarehouses}`,
          value === ALL_VALUE ? undefined : [value]
        )
      }
    },
    // iKeywords: {
    //   get() {
    //     return this.$store.state.SearchFilterModule.iKeywords
    //   },
    //   async set(value) {
    //     await this.$store.commit(`SearchFilterModule/${MutationSearchFilter.setIKeywords}`, value)
    //   }
    // },
    iQueries: {
      get() {
        return this.$store.state.SearchFilterModule.iQueries
      },
      async set(value) {
        await this.$store.commit(`SearchFilterModule/${MutationSearchFilter.iQueries}`, value)
      }
    },
    iBrands: {
      get() {
        return this.$store.state.SearchFilterModule.iBrands
      },
      async set(value) {
        await this.$store.commit(`SearchFilterModule/${MutationSearchFilter.iBrands}`, value)
      }
    },
    iIsShopMall: {
      get() {
        return this.$store.state.SearchFilterModule.iIsShopMall
      },
      async set(value) {
        await this.$store.commit(`SearchFilterModule/${MutationSearchFilter.iIsShopMall}`, value)
      }
    },
    iIsShopNormal: {
      get() {
        return this.$store.state.SearchFilterModule.iIsShopNormal
      },
      async set(value) {
        await this.$store.commit(`SearchFilterModule/${MutationSearchFilter.iIsShopNormal}`, value)
      }
    },
    // iPlatforms: {
    //   get() {
    //     return this.$store.state.SearchFilterModule.iPlatforms
    //   },
    //   async set(value) {
    //     await this.$store.commit(`SearchFilterModule/${MutationSearchFilter.setIPlatforms}`, value)
    //   }
    // },
    iDurationDay: {
      get() {
        return this.$store.state.SearchFilterModule.iDurationDay
      },
      async set(value) {
        await this.$store.commit(`SearchFilterModule/${MutationSearchFilter.iDurationDay}`, value)
      }
    },

  },
  methods: {
    async onSearch() {
      this.showPopupSearchFilter = false
      await this.$store.dispatch(`SearchFilterModule/${MutationSearchFilter.SEARCH_PRODUCT}`)
      return
    }
  }
}
</script>

<style scoped>
  .atitle {
    margin-top: 12px;
  }

  .atitle-first {
    margin-top: 0;
  }
</style>
