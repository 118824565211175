<template>
  <div v-if="columns" :class="isMobile ? 'mobile-view' : ''">
    <a-spin :spinning="isLoading">
      <a-table
        v-if="dataSource"
        :tableLayout="isMobile ? 'fixed' : ''"
        :columns="columns"
        :rowKey="record => `${record.brand}_${record.platform_id}`"
        :dataSource="dataSource"
        :pagination="pagination"
        :loading="!brands"
        @change="handleTableChange"
      >

        <template slot="brand" slot-scope="brand, record">
          <span class="flex justify-start items-center text-left font-medium" style="height: 40px; padding-left: 6px; text-transform: capitalize">
            <span>{{ brand }}</span>
            <template v-if="typeof record.platform_logo_url === 'string'">
              <img v-if="record.platform_logo_url" :src="record.platform_logo_url" class="img-platform ml-2"/>
            </template>
            <template v-else-if="typeof record.platform_logo_url === 'object'">
              <template v-for="url in record.platform_logo_url">
                <img :key="url" v-if="url" :src="url" class="img-platform ml-2"/>
              </template>
            </template>
          </span>
        </template>

        <template slot="product_total" slot-scope="number">
          {{ number | formatNumberHuman }}
        </template>

        <template slot="order_count_7d" slot-scope="number">
          {{ number | formatNumberHuman }}
        </template>

        <template slot="order_revenue_7d" slot-scope="number">
          {{ number | formatCurrency }}
        </template>

        <template slot="order_count_30d" slot-scope="number">
          {{ number | formatNumberHuman }}
        </template>

        <template slot="order_revenue_30d" slot-scope="number">
          {{ number | formatCurrency }}
        </template>

        <template slot="order_count_90d" slot-scope="number">
          {{ number | formatNumberHuman }}
        </template>

        <template slot="order_revenue_90d" slot-scope="number">
          {{ number | formatCurrency }}
        </template>
        <template slot="order_count_180d" slot-scope="number">
          {{ number | formatNumberHuman }}
        </template>

        <template slot="order_revenue_180d" slot-scope="number">
          {{ number | formatCurrency }}
        </template>

        <template slot="order_count_selected" slot-scope="number">
          {{ number | formatNumberHuman }}
        </template>

        <template slot="order_revenue_selected" slot-scope="number">
          {{ number | formatCurrency }}
        </template>

        <template slot="review_count_7d" slot-scope="number">
          {{ number | formatNumberHuman }}
        </template>

        <template slot="review_count_30d" slot-scope="number">
          {{ number | formatNumberHuman }}
        </template>

        <template slot="review_count_90d" slot-scope="number">
          {{ number | formatNumberHuman }}
        </template>
        <template slot="review_count_180d" slot-scope="number">
          {{ number | formatNumberHuman }}
        </template>
        <template slot="rating_count" slot-scope="number">
          {{ number | formatNumberHuman }}
        </template>

        <template slot="view_count" slot-scope="number">
          {{ number | formatNumberHuman }}
        </template>

        <template slot="order_revenue" slot-scope="number">
          {{ number | formatCurrency }}
        </template>
        <template slot="order_count" slot-scope="number">
          {{ number | formatNumberHuman }}
        </template>
      </a-table>
    </a-spin>
    <Pagination :current-page="currentPage" :total-page="(100-100%rowCount)/rowCount"
                v-if="isMobile && this.loadType === 'pagination'"
                class="container-bg-white px-2 py-3 text-sm" style="color: #8C8C8C; font-size: 8px;"
                @change="onChangePage"
    />
  </div>
</template>

<script>
import {mixinStoreGetterGlobal} from "@/mixin/store/MixinGlobal";
import {mixinStoreGetterPermission} from "@/mixin/store/MixinUser";
import {mixinStoreGetterBrands} from "@/mixin/store/MixinBrands";
import {MutationSearchFilter} from "@/store/modules/SearchFilterModule/mutation";
import {PERMISSION} from "@/constant/general/PermissionConstant";
import MallFlag from "@/components/product/MallFlag";
import Pagination from "@/components/common/Pagination";
import {getPlatformByUrl} from "@/service/platform/PlatformCommonService";
import {generateColumnsBrandTable} from "@/service/market/MarketService";
import {formatCurrency, formatNumberHuman} from "@/helper/FormatHelper";
import {transformBrands} from "@/service/search/SearchService";

export default {
  name: "TableBrand",
  components: {
    MallFlag,
    Pagination
  },
  props: {
    loadType: {
      default: 'pagination',
    },
    rowCount: {
      default: null
    },
    sortByProp: {
      default: null
    },
    isMultiplePlatform: {
      type: Boolean,
      default: false
    }
  },
  data() {
    let columns = []
    return {
      sortBy: null,
      isLoading: false,
      pagination: {},
      columns: columns,
      currentPage: 1
    }
  },
  async mounted() {
    this.pagination = this.loadType === 'pagination' && !this.isMobile
      ? {
        'current': 1,
        'pageSize': 10,
        'total': 100,
        'simple': true,
      }
      : false
    this.sortBy = this.iSortBy || (this.options?.durationDay !== 0 ? `order_revenue_${this.options.durationDay}d__desc` : 'order_revenue_custom__desc')
    let availableColumns = PERMISSION[this.permissionAvailableName]?.availableDataIndexBrandsTable || []
    let durationDay = this.$store.state.SearchFilterModule.options?.durationDay
    this.columns = generateColumnsBrandTable(durationDay).filter(item => availableColumns.includes(item.scopedSlots?.customRender || item.dataIndex))
    this.columns = this.columns.filter(item => item.dataIndex.indexOf('review') === -1 && item.dataIndex.indexOf('rating') === -1)

    if (this.isMobile) {
      this.columns = this.columns
        .filter(item => !['product_total'].includes(item.dataIndex))
        .map(item => {
          delete item.sorter
          // item.width = item.dataIndex === 'brand' ? '18%' : '18%'
          item.title = item.title.replace('ản phẩm', 'p')
          return item
        })
    }
    // }
    if (!this.brands?.length) {
      await this.onSearchBrands()
    }
  },
  methods: {
    getPlatformByUrl,
    async onSearchBrands() {
      // todo: update paging
      this.isLoading = true
      let optionsFilter = {...this.$store.state.SearchFilterModule.options}
      if (typeof optionsFilter.durationDay !== 'number') {
        return
      }
      optionsFilter['limit'] = this.pagination.total || 100
      optionsFilter['page'] = 1
      optionsFilter['sort_by'] = this.sortBy ? this.sortBy : optionsFilter.durationDay !== 0 ? `order_revenue_${optionsFilter.durationDay}d__desc` : 'order_revenue_custom__desc'
      if (optionsFilter.durationDay !== 0) {
        delete optionsFilter.start_date
        delete optionsFilter.end_date
      }
      await this.$store.dispatch(`SearchFilterModule/${MutationSearchFilter.SEARCH_BRANDS}`, optionsFilter)
      this.isLoading = false
    },
    async handleTableChange(pagination, filters, sorter) {
      const pager = { ...this.pagination }
      pager.current = pagination.current
      this.pagination = pager
      if (sorter != null && Object.keys(sorter).length > 0) {
        let sort = sorter?.['field']
        if (sorter['order'] === 'ascend') {
          sort += `__asc`
        } else if (sorter['order'] === 'descend') {
          sort += `__desc`
        } else {
          return
        }
        this.sortBy = sort
      }
      // await this.onSearchBrands()
    },
    onChangePage(page) {
      this.currentPage = page
    },
  },
  computed: {
    iDurationDay: {
      get() {
        return this.$store.state.SearchFilterModule.iDurationDay
      },
    },
    iPlatforms: {
      get() {
        return this.$store.state.SearchFilterModule.iPlatforms
      }
    },
    iSortBy: {
      get() {
        return this.$store.state.SearchFilterModule.iSortBy
      }
    },
    options: {
      get() {
        return this.$store.state.SearchFilterModule.options
      }
    },
    brandNormalize() {
      if (!this.brands?.length) {
        return []
      }
      let brandNormalize = this.brands
      // let dataSource = this.isMultiplePlatform
      //   ? this.transformBrandsFromMultiplePlatform(this.brands)
      //   : this.brands
      brandNormalize = transformBrands(brandNormalize)
      brandNormalize.forEach(item => {
        if (item.platforms?.length > 1) {
          item.children = item.platforms
        }
      })
      return brandNormalize
    },
    brandNormalizeSorted() {
      let brandNormalizeSorted = this.brandNormalize
      if (!this.sortBy) {
        return brandNormalizeSorted
      }
      let [sortField, sortDirection] = this.sortBy.split('__')
      sortDirection = sortDirection === 'desc' ? -1 : 1
      brandNormalizeSorted.sort((item1, item2) => sortDirection * (item1[sortField] - item2[sortField]))
      return brandNormalizeSorted
    },
    dataSource() {
      let dataSource = this.brandNormalizeSorted
      if (this.rowCount) {
        dataSource = dataSource.slice((this.currentPage - 1)*this.rowCount, this.currentPage * this.rowCount)
      }
      return dataSource
    }
  },
  filters: {
    formatCurrency: formatCurrency,
    formatNumberHuman: formatNumberHuman,
  },
  mixins: [
    mixinStoreGetterGlobal,
    mixinStoreGetterBrands,
    mixinStoreGetterPermission
  ],
  watch: {
    permissionAvailableName() {
      let availableColumns = PERMISSION[this.permissionAvailableName]?.availableDataIndexBrandsTable || []
      this.columns = generateColumnsBrandTable(this.iDurationDay).filter(item => availableColumns.includes(item.scopedSlots?.customRender || item.dataIndex))
    }
  }
}
</script>

<style lang="scss" scoped>
.img-platform {
  border-radius: 100%;
  width: 20px;
  height: 20px;
}

/deep/ .ant-table-tbody > tr > td:first-child {
  display: flex;
  align-items: center;
}
.mobile-view {
  .icon-mall-wrap {
    height: 10px;
    width: 20px;
    display: inline-block;
  }
  .icon-mall {
    transform: scale(0.5, 0.5);
    transform-origin: bottom left;
  }

  .img-platform {
    width: 10px;
    height: 10px;
  }

  /deep/ .ant-table-tbody > tr > td {
    font-size: 8px !important;
    padding: 2px !important;
    text-align: center;
  }

  /deep/ .ant-table-thead > tr > th {
    font-size: 10px !important;
    padding: 2px 6px !important;
    font-weight: 400;
    background: unset;
  }
}
</style>
