const fbTrackingSearch = () => {
  try {
    if (!fbq) {
      return
    }
    fbq(
      'track',
      'Search'
    )
  } catch (e) {
    return
  }
}

const fbTrackingInitiateCheckout = (listProduct = [], productType = 'product') => {
  try {
    if (!fbq) {
      return
    }
    let content_ids = listProduct.map(product => product.id)
    let totalValue = 0
    listProduct.forEach(product => totalValue += product.quantity * product.price)
    fbq(
      'track',
      'InitiateCheckout',
      {
        'content_ids' : content_ids,
        'content_type': productType,
        'contents' : listProduct,
        'value' : totalValue,
        'currency' : 'VND',
        'num_items' : content_ids.length
      }
    );
  } catch (e) {
    return
  }
}

const fbTrackingPurchase = (listProduct = [], productType = 'product') => {
  try {
    if (!fbq) {
      return
    }
    let content_ids = listProduct.map(product => product.id)
    let totalValue = 0
    listProduct.forEach(product => totalValue += product.quantity * product.price)
    fbq(
      'track',
      'Purchase',
      {
        'content_ids' : content_ids,
        'content_type': productType,
        'contents' : listProduct,
        'value' : totalValue,
        'currency' : 'VND',
        'num_items' : content_ids.length
      }
    );
  } catch (e) {
    return
  }
}

const generateContentTrackingFb = (id, quantity, price) => {
  return {
    'id' : id,
    'quantity' : quantity,
    'price' : price
  }
}

export {
  fbTrackingSearch,
  fbTrackingInitiateCheckout,
  fbTrackingPurchase,
  generateContentTrackingFb
}
