<template>
  <svg width="12" height="12" viewBox="0 0 19 19" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g clip-path="url(#clip0_3537_9679)">
      <path d="M17.8724 10.062C17.9797 14.8632 13.7424 19.0983 8.94723 18.9991C4.27629 19.0241 0.257586 15.2103 0.0224256 10.5414C-0.349475 5.56258 3.95804 1.01757 8.9432 1.12491C9.05896 1.12491 9.14972 1.22014 9.14972 1.33152V9.85537H17.67C17.7813 9.85537 17.8724 9.94657 17.8724 10.062Z" fill="currentColor"/>
      <path d="M19 8.93707C19 9.04844 18.9093 9.13964 18.7935 9.13964H10.0667C9.95542 9.13964 9.86426 9.04885 9.86426 8.93707V0.206609C9.86426 0.0907952 9.95501 0 10.0667 0C14.9938 0 19 4.0079 19 8.93707Z" fill="currentColor"/>
    </g>
    <defs>
      <clipPath id="clip0_3537_9679">
        <rect width="19" height="19" fill="white"/>
      </clipPath>
    </defs>
  </svg>
</template>

<script>
export default {
  name: "IconPieChart"
}
</script>

<style scoped>

</style>
