<template>
  <div class="relative flex flex-row justify-space-between border"
       :style="{'backgroundColor': backgroundColor, 'borderColor': baseColor}"
       style="padding: 16px; border-radius: 20px;"
  >
    <div class="relative z-10 origin-value-container text-left flex-grow-1"
         style="color: #658099;"
    >
      <p class="font-medium" :class="!valueComparison ? 'text-base' : 'text-xs lg:text-base'">{{title}}</p>
      <p class="text-sm lg:text-xl value font-medium"
         :class="!valueComparison ? 'text-xl' : 'text-sm lg:text-xl'">
        {{value}}
      </p>
      <p v-if="valueComparison && value !== valueComparison"
         style="height: 20px"
         :style="toNumber(value) > toNumber(valueComparison) ? 'color: #00C259' : 'color: #F50000'"
      >
        <template v-if="toNumber(value) > toNumber(valueComparison)">
          <a-icon type="arrow-up" />
        </template>
        <template v-else>
          <a-icon type="arrow-down" />
        </template>
        <span class="text-sm lg:text-base">{{value | compareNumberByPercent(valueComparison, true) | abs}}%</span>
      </p>
    </div>
    <div class="relative z-10 comparison-value-container flex flex-col justify-start items-start flex-grow-1"
         style="color: #6B6B6B"
    >
      <template v-if="valueComparison">
        <p class="comparison-title text-xs lg:text-base">{{titleComparison}}</p>
        <p class="comparison-value font-medium text-sm lg:text-lg">{{valueComparison}}</p>
      </template>
    </div>
    <img class="platform-logo" :style="valueComparison ? 'right: calc(50% + 18px)' : 'right: 18px'" :src="urlLogo" alt="logo">
    <img v-if="valueComparison" class="platform-logo platform-logo-grayscale" style="right: 18px" :src="urlLogo" alt="logo">
  </div>
</template>
<script>
import {compareNumberByPercent, formatCurrency, formatNumberHuman} from "@/helper/FormatHelper";
import {toNumber} from "@/helper/StringHelper";
import {mixinStoreGetterGlobal} from "@/mixin/store/MixinGlobal";
import IconGrowChart from "@/assets/icon/IconGrowChart";

export default {
  name: "platformRectangle",
  props: {
    'value': null,
    'title': null,
    'valueComparison': null,
    'titleComparison': null,
    'baseColor': {
      type: String,
      validator: function (value) {
        return value[0] === '#' || value.substring(0, 3) === 'rgb'
      }
    },
    urlLogo: {
      type: String,
    }
  },
  computed: {
    backgroundColor() {
      return this.baseColor + '0d'
    }
  },
  components: {
    IconGrowChart
  },
  filters: {
    compareNumberByPercent,
    abs(value) {
      return Math.abs(value)
    },
    formatCurrency: formatCurrency,
    formatNumberHuman: formatNumberHuman,
  },
  mixins: [
    mixinStoreGetterGlobal
  ],
  methods: {
   toNumber,
  }
}
</script>

<style scoped lang="scss">
.platform-logo {
  position: absolute;
  top: 10px;
  z-index: 1;
  width: 52px;
  height: 52px;
  border-radius: 100%;
  opacity: 0.05;

  &-grayscale {
   filter: grayscale(100%);
  }
}
</style>
