import {mapGetters} from "vuex";
import {MutationSearchStatisticComparisonResult} from "@/store/modules/SearchStatisticComparisonResultModule/mutation";
import {ALL_CHART_TAB, ALL_METRIC} from "@/constant/general/GeneralConstant";

let gettersAgg = {}
for (const chartTabKey in ALL_CHART_TAB) {
  let chartTabStoreName = ALL_CHART_TAB[chartTabKey].storeName
  for (const metricKey in ALL_METRIC) {
    let metricStoreName = ALL_METRIC[metricKey].storeName
    gettersAgg[`${MutationSearchStatisticComparisonResult[chartTabStoreName][metricStoreName]}_comparison`] = `${MutationSearchStatisticComparisonResult.MODULE_PATH}/${MutationSearchStatisticComparisonResult[chartTabStoreName][metricStoreName]}`
  }
}
const mixinGetSearchStatisticAggComparisonResult = {
  computed: {
    ...mapGetters({
      comparisonStatus: `${MutationSearchStatisticComparisonResult.MODULE_PATH}/status`,
      textComparison: `${MutationSearchStatisticComparisonResult.MODULE_PATH}/textComparison`,
      comparisonAggRevenueByCategories: `${MutationSearchStatisticComparisonResult.MODULE_PATH}/aggRevenueByCategories`,
      comparisonAggRevenueByPriceRange: `${MutationSearchStatisticComparisonResult.MODULE_PATH}/aggRevenueByPriceRange`,
      comparisonAggRevenueByBrands: `${MutationSearchStatisticComparisonResult.MODULE_PATH}/aggRevenueByBrands`,
      comparisonAggRevenueByLocations: `${MutationSearchStatisticComparisonResult.MODULE_PATH}/aggRevenueByLocations`,
      comparisonAggOrderCountByCategories: `${MutationSearchStatisticComparisonResult.MODULE_PATH}/aggOrderCountByCategories`,
      comparisonAggOrderCountByPriceRange: `${MutationSearchStatisticComparisonResult.MODULE_PATH}/aggOrderCountByPriceRange`,
      comparisonAggOrderCountByBrands: `${MutationSearchStatisticComparisonResult.MODULE_PATH}/aggOrderCountByBrands`,
      comparisonAggOrderCountByLocations: `${MutationSearchStatisticComparisonResult.MODULE_PATH}/aggOrderCountByLocations`,
      comparisonAggCommissionByCategories: `${MutationSearchStatisticComparisonResult.MODULE_PATH}/aggCommissionByCategories`,
      comparisonAggCommissionByPriceRange: `${MutationSearchStatisticComparisonResult.MODULE_PATH}/aggCommissionByPriceRange`,
      comparisonAggCommissionByBrands: `${MutationSearchStatisticComparisonResult.MODULE_PATH}/aggCommissionByBrands`,
      comparisonAggCommissionByLocations: `${MutationSearchStatisticComparisonResult.MODULE_PATH}/aggCommissionByLocations`,
      ...gettersAgg
    })
  }
}

const mixinGetSearchStatisticGeneralComparisonResult = {
  computed: {
    ...mapGetters({
      comparisonStatus: `${MutationSearchStatisticComparisonResult.MODULE_PATH}/status`,
      textComparison: `${MutationSearchStatisticComparisonResult.MODULE_PATH}/textComparison`,
      comparisonOrderTotal: `${MutationSearchStatisticComparisonResult.MODULE_PATH}/orderTotal`,
      comparisonReviewTotal: `${MutationSearchStatisticComparisonResult.MODULE_PATH}/reviewTotal`,
      comparisonRevenueTotal: `${MutationSearchStatisticComparisonResult.MODULE_PATH}/revenueTotal`,
      comparisonProductTotal: `${MutationSearchStatisticComparisonResult.MODULE_PATH}/productTotal`,
      comparisonShopTotal: `${MutationSearchStatisticComparisonResult.MODULE_PATH}/shopTotal`,
      comparisonCommissionTotal: `${MutationSearchStatisticComparisonResult.MODULE_PATH}/commissionTotal`,
    })
  }
}

export {
  mixinGetSearchStatisticAggComparisonResult,
  mixinGetSearchStatisticGeneralComparisonResult
}
