<template>
  <svg width="12" height="12" viewBox="0 0 26 26" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M19.4548 19.9069C19.3943 19.9069 19.3347 19.8962 19.2761 19.8743L6.36603 14.909C6.17424 14.8354 6.0481 14.6506 6.0481 14.4453V8.48711C6.0481 8.28136 6.17424 8.09703 6.36603 8.02348L19.2761 3.05767C19.4301 2.99808 19.5996 3.01996 19.736 3.11213C19.871 3.2043 19.9515 3.35744 19.9515 3.52129V19.4098C19.9515 19.5736 19.871 19.7268 19.736 19.8189C19.6512 19.8771 19.554 19.9069 19.4548 19.9069ZM7.04146 14.1037L18.9581 18.6873V4.24466L7.04146 8.82831V14.1037Z" fill="currentColor" stroke="currentColor"/>
    <path d="M6.54539 14.9418H3.07001C1.9747 14.9418 1.08374 14.0509 1.08374 12.9556V9.97647C1.08374 8.88118 1.9747 7.99023 3.07001 7.99023H6.54585C6.82003 7.99023 7.04254 8.21274 7.04254 8.48691V14.4452C7.04207 14.7193 6.81956 14.9418 6.54539 14.9418ZM3.07001 8.98358C2.52165 8.98358 2.07711 9.42859 2.07711 9.97647V12.9556C2.07711 13.5039 2.52212 13.9485 3.07001 13.9485H6.04917V8.98358H3.07001Z" fill="currentColor" stroke="currentColor"/>
    <path d="M8.53164 21.8931H5.55247C5.30529 21.8931 5.09582 21.7115 5.06091 21.4662L4.06801 14.5151C4.02937 14.2442 4.2179 13.9929 4.49021 13.9542C4.75648 13.9203 5.0125 14.1041 5.05113 14.3764L5.98352 20.9007H7.97537L7.31204 14.9876C7.28225 14.7153 7.47776 14.4705 7.75007 14.4383C8.01308 14.4095 8.26723 14.6041 8.29935 14.8764L9.02413 21.341C9.03996 21.4821 8.99527 21.6222 8.90077 21.7274C8.80767 21.8326 8.67361 21.8931 8.53164 21.8931Z" fill="currentColor" stroke="currentColor"/>
    <path d="M21.4409 8.98275C21.2813 8.98275 21.1239 8.90548 21.028 8.76118C20.8763 8.53356 20.9377 8.22494 21.1663 8.07319L24.1455 6.08696C24.3717 5.93614 24.6808 5.99572 24.8335 6.22521C24.9852 6.45283 24.9238 6.76145 24.6952 6.9132L21.716 8.89943C21.6318 8.95576 21.5364 8.98275 21.4409 8.98275Z" fill="currentColor" stroke="currentColor"/>
    <path d="M24.4201 17.92C24.3247 17.92 24.2292 17.893 24.145 17.8367L21.1658 15.8505C20.9382 15.6987 20.8758 15.3896 21.0276 15.1625C21.1807 14.9339 21.4903 14.8734 21.7156 15.0242L24.6947 17.0105C24.9224 17.1622 24.9847 17.4713 24.833 17.6984C24.7371 17.8427 24.5802 17.92 24.4201 17.92Z" fill="currentColor" stroke="currentColor"/>
    <path d="M24.42 12.4602H21.4408C21.1666 12.4602 20.9441 12.2377 20.9441 11.9635C20.9441 11.6893 21.1666 11.4668 21.4408 11.4668H24.42C24.6941 11.4668 24.9166 11.6893 24.9166 11.9635C24.9166 12.2377 24.6941 12.4602 24.42 12.4602Z" fill="currentColor" stroke="currentColor"/>
  </svg>
</template>

<script>
export default {
  name: "IconNotification"
}
</script>

<style scoped>

</style>
