<template>
  <div v-if="isDesktop" class="metric-rectangle relative" :style="{'backgroundColor': bgColor}">
    <div class="flex">
<!--      <slot></slot>-->
      <div class="content" :style="`--bee-value-color: ${valueColor}`">
        <p class="title" :style="{'color': titleColor}">{{title}}</p>
        <p class="value" :style="{'color': valueColor}">
          {{value}}
        </p>
        <p v-if="valueComparison && value !== valueComparison"> ({{toNumber(value) > toNumber(valueComparison) ? "+" : ''}}{{value | compareNumberByPercent(valueComparison, true)}}%) </p>
        <div class="comparison-content" v-if="valueComparison">
          <p class="comparison-title" :style="{'color': 'gray'}">{{titleComparison}}</p>
          <p class="comparison-value">{{valueComparison}}</p>
        </div>
      </div>
    </div>
    <div v-show="typeof hasDetail === 'boolean'"
         @click="onClickDetail"
         class="absolute cursor-pointer bg-white border p-1 flex items-center justify-center hover:custom-transform"
         style="height: 42px; border-radius: 20px; border-color: #DEDEDE; bottom: -20px; right: 10px; left: 10px"
    >
      <p class="w-full h-full bg-white border p-2 text-center text-sm flex items-center justify-center line-clamp__1"
         style="border-radius: 16px"
         :style="detailColor ? `background-color: ${detailColor}; color: white;` : ``"
      >
        <span style="margin-bottom: 2px">
          <IconGrowChart class="mr-1"/>
          <span>Chi tiết tăng trưởng</span>
        </span>
      </p>
    </div>
  </div>
  <div v-else-if="isMobile" class="flex flex-row justify-space-between" :style="{'backgroundColor': bgColor}" style="height: 75px; width: 100%; padding: 10px; border-radius: 10px; font-size: 12px">
    <div class="content text-left" :style="`--bee-value-color: ${valueColor}`">
      <p class="title font-medium uppercase" style="color: black;">{{title}}</p>
      <p class="value" :style="{'color': valueColor}" style="font-size: 14px">
        {{value}}
      </p>
      <p v-if="valueComparison && value !== valueComparison">
        <template v-if="toNumber(value) > toNumber(valueComparison)">
          <a-icon type="arrow-up" />
        </template>
        <template v-else>
          <a-icon type="arrow-down" />
        </template>
        <span>{{value | compareNumberByPercent(valueComparison, true) | abs}}%</span>
      </p>
<!--      <span v-if="!valueComparison" v-show="hasDetail" class="cursor-pointer h-5 underline" @click="onClickDetail" style="font-size: 10px">Xem chi tiết tăng trưởng</span>-->
    </div>
    <div class="comparison-content flex flex-col justify-center items-start">
      <template v-if="valueComparison">
        <p class="comparison-title">{{titleComparison}}</p>
        <p class="comparison-value font-medium" :style="{'color': valueColor}">{{valueComparison}}</p>
      </template>
      <template v-if="typeof hasDetail === 'boolean'">
        <div class="flex w-full" style="height: 28px">
          <div class="cursor-pointer border text-cente h-full bg-white"
               @click="onClickDetail"
               style="font-size: 10px; border-color: #DEDEDE; border-radius: 14px;"
          >
            <p class="px-2 py-1 border flex items-center h-full line-clamp__1"
               :style="detailColor ? `background-color: ${detailColor}; color: white; border-radius: 14px;` : ``"
            >
              <IconGrowChart class="mr-0.5 mb-0.5 h-2"/>
              <span>Chi tiết tăng trưởng</span>
            </p>
          </div>
        </div>
      </template>
    </div>
  </div>
</template>
<script>
import {compareNumberByPercent, formatCurrency, formatNumberHuman} from "@/helper/FormatHelper";
import {toNumber} from "@/helper/StringHelper";
import {mixinStoreGetterGlobal} from "@/mixin/store/MixinGlobal";
import IconGrowChart from "@/assets/icon/IconGrowChart";

export default {
    props: {
      'value': null,
      'title': null,
      'valueComparison': null,
      'titleComparison': null,
      'bgColor': null,
      'valueColor': null,
      'titleColor': null,
      'detailColor': null,
      'hasDetail': null
    },
    components: {
      IconGrowChart
    },
    filters: {
      compareNumberByPercent,
      abs(value) {
        return Math.abs(value)
      },
      formatCurrency: formatCurrency,
      formatNumberHuman: formatNumberHuman,
    },
  mixins: [
    mixinStoreGetterGlobal
  ],
    methods: {
     toNumber,
      onClickDetail() {
       this.$emit('clickDetail')
      }
    }
  }
</script>

<style scoped lang="scss">
  .metric-rectangle {
    display: flex;
    flex-direction: column;
    box-shadow: 0 0 15px 0 rgba(220, 220, 220, 0.2);
    padding: 20px 10px 30px 10px;
    /*background: #e6f7ff;*/
    border-radius: 2px;
    transition: all .3s;

    .content {

      display: flex;
      flex-direction: column;

      .title {
        display: block;
        /*color: #1f1f1f;*/
        font-weight: 500;
        font-size: 1rem;
      }

      .value {
        display: block;
        /*color: #096dd9;*/
        font-weight: 600;
        font-size: 18px;
      }

      .comparison-content {
        font-size: 1rem;
        font-weight: normal;
        color: rgba(var(--bee-value-color), 0.5);

        .comparison-value {
          color: var(--bee-value-color);
        }
      }
    }
  }

  .hover\:custom-transform:hover {
    transform: scale(1.05);
  }
</style>
