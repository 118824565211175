<template>
  <div v-if="isMobile && tabStatistic">
    <div class="container-bg-white container-space-mobile-default">
      <div class="flex flex-row justify-start">
        <template v-for="tab in listTab">
          <div :key="tab.name" v-if="tab"
               class="rounded py-1 px-2 text-white mr-2 cursor-pointer"
               :class="tab.name === tabStatistic ? 'bg-primary-500' : 'bg-unselected'"
               @click="onChangeTab(tab.name)"
          >
              <span>
                <a-icon :type="tab.iconType" class="mr-2"/>
                {{getTabLabel(tab)}}
              </span>
          </div>
        </template>
      </div>
      <div class="d-flex justify-space-between mt-4 mb-1">
        <h2 class="title mobile-title">
          <span class="border-left-line mobile-view"></span>
          Phân tích thị trường theo {{listTab[tabStatistic] ? listTab[tabStatistic].label : ''}}
        </h2>
      </div>
      <a-spin :spinning="isLoadingTabData">
        <div
          v-if="getData(tabStatistic, allLocalMetric.platforms.storeName) != null
                && getData(tabStatistic, allLocalMetric.platforms.storeName).seriesData.length"
          class="flex flex-col justify-start items-center"
        >
          <div class="text-center">
            <p class="text-sm" style="color: #3B3B3B">{{getData(tabStatistic, allLocalMetric.platforms.storeName)['title']}}</p>
            <p class="text-xs mt-1" style="color: #8A8A8A">{{getData(tabStatistic, allLocalMetric.platforms.storeName)['subTitle']}}</p>
          </div>
          <div class="w-full flex flex-col">
            <div class="flex flex-row">
              <div v-if="getData(tabStatistic, allLocalMetric.platforms.storeName)['categoriesName'] && getData(tabStatistic, allLocalMetric.platforms.storeName)['seriesColor']">
                <div v-for="(platform, index) in getData(tabStatistic, allLocalMetric.platforms.storeName)['categoriesName']"
                     :key="platform"
                     class="flex flex-row space-x-1 items-center justify-start"
                >
                  <div class="w-4 h-4 rounded"
                       :style="{backgroundColor: getData(tabStatistic, allLocalMetric.platforms.storeName)['seriesColor'][index]}"
                  ></div>
                  <span>{{platform}}</span>
                </div>
              </div>
              <PieChartWithInnerText color="#ff754a"
                                     :categories-name="getData(tabStatistic, allLocalMetric.platforms.storeName)['categoriesName']"
                                     :category-title="getData(tabStatistic, allLocalMetric.platforms.storeName)['categoryTitle']"
                                     :series-data="getData(tabStatistic, allLocalMetric.platforms.storeName)['seriesData']"
                                     :series-title="getData(tabStatistic, allLocalMetric.platforms.storeName)['seriesTitle']"
                                     :series-color="getData(tabStatistic, allLocalMetric.platforms.storeName)['seriesColor']"
                                     :unit="getTabUnit(tabStatistic)"
                                     :chart-size="200"
              />
            </div>
            <div class="flex flex-row" v-if="getComparisonSeriesData(tabStatistic, allLocalMetric.platforms.storeName)">
              <div v-if="getComparisonCategoriesNameData(tabStatistic, allLocalMetric.platforms.storeName)">
                <div v-for="(platform, index) in getComparisonCategoriesNameData(tabStatistic, allLocalMetric.platforms.storeName)"
                     :key="platform"
                     class="flex flex-row space-x-1 items-center justify-start"
                >
                  <div class="w-4 h-4 rounded"
                       :style="{backgroundColor: listColorAlternative[index]}"
                  ></div>
                  <span>{{platform}}</span>
                </div>
              </div>
              <PieChartWithInnerText color="#ff754a"
                                     :category-title="getData(tabStatistic, allLocalMetric.platforms.storeName)['categoryTitle']"
                                     :categories-name="getComparisonCategoriesNameData(tabStatistic, allLocalMetric.platforms.storeName)"
                                     :series-data="getComparisonSeriesData(tabStatistic, allLocalMetric.platforms.storeName)"
                                     :series-title="getComparisonSubTitleData(tabStatistic, allLocalMetric.platforms.storeName)"
                                     :series-color="listColorAlternative"
                                     :unit="getTabUnit(tabStatistic)"
                                     :chart-size="200"
              />
            </div>
          </div>
          <div class="w-full space-y-4">
            <PlatformRectangle v-for="(platform, index) in getData(tabStatistic, allLocalMetric.platforms.storeName)['categoriesName']"
                               :key="platform"
                               :title="platform"
                               :value="getData(tabStatistic, allLocalMetric.platforms.storeName)['seriesData'][index] | filterChartValue(tabStatistic)"
                               :title-comparison="`So với ${textComparison}`"
                               :value-comparison="(getComparisonSeriesData(tabStatistic, allLocalMetric.platforms.storeName) || {})[index] | filterChartValue(tabStatistic)"
                               :base-color="getData(tabStatistic, allLocalMetric.platforms.storeName)['seriesColor'][index]"
                               :url-logo="getData(tabStatistic, allLocalMetric.platforms.storeName)['seriesUrlLogo'][index]"
            />
          </div>
        </div>
      </a-spin>
    </div>
    <template v-if="!isLoadingTabData">
      <div v-if="getData(tabStatistic, allLocalMetric.categories.storeName) != null && getData(tabStatistic, allLocalMetric.categories.storeName).seriesData.length"
           class="container-bg-white container-space-mobile-default"
      >
        <div class="text-center">
          <p class="text-sm" style="color: #3B3B3B">{{getData(tabStatistic, allLocalMetric.categories.storeName)['title']}}</p>
          <p class="text-xs mt-1" style="color: #8A8A8A">{{getData(tabStatistic, allLocalMetric.categories.storeName)['subTitle']}}</p>
        </div>
        <BarChart class=""
                  :comparison-color="listColorAlternative"
                  :title="getData(tabStatistic, allLocalMetric.categories.storeName)['title']"
                  :sub-title="getData(tabStatistic, allLocalMetric.categories.storeName)['subTitle']"
                  :categories-name="getData(tabStatistic, allLocalMetric.categories.storeName)['categoriesName']"
                  :category-title="getData(tabStatistic, allLocalMetric.categories.storeName)['categoryTitle']"
                  :series-data="getData(tabStatistic, allLocalMetric.categories.storeName)['seriesData']"
                  :series-data-multiple-platform-by-categories="getData(tabStatistic, allLocalMetric.categories.storeName)['seriesDataMultiplePlatformByCategories']"
                  :platform-ids-included="getData(tabStatistic, allLocalMetric.categories.storeName)['platformIdsIncluded']"
                  :series-title="getData(tabStatistic, allLocalMetric.categories.storeName)['seriesTitle']"
                  :categories-comparison-name="getComparisonCategoriesNameData(tabStatistic, allLocalMetric.categories.storeName)"
                  :series-comparison-data="getComparisonSeriesData(tabStatistic, allLocalMetric.categories.storeName)"
                  :series-comparison-title="getComparisonSubTitleData(tabStatistic, allLocalMetric.categories.storeName)"
                  :series-comparison-data-multiple-platform-by-categories="getComparisonSeriesDataMultiplePlatformByCategories(tabStatistic, allLocalMetric.categories.storeName)"
                  :chart-height="300"
        />
        <div class="flex flex-row justify-space-between text-xs">
          <div class="w-1/2 grid grid-cols-2" style="max-width: 170px; row-gap: 8px" v-if="getData(tabStatistic, allLocalMetric.categories.storeName)['platformIdsIncluded']">
            <p style="visibility: hidden" class="col-span-full">{{getData(tabStatistic, allLocalMetric.categories.storeName)['subTitle']}}:</p>
            <div v-for="(platformId, index) in getData(tabStatistic, allLocalMetric.categories.storeName)['platformIdsIncluded']"
                 :key="platformId"
                 class="flex flex-row space-x-1 items-center justify-start"
            >
              <div class="w-4 h-4 rounded"
                   :style="{backgroundColor: getPlatformById(platformId).color}"
              ></div>
              <span>{{getPlatformById(platformId).name}}</span>
            </div>
          </div>
          <div class="w-1/2 grid grid-cols-2" style="max-width: 180px; row-gap: 8px" v-if="getData(tabStatistic, allLocalMetric.categories.storeName)['platformIdsIncluded'] && getComparisonSeriesDataMultiplePlatformByCategories(tabStatistic, allLocalMetric.categories.storeName)">
            <p class="col-span-full">{{getComparisonSubTitleData(tabStatistic, allLocalMetric.categories.storeName)}}:</p>
            <div v-for="(platformId, index) in getData(tabStatistic, allLocalMetric.categories.storeName)['platformIdsIncluded']"
                 :key="platformId"
                 class="flex flex-row space-x-1 items-center justify-start"
            >
              <div class="w-4 h-4 rounded"
                   :style="{backgroundColor: listColorAlternative[index]}"
              ></div>
              <span>{{getPlatformById(platformId).name}}</span>
            </div>
          </div>
        </div>
      </div>
      <div v-if="getData(tabStatistic, allLocalMetric.priceRange.storeName) != null && getData(tabStatistic, allLocalMetric.priceRange.storeName).seriesData.length"
           class="container-bg-white container-space-mobile-default"
      >
        <div class="text-center">
          <p class="text-sm" style="color: #3B3B3B">{{getData(tabStatistic, allLocalMetric.priceRange.storeName)['title']}}</p>
          <p class="text-xs mt-1" style="color: #8A8A8A">{{getData(tabStatistic, allLocalMetric.priceRange.storeName)['subTitle']}}</p>
        </div>
        <ColumnChart color="#ff754a"
                     :comparison-color="listColorAlternative"
                     :title="getData(tabStatistic, allLocalMetric.priceRange.storeName)['title']"
                     :sub-title="getData(tabStatistic, allLocalMetric.priceRange.storeName)['subTitle']"
                     :categories-name="getData(tabStatistic, allLocalMetric.priceRange.storeName)['categoriesName']"
                     :category-title="getData(tabStatistic, allLocalMetric.priceRange.storeName)['categoryTitle']"
                     :series-data="getData(tabStatistic, allLocalMetric.priceRange.storeName)['seriesData']"
                     :series-data-multiple-platform-by-categories="getData(tabStatistic, allLocalMetric.priceRange.storeName)['seriesDataMultiplePlatformByCategories']"
                     :platform-ids-included="getData(tabStatistic, allLocalMetric.priceRange.storeName)['platformIdsIncluded']"
                     :series-title="getData(tabStatistic, allLocalMetric.priceRange.storeName)['seriesTitle']"
                     :categories-comparison-name="getComparisonCategoriesNameData(tabStatistic, allLocalMetric.priceRange.storeName)"
                     :series-comparison-data="getComparisonSeriesData(tabStatistic, allLocalMetric.priceRange.storeName)"
                     :series-comparison-title="getComparisonSubTitleData(tabStatistic, allLocalMetric.priceRange.storeName)"
                     :series-comparison-data-multiple-platform-by-categories="getComparisonSeriesDataMultiplePlatformByCategories(tabStatistic, allLocalMetric.priceRange.storeName)"
                     :chart-height="300"
        />
        <div class="flex flex-row justify-space-between text-xs">
          <div class="w-1/2 grid grid-cols-2" style="max-width: 170px; row-gap: 8px" v-if="getData(tabStatistic, allLocalMetric.priceRange.storeName)['platformIdsIncluded']">
            <p style="visibility: hidden" class="col-span-full">{{getData(tabStatistic, allLocalMetric.priceRange.storeName)['subTitle']}}:</p>
            <div v-for="(platformId, index) in getData(tabStatistic, allLocalMetric.priceRange.storeName)['platformIdsIncluded']"
                 :key="platformId"
                 class="flex flex-row space-x-1 items-center justify-start"
            >
              <div class="w-4 h-4 rounded"
                   :style="{backgroundColor: getPlatformById(platformId).color}"
              ></div>
              <span>{{getPlatformById(platformId).name}}</span>
            </div>
          </div>
          <div class="w-1/2 grid grid-cols-2" style="max-width: 180px; row-gap: 8px" v-if="getData(tabStatistic, allLocalMetric.priceRange.storeName)['platformIdsIncluded'] && getComparisonSeriesDataMultiplePlatformByCategories(tabStatistic, allLocalMetric.priceRange.storeName)">
            <p class="col-span-full">{{getComparisonSubTitleData(tabStatistic, allLocalMetric.priceRange.storeName)}}:</p>
            <div v-for="(platformId, index) in getData(tabStatistic, allLocalMetric.priceRange.storeName)['platformIdsIncluded']"
                 :key="platformId"
                 class="flex flex-row space-x-1 items-center justify-start"
            >
              <div class="w-4 h-4 rounded"
                   :style="{backgroundColor: listColorAlternative[index]}"
              ></div>
              <span>{{getPlatformById(platformId).name}}</span>
            </div>
          </div>
        </div>
      </div>
      <div class="container-bg-white container-space-mobile-default" v-if="getData(tabStatistic, allLocalMetric.brands.storeName) != null && getData(tabStatistic, allLocalMetric.brands.storeName).seriesData.length">
        <div class="text-center">
          <p class="text-sm" style="color: #3B3B3B">{{getData(tabStatistic, allLocalMetric.brands.storeName)['title']}}</p>
          <p class="text-xs mt-1" style="color: #8A8A8A">{{getData(tabStatistic, allLocalMetric.brands.storeName)['subTitle']}}</p>
        </div>
        <PieChart color="#ff754a"
                  :title="getData(tabStatistic, allLocalMetric.brands.storeName)['title']"
                  :sub-title="getData(tabStatistic, allLocalMetric.brands.storeName)['subTitle']"
                  :categories-name="getData(tabStatistic, allLocalMetric.brands.storeName)['categoriesName']"
                  :category-title="getData(tabStatistic, allLocalMetric.brands.storeName)['categoryTitle']"
                  :series-data="getData(tabStatistic, allLocalMetric.brands.storeName)['seriesData']"
                  :series-title="getData(tabStatistic, allLocalMetric.brands.storeName)['seriesTitle']"
                  :categories-comparison-name="getComparisonCategoriesNameData(tabStatistic, allLocalMetric.brands.storeName)"
                  :series-comparison-data="getComparisonSeriesData(tabStatistic, allLocalMetric.brands.storeName)"
                  :series-comparison-title="getComparisonSubTitleData(tabStatistic, allLocalMetric.brands.storeName)"
                  :number-total="getData(tabStatistic, allLocalMetric.brands.storeName)['countTotal']"
                  :number-charting="getData(tabStatistic, allLocalMetric.brands.storeName)['countCharting']"
        />
      </div>
      <div class="container-bg-white container-space-mobile-default" v-if="getData(tabStatistic, allLocalMetric.location.storeName)!= null && getData(tabStatistic, allLocalMetric.location.storeName).seriesData.length">
        <div class="text-center">
          <p class="text-sm" style="color: #3B3B3B">{{getData(tabStatistic, allLocalMetric.location.storeName)['title']}}</p>
          <p class="text-xs mt-1" style="color: #8A8A8A">{{getData(tabStatistic, allLocalMetric.location.storeName)['subTitle']}}</p>
        </div>
        <PieChart color="#ff754a"
                  :title="getData(tabStatistic, allLocalMetric.location.storeName)['title']"
                  :sub-title="getData(tabStatistic, allLocalMetric.location.storeName)['subTitle']"
                  :categories-name="getData(tabStatistic, allLocalMetric.location.storeName)['categoriesName']"
                  :category-title="getData(tabStatistic, allLocalMetric.location.storeName)['categoryTitle']"
                  :series-data="getData(tabStatistic, allLocalMetric.location.storeName)['seriesData']"
                  :series-title="getData(tabStatistic, allLocalMetric.location.storeName)['seriesTitle']"
                  :categories-comparison-name="getComparisonCategoriesNameData(tabStatistic, allLocalMetric.location.storeName)"
                  :series-comparison-data="getComparisonSeriesData(tabStatistic, allLocalMetric.location.storeName)"
                  :series-comparison-title="getComparisonSubTitleData(tabStatistic, allLocalMetric.location.storeName)"
                  :platforms-supported="!!getData(tabStatistic, allLocalMetric.platforms.storeName) ? ['Shopee', 'Lazada'] : null"
        />
      </div>
      <div class="container-bg-white container-space-mobile-default"
           v-if="shops && shops.length > 1"
      >
        <div class="text-center">
          <p class="text-sm" style="color: #3B3B3B">{{`Thống kê ${numberShopCharting} Shop hàng đầu`}}</p>
          <p class="text-sm mt-1" style="color: #8A8A8A">{{`${
            getData(tabStatistic, allLocalMetric.categories.storeName)
              ? getData(tabStatistic, allLocalMetric.categories.storeName)['subTitle']
              : ''
          }`}}</p>
          <span style="visibility: hidden">Xem tổng quan</span>
        </div>
        <PieChart color="#ff754a"
                  :title="`Thống kê ${numberShopCharting} Shop hàng đầu`"
                  :sub-title="`${
                                      getData(tabStatistic, allLocalMetric.categories.storeName)
                                      ? getData(tabStatistic, allLocalMetric.categories.storeName)['subTitle']
                                      : ''
                                    }`"
                  :categories-name="getShopTitle()"
                  :category-title="'Shop'"
                  :series-data="getShop(tabStatistic)"
                  :series-title="allLocalChartTab[tabStatistic].label"
                  :categories-comparison-name="null"
                  :series-comparison-data="null"
                  :series-comparison-title="null"
                  :number-total="getShopTotalData(tabStatistic)"
                  :number-charting="getShopDrawChart(tabStatistic)"
                  :title-charting="`${allLocalChartTab[tabStatistic].label} của ${numberShopCharting} Shop hàng đầu`"
                  :title-un-charting="`${allLocalChartTab[tabStatistic].label} của ${formatNumberHuman(shopTotal - numberShopCharting)} shop còn lại`"
                  :unit="getTabUnit(tabStatistic)"
        />
      </div>
      <div class="container-bg-white container-space-mobile-default"
           v-if="shopMall && otherShop && getData(tabStatistic, allLocalMetric.shopType.storeName) != null
                            && getData(tabStatistic, allLocalMetric.shopType.storeName).seriesData.length"
      >
        <div class="text-center">
          <p class="text-sm" style="color: #3B3B3B">{{getData(tabStatistic, allLocalMetric.shopType.storeName)['title']}}</p>
          <p class="text-xs mt-1" style="color: #8A8A8A">{{getData(tabStatistic, allLocalMetric.shopType.storeName)['subTitle']}}</p>
        </div>
        <PieChart color="#ff754a"
                  :title="getData(tabStatistic, allLocalMetric.shopType.storeName)['title']"
                  :sub-title="getData(tabStatistic, allLocalMetric.shopType.storeName)['subTitle']"
                  :categories-name="getData(tabStatistic, allLocalMetric.shopType.storeName)['categoriesName']"
                  :category-title="getData(tabStatistic, allLocalMetric.shopType.storeName)['categoryTitle']"
                  :series-data="getData(tabStatistic, allLocalMetric.shopType.storeName)['seriesData']"
                  :series-title="getData(tabStatistic, allLocalMetric.shopType.storeName)['seriesTitle']"
                  :series-color="getData(tabStatistic, allLocalMetric.shopType.storeName)['seriesColor']"
                  :categories-comparison-name="getComparisonCategoriesNameData(tabStatistic, allLocalMetric.shopType.storeName)"
                  :series-comparison-data="getComparisonSeriesData(tabStatistic, allLocalMetric.shopType.storeName)"
                  :series-comparison-title="getComparisonSubTitleData(tabStatistic, allLocalMetric.shopType.storeName)"
                  :shop-mall="shopMall"
                  :other-shop="otherShop"
                  :unit="getTabUnit(tabStatistic)"
                  :platforms-supported="!!getData(tabStatistic, allLocalMetric.platforms.storeName) ? ['Shopee', 'Lazada', 'Sendo'] : null"
        />
      </div>
    </template>
  </div>
  <div v-else class="">
    <div class="d-flex justify-space-between">
      <h2 class="title">
        <span class="border-left-line"></span>
         Phân tích thị trường theo {{listTab[tabStatistic] ? listTab[tabStatistic].label : ''}}
      </h2>
    </div>
    <a-spin :spinning="isLoadingTabData">
      <a-tabs v-model="tabStatistic" size="large" type="card" class="tab-filter-option" @change="onChangeTab">
        <template v-for="tab in listTab">
          <a-tab-pane :key="tab.name" v-if="tab">
            <span slot="tab">
              <a-icon :type="tab.iconType"/>
              {{getTabLabel(tab)}}
            </span>
            <template v-if="tab.name === tabStatistic && !isLoadingTabData">
              <div class="market-chart"
                   v-if="getData(tab.name, allLocalMetric.platforms.storeName)
                 && getData(tab.name, allLocalMetric.platforms.storeName).seriesData.length"
              >
                <div class="w-full flex flex-row justify-space-between">
                  <div class="text-primary-500 font-medium space-x-2 flex flex-row items-center">
                    <IconPieChart width="19" height="19"/>
                    <span>Thị phần</span>
                  </div>
                  <div class="text-center">
                    <p class="text-xl" style="color: #3B3B3B">{{getData(tab.name, allLocalMetric.platforms.storeName)['title']}}</p>
                    <p class="text-sm mt-2" style="color: #8A8A8A">{{getData(tab.name, allLocalMetric.platforms.storeName)['subTitle']}}</p>
                  </div>
                  <div>
                    <span style="visibility: hidden">Xem tổng quan</span>
                  </div>
                </div>
                <div class="flex flex-row justify-space-between items-center">
                  <div class="w-1/2 flex flex-col">
                    <div class="flex flex-row">
                      <div v-if="getData(tab.name, allLocalMetric.platforms.storeName)['categoriesName'] && getData(tab.name, allLocalMetric.platforms.storeName)['seriesColor']">
                        <div v-for="(platform, index) in getData(tab.name, allLocalMetric.platforms.storeName)['categoriesName']"
                             :key="platform"
                             class="flex flex-row space-x-1 items-center justify-start"
                        >
                          <div class="w-4 h-4 rounded"
                               :style="{backgroundColor: getData(tab.name, allLocalMetric.platforms.storeName)['seriesColor'][index]}"
                          ></div>
                          <span>{{platform}}</span>
                        </div>
                      </div>
                      <PieChartWithInnerText color="#ff754a"
                                             :categories-name="getData(tab.name, allLocalMetric.platforms.storeName)['categoriesName']"
                                             :category-title="getData(tab.name, allLocalMetric.platforms.storeName)['categoryTitle']"
                                             :series-data="getData(tab.name, allLocalMetric.platforms.storeName)['seriesData']"
                                             :series-title="getData(tab.name, allLocalMetric.platforms.storeName)['seriesTitle']"
                                             :series-color="getData(tab.name, allLocalMetric.platforms.storeName)['seriesColor']"
                                             :unit="getTabUnit(tab.name)"
                                             :chart-size="270"
                      />
                    </div>
                    <div class="flex flex-row" v-if="getComparisonSeriesData(tab.name, allLocalMetric.platforms.storeName)">
                      <div v-if="getData(tab.name, allLocalMetric.platforms.storeName)['categoriesName'] && getData(tab.name, allLocalMetric.platforms.storeName)['seriesColor']">
                        <div v-for="(platform, index) in getData(tab.name, allLocalMetric.platforms.storeName)['categoriesName']"
                             :key="platform"
                             class="flex flex-row space-x-1 items-center justify-start"
                        >
                          <div class="w-4 h-4 rounded"
                               :style="{backgroundColor: listColorAlternative[index]}"
                          ></div>
                          <span>{{platform}}</span>
                        </div>
                      </div>
                      <PieChartWithInnerText color="#ff754a"
                                             :category-title="getData(tab.name, allLocalMetric.platforms.storeName)['categoryTitle']"
                                             :categories-name="getComparisonCategoriesNameData(tab.name, allLocalMetric.platforms.storeName)"
                                             :series-data="getComparisonSeriesData(tab.name, allLocalMetric.platforms.storeName)"
                                             :series-title="getComparisonSubTitleData(tab.name, allLocalMetric.platforms.storeName)"
                                             :series-color="listColorAlternative"
                                             :unit="getTabUnit(tab.name)"
                                             :chart-size="270"
                      />
                    </div>
                  </div>
                  <div class="w-1/2"
                       :class="getComparisonSeriesData(tab.name, allLocalMetric.platforms.storeName)
                              ? 'space-y-4'
                              : 'grid grid-cols-2 grid-flow-row gap-4 platforms-statistic-container-grid'
                            "
                  >
                    <PlatformRectangle v-for="(platform, index) in getData(tab.name, allLocalMetric.platforms.storeName)['categoriesName']"
                                       :key="platform"
                                       :title="platform"
                                       :value="getData(tab.name, allLocalMetric.platforms.storeName)['seriesData'][index] | filterChartValue(tab.name)"
                                       :title-comparison="`So với ${textComparison}`"
                                       :value-comparison="(getComparisonSeriesData(tab.name, allLocalMetric.platforms.storeName) || {})[index] | filterChartValue(tab.name)"
                                       :base-color="getData(tab.name, allLocalMetric.platforms.storeName)['seriesColor'][index]"
                                       :url-logo="getData(tab.name, allLocalMetric.platforms.storeName)['seriesUrlLogo'][index]"
                    />
                  </div>
                </div>
              </div>
              <div class="market-chart" v-if="getData(tab.name, allLocalMetric.categories.storeName) != null
                            && getData(tab.name, allLocalMetric.categories.storeName).seriesData.length"
              >
                <div class="w-full flex flex-row justify-space-between">
                  <div class="space-x-2 flex flex-row items-center">
                    <div v-if="getData(tab.name, allLocalMetric.categories.storeName)['platformIdsIncluded']">
                      <div v-for="(platformId, index) in getData(tab.name, allLocalMetric.categories.storeName)['platformIdsIncluded']"
                           :key="platformId"
                           class="flex flex-row space-x-1 items-center justify-start"
                      >
                        <div class="w-4 h-4 rounded"
                             :style="{backgroundColor: getPlatformById(platformId).color}"
                        ></div>
                        <span>{{getPlatformById(platformId).name}}</span>
                      </div>
                    </div>
                  </div>
                  <div class="text-center">
                    <p class="text-xl" style="color: #3B3B3B">{{getData(tab.name, allLocalMetric.categories.storeName)['title']}}</p>
                    <p class="text-sm mt-2" style="color: #8A8A8A">{{getData(tab.name, allLocalMetric.categories.storeName)['subTitle']}}</p>
                  </div>
                  <div>
                    <span style="visibility: hidden">Xem tổng quan</span>
                    <div v-if="getData(tab.name, allLocalMetric.categories.storeName)['platformIdsIncluded'] && getComparisonSeriesDataMultiplePlatformByCategories(tab.name, allLocalMetric.categories.storeName)">
                      <div v-for="(platformId, index) in getData(tab.name, allLocalMetric.categories.storeName)['platformIdsIncluded']"
                           :key="platformId"
                           class="flex flex-row space-x-1 items-center justify-start"
                      >
                        <div class="w-4 h-4 rounded"
                             :style="{backgroundColor: listColorAlternative[index]}"
                        ></div>
                        <span>{{getPlatformById(platformId).name}}</span>
                      </div>
                    </div>
                  </div>
                </div>
                <BarChart color="#096dd9"
                          :comparison-color="listColorAlternative"
                          :title="getData(tab.name, allLocalMetric.categories.storeName)['title']"
                          :sub-title="getData(tab.name, allLocalMetric.categories.storeName)['subTitle']"
                          :categories-name="getData(tab.name, allLocalMetric.categories.storeName)['categoriesName']"
                          :category-title="getData(tab.name, allLocalMetric.categories.storeName)['categoryTitle']"
                          :series-data="getData(tab.name, allLocalMetric.categories.storeName)['seriesData']"
                          :series-data-multiple-platform-by-categories="getData(tab.name, allLocalMetric.categories.storeName)['seriesDataMultiplePlatformByCategories']"
                          :platform-ids-included="getData(tab.name, allLocalMetric.categories.storeName)['platformIdsIncluded']"
                          :series-title="getData(tab.name, allLocalMetric.categories.storeName)['seriesTitle']"
                          :categories-comparison-name="getComparisonCategoriesNameData(tab.name, allLocalMetric.categories.storeName)"
                          :series-comparison-data="getComparisonSeriesData(tab.name, allLocalMetric.categories.storeName)"
                          :series-comparison-data-multiple-platform-by-categories="getComparisonSeriesDataMultiplePlatformByCategories(tab.name, allLocalMetric.categories.storeName)"
                          :series-comparison-title="getComparisonSubTitleData(tab.name, allLocalMetric.categories.storeName)"
                          :unit="getTabUnit(tab.name)"
                />
              </div>
              <div class="market-chart" v-if="getData(tab.name, allLocalMetric.priceRange.storeName) != null
                                && getData(tab.name, allLocalMetric.priceRange.storeName).seriesData.length"
              >
                <div class="w-full flex flex-row justify-space-between">
                  <div class="space-x-2 flex flex-row items-center mb-2">
                    <div v-if="getData(tab.name, allLocalMetric.priceRange.storeName)['platformIdsIncluded']">
                      <div v-for="(platformId, index) in getData(tab.name, allLocalMetric.priceRange.storeName)['platformIdsIncluded']"
                           :key="platformId"
                           class="flex flex-row space-x-1 items-center justify-start"
                      >
                        <div class="w-4 h-4 rounded"
                             :style="{backgroundColor: getPlatformById(platformId).color}"
                        ></div>
                        <span>{{getPlatformById(platformId).name}}</span>
                      </div>
                    </div>
                  </div>
                  <div class="text-center">
                    <p class="text-xl" style="color: #3B3B3B">{{getData(tab.name, allLocalMetric.priceRange.storeName)['title']}}</p>
                    <p class="text-sm mt-2" style="color: #8A8A8A">{{getData(tab.name, allLocalMetric.priceRange.storeName)['subTitle']}}</p>
                  </div>
                  <div>
                    <span style="visibility: hidden">Xem tổng quan</span>
                    <div v-if="getData(tab.name, allLocalMetric.priceRange.storeName)['platformIdsIncluded'] && getComparisonSeriesDataMultiplePlatformByCategories(tab.name, allLocalMetric.priceRange.storeName)">
                      <div v-for="(platformId, index) in getData(tab.name, allLocalMetric.priceRange.storeName)['platformIdsIncluded']"
                           :key="platformId"
                           class="flex flex-row space-x-1 items-center justify-start"
                      >
                        <div class="w-4 h-4 rounded"
                             :style="{backgroundColor: listColorAlternative[index]}"
                        ></div>
                        <span>{{getPlatformById(platformId).name}}</span>
                      </div>
                    </div>
                  </div>
                </div>
                <ColumnChart color="#ff754a"
                             :comparison-color="listColorAlternative"
                             :title="getData(tab.name, allLocalMetric.priceRange.storeName)['title']"
                             :sub-title="getData(tab.name, allLocalMetric.priceRange.storeName)['subTitle']"
                             :categories-name="getData(tab.name, allLocalMetric.priceRange.storeName)['categoriesName']"
                             :category-title="getData(tab.name, allLocalMetric.priceRange.storeName)['categoryTitle']"
                             :series-data="getData(tab.name, allLocalMetric.priceRange.storeName)['seriesData']"
                             :series-title="getData(tab.name, allLocalMetric.priceRange.storeName)['seriesTitle']"
                             :series-data-multiple-platform-by-categories="getData(tab.name, allLocalMetric.priceRange.storeName)['seriesDataMultiplePlatformByCategories']"
                             :platform-ids-included="getData(tab.name, allLocalMetric.priceRange.storeName)['platformIdsIncluded']"
                             :categories-comparison-name="getComparisonCategoriesNameData(tab.name, allLocalMetric.priceRange.storeName)"
                             :series-comparison-data="getComparisonSeriesData(tab.name, allLocalMetric.priceRange.storeName)"
                             :series-comparison-title="getComparisonSubTitleData(tab.name, allLocalMetric.priceRange.storeName)"
                             :series-comparison-data-multiple-platform-by-categories="getComparisonSeriesDataMultiplePlatformByCategories(tab.name, allLocalMetric.priceRange.storeName)"
                             :unit="getTabUnit(tab.name)"
                />
              </div>
              <a-row :gutter="24" type="flex">
                <a-col :span="24" :md="12" :lg="12" :xl="12" :xxl="12" class="" v-if="getData(tab.name, allLocalMetric.brands.storeName) != null && getData(tab.name, allLocalMetric.brands.storeName).seriesData.length">
                  <div class="market-chart"
                       v-if="getData(tab.name, allLocalMetric.brands.storeName) != null
                              && getData(tab.name, allLocalMetric.brands.storeName).seriesData.length"
                  >
                    <div class="text-center">
                      <p class="text-xl" style="color: #3B3B3B">{{getData(tab.name, allLocalMetric.brands.storeName)['title']}}</p>
                      <p class="text-sm mt-2" style="color: #8A8A8A">{{getData(tab.name, allLocalMetric.brands.storeName)['subTitle']}}</p>
                      <span style="visibility: hidden">Xem tổng quan</span>
                    </div>
                    <PieChart color="#ff754a"
                              :title="getData(tab.name, allLocalMetric.brands.storeName)['title']"
                              :sub-title="getData(tab.name, allLocalMetric.brands.storeName)['subTitle']"
                              :categories-name="getData(tab.name, allLocalMetric.brands.storeName)['categoriesName']"
                              :category-title="getData(tab.name, allLocalMetric.brands.storeName)['categoryTitle']"
                              :series-data="getData(tab.name, allLocalMetric.brands.storeName)['seriesData']"
                              :series-title="getData(tab.name, allLocalMetric.brands.storeName)['seriesTitle']"
                              :categories-comparison-name="getComparisonCategoriesNameData(tab.name, allLocalMetric.brands.storeName)"
                              :series-comparison-data="getComparisonSeriesData(tab.name, allLocalMetric.brands.storeName)"
                              :series-comparison-title="getComparisonSubTitleData(tab.name, allLocalMetric.brands.storeName)"
                              :number-total="getData(tab.name, allLocalMetric.brands.storeName)['countTotal']"
                              :number-charting="getData(tab.name, allLocalMetric.brands.storeName)['countCharting']"
                              :unit="getTabUnit(tab.name)"
                    />
                  </div>
                </a-col>
                <a-col :span="24" :md="12" :lg="12" :xl="12" :xxl="12" class="" v-if="getData(tab.name, allLocalMetric.location.storeName) != null && getData(tab.name, allLocalMetric.location.storeName).seriesData.length">
                  <div class="market-chart"
                       v-if="getData(tab.name, allLocalMetric.location.storeName) != null
                            && getData(tab.name, allLocalMetric.location.storeName).seriesData.length"
                  >
                    <div class="text-center">
                      <p class="text-xl" style="color: #3B3B3B">{{getData(tab.name, allLocalMetric.location.storeName)['title']}}</p>
                      <p class="text-sm mt-2" style="color: #8A8A8A">{{getData(tab.name, allLocalMetric.location.storeName)['subTitle']}}</p>
                      <span style="visibility: hidden">Xem tổng quan</span>
                    </div>
                    <PieChart color="#ff754a"
                              :title="getData(tab.name, allLocalMetric.location.storeName)['title']"
                              :sub-title="getData(tab.name, allLocalMetric.location.storeName)['subTitle']"
                              :categories-name="getData(tab.name, allLocalMetric.location.storeName)['categoriesName']"
                              :category-title="getData(tab.name, allLocalMetric.location.storeName)['categoryTitle']"
                              :series-data="getData(tab.name, allLocalMetric.location.storeName)['seriesData']"
                              :series-title="getData(tab.name, allLocalMetric.location.storeName)['seriesTitle']"
                              :categories-comparison-name="getComparisonCategoriesNameData(tab.name, allLocalMetric.location.storeName)"
                              :series-comparison-data="getComparisonSeriesData(tab.name, allLocalMetric.location.storeName)"
                              :series-comparison-title="getComparisonSubTitleData(tab.name, allLocalMetric.location.storeName)"
                              :unit="getTabUnit(tab.name)"
                              :platforms-supported="!!getData(tab.name, allLocalMetric.platforms.storeName) ? ['Shopee', 'Lazada'] : null"
                    />
                  </div>
                </a-col>
                <a-col :span="24" :md="12" :lg="12" :xl="12" :xxl="12" class="" v-if="shops && shops.length > 1">
                  <div class="market-chart">
                    <div class="text-center">
                      <p class="text-xl" style="color: #3B3B3B">{{`Thống kê ${numberShopCharting} Shop hàng đầu`}}</p>
                      <p class="text-sm mt-2" style="color: #8A8A8A">{{`${
                        getData(tab.name, allLocalMetric.categories.storeName)
                          ? getData(tab.name, allLocalMetric.categories.storeName)['subTitle']
                          : ''
                      }`}}</p>
                      <span style="visibility: hidden">Xem tổng quan</span>
                    </div>
                    <PieChart
                      color="#ff754a"
                      :title="`Thống kê ${numberShopCharting} Shop hàng đầu`"
                      :sub-title="`${
                              getData(tab.name, allLocalMetric.categories.storeName)
                              ? getData(tab.name, allLocalMetric.categories.storeName)['subTitle']
                              : ''
                            }`"
                      :categories-name="getShopTitle()"
                      :category-title="'Shop'"
                      :series-data="getShop(tab.name)"
                      :series-title="tab.label"
                      :categories-comparison-name="null"
                      :series-comparison-data="null"
                      :series-comparison-title="null"
                      :number-total="getShopTotalData(tab.name)"
                      :number-charting="getShopDrawChart(tab.name)"
                      :title-charting="`${tab.label} của ${numberShopCharting} Shop hàng đầu`"
                      :title-un-charting="`${tab.label} của ${formatNumberHuman(shopTotal - numberShopCharting)} shop còn lại`"
                      :unit="getTabUnit(tab.name)"
                    />
                  </div>
                </a-col>
                <a-col :span="24" :md="12" :lg="12" :xl="12" :xxl="12" class=""
                       v-if="shopMall && otherShop && getData(tab.name, allLocalMetric.shopType.storeName) != null
                            && getData(tab.name, allLocalMetric.shopType.storeName).seriesData.length"
                >
                  <div class="market-chart">
                    <div class="text-center">
                      <p class="text-xl" style="color: #3B3B3B">{{getData(tab.name, allLocalMetric.shopType.storeName)['title']}}</p>
                      <p class="text-sm mt-2" style="color: #8A8A8A">{{getData(tab.name, allLocalMetric.shopType.storeName)['subTitle']}}</p>
                      <span style="visibility: hidden">Xem tổng quan</span>
                    </div>
                    <PieChart
                      color="#ff754a"
                      :title="getData(tab.name, allLocalMetric.shopType.storeName)['title']"
                      :sub-title="getData(tab.name, allLocalMetric.shopType.storeName)['subTitle']"
                      :categories-name="getData(tab.name, allLocalMetric.shopType.storeName)['categoriesName']"
                      :category-title="getData(tab.name, allLocalMetric.shopType.storeName)['categoryTitle']"
                      :series-data="getData(tab.name, allLocalMetric.shopType.storeName)['seriesData']"
                      :series-title="getData(tab.name, allLocalMetric.shopType.storeName)['seriesTitle']"
                      :series-color="getData(tab.name, allLocalMetric.shopType.storeName)['seriesColor']"
                      :categories-comparison-name="getComparisonCategoriesNameData(tab.name, allLocalMetric.shopType.storeName)"
                      :series-comparison-data="getComparisonSeriesData(tab.name, allLocalMetric.shopType.storeName)"
                      :series-comparison-title="getComparisonSubTitleData(tab.name, allLocalMetric.shopType.storeName)"
                      :shop-mall="shopMall"
                      :other-shop="otherShop"
                      :unit="getTabUnit(tab.name)"
                      :platforms-supported="!!getData(tab.name, allLocalMetric.platforms.storeName) ? ['Shopee', 'Lazada', 'Sendo'] : null"
                    />
                  </div>
                </a-col>
              </a-row>
            </template>
          </a-tab-pane>
        </template>
      </a-tabs>
    </a-spin>
    <PopupMarketNotification/>
  </div>
</template>

<script>

  import TableProduct from '@/views/search/TableProduct'
  import {formatCurrency, formatNumberHuman} from '@/helper/FormatHelper'
  import SearchFilter from '@/views/search/SearchFilter'
  import BarChart from '@/components/chart/BarChart'
  import LineChart from '@/components/chart/LineChart'
  import ColumnChart from '@/components/chart/ColumnChart'
  import PieChart from '@/components/chart/PieChart'
  import BtnSearchFilter from "@/components/button/BtnSearchFilter";
  import {MutationGeneral} from "@/store/modules/GeneralModule/mutation";
  import PopupMarketNotification from "@/views/popup/PopupMarketNotification";
  import {
    ALL_CHART_TAB,
    ALL_GENERAL_STATISTIC,
    ALL_METRIC,
    ALL_PLATFORM_BASE_OBJECT,
    PARTNER_TYPE
  } from "@/constant/general/GeneralConstant";
  import {mixinGetSearchStatisticAggComparisonResult} from "@/mixin/store/MixinSearchStatisticComparisonResult";
  import {MutationSearchFilter} from "@/store/modules/SearchFilterModule/mutation";
  import {mixinGetSearchStatisticResult} from "@/mixin/store/MixinSearchStatisticResult";
  import {MutationSearchStatisticResult} from "@/store/modules/SearchStatisticResultModule/mutation";
  import {PLATFORM_CHART_TAB} from "@/constant/general/PlatformConstant";
  import {getPlatformById} from "@/service/platform/PlatformCommonService";
  import {MutationSearchStatisticComparisonResult} from "@/store/modules/SearchStatisticComparisonResultModule/mutation";
  import {mixinStoreGetterGlobal} from "@/mixin/store/MixinGlobal";
  import {mixinStoreGetterShops} from "@/mixin/store/MixinShops";
  import PieChartWithInnerText from "@/components/chart/PieChartWithInnerText";
  import IconPieChart from "@/assets/icon/IconPieChart";
  import PlatformRectangle from "@/components/statistic/metric/PlatformRectangle";
  import {sleep} from "@/helper/TimeHelper";

  export default {
    components: {
      BtnSearchFilter,
      SearchFilter,
      TableProduct,
      BarChart,
      LineChart,
      ColumnChart,
      PieChart,
      PopupMarketNotification,
      PieChartWithInnerText,
      IconPieChart,
      PlatformRectangle,
    },
    props: {
      partnerType: {
        default: null
      },
      isComparisonMode: {
        default: false
      },
      isSearchDemoInit: {
        default: false
      },
      showChart: {
        default: function () {
          return {
            isShowCategoriesChart: true,
            isShowPriceRangeChart: true,
            isShowBrandsChart: true,
            isShowLocationsChart: true,
          }
        }
      }
    },
    data() {
      return {
        tabStatistic: null,
        isInsightReportPage: this.$route.name === 'custom_market_view',
        PARTNER_TYPE: PARTNER_TYPE,
        allLocalMetric: ALL_METRIC,
        allLocalChartTab: ALL_CHART_TAB,
        PLATFORM_CHART_TAB: PLATFORM_CHART_TAB,
        isLoadingTabData: false,
        numberShopCharting: 10,
        listColorAlternative: ['#6B6B6B', '#8E8E8E', '#A7A7A7', '#E2E2E2'],
      }
    },
    async mounted() {
      this.tabStatistic = this.listTab[Object.keys(this.listTab)[0]].name
    },
    created() {
    },
    methods: {
      formatNumberHuman,
      getPlatformById,
      async onChangeTab(key) {
        this.$emit('changeTab', key)
        this.isLoadingTabData = true
        if (
          (key === 'commission' && this.partnerType === PARTNER_TYPE.delivery)
          || (key !== 'commission' && this.partnerType === PARTNER_TYPE.affiliate)
        ) {
          await this.$store.commit(`GeneralModule/${MutationGeneral.setShowPopupMarketNotification}`, true)
        }
        if (key !== 'revenue' && !this.listTab[key].isUpdatedData) {
          let optionsFilter = this.$store.state.SearchFilterModule.options
          let optionsStatistic = []
          switch (key) {
            case ALL_CHART_TAB.orderCount.name:
              optionsStatistic = await this.$store.dispatch(`${MutationSearchFilter.MODULE_PATH}/${MutationSearchFilter.BUILD_ORDER_COUNT_STATISTIC}`, optionsFilter)
              break
          }
          if (optionsStatistic?.length) {
            let options = {
              ...optionsFilter,
              'statistics': [
                ...optionsStatistic
              ]
            }
            options.type = MutationSearchFilter.SET_RESPONSE_SEARCH_PRODUCT
            await this.$store.commit(`SearchFilterModule/${MutationSearchFilter.isDemo}`, !this.isLogin)
            await this.$store.dispatch(`SearchFilterModule/${MutationSearchFilter.SEARCH_PRODUCT}`, options)
          }
        }
        if (key !== 'revenue' && !this.listTab[key].isUpdatedData && this.isComparisonMode) {
          let optionsFilter = this.$store.state.SearchFilterModule.options
          let durationRange = this.iDurationRangeComparison
          if (optionsFilter.durationDay) {
            let optionsText = JSON.stringify(optionsFilter)
            optionsText = optionsText.replaceAll(`${optionsFilter.durationDay}d`, 'custom')
            optionsText = optionsText.replaceAll(`${optionsFilter.durationDay}D`, 'CUSTOM')
            optionsFilter = JSON.parse(optionsText)
          }
          optionsFilter = {
            ...optionsFilter,
            'start_date': durationRange[0].format("YYYY MM DD").replaceAll(' ', ''),
            'end_date': durationRange[1].format("YYYY MM DD").replaceAll(' ', ''),
            'durationDay': 0
          }
          let optionsStatistic = []
          switch (key) {
            case ALL_CHART_TAB.orderCount.name:
              optionsStatistic = await this.$store.dispatch(`${MutationSearchFilter.MODULE_PATH}/${MutationSearchFilter.BUILD_ORDER_COUNT_STATISTIC}`, optionsFilter)
              break
          }
          if (optionsStatistic?.length) {
            let options = {
              ...optionsFilter,
              'statistics': [
                ...optionsStatistic
              ]
            }
            options.type = MutationSearchFilter.SET_RESPONSE_SEARCH_PRODUCT_COMPARISON
            await this.$store.dispatch(`SearchFilterModule/${MutationSearchFilter.SEARCH_PRODUCT}`, options)
          }
        }
        this.listTab[key].isUpdatedData = true
        this.tabStatistic = key
        setTimeout(() => this.isLoadingTabData = false, 0)
      },
      getData(tab, metric) {
        return this[MutationSearchStatisticResult[tab]?.[metric]]
      },
      getComparisonSeriesData(tab, metric) {
        return this[`${MutationSearchStatisticComparisonResult[tab]?.[metric]}_comparison`]?.['seriesData']
      },
      getComparisonSeriesDataMultiplePlatformByCategories(tab, metric) {
        return this[`${MutationSearchStatisticComparisonResult[tab]?.[metric]}_comparison`]?.['seriesDataMultiplePlatformByCategories']
      },
      getComparisonCategoriesNameData(tab, metric) {
        return this[`${MutationSearchStatisticComparisonResult[tab]?.[metric]}_comparison`]?.['categoriesName']
      },
      getComparisonSubTitleData(tab, metric) {
        return this[`${MutationSearchStatisticComparisonResult[tab]?.[metric]}_comparison`]?.['subTitle']
      },
      getTabLabel(tab) {
        return this.platformName === ALL_PLATFORM_BASE_OBJECT.lazada.name ? tab.label.replace('có lượt bán', 'có lượt đánh giá') : tab.label
      },
      getTabUnit(tab) {
        return ALL_GENERAL_STATISTIC[tab] ? ALL_GENERAL_STATISTIC[tab].unit : ''
      },
      getShopTitle() {
        return this.shops.map(shop => `${shop.shop_name || shop.shop_platform_id}` + (shop.platform_id ? ` - ${getPlatformById(shop.platform_id).name}` : ''))
          .slice(0, this.numberShopCharting)
      },
      getShop(tabName) {
        let metric = this.getMetricByTabName(tabName)
        return this.shops.map(shop => shop[metric]).slice(0, this.numberShopCharting)
      },
      getShopDrawChart(tabName) {
        let metric = this.getMetricByTabName(tabName)
        let shopSeriesData = this.shops.map(shop => shop[metric]).slice(0, this.numberShopCharting)
        return shopSeriesData.reduce((previousValue, currentValue) => previousValue + currentValue, 0)
      },
      getShopTotalData(tabName) {
        switch (tabName) {
          case 'revenue':
            return this.revenueTotal
          case 'orderCount':
            return this.orderTotal
        }
      },
      getMetricByTabName(tabName) {
        let durationDay = this.$store.state.SearchFilterModule.options?.durationDay
        switch (tabName) {
          case 'revenue':
            return durationDay ? `order_revenue_${durationDay}d` : `order_revenue_custom`
          case 'orderCount':
            return durationDay ? `order_count_${durationDay}d` : `order_count_custom`
        }
      },
      getSeriesDataByShopType(tabName) {
        switch (tabName) {
          case 'revenue':
            return [this.revenueByShopMall, this.revenueByOtherShop]
          case 'orderCount':
            return [this.orderByShopMall, this.orderByOtherShop]
        }
      },
      getCategoryNameByShopType(tabName) {
        let shopMallValue = 0
        let otherShopValue = 0
        switch (tabName) {
          case 'revenue':
            shopMallValue = this.revenueByShopMall
            otherShopValue = this.revenueByOtherShop
            break
          case 'orderCount':
            shopMallValue = this.orderByShopMall
            otherShopValue = this.orderByOtherShop
            break
        }
        return [
          `Shop Mall`,
          `Shop thường`
        ]
      },
    },
    computed: {
      iDurationRangeComparison: {
        get() {
          return this.$store.state.SearchFilterModule.iDurationRangeComparison
        }
      },
      options: {
        get() {
          return this.$store.state.SearchFilterModule.options
        }
      },
      platformName() {
        return getPlatformById(this.options?.include_query?.platforms?.[0]).name
      },
      listTab() {
        let listTab = {}
        let platform = getPlatformById(this.options?.include_query?.platforms?.[0])
        if (!platform) {
          return null
        }
        for (const chartTabKey in this.allLocalChartTab) {
          if (this.PLATFORM_CHART_TAB[platform.uniqueName].includes(chartTabKey)) {
            listTab[chartTabKey] = {
              'isUpdatedData': this.isSearchDemoInit,
              ...ALL_CHART_TAB[chartTabKey]
            }
          }
        }
        if (listTab.revenue) {
          listTab.revenue.isUpdatedData = true
        } else if (listTab.reviewCount) {
          listTab.reviewCount.isUpdatedData = true
        }
        return listTab
      },
      isLogin: {
        get() {
          return this.$store.state.UserModule.isLogin
        }
      },
    },
    mixins: [
      mixinStoreGetterGlobal,
      mixinGetSearchStatisticResult,
      mixinGetSearchStatisticAggComparisonResult,
      mixinStoreGetterShops,
    ],
    filters: {
      formatCurrency,
      formatNumberHuman,
      filterChartValue(value, tabStatisticName) {
        let filterValue
        switch (tabStatisticName) {
          case ALL_CHART_TAB.revenue.name:
          case ALL_CHART_TAB.commission.name:
            filterValue = formatCurrency
            break
          default:
            filterValue = formatNumberHuman
        }
        return filterValue(value)
      }
    },
    watch: {
      shops(value) {
        if (value) {
          this.numberShopCharting = value.length < this.numberShopCharting ? value.length : this.numberShopCharting
        }
      }
    }
  }
</script>

<style lang="scss">

  .tab-filter-option {
    .ant-tabs-nav {
      display: flex;
      justify-content: space-between
    }
  }

</style>

<style scoped lang="scss">
  .market-chart {
    padding: 20px;
    border: solid 0.5px #cfd8dc66;
    margin-top: 20px;
    margin-bottom: 20px;
  }

  .advance-feature {
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  .bg-unselected {
    background-color: #C4C4C4;
  }

  .platforms-statistic-container-grid {
    height: fit-content;
    margin: auto 0;
  }
</style>
