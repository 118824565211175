<template>
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M12 2C6.47768 2 2 6.47768 2 12C2 17.5223 6.47768 22 12 22C17.5223 22 22 17.5223 22 12C22 6.47768 17.5223 2 12 2ZM12 20.3036C7.41518 20.3036 3.69643 16.5848 3.69643 12C3.69643 7.41518 7.41518 3.69643 12 3.69643C16.5848 3.69643 20.3036 7.41518 20.3036 12C20.3036 16.5848 16.5848 20.3036 12 20.3036ZM13.0647 11.4821L12.4978 11.3504V8.35268C13.346 8.46875 13.8705 9 13.9598 9.65179C13.971 9.74107 14.0469 9.8058 14.1362 9.8058H15.1384C15.2433 9.8058 15.3259 9.71429 15.317 9.60938C15.1808 8.21875 14.0357 7.32589 12.5067 7.17188V6.44196C12.5067 6.34375 12.4263 6.26339 12.3281 6.26339H11.7009C11.6027 6.26339 11.5223 6.34375 11.5223 6.44196V7.17857C9.94196 7.33259 8.70536 8.20536 8.70536 9.83482C8.70536 11.3438 9.81696 12.0714 10.9844 12.3504L11.5357 12.4911V15.6763C10.5491 15.5446 9.99554 15.0179 9.8817 14.308C9.8683 14.2232 9.79241 14.1607 9.70536 14.1607H8.67411C8.5692 14.1607 8.48661 14.25 8.49554 14.3549C8.59598 15.5826 9.52679 16.7121 11.5134 16.8571V17.558C11.5134 17.6563 11.5938 17.7366 11.692 17.7366H12.3259C12.4241 17.7366 12.5045 17.6563 12.5045 17.5558L12.5 16.8482C14.2478 16.6942 15.4978 15.7589 15.4978 14.0804C15.4955 12.5313 14.5112 11.8393 13.0647 11.4821V11.4821ZM11.5335 11.1205C11.4085 11.0848 11.3036 11.0513 11.1987 11.0089C10.4442 10.7366 10.0938 10.2969 10.0938 9.72991C10.0938 8.91964 10.7076 8.45759 11.5335 8.35268V11.1205ZM12.4978 15.683V12.6987C12.567 12.7187 12.6295 12.7344 12.6942 12.7478C13.75 13.0692 14.1049 13.5156 14.1049 14.2009C14.1049 15.0737 13.4487 15.5982 12.4978 15.683Z"
      fill="currentColor"
    />
  </svg>
</template>

<script>
export default {
  name: "IconDollar",
};
</script>

<style scoped>
</style>
