import {mapGetters} from "vuex";
import {MutationSearchKeywordsResult} from "@/store/modules/SearchKeywordsResultModule/mutation";

const mixinStoreGetterKeywords = {
  computed: {
    ...mapGetters({
      keywords:  `${MutationSearchKeywordsResult.MODULE_PATH}/${MutationSearchKeywordsResult.keywords}`,
      products:  `${MutationSearchKeywordsResult.MODULE_PATH}/${MutationSearchKeywordsResult.products}`,
    })
  }
}

export {
  mixinStoreGetterKeywords
}
