const {apiGetShopDetail} = require("@/api/shop/ApiShop");

const getShopDetail = async (shopUrl, platformId) => {
  let data = {
    "shop_url": shopUrl,
    "search_platform_id": platformId
  }
  let shopDetail = await apiGetShopDetail(data)
  return shopDetail
}
const checkErrorShopUrl = async (shopUrl, platformId) => {
  let data = {
    "shop_url": shopUrl,
    "search_platform_id": platformId
  }
  let shopDetail = await apiGetShopDetail(data)
  if (!shopDetail) {
    return
  }
  if (!shopDetail.status) {
    return
  }
  return shopDetail.data?.message
}

export {
  getShopDetail,
  checkErrorShopUrl
}
