<template>
  <div>
    <table v-if="tableData" class="w-full" :class="isMobile ? 'category-table-mobile-view' : 'category-table'">
      <tr class="table-header gray-50">
        <th v-for="col in columns" :key="col.dataIndex">
          {{col.title}}
        </th>
      </tr>
      <tr class="table-content" :class="{'background-color': index%2===1}" v-for="(row, index) in tableData" :key="`${row.category_name}_${index}`">
        <td class="first-column">{{ row.category_name }}</td>
        <td>
          <p class="">
            {{ row.percent | compareNumberByPercent}}%
          </p>
<!--          <p v-if="row.percent"> (<template v-if="row.percent > row.percent_comparison">-->
<!--            <a-icon type="arrow-up" />-->
<!--          </template>-->
<!--            <template v-else>-->
<!--              <a-icon type="arrow-down" />-->
<!--            </template>{{row.percent - row.percent_comparison | compareNumberByPercent| abs}}%)</p>-->
          <p class="text-note">
            {{row.value | formatNumber(seriesTitle)}}
          </p>
<!--          <p class="text-note" v-if="row.value">-->
<!--            (<template v-if="row.value > row.value_comparison">-->
<!--            <a-icon type="arrow-up" />-->
<!--          </template>-->
<!--            <template v-else>-->
<!--              <a-icon type="arrow-down" />-->
<!--            </template>-->
<!--            <span>-->
<!--                {{row.value | compareNumberByPercent(row.value_comparison, true) | abs}}%-->
<!--              </span>)-->
<!--          </p>-->
        </td>
        <td>
          <p>{{ row.percent_comparison | compareNumberByPercent}}%</p>
          <p class="text-note">{{row.value_comparison | formatNumber(seriesTitle)}}</p>
        </td>
      </tr>
    </table>
    <slot name="show_more"></slot>
    <div class="note-box" v-if="numberTotal && numberCharting && isDisplayNote">
      <p class="charting-percent">Có {{categoryTitle}}: {{numberCharting | compareNumberByPercent(numberTotal)}}%</p>
      <p class="unknown-percent">Không xác định: {{ numberTotal - numberCharting | compareNumberByPercent(numberTotal)}}%</p>
    </div>
  </div>
</template>

<script>
import {mixinStoreGetterGlobal} from "@/mixin/store/MixinGlobal";
import {compareNumberByPercent, formatCurrency, formatNumberHuman} from "@/helper/FormatHelper";

export default {
  name: "TableStatistic",
  props: {
    title: null,
    subTitle: null,
    categoryTitle: null,
    tableData: null,
    columns: null,
    seriesTitle: null,
    numberTotal: null,
    numberCharting: null,
    isDisplayNote: false
  },
  filters: {
    compareNumberByPercent,
    formatNumber(value, seriesTitle) {
      if (seriesTitle === "Doanh số") {
        return formatCurrency(value)
      } else {
        return formatNumberHuman(value)
      }
    },
    abs(value) {
      return Math.abs(value)
    },
  },
  mixins: [
    mixinStoreGetterGlobal
  ]
}
</script>

<style lang="scss" scoped>

.chart-title {

  &.mobile-view {
    font-size: 14px;
    font-weight: normal;
  }
}

.category-table {
  width: 100%;
  margin-bottom: 12px;
  border-collapse: collapse;
  border-color: rgba(229, 231, 235, 1);
  border-top-width: 1px;
  border-bottom-width: 1px;
  border-radius: 0.5rem;
  --tw-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.1), 0 1px 2px 0 rgba(0, 0, 0, 0.06);
  box-shadow: 0 0 #0000, 0 0 #0000, var(--tw-shadow);

  .table-header {

    th {
      padding: 12px 24px;
      text-align: left;
      font-size: .875rem;
      line-height: 1.25rem;
      color: rgba(107,114,128,1);
      text-transform: uppercase;
    }
  }

  .table-content {
    &.background-color {
      @apply gray-50;
    }
    td {
      &.first-column {
        color: rgba(17,24,39,1);
        font-weight: 500;
      }

      padding: 12px 24px;
      text-align: left;
      font-size: .875rem;
      line-height: 1.25rem;
      color: rgba(107,114,128,1);

      .text-note {
        font-size: .75rem;
        color: rgba(107,114,128,0.75);
      }
    }
  }
}

.category-table-mobile-view {
  font-size: 12px;
  border-radius: 4px;

  .table-header {

    th {
      width: calc(100% / 3);
      padding: 10px 4px;
      text-align: left;
      font-size: 12px;
      line-height: 14px;
      color: #3B3B3B;
      font-weight: normal;
    }
  }

  .table-content {
    &.background-color {
      background-color: #F8F8F8;
    }
    td {
      color: #3B3B3B;
      padding: 10px 4px;
      text-align: left;
      font-size: 12px;
      line-height: 14px;

      .text-note {
        font-size: 9px;
        color: #717171;
      }
    }
  }

}

.note-box {

  .charting-percent {
    color: #096dd9;
  }
}


.gray-50 {
  background-color: rgba(249,250,251,1);
}

</style>
