<template>
  <a-button @click="onOpenFilterPopup" type="default" size="large" :icon="isLoading ? 'loading' : 'filter'" :ghost="isGhost">
    <span>{{text}}</span>
    <PopupSearchFilter/>
  </a-button>
</template>

<script>
import {MutationGeneral} from "@/store/modules/GeneralModule/mutation";
import PopupShareCustomQuery from "@/views/popup/PopupShareCustomQuery";
import PopupSearchFilter from "@/views/popup/PopupSearchFilter";

export default {
  name: "BtnSearchFilter",
  components: {
    PopupShareCustomQuery,
    PopupSearchFilter
  },
  props: {
    type: {
      default: 'default'
    },
    size: {
      default: 'large'
    },
    text: {
      default: 'Bộ lọc'
    },
    url: {
      default: document.location.href
    },
    isGhost: {
      default: false
    }
  },
  computed: {
    isLoading: {
      get() {
        return this.$store.state.SearchFilterModule.isLoading
      }
    },
  },
  methods: {
    async onOpenFilterPopup() {
      this.$store.dispatch(`GeneralModule/${MutationGeneral.setShowPopupSearchFilter}`, true)
    },
  }
}
</script>

<style scoped>

</style>
